import React, { useMemo, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Alert,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Dormitory = ({ val }) => {
    const navigate = useNavigate();

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const dormitoryData = [
        {
            name: "社宅A",
            number: 3001,
            address: "東京都千代田区1-1-1",
            occupant: "入居中",
            tenant: "桐ヶ谷 和人",
            division: "営業部",
        },
        {
            name: "社宅A",
            number: 3002,
            address: "東京都千代田区1-1-1",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅A",
            number: 3003,
            address: "東京都千代田区1-1-1",
            occupant: "入居中",
            tenant: "結城 明日奈",
            division: "営業部",
        },
        {
            name: "社宅B",
            number: 4001,
            address: "東京都新宿区2-2-2",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅B",
            number: 4002,
            address: "東京都新宿区2-2-2",
            occupant: "入居中",
            tenant: "朝田 詩乃",
            division: "営業部",
        },
        {
            name: "社宅B",
            number: 4003,
            address: "東京都新宿区2-2-2",
            occupant: "入居中",
            tenant: "シノン",
            division: "営業部",
        },
        {
            name: "社宅C",
            number: 5001,
            address: "東京都渋谷区3-3-3",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅C",
            number: 5002,
            address: "東京都渋谷区3-3-3",
            occupant: "入居中",
            tenant: "ユイ",
            division: "営業部",
        },
        {
            name: "社宅C",
            number: 5003,
            address: "東京都渋谷区3-3-3",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅D",
            number: 6001,
            address: "東京都港区4-4-4",
            occupant: "入居中",
            tenant: "リーファ",
            division: "営業部",
        },
        {
            name: "社宅D",
            number: 6002,
            address: "東京都港区4-4-4",
            occupant: "入居中",
            tenant: "シリカ",
            division: "営業部",
        },
        {
            name: "社宅D",
            number: 6003,
            address: "東京都港区4-4-4",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅E",
            number: 7001,
            address: "東京都品川区5-5-5",
            occupant: "入居中",
            tenant: "クライン",
            division: "営業部",
        },
        {
            name: "社宅E",
            number: 7002,
            address: "東京都品川区5-5-5",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅E",
            number: 7003,
            address: "東京都品川区5-5-5",
            occupant: "入居中",
            tenant: "エギル",
            division: "営業部",
        },
        {
            name: "社宅F",
            number: 8001,
            address: "東京都目黒区6-6-6",
            occupant: "入居中",
            tenant: "ユウキ",
            division: "営業部",
        },
        {
            name: "社宅F",
            number: 8002,
            address: "東京都目黒区6-6-6",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
        {
            name: "社宅F",
            number: 8003,
            address: "東京都目黒区6-6-6",
            occupant: "入居中",
            tenant: "アリス",
            division: "営業部",
        },
        {
            name: "社宅G",
            number: 9001,
            address: "東京都大田区7-7-7",
            occupant: "入居中",
            tenant: "ユージオ",
            division: "営業部",
        },
        {
            name: "社宅G",
            number: 9002,
            address: "東京都大田区7-7-7",
            occupant: "未入居",
            tenant: "なし",
            division: "営業部",
        },
    ];

    const [occupantStatus, setOccupantStatus] = useState(["入居中", "未入居"]);

    const columns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
            },
            {
                header: "住所",
                accessorKey: "address",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "入居区分",
                accessorKey: "occupant",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: occupantStatus,
            },
            {
                header: "入居者",
                accessorKey: "tenant",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "管轄部署",
                accessorKey: "division",
                muiTableBodyCellProps: { sx: { maxWidth: "200px" } },
                filterVariant: "select",
                filterSelectOptions: divisionData,
            },
        ],
        []
    );

    const [ManagedCompany, setManagedCompany] = useState([
        { company: "〇〇不動産", phone: "03-1234-5678", mail: "a@fudosan.com" },
        { company: "B社", phone: "03-1111-2222", mail: "b@fudosan.com" },
        { company: "C社", phone: "03-3333-4444", mail: "c@fudosan.com" },
        { company: "D社", phone: "03-5555-6666", mail: "d@fudosan.com" },
        { company: "E社", phone: "03-7777-8888", mail: "e@fudosan.com" },
        { company: "F社", phone: "03-9999-0000", mail: "f@fudosan.com" },
        { company: "G社", phone: "03-2222-3333", mail: "g@fudosan.com" },
        { company: "H社", phone: "03-4444-5555", mail: "h@fudosan.com" },
        { company: "I社", phone: "03-6666-7777", mail: "i@fudosan.com" },
    ]);

    const ManagedColumns = useMemo(
        () => [
            {
                header: "会社名",
                accessorKey: "company",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { width: "400px" } },
            },
            {
                header: "電話番号",
                accessorKey: "phone",
                muiTableBodyCellProps: { sx: { width: "100px" } },
            },
            {
                header: "メールアドレス",
                accessorKey: "mail",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { width: "400px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <Button color="primary" onClick={handleEditManagedCompany}>
                        編集する
                    </Button>
                ),
                muiTableBodyCellProps: { sx: { width: "150px" } },
            },
        ],
        []
    );

    const handleAddDormitory = () => {
        window.scrollTo(0, 0);
        navigate("/dormitory/add");
    };

    const handleDormitoryDetail = (id) => {
        window.scrollTo(0, 0);
        //navigate(`/dormitory/${id}`);
        navigate("/dormitory/detail");
    };

    const [open, setOpen] = useState(false);

    const [addManagedCompanyOpen, setAddManagedCompanyOpen] = useState(false);
    const [addManagedCompanyCompleteOpen, setAddManagedCompanyCompleteOpen] = useState(false);

    const [editManagedCompanyOpen, setEditManagedCompanyOpen] = useState(false);
    const [editManagedCompanyCompleteOpen, setEditManagedCompanyCompleteOpen] = useState(false);

    const [deleteManagedCompanyOpen, setDeleteManagedCompanyOpen] = useState(false);
    const [deleteManagedCompanyCompleteOpen, setDeleteManagedCompanyCompleteOpen] = useState(false);

    const [addValidation, setAddValidation] = useState("");
    const [addManagedCompanyData, setAddManagedCompanyData] = useState({
        company: "",
        phone: "",
        mail: "",
    });

    const [editValidation, setEditValidation] = useState("");
    const [editManagedCompanyData, setEditManagedCompanyData] = useState({
        company: "〇〇不動産",
        phone: "03-1234-5678",
        mail: "a@fudosan.com",
    });

    const [addManagedCompanyError, setAddManagedCompanyError] = useState({
        company: "",
        phone: "",
        mail: "",
        register: "",
    });

    const [editManagedCompanyError, setEditManagedCompanyError] = useState({
        company: "",
        phone: "",
        mail: "",
    });

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleManagedCompanyAdd = () => {
        setOpen(false);
        setAddManagedCompanyOpen(true);
    };

    const handleAddManagedCompanyClose = () => {
        setAddManagedCompanyOpen(false);
        setAddManagedCompanyCompleteOpen(false);
        setOpen(true);
    };

    const handleAddManagedCompanyNameChange = (e) => {
        setAddManagedCompanyData({ ...addManagedCompanyData, company: e.target.value });
    };
    const handleAddManagedCompanyNameBlur = (e) => {
        const value = e.target.value.replace(/　/g, " ").trim();
        setAddManagedCompanyData({ ...addManagedCompanyData, company: value });
        setAddManagedCompanyError({
            ...addManagedCompanyError,
            company: value === "" ? "会社名を入力してください" : "",
        });
    };

    const handleAddManagedCompanyPhoneChange = (e) => {
        setAddManagedCompanyData({ ...addManagedCompanyData, phone: e.target.value });
    };
    const handleAddManagedCompanyPhoneBlur = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setAddManagedCompanyData({ ...addManagedCompanyData, phone: value });
        setAddManagedCompanyError({
            ...addManagedCompanyError,
            phone: value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleAddManagedCompanyMailChange = (e) => {
        setAddManagedCompanyData({ ...addManagedCompanyData, mail: e.target.value });
    };
    const handleAddManagedCompanyMailBlur = (e) => {
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (addManagedCompanyData.mail === "") {
            setAddManagedCompanyError({
                ...addManagedCompanyError,
                mail: "メールアドレスを入力してください",
            });
        } else if (!emailRegex.test(addManagedCompanyData.mail)) {
            setAddManagedCompanyError({
                ...addManagedCompanyError,
                mail: "メールアドレスの形式が正しくありません",
            });
        } else {
            setAddManagedCompanyError({
                ...addManagedCompanyError,
                mail: "",
            });
        }
    };

    const handleAddManagedCompanyRegister = () => {
        addManagedCompanyError.company = addManagedCompanyData.company === "" ? "会社名を入力してください" : "";
        addManagedCompanyError.phone = addManagedCompanyData.phone === "" ? "電話番号を入力してください" : "";
        addManagedCompanyError.mail = addManagedCompanyData.mail === "" ? "メールアドレスを入力してください" : "";

        if (
            addManagedCompanyError.company === "" &&
            addManagedCompanyError.phone === "" &&
            addManagedCompanyError.mail === ""
        ) {
            setAddValidation("");
            setAddManagedCompanyOpen(false);
            setAddManagedCompanyCompleteOpen(true);
        } else {
            setAddValidation("入力内容に不備があります");
        }
    };

    const handleAddManagedCompanyCompleteClose = () => {
        setAddManagedCompanyCompleteOpen(false);
        setAddManagedCompanyData({
            company: "",
            phone: "",
            mail: "",
        });
        setOpen(true);
    };

    const handleEditManagedCompany = () => {
        setOpen(false);
        setEditManagedCompanyOpen(true);
    };

    const handleEditManagedCompanyClose = () => {
        setEditManagedCompanyOpen(false);
        setEditManagedCompanyCompleteOpen(false);
        setOpen(true);
    };

    const handleEditManagedCompanyNameChange = (e) => {
        setEditManagedCompanyData({ ...editManagedCompanyData, company: e.target.value });
    };
    const handleEditManagedCompanyNameBlur = (e) => {
        const value = e.target.value.replace(/　/g, " ").trim();
        setEditManagedCompanyData({ ...editManagedCompanyData, company: value });
        setEditManagedCompanyError({
            ...editManagedCompanyError,
            company: value === "" ? "会社名を入力してください" : "",
        });
    };

    const handleEditManagedCompanyPhoneChange = (e) => {
        setEditManagedCompanyData({ ...editManagedCompanyData, phone: e.target.value });
    };
    const handleEditManagedCompanyPhoneBlur = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setEditManagedCompanyData({ ...editManagedCompanyData, phone: value });
        setEditManagedCompanyError({
            ...editManagedCompanyError,
            phone: value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleEditManagedCompanyMailChange = (e) => {
        setEditManagedCompanyData({ ...editManagedCompanyData, mail: e.target.value });
    };
    const handleEditManagedCompanyMailBlur = (e) => {
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (editManagedCompanyData.mail === "") {
            setEditManagedCompanyError({
                ...editManagedCompanyError,
                mail: "メールアドレスを入力してください",
            });
        } else if (!emailRegex.test(editManagedCompanyData.mail)) {
            setEditManagedCompanyError({
                ...editManagedCompanyError,
                mail: "メールアドレスの形式が正しくありません",
            });
        } else {
            setEditManagedCompanyError({
                ...editManagedCompanyError,
                mail: "",
            });
        }
    };

    const handleEditManagedCompanyUpdate = () => {
        editManagedCompanyError.company = editManagedCompanyData.company === "" ? "会社名を入力してください" : "";
        editManagedCompanyError.phone = editManagedCompanyData.phone === "" ? "電話番号を入力してください" : "";
        editManagedCompanyError.mail = editManagedCompanyData.mail === "" ? "メールアドレスを入力してください" : "";

        if (
            editManagedCompanyError.company === "" &&
            editManagedCompanyError.phone === "" &&
            editManagedCompanyError.mail === ""
        ) {
            setEditValidation("");
            setEditManagedCompanyOpen(false);
            setEditManagedCompanyCompleteOpen(true);
        } else {
            setEditValidation("入力内容に不備があります");
        }
    };

    const handleEditManagedCompanyCompleteClose = () => {
        setEditManagedCompanyCompleteOpen(false);
        setEditManagedCompanyData({
            company: "〇〇不動産",
            phone: "03-1234-5678",
            mail: "a@fudosan.com",
        });
        setOpen(true);
    };

    const handleDeleteManagedCompany = () => {
        setEditManagedCompanyOpen(false);
        setDeleteManagedCompanyOpen(true);
    };

    const handleDeleteManagedCompanyClose = () => {
        setDeleteManagedCompanyOpen(false);
        setEditManagedCompanyOpen(true);
    };

    const handleManagedCompanyDeleteExecute = () => {
        setDeleteManagedCompanyOpen(false);
        setDeleteManagedCompanyCompleteOpen(true);
    };

    const handleDeleteManagedCompanyCompleteClose = () => {
        setDeleteManagedCompanyCompleteOpen(false);
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>社宅一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">社宅一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleAddDormitory}>
                                社宅を追加する
                            </Button>
                            <IconButton sx={{ ml: 2 }} onClick={handleOpen}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={dormitoryData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleDormitoryDetail(row.original.id),
                                sx: { cursor: "pointer" },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{
                                shape: "rounded",
                                color: "primary",
                            }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, minHeight: "700px", maxHeight: "1200px" }}>
                    <Box sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid2 container sx={{ mb: 3 }}>
                                <Grid2
                                    size={{ xs: 8, md: 8 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <Typography variant="h6">管理会社一覧</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 4, md: 4 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        color="primary"
                                        onClick={handleManagedCompanyAdd}
                                    >
                                        管理会社を追加する
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Box sx={{ minHeight: "530px", maxHeight: "530px", overflow: "auto", flexGrow: 1 }}>
                                <MaterialReactTable
                                    columns={ManagedColumns}
                                    data={ManagedCompany}
                                    columnFilterDisplayMode="popover"
                                    enableColumnActions={false}
                                    enableColumnResizing={false}
                                    enableDensityToggle={false}
                                    enableFullScreenToggle={false}
                                    enableHiding={false}
                                    localization={MRT_Localization_JA}
                                    initialState={{
                                        pagination: { pageIndex: 0, pageSize: 10 },
                                        showGlobalFilter: true,
                                    }}
                                    muiPaginationProps={{
                                        shape: "rounded",
                                        color: "primary",
                                    }}
                                    paginationDisplayMode="pages"
                                    positionGlobalFilter="left"
                                    muiTablePaperProps={{ elevation: 0 }}
                                    muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                                    muiTableBodyRowProps={{ hover: false }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 5,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={handleClose}
                                    sx={{ width: "400px" }}
                                >
                                    社宅一覧へ戻る
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={addManagedCompanyOpen} onClose={handleAddManagedCompanyClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleAddManagedCompanyClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">管理会社を追加</Typography>

                    <TextField
                        label="会社名"
                        onChange={handleAddManagedCompanyNameChange}
                        onBlur={handleAddManagedCompanyNameBlur}
                        error={addManagedCompanyError.company !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addManagedCompanyError.company !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addManagedCompanyError.company}
                        </Alert>
                    )}

                    <TextField
                        label="電話番号"
                        onChange={handleAddManagedCompanyPhoneChange}
                        onBlur={handleAddManagedCompanyPhoneBlur}
                        error={addManagedCompanyError.phone !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addManagedCompanyError.phone !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addManagedCompanyError.phone}
                        </Alert>
                    )}

                    <TextField
                        label="メールアドレス"
                        onChange={handleAddManagedCompanyMailChange}
                        onBlur={handleAddManagedCompanyMailBlur}
                        error={addManagedCompanyError.mail !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addManagedCompanyError.mail !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addManagedCompanyError.mail}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddManagedCompanyRegister}
                    >
                        管理会社を登録する
                    </Button>
                    {addValidation !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addValidation}
                        </Alert>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5, mb: 2 }}
                        onClick={handleAddManagedCompanyClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addManagedCompanyCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        管理会社の登録が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddManagedCompanyCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={editManagedCompanyOpen} onClose={handleEditManagedCompanyClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleEditManagedCompanyClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">管理会社を編集</Typography>

                    <TextField
                        label="会社名"
                        value={editManagedCompanyData.company}
                        onChange={handleEditManagedCompanyNameChange}
                        onBlur={handleEditManagedCompanyNameBlur}
                        error={editManagedCompanyError.company !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editManagedCompanyError.company !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editManagedCompanyError.company}
                        </Alert>
                    )}

                    <TextField
                        label="電話番号"
                        value={editManagedCompanyData.phone}
                        onChange={handleEditManagedCompanyPhoneChange}
                        onBlur={handleEditManagedCompanyPhoneBlur}
                        error={editManagedCompanyError.phone !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editManagedCompanyError.phone !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editManagedCompanyError.phone}
                        </Alert>
                    )}

                    <TextField
                        label="メールアドレス"
                        value={editManagedCompanyData.mail}
                        onChange={handleEditManagedCompanyMailChange}
                        onBlur={handleEditManagedCompanyMailBlur}
                        error={editManagedCompanyError.mail !== ""}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editManagedCompanyError.mail !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editManagedCompanyError.mail}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEditManagedCompanyUpdate}
                    >
                        管理会社を更新する
                    </Button>

                    <Button color="error" size="large" sx={{ mt: 5, mb: 5 }} onClick={handleDeleteManagedCompany}>
                        管理会社を削除する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mb: 2 }}
                        onClick={handleEditManagedCompanyClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={editManagedCompanyCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        管理会社の編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEditManagedCompanyCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteManagedCompanyOpen} onClose={handleDeleteManagedCompanyClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleDeleteManagedCompanyClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        {editManagedCompanyData.company}を削除します、よろしいですか？
                    </DialogContentText>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "140px" }}
                            onClick={handleManagedCompanyDeleteExecute}
                        >
                            削除する
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "140px", ml: 5 }}
                            onClick={handleDeleteManagedCompanyClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteManagedCompanyCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        管理会社の削除が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDeleteManagedCompanyCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Dormitory;
