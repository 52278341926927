import { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import { Button, CircularProgress, Typography, TextField, Alert } from "@mui/material";

import { Helmet } from "react-helmet-async";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { db } from "../../firebase";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const Login = () => {
    const [loading, setLoading] = useState(true);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const auth = getAuth();
    const navigate = useNavigate();

    // const handleKeyPress = (event) => {
    //     if (event.key === "Enter") {
    //         handleSubmit();
    //     }
    // };

    const handleSubmit = async () => {
        //     if (email === "" || password === "") {
        //         setErrorMessage("メールアドレス・パスワードを入力してください");
        //         return;
        //     } else {
        //         await signInWithEmailAndPassword(auth, email, password)
        //             .then(() => {
        //                 navigate("/", { replace: true });
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //                 setErrorMessage("メールアドレスまたはパスワードが間違っています");
        //             });
        //     }
        window.scrollTo(0, 0);
        navigate("/dispatch");
    };

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(async (userLogin) => {
            if (userLogin) {
                window.scrollTo(0, 0);
                navigate("/dispatch", { replace: true });
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return (
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>ログイン - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Container
                maxWidth="1280"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    maxWidth: 1280,
                }}
                disableGutters
            >
                <Box sx={{ mb: 10 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h3">HR System (仮)</Typography>
                </Box>

                <Box sx={{ mt: 1 }}>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="メールアドレス"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                            sx={{ width: 400 }}
                            // onKeyPress={handleKeyPress}
                        />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <TextField
                            label="パスワード"
                            type="password"
                            value={password}
                            sx={{ width: 400 }}
                            onChange={(e) => setPassword(e.target.value)}
                            // onKeyPress={handleKeyPress}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        {errorMessage !== "" && (
                            <Alert variant="filled" severity="error">
                                {errorMessage}
                            </Alert>
                        )}
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleSubmit}
                    >
                        ログイン
                    </Button>

                    <Box sx={{ mt: 5 }}>
                        <Typography
                            sx={{ textDecoration: "underline", cursor: "pointer", color: "blue" }}
                            onClick={() => navigate("/reset-password")}
                        >
                            パスワードを忘れた方はコチラ
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Login;
