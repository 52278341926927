import React, { useEffect, useMemo, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ManagerDetail = ({ val }) => {
    const navigate = useNavigate();

    const [manageStaffData, setManageStaffData] = useState([
        { name: "桐ヶ谷 和人", gender: "男性", age: 18, employment: "株式会社SAO" },
        { name: "結城 明日奈", gender: "女性", age: 18, employment: "株式会社ALO" },
        { name: "朝田 詩乃", gender: "女性", age: 17, employment: "株式会社ABC" },
        { name: "桐ヶ谷 直葉", gender: "女性", age: 16, employment: "株式会社DEF" },
        { name: "ユイ", gender: "女性", age: 10, employment: "株式会社GHI" },
        { name: "リーファ", gender: "女性", age: 16, employment: "株式会社JKL" },
        { name: "シリカ", gender: "女性", age: 14, employment: "株式会社MNO" },
        { name: "リズベット", gender: "女性", age: 18, employment: "株式会社PQR" },
        { name: "クライン", gender: "男性", age: 24, employment: "株式会社STU" },
        { name: "エギル", gender: "男性", age: 27, employment: "株式会社VWX" },
        { name: "シノン", gender: "女性", age: 17, employment: "株式会社YZA" },
        { name: "ユウキ", gender: "女性", age: 19, employment: "株式会社BCD" },
        { name: "アリス", gender: "女性", age: 20, employment: "株式会社EFG" },
        { name: "ユージオ", gender: "男性", age: 20, employment: "株式会社HIJ" },
        { name: "ティーゼ", gender: "女性", age: 16, employment: "株式会社KLM" },
        { name: "ロニエ", gender: "女性", age: 16, employment: "株式会社NOP" },
        { name: "セルカ", gender: "女性", age: 12, employment: "株式会社QRS" },
        { name: "カーディナル", gender: "女性", age: 200, employment: "株式会社TUV" },
        { name: "アスナ", gender: "女性", age: 18, employment: "株式会社WXY" },
        { name: "キリト", gender: "男性", age: 18, employment: "株式会社ZAB" },
    ]);

    const [notManageStaffData, setNotManageStaffData] = useState([
        { name: "立花 響", gender: "女性", age: 18, employment: "株式会社Symphogear" },
        { name: "風鳴 翼", gender: "女性", age: 18, employment: "株式会社SymphogearG" },
        { name: "天羽 奏", gender: "女性", age: 18, employment: "株式会社SymphogearGX" },
        { name: "雪音 クリス", gender: "女性", age: 18, employment: "株式会社SymphogearAXZ" },
        { name: "マリア・カデンツァヴナ・イヴ", gender: "女性", age: 18, employment: "株式会社SymphogearXV" },
        { name: "桐ヶ谷 和人", gender: "男性", age: 18, employment: "株式会社SAO" },
        { name: "結城 明日奈", gender: "女性", age: 18, employment: "株式会社ALO" },
        { name: "朝田 詩乃", gender: "女性", age: 17, employment: "株式会社ABC" },
        { name: "桐ヶ谷 直葉", gender: "女性", age: 16, employment: "株式会社DEF" },
        { name: "ユイ", gender: "女性", age: 10, employment: "株式会社GHI" },
        { name: "リーファ", gender: "女性", age: 16, employment: "株式会社JKL" },
        { name: "シリカ", gender: "女性", age: 14, employment: "株式会社MNO" },
        { name: "リズベット", gender: "女性", age: 18, employment: "株式会社PQR" },
        { name: "クライン", gender: "男性", age: 24, employment: "株式会社STU" },
        { name: "エギル", gender: "男性", age: 27, employment: "株式会社VWX" },
        { name: "シノン", gender: "女性", age: 17, employment: "株式会社YZA" },
        { name: "ユウキ", gender: "女性", age: 19, employment: "株式会社BCD" },
        { name: "アリス", gender: "女性", age: 20, employment: "株式会社EFG" },
        { name: "ユージオ", gender: "男性", age: 20, employment: "株式会社HIJ" },
        { name: "ティーゼ", gender: "女性", age: 16, employment: "株式会社KLM" },
        { name: "ロニエ", gender: "女性", age: 16, employment: "株式会社NOP" },
        { name: "セルカ", gender: "女性", age: 12, employment: "株式会社QRS" },
        { name: "カーディナル", gender: "女性", age: 200, employment: "株式会社TUV" },
        { name: "アスナ", gender: "女性", age: 18, employment: "株式会社WXY" },
        { name: "キリト", gender: "男性", age: 18, employment: "株式会社ZAB" },
    ]);

    const [genderData, setGenderData] = useState(["男性", "女性"]);

    const [dispatchCompanyData, setDispatchCompanyData] = useState([
        "株式会社SAO",
        "株式会社ALO",
        "株式会社ABC",
        "株式会社DEF",
        "株式会社GHI",
        "株式会社JKL",
        "株式会社MNO",
        "株式会社PQR",
        "株式会社STU",
        "株式会社VWX",
        "株式会社YZA",
        "株式会社BCD",
        "株式会社EFG",
        "株式会社HIJ",
        "株式会社KLM",
        "株式会社NOP",
        "株式会社QRS",
        "株式会社TUV",
        "株式会社WXY",
        "株式会社ZAB",
    ]);

    const columns = useMemo(
        () => [
            {
                header: "氏名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "性別",
                accessorKey: "gender",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: genderData,
            },
            {
                header: "年齢",
                accessorKey: "age",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
            {
                header: "就業先企業",
                accessorKey: "employment",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "210px", minWidth: "210px" } },
                filterVariant: "select",
                filterSelectOptions: dispatchCompanyData,
            },
        ],
        []
    );

    const [open, setOpen] = useState(false);

    const handleManageStaffEdit = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [addStaffComplete, setAddStaffComplete] = useState(false);
    const handleAddStaff = () => {
        setOpen(false);
        setAddStaffComplete(true);
    };

    const handleAddStaffCompleteClose = () => {
        setAddStaffComplete(false);
    };

    const handleReturn = () => {
        navigate("/manager");
    };

    const handleRowSelectionChange = (row) => {
        const isSelected = manageStaffData.some((staff) => staff.name === row.original.name);
        if (isSelected) {
            setManageStaffData(manageStaffData.filter((staff) => staff.name !== row.original.name));
            row.toggleSelected(false);
        } else {
            setManageStaffData([...manageStaffData, row.original]);
            row.toggleSelected(true);
        }
    };

    useEffect(() => {}, []);

    return (
        <>
            <Helmet>
                <title>派遣元責任者のスタッフ一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">派遣元責任者のスタッフ一覧</Typography>
                            <Typography sx={{ ml: 5 }}>20人</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleManageStaffEdit}>
                                スタッフを編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={manageStaffData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiTablePaperProps={{ elevation: 0 }}
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTableBodyRowProps={{ hover: false }}
                        />
                    </Box>

                    <Box sx={{ mt: 2, mb: 10 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleReturn}
                            sx={{ width: "400px" }}
                        >
                            派遣元責任者一覧に戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6">スタッフ編集</Typography>

                    <MaterialReactTable
                        columns={columns}
                        data={notManageStaffData}
                        columnFilterDisplayMode="popover"
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableHiding={false}
                        enableRowSelection
                        positionToolbarAlertBanner="bottom"
                        localization={MRT_Localization_JA}
                        initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                        muiPaginationProps={{ shape: "rounded", color: "primary" }}
                        paginationDisplayMode="pages"
                        positionGlobalFilter="left"
                        enableStickyHeader={true}
                        muiTablePaperProps={{
                            sx: { mt: 2 },
                            elevation: 0,
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => {
                                handleRowSelectionChange(row);
                            },
                            selected: manageStaffData.some((staff) => staff.name === row.original.name),
                            sx: { cursor: "pointer" },
                        })}
                        muiTableContainerProps={{
                            sx: { minHeight: "300px", maxHeight: "300px", overflow: "auto" },
                        }}
                        getRowId={(row) => row.name}
                        state={{
                            rowSelection: Object.fromEntries(
                                notManageStaffData.map((row) => [
                                    row.name,
                                    manageStaffData.some((staff) => staff.name === row.name),
                                ])
                            ),
                        }}
                    />

                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleAddStaff}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        選択したスタッフを追加する
                    </Button>

                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleClose}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addStaffComplete}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        スタッフの追加が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddStaffCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ManagerDetail;
