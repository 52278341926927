import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const NoMatch = ({ val }) => {
    const navigate = useNavigate();

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate(-1, { replace: true });
    };

    return (
        <>
            <Helmet>
                <title>404 Not Found - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 64px)",
                    pl: val === true ? 6 : 30,
                }}
            >
                <Typography variant="h6">404 Not Found</Typography>

                <Typography sx={{ mt: 2 }}>お探しのページが見つかりませんでした。</Typography>

                <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{ mt: 5, width: "600px" }}
                    onClick={handleReturn}
                >
                    前のページへ戻る
                </Button>
            </Box>
        </>
    );
};

export default NoMatch;
