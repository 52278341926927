import React, { useState, useMemo, useEffect } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    Alert,
    Grid2,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Tabs,
    Tab,
    RadioGroup,
    Radio,
    Tooltip,
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ja from "date-fns/locale/ja";

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FilterNoneIcon from "@mui/icons-material/FilterNone";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const StaffAdd = ({ val }) => {
    const navigate = useNavigate();

    const [request, setRequest] = useState(false);

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const [employeeData, setEmployeeData] = useState(["一般", "エキスパート社員"]);

    const [positionData, setPositionData] = useState(["一般", "エキスパート社員"]);

    const [commutingData, setCommutingData] = useState(["電車", "バス", "自動車", "有料道路", "自転車", "徒歩"]);

    const [otherApplicableReason] = useState([
        { title: "出生", value: "出生" },
        { title: "離職", value: "離職" },
        { title: "収入減", value: "収入減" },
        { title: "同居", value: "同居" },
        { title: "その他", value: "その他" },
    ]);
    const [otherNotApplicableReason] = useState([
        { title: "死亡", value: "死亡" },
        { title: "就職", value: "就職" },
        { title: "収入増加", value: "収入増加" },
        { title: "75歳到達", value: "75歳到達" },
        { title: "障害認定", value: "障害認定" },
        { title: "その他", value: "その他" },
    ]);

    const [otherOverseasApplicableReason] = useState([
        { title: "留学", value: "留学" },
        { title: "同行家族", value: "同行家族" },
        { title: "特定活動", value: "特定活動" },
        { title: "海外婚姻", value: "海外婚姻" },
        { title: "その他", value: "その他" },
    ]);
    const [otherOverseasNotApplicableReason] = useState([
        { title: "国内転入", value: "国内転入" },
        { title: "その他", value: "その他" },
    ]);

    const [relationshipData] = useState([
        { title: "父", value: "父" },
        { title: "母", value: "母" },
        { title: "子", value: "子" },
        { title: "兄", value: "兄" },
        { title: "姉", value: "姉" },
        { title: "弟", value: "弟" },
        { title: "妹", value: "妹" },
        { title: "孫", value: "孫" },
        { title: "祖父", value: "祖父" },
        { title: "祖母", value: "祖母" },
        { title: "義父", value: "義父" },
        { title: "義母", value: "義母" },
        { title: "義祖父", value: "義祖父" },
        { title: "義祖母", value: "義祖母" },
        { title: "曽祖父", value: "曽祖父" },
        { title: "曽祖母", value: "曽祖母" },
        { title: "配偶者の連れ子", value: "配偶者の連れ子" },
        { title: "その他", value: "その他" },
    ]);

    const [spouseApplicableReason] = useState([
        { title: "配偶者の就職", value: "配偶者の就職" },
        { title: "婚姻", value: "婚姻" },
        { title: "離職", value: "離職" },
        { title: "収入減少", value: "収入減少" },
        { title: "その他", value: "その他" },
    ]);
    const [spouseNotApplicableReason] = useState([
        { title: "死亡", value: "死亡" },
        { title: "離婚", value: "離婚" },
        { title: "就職・収入増加", value: "就職・収入増加" },
        { title: "75歳到達", value: "75歳到達" },
        { title: "障害認定", value: "障害認定" },
        { title: "その他", value: "その他" },
    ]);

    const [spouseOverseasApplicableReason] = useState([
        { title: "留学", value: "留学" },
        { title: "同行家族", value: "同行家族" },
        { title: "特定活動", value: "特定活動" },
        { title: "海外婚姻", value: "海外婚姻" },
        { title: "その他", value: "その他" },
    ]);
    const [spouseOverseasNotApplicableReason] = useState([
        { title: "国内転入", value: "国内転入" },
        { title: "その他", value: "その他" },
    ]);

    const [dormitoryData, setDormitoryData] = useState([
        {
            name: "社宅A",
            number: 3001,
            address: "東京都千代田区1-1-1",
        },
        {
            name: "社宅A",
            number: 3002,
            address: "東京都千代田区1-1-1",
        },
        {
            name: "社宅A",
            number: 3003,
            address: "東京都千代田区1-1-1",
        },
        {
            name: "社宅B",
            number: 4001,
            address: "東京都新宿区2-2-2",
        },
        {
            name: "社宅B",
            number: 4002,
            address: "東京都新宿区2-2-2",
        },
        {
            name: "社宅B",
            number: 4003,
            address: "東京都新宿区2-2-2",
        },
        {
            name: "社宅C",
            number: 5001,
            address: "東京都渋谷区3-3-3",
        },
        {
            name: "社宅C",
            number: 5002,
            address: "東京都渋谷区3-3-3",
        },
        {
            name: "社宅C",
            number: 5003,
            address: "東京都渋谷区3-3-3",
        },
        {
            name: "社宅D",
            number: 6001,
            address: "東京都港区4-4-4",
        },
        {
            name: "社宅D",
            number: 6002,
            address: "東京都港区4-4-4",
        },
        {
            name: "社宅D",
            number: 6003,
            address: "東京都港区4-4-4",
        },
        {
            name: "社宅E",
            number: 7001,
            address: "東京都品川区5-5-5",
        },
        {
            name: "社宅E",
            number: 7002,
            address: "東京都品川区5-5-5",
        },
        {
            name: "社宅E",
            number: 7003,
            address: "東京都品川区5-5-5",
        },
        {
            name: "社宅F",
            number: 8001,
            address: "東京都目黒区6-6-6",
        },
        {
            name: "社宅F",
            number: 8002,
            address: "東京都目黒区6-6-6",
        },
        {
            name: "社宅F",
            number: 8003,
            address: "東京都目黒区6-6-6",
        },
        {
            name: "社宅G",
            number: 9001,
            address: "東京都大田区7-7-7",
        },
        {
            name: "社宅G",
            number: 9002,
            address: "東京都大田区7-7-7",
        },
    ]);

    const [equipmentData, setEquipmentData] = useState([
        { name: "自転車", amount: 200, section: "レンタル", contractor: "レンティック" },
        { name: "寝具一式", amount: 150, section: "レンタル", contractor: "業者A" },
        { name: "テレビ", amount: 50, section: "レンタル", contractor: "レンティック" },
        { name: "電子レンジ", amount: 300, section: "レンタル", contractor: "業者A" },
        { name: "冷蔵庫", amount: 100, section: "レンタル", contractor: "レンティック" },
        { name: "洗濯機", amount: 80, section: "レンタル", contractor: "業者A" },
        { name: "ガスコンロ", amount: 60, section: "レンタル", contractor: "レンティック" },
        { name: "ローテーブル", amount: 120, section: "レンタル", contractor: "業者A" },
        { name: "カーテン135cm", amount: 1800, section: "購入", contractor: "レンティック" },
        { name: "カーテン200cm", amount: 2400, section: "購入", contractor: "業者A" },
        { name: "レース135cm", amount: 1400, section: "購入", contractor: "レンティック" },
    ]);

    const [staffData, setStaffData] = useState({
        staffName: { title: "氏名", value: "" },
        staffKana: { title: "フリガナ", value: "" },
        staffGender: { title: "性別", value: "male" },
        staffBirthday: { title: "生年月日", value: null },
        staffPhone: { title: "電話番号", value: "" },
        staffMail: { title: "メールアドレス", value: "" },
        staffAddress: {
            title: "住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        staffResidentAddress: {
            title: "住民票住所",
            ibid: true,
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        staffNationality: { title: "国籍", select: "japanese", value: "" },
        staffEmploymentInsuranceNumber: { title: "雇用保険被保険者番号 (省略可)", value: "" },
        staffBasicPensionNumber: { title: "基礎年金番号 (省略可)", value: "" },
        staffBank: { title: "銀行口座", bankName: "", select: "bank", branch: "", number: "", holder: "" },
    });

    const [staffMoreData, setStaffMoreData] = useState({
        staffJoinDate: { title: "入社日", value: null },
        staffDivision: { title: "所属部署", value: "" },
        staffCategory: { title: "社員区分", value: "" },
        staffPosition: { title: "職制区分", value: "" },
        staffConflictDate: { title: "個人抵触日", value: null },
        staffAgreementFlag: { title: "協定対象労働者", value: true },
        staffDispatch: { title: "就業先企業", value: "", date: null },
        staffCommuting: { title: "通勤手段", value: "" },
        staffDispatchHistory: { title: "就業履歴", value: [] },
        staffBetweenFlag: { title: "転籍", value: false },
        staffBetweenDate: { title: "転籍日", value: null },
        staffBetweenCompany: { title: "転籍先", value: "" },
        staffRetirementFlag: { title: "退職", value: false },
        staffRetirementDate: { title: "退職日", value: null },
    });
    const [emergencyContactData, setEmergencyContactData] = useState({
        emergencyContactName: { title: "緊急連絡先氏名", value: "" },
        emergencyContactKana: { title: "緊急連絡先フリガナ", value: "" },
        emergencyContactPhone: { title: "緊急連絡先電話番号", value: "" },
        emergencyContactRelationship: { title: "本人との関係", value: "" },
        emergencyContactAddress: {
            title: "緊急連絡先住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
    });

    const [spouseData, setSpouseData] = useState({
        spouseFlag: { title: "配偶者", value: false },
        spouseName: { title: "配偶者の氏名", value: "" },
        spouseKana: { title: "配偶者のフリガナ", value: "" },
        spouseBirthday: { title: "配偶者の生年月日", value: null },
        spouseGender: { title: "配偶者の性別", value: "female" },
        spouseTogether: { title: "同居区分", value: true },
        spouseAddress: {
            title: "配偶者の住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        spouseNationality: { title: "配偶者の国籍", select: "japanese", value: "" },
    });

    const [dependentData, setDependentData] = useState({
        dependentFlag: { title: "被扶養者届", value: false },
        dependentSpouseFlag: { title: "配偶者", value: false },
        dependentSpouseItem: { title: "項目", value: "" },
        dependentSpouseMarried: { title: "婚姻届の有無", value: true },
        dependentSpouseNumber: { title: "個人番号または基礎年金番号", value: "" },
        dependentSpousePhone: { title: "電話番号", select: "", value: "" },
        dependentSpouseDate: { title: "被扶養者になった日", value: null },
        dependentSpouseReason: { title: "理由", value: "", note: "", deadDate: null },
        dependentSpouseBusiness: { title: "職業", value: "", note: "" },
        dependentSpouseIncome: { title: "年収", value: "" },
        dependentSpouseOverseas: { title: "海外特例要件", value: "" },
        dependentSpouseOverseasDate: { title: "該当した日", value: null },
        dependentSpouseOverseasReason: { title: "理由", value: "", note: "", date: null },
        dependentSpouseMyNumberFront: { title: "マイナンバー・表", value: "" },
        dependentSpouseMyNumberBack: { title: "マイナンバー・裏", value: "" },
        dependentSpouseRelationshipCertificate: { title: "続柄証明書類", value: "" },
        dependentSpouseResident: { title: "住民票", value: "" },
        dependentSpouseIncomeCertificate: { title: "収入証明書", value: "" },
    });

    const [dependentOtherFlag, setDependentOtherFlag] = useState({ title: "被扶養者", value: false });

    const [dependentOtherData, setDependentOtherData] = useState([
        {
            dependentOtherItem: { title: "項目", value: "" },
            dependentOtherName: { title: "氏名", value: "" },
            dependentOtherKana: { title: "フリガナ", value: "" },
            dependentOtherGender: { title: "性別", value: "female" },
            dependentOtherBirthday: { title: "生年月日", value: null },
            dependentOtherRelationship: { title: "続柄", value: "" },
            dependentOtherNumber: { title: "個人番号または基礎年金番号", value: "" },
            dependentOtherTogether: { title: "同居区分", value: true },
            dependentOtherAddress: {
                title: "住所",
                post: "",
                prefecture: "",
                city: "",
                other: "",
                building: "",
            },
            dependentOtherDate: { title: "被扶養者になった日", value: null },
            dependentOtherReason: { title: "理由", value: "", note: "", deadDate: null },
            dependentOtherBusiness: { title: "職業", value: "", note: "" },
            dependentOtherIncome: { title: "年収", value: "" },
            dependentOtherOverseas: { title: "海外特例要件", value: "" },
            dependentOtherOverseasDate: { title: "該当した日", value: null },
            dependentOtherOverseasReason: { title: "理由", value: "", note: "", date: null },
            dependentOtherMyNumberFront: { title: "マイナンバー・表", value: "" },
            dependentOtherMyNumberBack: { title: "マイナンバー・裏", value: "" },
            dependentOtherRelationshipCertificate: { title: "続柄証明書類", value: "" },
            dependentOtherResident: { title: "住民票", value: "" },
            dependentOtherIncomeCertificate: { title: "収入証明書", value: "" },
            dependentOtherStudentCertificate: { title: "学生証", value: "" },
        },
    ]);
    const [staffUploadData, setStaffUploadData] = useState({
        staffResume: { title: "履歴書", value: "" },
        staffJobHistory: { title: "職務経歴書", value: "" },
        staffMyNumberFront: { title: "マイナンバー・表", value: "" },
        staffMyNumberBack: { title: "マイナンバー・裏", value: "" },
        staffIdentificationFront: { title: "顔写真付き本人確認書類・表", value: "" },
        staffIdentificationBack: { title: "顔写真付き本人確認書類・裏", value: "" },
        staffResident: { title: "住民票", value: "" },
        staffWithholding: { title: "源泉徴収票", value: "" },
        // staffResidentTax: { title: "住民税", value: "" },
        staffHealthCheck: { title: "健康診断書", value: "" },
        staffSpecialResidence: { title: "特別永住者証明書", value: "" },
        staffWorkVisa: { title: "在留カードまたは就労ビザ", value: "" },
        staffPassport: { title: "パスポート", value: "" },
        staffCommute: {
            title: "自転車・バイク通勤",
            select: false,
            driverLicenseFront: "",
            driverLicenseBack: "",
            vehicleInspection: "",
            compulsoryInsurance: "",
            optionalInsurance: "",
        },
    });

    const [staffLicenseData, setStaffLicenseData] = useState([]);

    const [staffDomitoryData, setStaffDomitoryData] = useState({
        staffDomitoryFlag: { title: "社宅利用", value: false },
        staffDomitoryName: { title: "社宅名", value: "" },
        staffDomitoryNumber: { title: "部屋番号", value: "" },
        staffDomitoryInDate: { title: "入居日", value: null },
        staffDomitoryOutDate: { title: "退去日", value: null },
    });

    const [equip, setEquip] = useState({
        staffEquipmentFlag: { title: "レンタル備品", value: false },
        staffEquipmentContractor: { title: "業者", value: "" },
    });
    const [staffEquipmentData, setStaffEquipmentData] = useState(
        equipmentData.map((equipment) => ({
            selected: false,
            name: equipment.name,
            amount: equipment.amount,
            value: "",
            inDate: null,
            outDate: null,
        }))
    );

    const [staffDataError, setStaffDataError] = useState({
        staffName: "",
        staffKana: "",
        staffBirthday: "",
        staffPhone: "",
        staffMail: "",
        staffAddressPost: "",
        staffAddressPrefecture: "",
        staffAddressCity: "",
        staffAddressOther: "",
        staffResidentAddressPost: "",
        staffResidentAddressPrefecture: "",
        staffResidentAddressCity: "",
        staffResidentAddressOther: "",
        staffNationality: "",
        staffBankName: "",
        staffBankBranch: "",
        staffBankNumber: "",
        staffBankHolder: "",
    });
    const [emergencyContactError, setEmergencyContactError] = useState({
        emergencyContactName: "",
        emergencyContactKana: "",
        emergencyContactPhone: "",
        emergencyContactRelationship: "",
        emergencyContactAddressPost: "",
        emergencyContactAddressPrefecture: "",
        emergencyContactAddressCity: "",
        emergencyContactAddressOther: "",
    });
    const [spouseError, setSpouseError] = useState({
        spouseName: "",
        spouseKana: "",
        spouseBirthday: "",
        spousePhone: "",
        spouseRelationship: "",
        spouseAddressPost: "",
        spouseAddressPrefecture: "",
        spouseAddressCity: "",
        spouseAddressOther: "",
        spouseNationality: "",
    });
    const [dependentError, setDependentError] = useState({
        dependentSpouseItem: "",
        dependentSpouseNumber: "",
        dependentSpousePhoneSelect: "",
        dependentSpousePhoneValue: "",
        dependentSpouseDate: "",
        dependentSpouseReason: "",
        dependentSpouseReasonNote: "",
        dependentSpouseReasonDeadDate: "",
        dependentSpouseDeadDate: "",
        dependentSpouseBusiness: "",
        dependentSpouseBusinessNote: "",
        dependentSpouseIncome: "",
        dependentSpouseOverseas: "",
        dependentSpouseOverseasDate: "",
        dependentSpouseOverseasReason: "",
        dependentSpouseOverseasReasonNote: "",
        dependentSpouseOverseasReasonDate: "",
    });
    const [dependentOtherError, setDependentOtherError] = useState([
        {
            dependentOtherItem: "",
            dependentOtherName: "",
            dependentOtherKana: "",
            dependentOtherBirthday: "",
            dependentOtherRelationship: "",
            dependentOtherAddressPost: "",
            dependentOtherAddressPrefecture: "",
            dependentOtherAddressCity: "",
            dependentOtherAddressOther: "",
            dependentOtherNumber: "",
            dependentOtherDate: "",
            dependentOtherReason: "",
            dependentOtherReasonNote: "",
            dependentOtherReasonDeadDate: "",
            dependentOtherBusiness: "",
            dependentOtherBusinessNote: "",
            dependentOtherIncome: "",
            dependentOtherOverseas: "",
            dependentOtherOverseasDate: "",
            dependentOtherOverseasReason: "",
            dependentOtherOverseasReasonNote: "",
            dependentOtherOverseasReasonDate: "",
        },
    ]);
    const [staffLicenseError, setStaffLicenseError] = useState([]);

    const columns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableHeadCellProps: { sx: { minWidth: "150px", maxWidth: "150px" } },
                muiTableBodyCellProps: { sx: { minWidth: "150px", maxWidth: "150px" } },
            },
            {
                header: "住所",
                accessorKey: "address",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
        ],
        []
    );

    const kanaMap = {
        ｶﾞ: "ガ",
        ｷﾞ: "ギ",
        ｸﾞ: "グ",
        ｹﾞ: "ゲ",
        ｺﾞ: "ゴ",
        ｻﾞ: "ザ",
        ｼﾞ: "ジ",
        ｽﾞ: "ズ",
        ｾﾞ: "ゼ",
        ｿﾞ: "ゾ",
        ﾀﾞ: "ダ",
        ﾁﾞ: "ヂ",
        ﾂﾞ: "ヅ",
        ﾃﾞ: "デ",
        ﾄﾞ: "ド",
        ﾊﾞ: "バ",
        ﾋﾞ: "ビ",
        ﾌﾞ: "ブ",
        ﾍﾞ: "ベ",
        ﾎﾞ: "ボ",
        ﾊﾟ: "パ",
        ﾋﾟ: "ピ",
        ﾌﾟ: "プ",
        ﾍﾟ: "ペ",
        ﾎﾟ: "ポ",
        ｳﾞ: "ヴ",
        ﾜﾞ: "ヷ",
        ｦﾞ: "ヺ",
        ｱ: "ア",
        ｲ: "イ",
        ｳ: "ウ",
        ｴ: "エ",
        ｵ: "オ",
        ｶ: "カ",
        ｷ: "キ",
        ｸ: "ク",
        ｹ: "ケ",
        ｺ: "コ",
        ｻ: "サ",
        ｼ: "シ",
        ｽ: "ス",
        ｾ: "セ",
        ｿ: "ソ",
        ﾀ: "タ",
        ﾁ: "チ",
        ﾂ: "ツ",
        ﾃ: "テ",
        ﾄ: "ト",
        ﾅ: "ナ",
        ﾆ: "ニ",
        ﾇ: "ヌ",
        ﾈ: "ネ",
        ﾉ: "ノ",
        ﾊ: "ハ",
        ﾋ: "ヒ",
        ﾌ: "フ",
        ﾍ: "ヘ",
        ﾎ: "ホ",
        ﾏ: "マ",
        ﾐ: "ミ",
        ﾑ: "ム",
        ﾒ: "メ",
        ﾓ: "モ",
        ﾔ: "ヤ",
        ﾕ: "ユ",
        ﾖ: "ヨ",
        ﾗ: "ラ",
        ﾘ: "リ",
        ﾙ: "ル",
        ﾚ: "レ",
        ﾛ: "ロ",
        ﾜ: "ワ",
        ｦ: "ヲ",
        ﾝ: "ン",
        ｧ: "ァ",
        ｨ: "ィ",
        ｩ: "ゥ",
        ｪ: "ェ",
        ｫ: "ォ",
        ｯ: "ッ",
        ｬ: "ャ",
        ｭ: "ュ",
        ｮ: "ョ",
        "｡": "。",
        "､": "、",
        ｰ: "ー",
        "｢": "「",
        "｣": "」",
        "･": "・",
    };

    const handleStaffInputRequestChange = (e) => {
        setRequest(e.target.checked);
    };

    const [tab, setTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleStaffNameChange = (e) => {
        setStaffData({ ...staffData, staffName: { ...staffData.staffName, value: e.target.value } });
    };
    const handleStaffNameBlur = () => {
        const value = staffData.staffName.value.replace(/　/g, " ").trim();
        setStaffData({ ...staffData, staffName: { ...staffData.staffName, value: value } });
        setStaffDataError({
            ...staffDataError,
            staffName: staffData.staffName.value === "" ? "氏名を入力してください" : "",
        });
    };

    const handleStaffKanaChange = (e) => {
        setStaffData({ ...staffData, staffKana: { ...staffData.staffKana, value: e.target.value } });
    };
    const handleStaffKanaBlur = () => {
        const hiraganaToKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60);
            });
        };
        const value = hiraganaToKatakana(staffData.staffKana.value.replace(/ /g, "　").trim());

        const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
        const convertedValue = value
            .replace(reg, (match) => kanaMap[match])
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");

        setStaffData({
            ...staffData,
            staffKana: { ...staffData.staffKana, value: convertedValue },
        });

        setStaffDataError({
            ...staffDataError,
            staffKana: staffData.staffKana.value === "" ? "フリガナを入力してください" : "",
        });
    };

    const handleStaffGenderChange = (e) => {
        setStaffData({
            ...staffData,
            staffGender: { ...staffData.staffGender, value: e.target.value },
        });
    };

    const handleStaffBirthdayChange = (value) => {
        setStaffData({ ...staffData, staffBirthday: { ...staffData.staffBirthday, value: value } });
        setStaffDataError({
            ...staffDataError,
            staffBirthday: value === null ? "生年月日を選択してください" : "",
        });
    };
    const handleStaffBirthdayBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffBirthday: staffData.staffBirthday.value === null ? "生年月日を選択してください" : "",
        });
    };

    const handleStaffPhoneChange = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setStaffData({ ...staffData, staffPhone: { ...staffData.staffPhone, value: value } });
    };
    const handleStaffPhoneBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffPhone: staffData.staffPhone.value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleStaffMailChange = (e) => {
        setStaffData({ ...staffData, staffMail: { ...staffData.staffMail, value: e.target.value } });
    };
    const handleStaffMailBlur = () => {
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (staffData.staffMail.value === "") {
            setStaffDataError({
                ...staffDataError,
                staffMail: "メールアドレスを入力してください",
            });
        } else if (!emailRegex.test(staffData.staffMail.value)) {
            setStaffDataError({
                ...staffDataError,
                staffMail: "メールアドレスの形式が正しくありません",
            });
        } else {
            setStaffDataError({
                ...staffDataError,
                staffMail: "",
            });
        }
    };

    const handleStaffAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ""); // 数字のみ
        setStaffData({ ...staffData, staffAddress: { ...staffData.staffAddress, post: value } });
    };
    const handleStaffAddressPostBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffAddressPost: staffData.staffAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleStaffAddressPrefectureChange = (e) => {
        setStaffData({
            ...staffData,
            staffAddress: { ...staffData.staffAddress, prefecture: e.target.value },
        });
    };
    const handleStaffAddressPrefectureBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffAddressPrefecture: staffData.staffAddress.prefecture === "" ? "都道府県を入力してください" : "",
        });
    };

    const handleStaffAddressCityChange = (e) => {
        setStaffData({ ...staffData, staffAddress: { ...staffData.staffAddress, city: e.target.value } });
    };
    const handleStaffAddressCityBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffAddressCity: staffData.staffAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleStaffAddressOtherChange = (e) => {
        setStaffData({
            ...staffData,
            staffAddress: { ...staffData.staffAddress, other: e.target.value },
        });
    };
    const handleStaffAddressOtherBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffAddressOther: staffData.staffAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleStaffAddressBuildingChange = (e) => {
        setStaffData({
            ...staffData,
            staffAddress: { ...staffData.staffAddress, building: e.target.value },
        });
    };

    const handleStaffResidentAddressIbidChange = (e) => {
        const value = e.target.value === "true" ? true : false;
        setStaffData({
            ...staffData,
            staffResidentAddress: { ...staffData.staffResidentAddress, ibid: value },
        });
    };

    const handleStaffResidentAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setStaffData({ ...staffData, staffResidentAddress: { ...staffData.staffResidentAddress, post: value } });
    };
    const handleStaffResidentAddressPostBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffResidentAddressPost: staffData.staffResidentAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleStaffResidentAddressPrefectureChange = (e) => {
        setStaffData({
            ...staffData,
            staffResidentAddress: { ...staffData.staffResidentAddress, prefecture: e.target.value },
        });
    };
    const handleStaffResidentAddressPrefectureBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffResidentAddressPrefecture:
                staffData.staffResidentAddress.prefecture === "" ? "都道府県を入力してください" : "",
        });
    };

    const handleStaffResidentAddressCityChange = (e) => {
        setStaffData({
            ...staffData,
            staffResidentAddress: { ...staffData.staffResidentAddress, city: e.target.value },
        });
    };
    const handleStaffResidentAddressCityBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffResidentAddressCity: staffData.staffResidentAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleStaffResidentAddressOtherChange = (e) => {
        setStaffData({
            ...staffData,
            staffResidentAddress: { ...staffData.staffResidentAddress, other: e.target.value },
        });
    };
    const handleStaffResidentAddressOtherBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffResidentAddressOther:
                staffData.staffResidentAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleStaffResidentAddressBuildingChange = (e) => {
        setStaffData({
            ...staffData,
            staffResidentAddress: { ...staffData.staffResidentAddress, building: e.target.value },
        });
    };

    const handleStaffNationalityChange = (e) => {
        setStaffData({
            ...staffData,
            staffNationality: { ...staffData.staffNationality, select: e.target.value, value: "" },
        });
        setStaffDataError({
            ...staffDataError,
            staffNationality: "",
        });
    };

    const handleStaffCountryChange = (e) => {
        setStaffData({ ...staffData, staffNationality: { ...staffData.staffNationality, value: e.target.value } });
    };
    const handleStaffCountryBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffNationality: staffData.staffNationality.value === "" ? "国名を入力してください" : "",
        });
    };

    const handleStaffEmploymentInsuranceNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setStaffData({
            ...staffData,
            staffEmploymentInsuranceNumber: { ...staffData.staffEmploymentInsuranceNumber, value: value },
        });
    };

    const handleStaffBasicPensionNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setStaffData({
            ...staffData,
            staffBasicPensionNumber: { ...staffData.staffBasicPensionNumber, value: value },
        });
    };

    const handleStaffBankChange = (e) => {
        setStaffData({ ...staffData, staffBank: { ...staffData.staffBank, bankName: e.target.value } });
    };
    const handleStaffBankBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffBankName: staffData.staffBank.bankName === "" ? "金融機関名を入力してください" : "",
        });
    };

    const handleStaffBankSelectChange = (e) => {
        setStaffData({
            ...staffData,
            staffBank: { ...staffData.staffBank, select: e.target.value },
        });
    };

    const handleStaffBankBranchChange = (e) => {
        setStaffData({ ...staffData, staffBank: { ...staffData.staffBank, branch: e.target.value } });
    };
    const handleStaffBankBranchBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffBankBranch: staffData.staffBank.branch === "" ? "支店名を入力してください" : "",
        });
    };

    const handleStaffBankNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setStaffData({ ...staffData, staffBank: { ...staffData.staffBank, number: value } });
    };
    const handleStaffBankNumberBlur = () => {
        setStaffDataError({
            ...staffDataError,
            staffBankNumber: staffData.staffBank.number === "" ? "口座番号を入力してください" : "",
        });
    };

    const handleStaffBankHolderChange = (e) => {
        setStaffData({ ...staffData, staffBank: { ...staffData.staffBank, holder: e.target.value } });
    };
    const handleStaffBankHolderBlur = () => {
        const hiraganaToKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60);
            });
        };
        const value = hiraganaToKatakana(staffData.staffBank.holder.replace(/ /g, "　").trim());

        const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
        const convertedValue = value
            .replace(reg, (match) => kanaMap[match])
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");

        setStaffData({
            ...staffData,
            staffBank: { ...staffData.staffBank, holder: convertedValue },
        });

        setStaffDataError({
            ...staffDataError,
            staffBankHolder: staffData.staffBank.holder === "" ? "口座名義 (カタカナ) を入力してください" : "",
        });
    };

    const handleEmergencyContactNameChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactName: { ...emergencyContactData.emergencyContactName, value: e.target.value },
        });
    };
    const handleEmergencyContactNameBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactName:
                emergencyContactData.emergencyContactName.value === "" ? "続柄を入力してください" : "",
        });
    };

    const handleEmergencyContactKanaChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactKana: { ...emergencyContactData.emergencyContactKana, value: e.target.value },
        });
    };
    const handleEmergencyContactKanaBlur = () => {
        const hiraganaToKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60);
            });
        };
        const value = hiraganaToKatakana(emergencyContactData.emergencyContactKana.value.replace(/ /g, "　").trim());

        const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
        const convertedValue = value
            .replace(reg, (match) => kanaMap[match])
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");

        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactKana: { ...emergencyContactData.emergencyContactKana, value: convertedValue },
        });

        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactKana:
                emergencyContactData.emergencyContactKana.value === "" ? "フリガナを入力してください" : "",
        });
    };

    const handleEmergencyContactPhoneChange = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactPhone: { ...emergencyContactData.emergencyContactPhone, value: value },
        });
    };
    const handleEmergencyContactPhoneBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactPhone:
                emergencyContactData.emergencyContactPhone.value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleEmergencyContactRelationshipChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactRelationship: {
                ...emergencyContactData.emergencyContactRelationship,
                value: e.target.value,
            },
        });
    };
    const handleEmergencyContactRelationshipBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactRelationship:
                emergencyContactData.emergencyContactRelationship.value === "" ? "本人との関係を入力してください" : "",
        });
    };

    const handleEmergencyContactAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactAddress: { ...emergencyContactData.emergencyContactAddress, post: value },
        });
    };
    const handleEmergencyContactAddressPostBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactAddressPost:
                emergencyContactData.emergencyContactAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleEmergencyContactAddressPrefectureChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactAddress: { ...emergencyContactData.emergencyContactAddress, prefecture: e.target.value },
        });
    };
    const handleEmergencyContactAddressPrefectureBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactAddressPrefecture:
                emergencyContactData.emergencyContactAddress.prefecture === "" ? "都道府県を入力してください" : "",
        });
    };

    const handleEmergencyContactAddressCityChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactAddress: { ...emergencyContactData.emergencyContactAddress, city: e.target.value },
        });
    };
    const handleEmergencyContactAddressCityBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactAddressCity:
                emergencyContactData.emergencyContactAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleEmergencyContactAddressOtherChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactAddress: { ...emergencyContactData.emergencyContactAddress, other: e.target.value },
        });
    };
    const handleEmergencyContactAddressOtherBlur = () => {
        setEmergencyContactError({
            ...emergencyContactError,
            emergencyContactAddressOther:
                emergencyContactData.emergencyContactAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleEmergencyContactAddressBuildingChange = (e) => {
        setEmergencyContactData({
            ...emergencyContactData,
            emergencyContactAddress: { ...emergencyContactData.emergencyContactAddress, building: e.target.value },
        });
    };

    const handleSpouseFlagChange = (e) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setSpouseData({
                ...spouseData,
                spouseFlag: { ...spouseData.spouseFlag, value: boolValue },
                spouseName: { ...spouseData.spouseName, value: "" },
                spouseKana: { ...spouseData.spouseKana, value: "" },
                spouseBirthday: { ...spouseData.spouseBirthday, value: null },
                spouseGender: { ...spouseData.spouseGender, value: "female" },
                spouseTogether: { ...spouseData.spouseTogether, value: true },
                spouseAddress: {
                    ...spouseData.spouseAddress,
                    post: "",
                    prefecture: "",
                    city: "",
                    other: "",
                    building: "",
                },
                spouseNationality: { ...spouseData.spouseNationality, select: "japanese", value: "" },
            });
            setSpouseError({
                spouseName: "",
                spouseKana: "",
                spouseBirthday: "",
                spouseAddressPost: "",
                spouseAddressPrefecture: "",
                spouseAddressCity: "",
                spouseAddressOther: "",
                spouseNationality: "",
            });
            setDependentData({
                ...dependentData,
                dependentSpouseFlag: { ...dependentData.dependentSpouseFlag, value: false },
                dependentSpouseItem: { ...dependentData.dependentSpouseItem, value: "" },
                dependentSpouseNumber: { ...dependentData.dependentSpouseNumber, value: "" },
                dependentSpousePhone: { ...dependentData.dependentSpousePhone, select: "", value: "" },
                dependentSpouseDate: { ...dependentData.dependentSpouseDate, value: null },
                dependentSpouseReason: { ...dependentData.dependentSpouseReason, value: "", note: "", deadDate: null },
                dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, value: "", note: "" },
                dependentSpouseIncome: { ...dependentData.dependentSpouseIncome, value: "" },
                dependentSpouseOverseas: { ...dependentData.dependentSpouseOverseas, value: "" },
                dependentSpouseOverseasDate: { ...dependentData.dependentSpouseOverseasDate, value: null },
                dependentSpouseOverseasReason: { ...dependentData.dependentSpouseOverseasReason, value: "", note: "" },
                dependentSpouseMyNumberFront: { ...dependentData.dependentSpouseMyNumberFront, value: "" },
                dependentSpouseMyNumberBack: { ...dependentData.dependentSpouseMyNumberBack, value: "" },
                dependentSpouseRelationshipCertificate: {
                    ...dependentData.dependentSpouseRelationshipCertificate,
                    value: "",
                },
                dependentSpouseResident: { ...dependentData.dependentSpouseResident, value: "" },
                dependentSpouseIncomeCertificate: {
                    ...dependentData.dependentSpouseIncomeCertificate,
                    value: "",
                },
            });
            setDependentError({
                dependentSpouseItem: "",
                dependentSpouseNumber: "",
                dependentSpousePhoneSelect: "",
                dependentSpousePhoneValue: "",
                dependentSpouseDate: "",
                dependentSpouseReason: "",
                dependentSpouseReasonNote: "",
                dependentSpouseBusiness: "",
                dependentSpouseBusinessNote: "",
                dependentSpouseIncome: "",
                dependentSpouseOverseas: "",
                dependentSpouseOverseasDate: "",
                dependentSpouseOverseasReason: "",
                dependentSpouseOverseasReasonNote: "",
            });
        } else {
            setSpouseData({
                ...spouseData,
                spouseFlag: { ...spouseData.spouseFlag, value: boolValue },
            });
        }
    };

    const handleSpouseNameChange = (e) => {
        setSpouseData({ ...spouseData, spouseName: { ...spouseData.spouseName, value: e.target.value } });
    };
    const handleSpouseNameBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseName: spouseData.spouseName.value === "" ? "配偶者の氏名を入力してください" : "",
        });
    };

    const handleSpouseKanaChange = (e) => {
        setSpouseData({ ...spouseData, spouseKana: { ...spouseData.spouseKana, value: e.target.value } });
    };
    const handleSpouseKanaBlur = () => {
        const hiraganaToKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60);
            });
        };
        const value = hiraganaToKatakana(spouseData.spouseKana.value.replace(/ /g, "　").trim());

        const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
        const convertedValue = value
            .replace(reg, (match) => kanaMap[match])
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");

        setSpouseData({
            ...spouseData,
            spouseKana: { ...spouseData.spouseKana, value: convertedValue },
        });

        setSpouseError({
            ...spouseError,
            spouseKana: spouseData.spouseKana.value === "" ? "配偶者のフリガナを入力してください" : "",
        });
    };

    const handleSpouseBirthdayChange = (value) => {
        setSpouseData({ ...spouseData, spouseBirthday: { ...spouseData.spouseBirthday, value: value } });
        setSpouseError({
            ...spouseError,
            spouseBirthday: value === null ? "配偶者の生年月日を選択してください" : "",
        });
    };
    const handleSpouseBirthdayBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseBirthday: spouseData.spouseBirthday.value === null ? "配偶者の生年月日を選択してください" : "",
        });
    };

    const handleSpouseGenderChange = (e) => {
        setSpouseData({
            ...spouseData,
            spouseGender: { ...spouseData.spouseGender, value: e.target.value },
        });
        setSpouseError({
            ...spouseError,
            spouseGender: "",
        });
    };

    const handleSpouseTogetherChange = (e) => {
        const boolValue = e.target.value === "true";
        if (boolValue) {
            setSpouseData({
                ...spouseData,
                spouseTogether: { ...spouseData.spouseTogether, value: boolValue },
                spouseAddress: boolValue
                    ? spouseData.spouseAddress
                    : {
                          ...spouseData.spouseAddress,
                          post: "",
                          prefecture: "",
                          city: "",
                          other: "",
                          building: "",
                      },
            });
            setSpouseError({
                ...spouseError,
                spouseTogether: "",
                spouseAddressPost: "",
                spouseAddressPrefecture: "",
                spouseAddressCity: "",
                spouseAddressOther: "",
            });
        } else {
            setSpouseData({
                ...spouseData,
                spouseTogether: { ...spouseData.spouseTogether, value: boolValue },
            });
        }
    };

    const handleSpouseAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setSpouseData({ ...spouseData, spouseAddress: { ...spouseData.spouseAddress, post: value } });
    };
    const handleSpouseAddressPostBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseAddressPost: spouseData.spouseAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleSpouseAddressPrefectureChange = (e) => {
        setSpouseData({ ...spouseData, spouseAddress: { ...spouseData.spouseAddress, prefecture: e.target.value } });
    };
    const handleSpouseAddressPrefectureBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseAddressPrefecture: spouseData.spouseAddress.prefecture === "" ? "都道府県を入力してください" : "",
        });
    };

    const handleSpouseAddressCityChange = (e) => {
        setSpouseData({ ...spouseData, spouseAddress: { ...spouseData.spouseAddress, city: e.target.value } });
    };
    const handleSpouseAddressCityBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseAddressCity: spouseData.spouseAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleSpouseAddressOtherChange = (e) => {
        setSpouseData({ ...spouseData, spouseAddress: { ...spouseData.spouseAddress, other: e.target.value } });
    };
    const handleSpouseAddressOtherBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseAddressOther: spouseData.spouseAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleSpouseAddressBuildingChange = (e) => {
        setSpouseData({ ...spouseData, spouseAddress: { ...spouseData.spouseAddress, building: e.target.value } });
    };

    const handleSpouseNationalityChange = (e) => {
        setSpouseData({
            ...spouseData,
            spouseNationality: { ...spouseData.spouseNationality, select: e.target.value },
        });
        setSpouseError({
            ...spouseError,
            spouseNationality: "",
        });
    };

    const handleSpouseCountryChange = (e) => {
        setSpouseData({ ...spouseData, spouseNationality: { ...spouseData.spouseNationality, value: e.target.value } });
    };
    const handleSpouseCountryBlur = () => {
        setSpouseError({
            ...spouseError,
            spouseNationality: spouseData.spouseNationality.value === "" ? "国名を入力してください" : "",
        });
    };

    const handleDependentFlagChange = (e) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setDependentData({
                ...dependentData,
                dependentFlag: { ...dependentData.dependentFlag, value: boolValue },
                dependentSpouseFlag: { ...dependentData.dependentSpouseFlag, value: false },
                dependentSpouseItem: { ...dependentData.dependentSpouseItem, value: "" },
                dependentSpouseMarried: { ...dependentData.dependentSpouseMarried, value: true },
                dependentSpouseNumber: { ...dependentData.dependentSpouseNumber, value: "" },
                dependentSpousePhone: { ...dependentData.dependentSpousePhone, select: "", value: "" },
                dependentSpouseDate: { ...dependentData.dependentSpouseDate, value: null },
                dependentSpouseReason: { ...dependentData.dependentSpouseReason, value: "", note: "", deadDate: null },
                dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, value: "", note: "" },
                dependentSpouseIncome: { ...dependentData.dependentSpouseIncome, value: "" },
                dependentSpouseOverseas: { ...dependentData.dependentSpouseOverseas, value: "" },
                dependentSpouseOverseasDate: { ...dependentData.dependentSpouseOverseasDate, value: null },
                dependentSpouseOverseasReason: { ...dependentData.dependentSpouseOverseasReason, value: "", note: "" },
                dependentOtherMyNumberFront: { ...dependentData.dependentOtherMyNumberFront, value: "" },
                dependentOtherMyNumberBack: { ...dependentData.dependentOtherMyNumberBack, value: "" },
                dependentSpouseRelationshipCertificate: {
                    ...dependentData.dependentSpouseRelationshipCertificate,
                    value: "",
                },
                dependentSpouseResident: { ...dependentData.dependentSpouseResident, value: "" },
                dependentSpouseIncomeCertificate: {
                    ...dependentData.dependentSpouseIncomeCertificate,
                    value: "",
                },
            });
            setDependentError({
                dependentSpouseItem: "",
                dependentSpouseNumber: "",
                dependentSpousePhoneSelect: "",
                dependentSpousePhoneValue: "",
                dependentSpouseDate: "",
                dependentSpouseReason: "",
                dependentSpouseReasonNote: "",
                dependentSpouseReasonDeadDate: null,
                dependentSpouseBusiness: "",
                dependentSpouseBusinessNote: "",
                dependentSpouseIncome: "",
                dependentSpouseOverseas: "",
                dependentSpouseOverseasDate: null,
                dependentSpouseOverseasReason: "",
                dependentSpouseOverseasReasonNote: "",
            });

            setDependentOtherFlag((prev) => ({
                ...prev,
                value: false,
            }));
            setDependentOtherData([
                {
                    dependentOtherItem: { title: "項目", value: "" },
                    dependentOtherName: { title: "氏名", value: "" },
                    dependentOtherKana: { title: "フリガナ", value: "" },
                    dependentOtherGender: { title: "性別", value: "female" },
                    dependentOtherBirthday: { title: "生年月日", value: null },
                    dependentOtherRelationship: { title: "続柄", value: "", note: "" },
                    dependentOtherTogether: { title: "同居区分", value: true },
                    dependentOtherAddress: {
                        title: "住所",
                        post: "",
                        prefecture: "",
                        city: "",
                        other: "",
                        building: "",
                    },
                    dependentOtherNumber: { title: "個人番号または基礎年金番号", value: "" },
                    dependentOtherDate: { title: "被扶養者になった日", value: null },
                    dependentOtherReason: { title: "理由", value: "", note: "", deadDate: null },
                    dependentOtherBusiness: { title: "職業", value: "", note: "" },
                    dependentOtherIncome: { title: "年収", value: "" },
                    dependentOtherOverseas: { title: "海外特例要件", value: "" },
                    dependentOtherOverseasDate: { title: "該当した日", value: null },
                    dependentOtherOverseasReason: { title: "理由", value: "", note: "" },
                    dependentOtherNote: { title: "備考", value: "" },
                    dependentOtherMyNumberFront: { title: "マイナンバー・表", value: "" },
                    dependentOtherMyNumberBack: { title: "マイナンバー・裏", value: "" },
                    dependentOtherRelationshipCertificate: { title: "続柄証明書類", value: "" },
                    dependentOtherResident: { title: "住民票", value: "" },
                    dependentOtherIncomeCertificate: { title: "収入証明書", value: "" },
                    dependentOtherStudentCertificate: { title: "学生証", value: "" },
                },
            ]);
            setDependentOtherError([
                {
                    dependentOtherItem: "",
                    dependentOtherName: "",
                    dependentOtherKana: "",
                    dependentOtherBirthday: "",
                    dependentOtherRelationship: "",
                    dependentOtherRelationshipNote: "",
                    dependentOtherAddressPost: "",
                    dependentOtherAddressPrefecture: "",
                    dependentOtherAddressCity: "",
                    dependentOtherAddressOther: "",
                    dependentOtherNumber: "",
                    dependentOtherDate: "",
                    dependentOtherReason: "",
                    dependentOtherReasonNote: "",
                    dependentOtherReasonDeadDate: "",
                    dependentOtherBusiness: "",
                    dependentOtherBusinessNote: "",
                    dependentOtherIncome: "",
                    dependentOtherOverseas: "",
                    dependentOtherOverseasDate: "",
                    dependentOtherOverseasReason: "",
                    dependentOtherOverseasReasonNote: "",
                },
            ]);
        } else {
            setDependentData({
                ...dependentData,
                dependentFlag: { ...dependentData.dependentFlag, value: boolValue },
            });
        }
    };

    const handleDependentSpouseFlagChange = (e) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setDependentData({
                ...dependentData,
                dependentSpouseFlag: { ...dependentData.dependentSpouseFlag, value: false },
                dependentSpouseItem: { ...dependentData.dependentSpouseItem, value: "" },
                dependentSpouseMarried: { ...dependentData.dependentSpouseMarried, value: true },
                dependentSpouseNumber: { ...dependentData.dependentSpouseNumber, value: "" },
                dependentSpousePhone: { ...dependentData.dependentSpousePhone, select: "", value: "" },
                dependentSpouseDate: { ...dependentData.dependentSpouseDate, value: null },
                dependentSpouseReason: { ...dependentData.dependentSpouseReason, value: "", note: "", deadDate: null },
                dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, value: "", note: "" },
                dependentSpouseIncome: { ...dependentData.dependentSpouseIncome, value: "" },
                dependentSpouseOverseas: { ...dependentData.dependentSpouseOverseas, value: "" },
                dependentSpouseOverseasDate: { ...dependentData.dependentSpouseOverseasDate, value: null },
                dependentSpouseOverseasReason: { ...dependentData.dependentSpouseOverseasReason, value: "", note: "" },
                dependentOtherMyNumberFront: { ...dependentData.dependentOtherMyNumberFront, value: "" },
                dependentOtherMyNumberBack: { ...dependentData.dependentOtherMyNumberBack, value: "" },
                dependentSpouseRelationshipCertificate: {
                    ...dependentData.dependentSpouseRelationshipCertificate,
                    value: "",
                },
                dependentSpouseResident: { ...dependentData.dependentSpouseResident, value: "" },
                dependentSpouseIncomeCertificate: {
                    ...dependentData.dependentSpouseIncomeCertificate,
                    value: "",
                },
            });
            setDependentError({
                dependentSpouseItem: "",
                dependentSpouseNumber: "",
                dependentSpousePhoneSelect: "",
                dependentSpousePhoneValue: "",
                dependentSpouseDate: "",
                dependentSpouseReason: "",
                dependentSpouseReasonNote: "",
                dependentSpouseReasonDeadDate: null,
                dependentSpouseBusiness: "",
                dependentSpouseBusinessNote: "",
                dependentSpouseIncome: "",
                dependentSpouseOverseas: "",
                dependentSpouseOverseasDate: null,
                dependentSpouseOverseasReason: "",
                dependentSpouseOverseasReasonNote: "",
            });
        } else {
            setDependentData({
                ...dependentData,
                dependentSpouseFlag: { ...dependentData.dependentSpouseFlag, value: boolValue },
            });
        }
    };

    const handleDependentSpouseItemChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseItem: { ...dependentData.dependentSpouseItem, value: e.target.value },
            dependentSpouseDate: { ...dependentData.dependentSpouseDate, value: null },
            dependentSpouseReason: { ...dependentData.dependentSpouseReason, value: "", note: "", deadDate: null },
            dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, value: "", note: "" },
            dependentSpouseIncome: { ...dependentData.dependentSpouseIncome, value: "" },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseItem: "",
            dependentSpouseDate: "",
            dependentSpouseReason: "",
            dependentSpouseBusiness: "",
            dependentSpouseIncome: "",
        });
    };

    const handleDependentSpouseMarriedChange = (e) => {
        const boolValue = e.target.value === "true";
        setDependentData({
            ...dependentData,
            dependentSpouseMarried: { ...dependentData.dependentSpouseMarried, value: boolValue },
        });
    };

    const handleDependentSpouseNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentData({
            ...dependentData,
            dependentSpouseNumber: { ...dependentData.dependentSpouseNumber, value: value },
        });
    };
    const handleDependentSpouseNumberBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseNumber:
                dependentData.dependentSpouseNumber.value === "" ? "個人番号または基礎年金番号を入力してください" : "",
        });
    };

    const handleDependentSpousePhoneSelectChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpousePhone: { ...dependentData.dependentSpousePhone, select: e.target.value },
        });
        setDependentError({
            ...dependentError,
            dependentSpousePhoneSelect: "",
        });
    };

    const handleDependentSpousePhoneValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setDependentData({
            ...dependentData,
            dependentSpousePhone: { ...dependentData.dependentSpousePhone, value: value },
        });
    };
    const handleDependentSpousePhoneValueBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpousePhoneValue:
                dependentData.dependentSpousePhone.value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleDependentSpouseDateChange = (value) => {
        setDependentData({
            ...dependentData,
            dependentSpouseDate: { ...dependentData.dependentSpouseDate, value: value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseDate: "",
        });
    };
    const handleDependentSpouseDateBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseDate: dependentData.dependentSpouseDate.value === null ? "日付を選択してください" : "",
        });
    };

    const handleDependentSpouseReasonChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseReason: { ...dependentData.dependentSpouseReason, value: e.target.value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseReason: "",
        });
    };

    const handleDependentSpouseReasonNoteChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseReason: { ...dependentData.dependentSpouseReason, note: e.target.value },
        });
    };
    const handleDependentSpouseReasonNoteBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseReasonNote: dependentData.dependentSpouseReason.note === "" ? "理由を入力してください" : "",
        });
    };

    const handleDependentSpouseDeadDateChange = (value) => {
        setDependentData({
            ...dependentData,
            dependentSpouseReason: { ...dependentData.dependentSpouseReason, deadDate: value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseDeadDate: "",
        });
    };
    const handleDependentSpouseDeadDateBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseDeadDate:
                dependentData.dependentSpouseReason.deadDate === null ? "日付を選択してください" : "",
        });
    };

    const handleDependentSpouseBusinessChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, value: e.target.value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseBusiness: "",
        });
    };

    const handleDependentSpouseBusinessNoteChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseBusiness: { ...dependentData.dependentSpouseBusiness, note: e.target.value },
        });
    };
    const handleDependentSpouseBusinessNoteBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseBusinessNote:
                dependentData.dependentSpouseBusiness.note === "" ? "職業を入力してください" : "",
        });
    };

    const handleDependentSpouseIncomeChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentData({
            ...dependentData,
            dependentSpouseIncome: { ...dependentData.dependentSpouseIncome, value: value },
        });
    };
    const handleDependentSpouseIncomeBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseIncome: dependentData.dependentSpouseIncome.value === "" ? "年収を入力してください" : "",
        });
    };

    const handleDependentSpouseOverseasChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseOverseas: { ...dependentData.dependentSpouseOverseas, value: e.target.value },
            dependentSpouseOverseasDate: { ...dependentData.dependentSpouseOverseasDate, value: null },
            dependentSpouseOverseasReason: {
                ...dependentData.dependentSpouseOverseasReason,
                value: "",
                note: "",
                date: null,
            },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasReason: "",
            dependentSpouseOverseasReasonNote: "",
            dependentSpouseOverseasReasonDate: "",
        });
    };

    const handleDependentSpouseOverseasDateChange = (value) => {
        setDependentData({
            ...dependentData,
            dependentSpouseOverseasDate: { ...dependentData.dependentSpouseOverseasDate, value: value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasDate: "",
        });
    };
    const handleDependentSpouseOverseasDateBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasDate:
                dependentData.dependentSpouseOverseasDate.value === null ? "日付を選択してください" : "",
        });
    };

    const handleDependentSpouseOverseasReasonChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseOverseasReason: {
                ...dependentData.dependentSpouseOverseasReason,
                value: e.target.value,
                note: "",
                date: null,
            },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasReason: "",
            dependentSpouseOverseasReasonNote: "",
            dependentSpouseOverseasReasonDate: "",
        });
    };

    const handleDependentSpouseOverseasReasonNoteChange = (e) => {
        setDependentData({
            ...dependentData,
            dependentSpouseOverseasReason: { ...dependentData.dependentSpouseOverseasReason, note: e.target.value },
        });
    };
    const handleDependentSpouseOverseasReasonNoteBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasReasonNote:
                dependentData.dependentSpouseOverseasReason.note === "" ? "理由を入力してください" : "",
        });
    };

    const handleDependentSpouseOverseasReasonDateChange = (value) => {
        setDependentData({
            ...dependentData,
            dependentSpouseOverseasReason: { ...dependentData.dependentSpouseOverseasReason, date: value },
        });
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasReasonDate: "",
        });
    };
    const handleDependentSpouseOverseasReasonDateBlur = () => {
        setDependentError({
            ...dependentError,
            dependentSpouseOverseasReasonDate:
                dependentData.dependentSpouseOverseasReason.date === null ? "転入日を選択してください" : "",
        });
    };

    const handleDependentOtherFlagChange = (e) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setDependentOtherFlag({
                ...dependentOtherFlag,
                value: false,
            });
            setDependentOtherData([
                {
                    dependentOtherItem: { title: "項目", value: "" },
                    dependentOtherName: { title: "氏名", value: "" },
                    dependentOtherKana: { title: "フリガナ", value: "" },
                    dependentOtherGender: { title: "性別", value: "female" },
                    dependentOtherBirthday: { title: "生年月日", value: null },
                    dependentOtherRelationship: { title: "続柄", value: "", note: "" },
                    dependentOtherTogether: { title: "同居区分", value: true },
                    dependentOtherAddress: {
                        title: "住所",
                        post: "",
                        prefecture: "",
                        city: "",
                        other: "",
                        building: "",
                    },
                    dependentOtherNumber: { title: "個人番号または基礎年金番号", value: "" },
                    dependentOtherDate: { title: "被扶養者になった日", value: null },
                    dependentOtherReason: { title: "理由", value: "", note: "", deadDate: null },
                    dependentOtherBusiness: { title: "職業", value: "", note: "" },
                    dependentOtherIncome: { title: "年収", value: "" },
                    dependentOtherOverseas: { title: "海外特例要件", value: "" },
                    dependentOtherOverseasDate: { title: "該当した日", value: null },
                    dependentOtherOverseasReason: { title: "理由", value: "", note: "" },
                    dependentOtherNote: { title: "備考", value: "" },
                    dependentOtherMyNumberFront: { title: "マイナンバー・表", value: "" },
                    dependentOtherMyNumberBack: { title: "マイナンバー・裏", value: "" },
                    dependentOtherRelationshipCertificate: { title: "続柄証明書類", value: "" },
                    dependentOtherResident: { title: "住民票", value: "" },
                    dependentOtherIncomeCertificate: { title: "収入証明書", value: "" },
                    dependentOtherStudentCertificate: { title: "学生証", value: "" },
                },
            ]);
            setDependentOtherError([
                {
                    dependentOtherItem: "",
                    dependentOtherName: "",
                    dependentOtherKana: "",
                    dependentOtherBirthday: "",
                    dependentOtherRelationship: "",
                    dependentOtherRelationshipNote: "",
                    dependentOtherAddressPost: "",
                    dependentOtherAddressPrefecture: "",
                    dependentOtherAddressCity: "",
                    dependentOtherAddressOther: "",
                    dependentOtherNumber: "",
                    dependentOtherDate: "",
                    dependentOtherReason: "",
                    dependentOtherReasonNote: "",
                    dependentOtherReasonDeadDate: "",
                    dependentOtherBusiness: "",
                    dependentOtherBusinessNote: "",
                    dependentOtherIncome: "",
                    dependentOtherOverseas: "",
                    dependentOtherOverseasDate: "",
                    dependentOtherOverseasReason: "",
                    dependentOtherOverseasReasonNote: "",
                },
            ]);
        } else {
            setDependentOtherFlag({
                ...dependentOtherFlag,
                value: boolValue,
            });
        }
    };

    const handleDependentOtherItemChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherItem: { ...newDependentOtherData[index].dependentOtherItem, value: e.target.value },
                dependentOtherDate: { ...newDependentOtherData[index].dependentOtherDate, value: null },
                dependentOtherReason: {
                    ...newDependentOtherData[index].dependentOtherReason,
                    value: "",
                    note: "",
                    deadDate: null,
                },
                dependentOtherBusiness: { ...newDependentOtherData[index].dependentOtherBusiness, value: "", note: "" },
                dependentOtherIncome: { ...newDependentOtherData[index].dependentOtherIncome, value: "" },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = { ...newData[index], dependentOtherItem: "" };
            return newData;
        });
    };

    const handleDependentOtherNameChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherName: { ...newDependentOtherData[index].dependentOtherName, value: e.target.value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherNameBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherName:
                    dependentOtherData[index].dependentOtherName.value === "" ? "被扶養者の氏名を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherKanaChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherKana: { ...newDependentOtherData[index].dependentOtherKana, value: e.target.value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherKanaBlur = (index) => {
        const hiraganaToKatakana = (str) => {
            return str.replace(/[\u3041-\u3096]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) + 0x60);
            });
        };
        const value = hiraganaToKatakana(dependentOtherData[index].dependentOtherKana.value.replace(/ /g, "　").trim());

        const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
        const convertedValue = value
            .replace(reg, (match) => kanaMap[match])
            .replace(/ﾞ/g, "゛")
            .replace(/ﾟ/g, "゜");

        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherKana: { ...newDependentOtherData[index].dependentOtherKana, value: convertedValue },
            };
            return newDependentOtherData;
        });

        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherKana:
                    dependentOtherData[index].dependentOtherKana.value === ""
                        ? "被扶養者のフリガナを入力してください"
                        : "",
            };
            return newData;
        });
    };

    const handleDependentOtherBirthdayChange = (value, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherBirthday: {
                    ...newDependentOtherData[index].dependentOtherBirthday,
                    value: value,
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherBirthday: "",
            };
            return newData;
        });
    };
    const handleDependentOtherBirthdayBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherBirthday:
                    dependentOtherData[index].dependentOtherBirthday.value === null ? "生年月日を選択してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherGenderChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherGender: {
                    ...newDependentOtherData[index].dependentOtherGender,
                    value: e.target.value,
                },
            };
            return newDependentOtherData;
        });
    };

    const handleDependentOtherRelationshipChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherRelationship: {
                    ...newDependentOtherData[index].dependentOtherRelationship,
                    value: e.target.value,
                    note: "",
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherRelationship: "",
                dependentOtherRelationshipNote: "",
            };
            return newData;
        });
    };

    const handleDependentOtherNumberChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherNumber: { ...newDependentOtherData[index].dependentOtherNumber, value: value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherNumberBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherNumber:
                    dependentOtherData[index].dependentOtherNumber.value === ""
                        ? "個人番号または基礎年金番号を入力してください"
                        : "",
            };
            return newData;
        });
    };

    const handleDependentOtherTogetherChange = (e, index) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setDependentOtherData((prev) => {
                const newDependentOtherData = [...prev];
                newDependentOtherData[index] = {
                    ...newDependentOtherData[index],
                    dependentOtherTogether: {
                        ...newDependentOtherData[index].dependentOtherTogether,
                        value: boolValue,
                    },
                };
                return newDependentOtherData;
            });
        } else {
            setDependentOtherData((prev) => {
                const newDependentOtherData = [...prev];
                newDependentOtherData[index] = {
                    ...newDependentOtherData[index],
                    dependentOtherTogether: {
                        ...newDependentOtherData[index].dependentOtherTogether,
                        value: boolValue,
                    },
                    dependentOtherAddress: {
                        ...newDependentOtherData[index].dependentOtherAddress,
                        post: "",
                        prefecture: "",
                        city: "",
                        other: "",
                        building: "",
                    },
                };
                return newDependentOtherData;
            });

            setDependentOtherError((prev) => {
                const newData = [...prev];
                newData[index] = {
                    ...newData[index],
                    dependentOtherAddressPost: "",
                    dependentOtherAddressPrefecture: "",
                    dependentOtherAddressCity: "",
                    dependentOtherAddressOther: "",
                };
                return newData;
            });
        }
    };

    const handleDependentOtherAddressPostChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherAddress: { ...newDependentOtherData[index].dependentOtherAddress, post: value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherAddressPostBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherAddressPost:
                    dependentOtherData[index].dependentOtherAddress.post === "" ? "郵便番号を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherAddressPrefectureChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherAddress: {
                    ...newDependentOtherData[index].dependentOtherAddress,
                    prefecture: e.target.value,
                },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherAddressPrefectureBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherAddressPrefecture:
                    dependentOtherData[index].dependentOtherAddress.prefecture === ""
                        ? "都道府県を選択してください"
                        : "",
            };
            return newData;
        });
    };

    const handleDependentOtherAddressCityChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherAddress: { ...newDependentOtherData[index].dependentOtherAddress, city: e.target.value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherAddressCityBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherAddressCity:
                    dependentOtherData[index].dependentOtherAddress.city === "" ? "市区町村を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherAddressOtherChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherAddress: { ...newDependentOtherData[index].dependentOtherAddress, other: e.target.value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherAddressOtherBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherAddressOther:
                    dependentOtherData[index].dependentOtherAddress.other === ""
                        ? "上記以降の住所を入力してください"
                        : "",
            };
            return newData;
        });
    };

    const handleDependentOtherAddressBuildingChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherAddress: {
                    ...newDependentOtherData[index].dependentOtherAddress,
                    building: e.target.value,
                },
            };
            return newDependentOtherData;
        });
    };

    const handleDependentOtherDateChange = (value, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherDate: { ...newDependentOtherData[index].dependentOtherDate, value: value },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherDate: "",
            };
            return newData;
        });
    };
    const handleDependentOtherDateBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherDate:
                    dependentOtherData[index].dependentOtherDate.value === null ? "日付を選択してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherReasonChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherReason: {
                    ...newDependentOtherData[index].dependentOtherReason,
                    value: e.target.value,
                    note: "",
                    deadDate: null,
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherReason: "",
                dependentOtherReasonNote: "",
                dependentOtherReasonDeadDate: "",
            };
            return newData;
        });
    };

    const handleDependentOtherReasonNoteChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherReason: { ...newDependentOtherData[index].dependentOtherReason, note: e.target.value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherReasonNoteBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherReasonNote:
                    dependentOtherData[index].dependentOtherReason.note === "" ? "理由を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherReasonDeadDateChange = (value, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherReason: {
                    ...newDependentOtherData[index].dependentOtherReason,
                    deadDate: value,
                },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherReasonDeadDateBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherReasonDeadDate:
                    dependentOtherData[index].dependentOtherReason.deadDate === null ? "日付を選択してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherBusinessChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherBusiness: {
                    ...newDependentOtherData[index].dependentOtherBusiness,
                    value: e.target.value,
                    note: "",
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherBusiness: "",
                dependentOtherBusinessNote: "",
            };
            return newData;
        });
    };

    const handleDependentOtherBusinessNoteChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherBusiness: {
                    ...newDependentOtherData[index].dependentOtherBusiness,
                    note: value,
                },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherBusinessNoteBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherBusinessNote:
                    dependentOtherData[index].dependentOtherBusiness.note === "" ? "学年を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherIncomeChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherIncome: { ...newDependentOtherData[index].dependentOtherIncome, value: value },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherIncomeBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherIncome:
                    dependentOtherData[index].dependentOtherIncome.value === "" ? "年収を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherOverseasChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherOverseas: {
                    ...newDependentOtherData[index].dependentOtherOverseas,
                    value: e.target.value,
                },
                dependentOtherOverseasDate: {
                    ...newDependentOtherData[index].dependentOtherOverseasDate,
                    value: null,
                },
                dependentOtherOverseasReason: {
                    ...newDependentOtherData[index].dependentOtherOverseasReason,
                    value: "",
                    note: "",
                    date: null,
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseas: "",
                dependentOtherOverseasDate: "",
                dependentOtherOverseasReason: "",
                dependentOtherOverseasReasonNote: "",
                dependentOtherOverseasReasonDate: "",
            };
            return newData;
        });
    };

    const handleDependentOtherOverseasDateChange = (value, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherOverseasDate: {
                    ...newDependentOtherData[index].dependentOtherOverseasDate,
                    value: value,
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasDate: "",
            };
            return newData;
        });
    };
    const handleDependentOtherOverseasDateBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasDate:
                    dependentOtherData[index].dependentOtherOverseasDate.value === null ? "日付を選択してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherOverseasReasonChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherOverseasReason: {
                    ...newDependentOtherData[index].dependentOtherOverseasReason,
                    value: e.target.value,
                    note: "",
                    date: null,
                },
            };
            return newDependentOtherData;
        });
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasReason: "",
                dependentOtherOverseasReasonNote: "",
                dependentOtherOverseasReasonDate: "",
            };
            return newData;
        });
    };

    const handleDependentOtherOverseasReasonNoteChange = (e, index) => {
        setDependentOtherData((prev) => {
            const newDependentOtherData = [...prev];
            newDependentOtherData[index] = {
                ...newDependentOtherData[index],
                dependentOtherOverseasReason: {
                    ...newDependentOtherData[index].dependentOtherOverseasReason,
                    note: e.target.value,
                },
            };
            return newDependentOtherData;
        });
    };
    const handleDependentOtherOverseasReasonNoteBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasReasonNote:
                    dependentOtherData[index].dependentOtherOverseasReason.note === "" ? "理由を入力してください" : "",
            };
            return newData;
        });
    };

    const handleDependentOtherOverseasReasonDateChange = (value, index) => {
        setDependentOtherData((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasReason: {
                    ...newData[index].dependentOtherOverseasReason,
                    date: value,
                },
            };
            return newData;
        });
    };
    const handleDependentOtherOverseasReasonDateBlur = (index) => {
        setDependentOtherError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                dependentOtherOverseasReasonDate:
                    dependentOtherData[index].dependentOtherOverseasReason.date === null
                        ? "転入日を選択してください"
                        : "",
            };
            return newData;
        });
    };

    const handleDependentOtherAdd = () => {
        if (dependentOtherData.length <= 5) {
            setDependentOtherData([
                ...dependentOtherData,
                {
                    dependentOtherItem: { title: "項目", value: "" },
                    dependentOtherName: { title: "氏名", value: "" },
                    dependentOtherKana: { title: "フリガナ", value: "" },
                    dependentOtherGender: { title: "性別", value: "female" },
                    dependentOtherBirthday: { title: "生年月日", value: null },
                    dependentOtherRelationship: { title: "続柄", value: "", note: "" },
                    dependentOtherTogether: { title: "同居区分", value: true },
                    dependentOtherAddress: {
                        title: "住所",
                        post: "",
                        prefecture: "",
                        city: "",
                        other: "",
                        building: "",
                    },
                    dependentOtherNumber: { title: "個人番号または基礎年金番号", value: "" },
                    dependentOtherDate: { title: "被扶養者になった日", value: null },
                    dependentOtherReason: { title: "理由", value: "", note: "", deadDate: null },
                    dependentOtherBusiness: { title: "職業", value: "", note: "" },
                    dependentOtherIncome: { title: "年収", value: "" },
                    dependentOtherOverseas: { title: "海外特例要件", value: "" },
                    dependentOtherOverseasDate: { title: "該当した日", value: null },
                    dependentOtherOverseasReason: { title: "理由", value: "", note: "" },
                    dependentOtherNote: { title: "備考", value: "" },
                    dependentOtherMyNumberFront: { title: "マイナンバー・表", value: "" },
                    dependentOtherMyNumberBack: { title: "マイナンバー・裏", value: "" },
                    dependentOtherRelationshipCertificate: { title: "続柄証明書類", value: "" },
                    dependentOtherResident: { title: "住民票", value: "" },
                    dependentOtherIncomeCertificate: { title: "収入証明書", value: "" },
                    dependentOtherStudentCertificate: { title: "学生証", value: "" },
                },
            ]);

            setDependentOtherError([
                ...dependentOtherError,
                {
                    dependentOtherItem: "",
                    dependentOtherName: "",
                    dependentOtherKana: "",
                    dependentOtherBirthday: "",
                    dependentOtherRelationship: "",
                    dependentOtherRelationshipNote: "",
                    dependentOtherAddressPost: "",
                    dependentOtherAddressPrefecture: "",
                    dependentOtherAddressCity: "",
                    dependentOtherAddressOther: "",
                    dependentOtherNumber: "",
                    dependentOtherDate: "",
                    dependentOtherReason: "",
                    dependentOtherReasonNote: "",
                    dependentOtherReasonDeadDate: "",
                    dependentOtherBusiness: "",
                    dependentOtherBusinessNote: "",
                    dependentOtherIncome: "",
                    dependentOtherOverseas: "",
                    dependentOtherOverseasDate: "",
                    dependentOtherOverseasReason: "",
                    dependentOtherOverseasReasonNote: "",
                },
            ]);
        }
    };

    const handleDependentOtherDelete = (index) => {
        setDependentOtherData(dependentOtherData.filter((_, i) => i !== index));
        setDependentOtherError(dependentOtherError.filter((_, i) => i !== index));
    };

    const handleStaffJoinDateChange = (value) => {
        setStaffMoreData({
            ...staffMoreData,
            staffJoinDate: { ...staffMoreData.staffJoinDate, value: value },
        });
    };

    const handleStaffDivisionChange = (e) => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffDivision.value = e.target.value;
            return newData;
        });
    };

    const handleStaffPositionChange = (e) => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffPosition.value = e.target.value;
            return newData;
        });
    };

    const handleStaffCategoryChange = (e) => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffCategory.value = e.target.value;
            return newData;
        });
    };

    const handleStaffConflictDateChange = (value) => {
        setStaffMoreData({
            ...staffMoreData,
            staffConflictDate: { ...staffMoreData.staffConflictDate, value: value },
        });
    };

    const handleStaffAgreementFlagChange = (e) => {
        const value = e.target.value === "true";
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffAgreementFlag.value = value;
            return newData;
        });
    };

    const handleStaffDispatchOpenDialog = () => {
        // setDispatchDialog(true);
    };
    const handleStaffDispatchCloseDialog = () => {
        // setDispatchDialog(false);
    };
    const handleStaffDispatchValueChange = (e) => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffDispatch.value = e.target.value;
            return newData;
        });
        // 登録した場合、履歴の配列に値を放り込む
    };
    const handleStaffDispatchValueClear = () => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffDispatch.value = "";
            return newData;
        });
    };

    const handleStaffDispatchDateChange = (value) => {
        setStaffMoreData({
            ...staffMoreData,
            staffDispatch: { ...staffMoreData.staffDispatch, date: value },
        });
        // 登録した場合、履歴の配列に値を放り込む
    };

    const handleStaffCommutingChange = (e) => {
        setStaffMoreData((prev) => {
            const newData = { ...prev };
            newData.staffCommuting.value = e.target.value;
            return newData;
        });
    };

    const handleStaffCommuteSelectChange = (e) => {
        const boolValue = e.target.value === "true";
        if (!boolValue) {
            setStaffUploadData({
                ...staffUploadData,
                staffCommute: {
                    ...staffUploadData.staffCommute,
                    select: boolValue,
                    driverLicenseFront: "",
                    driverLicenseBack: "",
                    vehicleInspection: "",
                    compulsoryInsurance: "",
                    optionalInsurance: "",
                },
            });
        } else {
            setStaffUploadData({
                ...staffUploadData,
                staffCommute: { ...staffUploadData.staffCommute, select: boolValue },
            });
        }
    };

    const handleStaffLicenseChange = (e, index) => {
        setStaffLicenseData((prev) => {
            const newData = [...prev];
            newData[index] = { ...newData[index], title: e.target.value };
            return newData;
        });
    };
    const handleStaffLicenseBlur = (index) => {
        setStaffLicenseError((prev) => {
            const newData = [...prev];
            newData[index] = {
                ...newData[index],
                title: staffLicenseData[index].title === "" ? "資格・免許を入力してください" : "",
            };
            return newData;
        });
    };

    const handleStaffLicenseAdd = () => {
        if (staffLicenseData.length <= 5) {
            setStaffLicenseData([...staffLicenseData, { title: "", value: "" }]);
            setStaffLicenseError([...staffLicenseError, { title: "", value: "" }]);
        }
    };

    const handleStaffLicenseDelete = (index) => {
        setStaffLicenseData(staffLicenseData.filter((_, i) => i !== index));
        setStaffLicenseError(staffLicenseError.filter((_, i) => i !== index));
    };

    const handleStaffDomitoryFlagChange = (e) => {
        const value = e.target.value === "true";
        setStaffDomitoryData((prev) => {
            const newData = { ...prev };
            newData.staffDomitoryFlag.value = value;
            return newData;
        });
    };

    const [selectedDomitory, setSelectedDomitory] = useState(null);
    const [domitoryOpen, setDomitoryOpen] = useState(false);
    const handleStaffDomitoryNameChange = (e) => {
        setDomitoryOpen(true);
    };

    const handleDomitoryClose = () => {
        setDomitoryOpen(false);
    };

    const handleRowSelectionChange = (row) => {
        const isSelected =
            selectedDomitory?.name === row.original.name && selectedDomitory?.number === row.original.number;

        setSelectedDomitory(isSelected ? null : row.original);
    };

    const handleAddDomitory = () => {
        if (selectedDomitory) {
            setStaffDomitoryData((prev) => ({
                ...prev,
                staffDomitoryName: {
                    ...prev.staffDomitoryName,
                    value: selectedDomitory.name,
                },
                staffDomitoryNumber: {
                    ...prev.staffDomitoryNumber,
                    value: selectedDomitory.number,
                },
            }));
        } else {
            setStaffDomitoryData((prev) => ({
                ...prev,
                staffDomitoryName: {
                    ...prev.staffDomitoryName,
                    value: "",
                },
                staffDomitoryNumber: {
                    ...prev.staffDomitoryNumber,
                    value: "",
                },
            }));
        }
        handleDomitoryClose();
    };

    const handleStaffDomitoryNameClear = (e) => {
        e.stopPropagation();
        setStaffDomitoryData({
            ...staffDomitoryData,
            staffDomitoryName: { ...staffDomitoryData.staffDomitoryName, value: "" },
            staffDomitoryNumber: { ...staffDomitoryData.staffDomitoryNumber, value: "" },
        });
        setSelectedDomitory(null);
    };

    const handleStaffDomitoryInDateChange = (value) => {
        setStaffDomitoryData({
            ...staffDomitoryData,
            staffDomitoryInDate: { ...staffDomitoryData.staffDomitoryInDate, value: value },
        });
    };

    const handleStaffEquipmentFlagChange = (e) => {
        const value = e.target.value === "true";

        if (!value) {
            setEquip((prev) => {
                const newData = { ...prev };
                newData.staffEquipmentFlag.value = value;
                newData.staffEquipmentContractor.value = "";
                return newData;
            });
        } else {
            setEquip((prev) => {
                const newData = { ...prev };
                newData.staffEquipmentFlag.value = value;
                return newData;
            });
        }
    };

    const handleStaffEquipmentCheckboxChange = (e, index) => {
        const newStaffEquipmentData = [...staffEquipmentData];
        newStaffEquipmentData[index] = {
            ...newStaffEquipmentData[index],
            selected: e.target.checked,
            value: e.target.checked ? newStaffEquipmentData[index].value : "",
            inDate: e.target.checked ? newStaffEquipmentData[index].inDate : null,
        };
        setStaffEquipmentData(newStaffEquipmentData);
    };

    const handleStaffEquipmentNumberChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        const newStaffEquipmentData = [...staffEquipmentData];
        newStaffEquipmentData[index] = {
            ...newStaffEquipmentData[index],
            value: value,
        };
        setStaffEquipmentData(newStaffEquipmentData);
    };

    const handleStaffEquipmentInDateChange = (date, index) => {
        const newStaffEquipmentData = [...staffEquipmentData];
        newStaffEquipmentData[index] = {
            ...newStaffEquipmentData[index],
            inDate: date,
        };
        setStaffEquipmentData(newStaffEquipmentData);
    };

    const [validation, setValidation] = useState("");

    const handleAddConfirm = () => {
        if (request === true) {
            console.log("request: true");
            // setRequestOpen(true);
        } else {
            const staffDataErrors = {
                staffName: staffData.staffName.value === "" ? "スタッフ名を入力してください" : "",
                staffKana: staffData.staffKana.value === "" ? "フリガナを入力してください" : "",
                staffBirthday: staffData.staffBirthday.value === null ? "生年月日を入力してください" : "",
                staffPhone: staffData.staffPhone.value === "" ? "電話番号を入力してください" : "",
                staffMail: staffData.staffMail.value === "" ? "メールアドレスを入力してください" : "",
                staffAddressPost: staffData.staffAddress.post === "" ? "郵便番号を入力してください" : "",
                staffAddressPrefecture: staffData.staffAddress.prefecture === "" ? "都道府県を選択してください" : "",
                staffAddressCity: staffData.staffAddress.city === "" ? "市区町村を入力してください" : "",
                staffAddressOther: staffData.staffAddress.other === "" ? "上記以降の住所を入力してください" : "",
                staffResidentAddressPost:
                    !staffData.staffResidentAddress.ibid && staffData.staffResidentAddress.post === ""
                        ? "郵便番号を入力してください"
                        : "",
                staffResidentAddressPrefecture:
                    !staffData.staffResidentAddress.ibid && staffData.staffResidentAddress.prefecture === ""
                        ? "都道府県を選択してください"
                        : "",
                staffResidentAddressCity:
                    !staffData.staffResidentAddress.ibid && staffData.staffResidentAddress.city === ""
                        ? "市区町村を入力してください"
                        : "",
                staffResidentAddressOther:
                    !staffData.staffResidentAddress.ibid && staffData.staffResidentAddress.other === ""
                        ? "上記以降の住所を入力してください"
                        : "",
                staffNationality:
                    staffData.staffNationality.select !== "japanese" && staffData.staffNationality.value === ""
                        ? "国名を入力してください"
                        : "",
                staffBankName: staffData.staffBank.bankName === "" ? "銀行名を入力してください" : "",
                staffBankBranch: staffData.staffBank.branch === "" ? "支店名を入力してください" : "",
                staffBankNumber: staffData.staffBank.number === "" ? "口座番号を入力してください" : "",
                staffBankHolder: staffData.staffBank.holder === "" ? "口座名義を入力してください" : "",
            };
            setStaffDataError((prevState) => ({
                ...prevState,
                ...staffDataErrors,
            }));

            const emergencyContactDataErrors = {
                emergencyContactName:
                    emergencyContactData.emergencyContactName.value === "" ? "緊急連絡先の氏名を入力してください" : "",
                emergencyContactKana:
                    emergencyContactData.emergencyContactKana.value === ""
                        ? "緊急連絡先のフリガナを入力してください"
                        : "",
                emergencyContactPhone:
                    emergencyContactData.emergencyContactPhone.value === ""
                        ? "緊急連絡先の電話番号を入力してください"
                        : "",
                emergencyContactRelationship:
                    emergencyContactData.emergencyContactRelationship.value === ""
                        ? "本人との関係を入力してください"
                        : "",
                emergencyContactAddressPost:
                    emergencyContactData.emergencyContactAddress.post === "" ? "郵便番号を入力してください" : "",
                emergencyContactAddressPrefecture:
                    emergencyContactData.emergencyContactAddress.prefecture === "" ? "都道府県を選択してください" : "",
                emergencyContactAddressCity:
                    emergencyContactData.emergencyContactAddress.city === "" ? "市区町村を入力してください" : "",
                emergencyContactAddressOther:
                    emergencyContactData.emergencyContactAddress.other === "" ? "上記以降の住所を入力してください" : "",
            };
            setEmergencyContactError((prevState) => ({
                ...prevState,
                ...emergencyContactDataErrors,
            }));

            let spouseErrors = false;
            if (spouseData.spouseFlag.value === true) {
                const spouseDataErrors = {
                    spouseName: spouseData.spouseName.value === "" ? "配偶者の氏名を入力してください" : "",
                    spouseKana: spouseData.spouseKana.value === "" ? "配偶者のフリガナを入力してください" : "",
                    spouseBirthday:
                        spouseData.spouseBirthday.value === null ? "配偶者の生年月日を入力してください" : "",
                    spouseAddressPost:
                        spouseData.spouseTogether.value === false && spouseData.spouseAddress.post === ""
                            ? "郵便番号を入力してください"
                            : "",
                    spouseAddressPrefecture:
                        spouseData.spouseTogether.value === false && spouseData.spouseAddress.prefecture === ""
                            ? "都道府県を選択してください"
                            : "",
                    spouseAddressCity:
                        spouseData.spouseTogether.value === false && spouseData.spouseAddress.city === ""
                            ? "市区町村を入力してください"
                            : "",
                    spouseAddressOther:
                        spouseData.spouseTogether.value === false && spouseData.spouseAddress.other === ""
                            ? "上記以降の住所を入力してください"
                            : "",
                    spouseNationality:
                        spouseData.spouseNationality.select !== "japanese" && spouseData.spouseNationality.value === ""
                            ? "国籍を選択してください"
                            : "",
                };
                setSpouseError((prevState) => ({
                    ...prevState,
                    ...spouseDataErrors,
                }));
                spouseErrors = Object.values(spouseDataErrors).some((error) => error !== "");
            }

            let dependentErrors = false;
            if (dependentData.dependentFlag.value === true && dependentData.dependentSpouseFlag.value === true) {
                const dependentDataErrors = {
                    dependentSpouseItem: dependentData.dependentSpouseItem.value === "" ? "項目を選択してください" : "",
                    dependentSpouseNumber:
                        dependentData.dependentSpouseNumber.value === ""
                            ? "個人番号または基礎年金番号を入力してください"
                            : "",
                    dependentSpousePhoneSelect:
                        dependentData.dependentSpousePhone.select === "" ? "電話の種類を選択してください" : "",
                    dependentSpousePhoneValue:
                        dependentData.dependentSpousePhone.value === "" ? "電話番号を入力してください" : "",
                    dependentSpouseDate:
                        (dependentData.dependentSpouseItem.value === "該当" &&
                            dependentData.dependentSpouseDate.value === null) ||
                        (dependentData.dependentSpouseItem.value === "非該当" &&
                            dependentData.dependentSpouseDate.value === null) ||
                        (dependentData.dependentSpouseItem.value === "" &&
                            dependentData.dependentSpouseDate.value === null)
                            ? "日付を選択してください"
                            : "",
                    dependentSpouseReason:
                        dependentData.dependentSpouseReason.value === "" ? "理由を選択してください" : "",
                    dependentSpouseReasonNote:
                        dependentData.dependentSpouseReason.value === "その他" &&
                        dependentData.dependentSpouseReason.note === ""
                            ? "理由を入力してください"
                            : "",
                    dependentSpouseReasonDeadDate:
                        dependentData.dependentSpouseReason.value === "死亡" &&
                        dependentData.dependentSpouseReason.deadDate === null
                            ? "日付を選択してください"
                            : "",
                    dependentSpouseBusiness:
                        (dependentData.dependentSpouseItem.value === "該当" &&
                            dependentData.dependentSpouseBusiness.value === "") ||
                        (dependentData.dependentSpouseItem.value === "変更" &&
                            dependentData.dependentSpouseBusiness.value === "") ||
                        (dependentData.dependentSpouseItem.value === "" &&
                            dependentData.dependentSpouseBusiness.value === "")
                            ? "職業を選択してください"
                            : "",
                    dependentSpouseBusinessNote:
                        dependentData.dependentSpouseItem.value === "該当" &&
                        dependentData.dependentSpouseBusiness.value === "その他" &&
                        dependentData.dependentSpouseBusiness.note === ""
                            ? "職業を入力してください"
                            : "",
                    dependentSpouseIncome:
                        (dependentData.dependentSpouseItem.value === "該当" &&
                            dependentData.dependentSpouseIncome.value === "") ||
                        (dependentData.dependentSpouseItem.value === "変更" &&
                            dependentData.dependentSpouseIncome.value === "") ||
                        (dependentData.dependentSpouseItem.value === "" &&
                            dependentData.dependentSpouseIncome.value === "")
                            ? "年収を入力してください"
                            : "",
                    dependentSpouseOverseasDate:
                        dependentData.dependentSpouseOverseas.value === "該当" &&
                        dependentData.dependentSpouseOverseasDate.value === null
                            ? "該当した日を選択してください"
                            : dependentData.dependentSpouseOverseas.value === "非該当" &&
                              dependentData.dependentSpouseOverseasDate.value === null
                            ? "非該当となった日を選択してください"
                            : "",
                    dependentSpouseOverseasReason:
                        (dependentData.dependentSpouseOverseas.value === "該当" ||
                            dependentData.dependentSpouseOverseas.value === "非該当") &&
                        dependentData.dependentSpouseOverseasReason.value === ""
                            ? "理由を選択してください"
                            : "",
                    dependentSpouseOverseasReasonNote:
                        dependentData.dependentSpouseOverseasReason.value === "その他" &&
                        dependentData.dependentSpouseOverseasReason.note === ""
                            ? "理由を入力してください"
                            : dependentData.dependentSpouseOverseasReason.value === "国内転入" &&
                              dependentData.dependentSpouseOverseasReason.date === null
                            ? "転入日を選択してください"
                            : "",
                };
                setDependentError((prevState) => ({
                    ...prevState,
                    ...dependentDataErrors,
                }));
                dependentErrors = Object.values(dependentDataErrors).some((error) => error !== "");
            }

            let dependentOtherErrors = false;
            if (dependentOtherFlag.value === true) {
                if (Array.isArray(dependentOtherData)) {
                    dependentOtherData.forEach((item, index) => {
                        const errors = {
                            dependentOtherItem: item.dependentOtherItem.value === "" ? "項目を選択してください" : "",
                            dependentOtherName:
                                item.dependentOtherName.value === "" ? "被扶養者の氏名を入力してください" : "",
                            dependentOtherKana:
                                item.dependentOtherKana.value === "" ? "被扶養者のフリガナを入力してください" : "",
                            dependentOtherBirthday:
                                item.dependentOtherBirthday.value === null ? "生年月日を選択してください" : "",
                            dependentOtherRelationship:
                                item.dependentOtherRelationship.value === "" ? "続柄を選択してください" : "",
                            dependentOtherRelationshipNote:
                                item.dependentOtherRelationship.value === "その他" &&
                                item.dependentOtherRelationship.note === ""
                                    ? "続柄を入力してください"
                                    : "",
                            dependentOtherNumber:
                                item.dependentOtherNumber.value === ""
                                    ? "個人番号または基礎年金番号を入力してください"
                                    : "",
                            dependentOtherAddressPost:
                                item.dependentOtherTogether.value === false && item.dependentOtherAddress.post === ""
                                    ? "郵便番号を入力してください"
                                    : "",
                            dependentOtherAddressPrefecture:
                                item.dependentOtherTogether.value === false &&
                                item.dependentOtherAddress.prefecture === ""
                                    ? "都道府県を選択してください"
                                    : "",
                            dependentOtherAddressCity:
                                item.dependentOtherTogether.value === false && item.dependentOtherAddress.city === ""
                                    ? "市区町村を入力してください"
                                    : "",
                            dependentOtherAddressOther:
                                item.dependentOtherTogether.value === false && item.dependentOtherAddress.other === ""
                                    ? "上記以降の住所を入力してください"
                                    : "",
                            dependentOtherDate:
                                (item.dependentOtherItem.value === "該当" && item.dependentOtherDate.value === null) ||
                                (item.dependentOtherItem.value === "非該当" &&
                                    item.dependentOtherDate.value === null) ||
                                (item.dependentOtherItem.value === "" && item.dependentOtherDate.value === null)
                                    ? "日付を選択してください"
                                    : "",
                            dependentOtherReason:
                                item.dependentOtherReason.value === "" ? "理由を選択してください" : "",
                            dependentOtherReasonNote:
                                item.dependentOtherReason.value === "その他" && item.dependentOtherReason.note === ""
                                    ? "理由を入力してください"
                                    : "",
                            dependentOtherReasonDeadDate:
                                item.dependentOtherReason.value === "死亡" &&
                                item.dependentOtherReason.deadDate === null
                                    ? "日付を選択してください"
                                    : "",
                            dependentOtherBusiness:
                                (item.dependentOtherItem.value === "該当" &&
                                    item.dependentOtherBusiness.value === "") ||
                                (item.dependentOtherItem.value === "変更" && item.dependentOtherBusiness.value === "")
                                    ? "職業を選択してください"
                                    : "",
                            dependentOtherBusinessNote:
                                item.dependentOtherItem.value === "該当" &&
                                item.dependentOtherBusiness.value === "その他" &&
                                item.dependentOtherBusiness.note === ""
                                    ? "職業を入力してください"
                                    : "",
                            dependentOtherIncome:
                                (item.dependentOtherItem.value === "該当" && item.dependentOtherIncome.value === "") ||
                                (item.dependentOtherItem.value === "変更" && item.dependentOtherIncome.value === "")
                                    ? "年収を入力してください"
                                    : "",
                            dependentOtherOverseasDate:
                                item.dependentOtherOverseas.value === "該当" &&
                                item.dependentOtherOverseasDate.value === null
                                    ? "該当した日を選択してください"
                                    : item.dependentOtherOverseas.value === "非該当" &&
                                      item.dependentOtherOverseasDate.value === null
                                    ? "非該当となった日を選択してください"
                                    : "",
                            dependentOtherOverseasReason:
                                (item.dependentOtherOverseas.value === "該当" ||
                                    item.dependentOtherOverseas.value === "非該当") &&
                                item.dependentOtherOverseasReason.value === ""
                                    ? "理由を選択してください"
                                    : "",
                            dependentOtherOverseasReasonNote:
                                item.dependentOtherOverseasReason.value === "その他" &&
                                item.dependentOtherOverseasReason.note === ""
                                    ? "理由を入力してください"
                                    : item.dependentOtherOverseasReason.value === "国内転入" &&
                                      item.dependentOtherOverseasReason.date === null
                                    ? "転入日を選択してください"
                                    : "",
                        };
                        setDependentOtherError((prev) => {
                            const newData = [...prev];
                            newData[index] = {
                                ...newData[index],
                                ...errors,
                            };
                            return newData;
                        });
                        if (Object.values(errors).some((error) => error !== "")) {
                            dependentOtherErrors = true;
                        }
                    });
                }
            }

            const hasErrors =
                Object.values(staffDataErrors).some((error) => error !== "") ||
                Object.values(emergencyContactDataErrors).some((error) => error !== "") ||
                spouseErrors ||
                dependentErrors ||
                dependentOtherErrors;

            if (hasErrors) {
                setValidation("基本情報の入力内容に不備があります。");
            } else {
                setValidation("");
                setOpen(true);
            }
        }
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const [openTab, setOpenTab] = useState(0);
    const handleOpenTabChange = (event, newValue) => {
        setOpenTab(newValue);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/staff");
    };

    const handleAddRegister = () => {
        setOpen(false);
        setUploadOpen(true);
        const timer = setInterval(() => {
            setUploadProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(timer);
                    setUploadOpen(false);
                    setCompleteOpen(true);
                    return 100;
                }
                return prevProgress + 25;
            });
        }, 800);
    };

    const [uploadProgress, setUploadProgress] = useState(0);

    const [uploadOpen, setUploadOpen] = useState(false);

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleAddComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/staff/detail");
    };

    return (
        <>
            <Helmet>
                <title>スタッフ登録 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">スタッフを登録</Typography>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="入力を依頼する"
                            onChange={handleStaffInputRequestChange}
                            disabled
                        />
                    </Box>

                    {request !== true && (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                                <Tabs value={tab} onChange={handleTabChange}>
                                    <Tab label="基本情報" />
                                    <Tab label="アップロード書類" />
                                    <Tab label="社宅・備品" />
                                </Tabs>
                            </Box>

                            {tab === 0 ? (
                                <>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={staffData.staffName.title}
                                            value={staffData.staffName.value}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffNameChange}
                                            onBlur={handleStaffNameBlur}
                                            error={staffDataError.staffName !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffName !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffName}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                            mb: 1,
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={staffData.staffKana.title}
                                            value={staffData.staffKana.value}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffKanaChange}
                                            onBlur={handleStaffKanaBlur}
                                            error={staffDataError.staffKana !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffKana !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffKana}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <Typography textAlign="left">性別</Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <RadioGroup
                                                    row
                                                    value={staffData.staffGender.value}
                                                    onChange={handleStaffGenderChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel value="male" control={<Radio />} label="男性" />
                                                    <FormControlLabel value="female" control={<Radio />} label="女性" />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                mt: 3,
                                            }}
                                        >
                                            <Grid2
                                                container
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "600px",
                                                }}
                                            >
                                                <Grid2 size={{ xs: 7, md: 7 }}>
                                                    <DesktopDatePicker
                                                        label={staffData.staffBirthday.title}
                                                        value={staffData.staffBirthday.value}
                                                        format="yyyy/MM/dd"
                                                        onChange={handleStaffBirthdayChange}
                                                        slotProps={{
                                                            textField: {
                                                                error: staffDataError.staffBirthday !== "",
                                                                clearable: true,
                                                                onBlur: handleStaffBirthdayBlur,
                                                                sx: { width: "530px" },
                                                            },
                                                            calendarHeader: {
                                                                format: "yyyy年MM月",
                                                            },
                                                        }}
                                                    />
                                                </Grid2>
                                                <Grid2 size={{ xs: 5, md: 5 }}>
                                                    <Typography textAlign="right">
                                                        {staffData.staffBirthday.value
                                                            ? `${
                                                                  new Date().getFullYear() -
                                                                  staffData.staffBirthday.value.getFullYear()
                                                              }歳`
                                                            : "00歳"}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                mt: 1,
                                            }}
                                        >
                                            {staffDataError.staffBirthday !== "" && (
                                                <Alert severity="error" sx={{ width: "600px" }}>
                                                    {staffDataError.staffBirthday}
                                                </Alert>
                                            )}
                                        </Box>
                                    </LocalizationProvider>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label="電話番号"
                                            value={staffData.staffPhone.value}
                                            sx={{ width: "600px" }}
                                            helperText="数字とハイフンのみ入力してください"
                                            onChange={handleStaffPhoneChange}
                                            onBlur={handleStaffPhoneBlur}
                                            error={staffDataError.staffPhone !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffPhone !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffPhone}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label="メールアドレス"
                                            value={staffData.staffMail.value}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffMailChange}
                                            onBlur={handleStaffMailBlur}
                                            error={staffDataError.staffMail !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffMail !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffMail}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography sx={{ width: "600px", textAlign: "left" }}>住所</Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            label="郵便番号"
                                            value={staffData.staffAddress.post}
                                            sx={{ width: "424px" }}
                                            onChange={handleStaffAddressPostChange}
                                            onBlur={handleStaffAddressPostBlur}
                                            helperText="数字のみ入力してください"
                                            error={staffDataError.staffAddressPost !== ""}
                                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        />
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            sx={{ width: "152px", height: "56px", ml: 3 }}
                                        >
                                            住所を検索する
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffAddressPost !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffAddressPost}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="都道府県"
                                            value={staffData.staffAddress.prefecture}
                                            sx={{ width: "288px" }}
                                            onChange={handleStaffAddressPrefectureChange}
                                            onBlur={handleStaffAddressPrefectureBlur}
                                            error={staffDataError.staffAddressPrefecture !== ""}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="市区町村"
                                            value={staffData.staffAddress.city}
                                            sx={{ width: "288px", ml: 3 }}
                                            onChange={handleStaffAddressCityChange}
                                            onBlur={handleStaffAddressCityBlur}
                                            error={staffDataError.staffAddressCity !== ""}
                                        />
                                    </Box>
                                    <Grid2
                                        container
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        <Grid2 sx={{ width: "288px" }}>
                                            {staffDataError.staffAddressPrefecture !== "" && (
                                                <Alert severity="error">{staffDataError.staffAddressPrefecture}</Alert>
                                            )}
                                        </Grid2>

                                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                                            {staffDataError.staffAddressCity !== "" && (
                                                <Alert severity="error">{staffDataError.staffAddressCity}</Alert>
                                            )}
                                        </Grid2>
                                    </Grid2>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="上記以降の住所"
                                            value={staffData.staffAddress.other}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffAddressOtherChange}
                                            onBlur={handleStaffAddressOtherBlur}
                                            error={staffDataError.staffAddressOther !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffAddressOther !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffAddressOther}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="建物名・部屋番号"
                                            value={staffData.staffAddress.building}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffAddressBuildingChange}
                                        />
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <Typography textAlign="left">住民票住所</Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <RadioGroup
                                                    row
                                                    value={staffData.staffResidentAddress.ibid}
                                                    onChange={handleStaffResidentAddressIbidChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="同上" />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="別住所"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {!staffData.staffResidentAddress.ibid && (
                                        <>
                                            <Box>
                                                <TextField
                                                    variant="outlined"
                                                    label="郵便番号"
                                                    value={staffData.staffResidentAddress.post}
                                                    sx={{ width: "424px" }}
                                                    onChange={handleStaffResidentAddressPostChange}
                                                    onBlur={handleStaffResidentAddressPostBlur}
                                                    helperText="数字のみ入力してください"
                                                    error={staffDataError.staffResidentAddressPost !== ""}
                                                    InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    sx={{ width: "152px", height: "56px", ml: 3 }}
                                                >
                                                    住所を検索する
                                                </Button>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                {staffDataError.staffResidentAddressPost !== "" && (
                                                    <Alert severity="error" sx={{ width: "600px" }}>
                                                        {staffDataError.staffResidentAddressPost}
                                                    </Alert>
                                                )}
                                            </Box>

                                            <Box sx={{ mt: 1 }}>
                                                <TextField
                                                    variant="outlined"
                                                    label="都道府県"
                                                    value={staffData.staffResidentAddress.prefecture}
                                                    sx={{ width: "288px" }}
                                                    onChange={handleStaffResidentAddressPrefectureChange}
                                                    onBlur={handleStaffResidentAddressPrefectureBlur}
                                                    error={staffDataError.staffResidentAddressPrefecture !== ""}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label="市区町村"
                                                    value={staffData.staffResidentAddress.city}
                                                    sx={{ width: "288px", ml: 3 }}
                                                    onChange={handleStaffResidentAddressCityChange}
                                                    onBlur={handleStaffResidentAddressCityBlur}
                                                    error={staffDataError.staffResidentAddressCity !== ""}
                                                />
                                            </Box>
                                            <Grid2
                                                container
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Grid2 sx={{ width: "288px" }}>
                                                    {staffDataError.staffResidentAddressPrefecture !== "" && (
                                                        <Alert severity="error">
                                                            {staffDataError.staffResidentAddressPrefecture}
                                                        </Alert>
                                                    )}
                                                </Grid2>

                                                <Grid2 sx={{ width: "288px", ml: 3 }}>
                                                    {staffDataError.staffResidentAddressCity !== "" && (
                                                        <Alert severity="error">
                                                            {staffDataError.staffResidentAddressCity}
                                                        </Alert>
                                                    )}
                                                </Grid2>
                                            </Grid2>

                                            <Box sx={{ mt: 1 }}>
                                                <TextField
                                                    variant="outlined"
                                                    label="上記以降の住所"
                                                    value={staffData.staffResidentAddress.other}
                                                    sx={{ width: "600px" }}
                                                    onChange={handleStaffResidentAddressOtherChange}
                                                    onBlur={handleStaffResidentAddressOtherBlur}
                                                    error={staffDataError.staffResidentAddressOther !== ""}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                {staffDataError.staffResidentAddressOther !== "" && (
                                                    <Alert severity="error" sx={{ width: "600px" }}>
                                                        {staffDataError.staffResidentAddressOther}
                                                    </Alert>
                                                )}
                                            </Box>

                                            <Box sx={{ mt: 1 }}>
                                                <TextField
                                                    variant="outlined"
                                                    label="建物名・部屋番号"
                                                    value={staffData.staffResidentAddress.building}
                                                    sx={{ width: "600px" }}
                                                    onChange={handleStaffResidentAddressBuildingChange}
                                                />
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                                            {staffData.staffNationality.title}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        <Grid2 container>
                                            <Grid2
                                                size={{ xs: 4, md: 4 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <RadioGroup
                                                    row
                                                    value={staffData.staffNationality.select}
                                                    onChange={handleStaffNationalityChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel
                                                        value="japanese"
                                                        control={<Radio />}
                                                        label="日本"
                                                    />
                                                    <FormControlLabel
                                                        value="overseas"
                                                        control={<Radio />}
                                                        label="外国"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                            <Grid2 size={{ xs: 8, md: 8 }}>
                                                <TextField
                                                    variant="outlined"
                                                    label="国名"
                                                    value={staffData.staffNationality.value}
                                                    sx={{ width: "390px" }}
                                                    onChange={handleStaffCountryChange}
                                                    onBlur={handleStaffCountryBlur}
                                                    error={staffDataError.staffNationality !== ""}
                                                    disabled={staffData.staffNationality.select === "japanese"}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffNationality !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffNationality}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={staffData.staffEmploymentInsuranceNumber.title}
                                            value={staffData.staffEmploymentInsuranceNumber.value}
                                            helperText="数字のみ入力してください"
                                            onChange={handleStaffEmploymentInsuranceNumberChange}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={staffData.staffBasicPensionNumber.title}
                                            value={staffData.staffBasicPensionNumber.value}
                                            helperText="数字のみ入力してください"
                                            onChange={handleStaffBasicPensionNumberChange}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                                            {staffData.staffBank.title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 7.5, md: 7.5 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label="金融機関名"
                                                    value={staffData.staffBank.bankName}
                                                    onChange={handleStaffBankChange}
                                                    onBlur={handleStaffBankBlur}
                                                    error={staffDataError.staffBankName !== ""}
                                                    fullWidth
                                                />
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 4.5, md: 4.5 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <RadioGroup
                                                    row
                                                    value={staffData.staffBank.select}
                                                    onChange={handleStaffBankSelectChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel value="bank" control={<Radio />} label="銀行" />
                                                    <FormControlLabel
                                                        value="shinkin"
                                                        control={<Radio />}
                                                        label="信用金庫"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffBankName !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffBankName}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 11, md: 11 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label="支店名"
                                                    value={staffData.staffBank.branch}
                                                    onChange={handleStaffBankBranchChange}
                                                    onBlur={handleStaffBankBranchBlur}
                                                    error={staffDataError.staffBankBranch !== ""}
                                                    fullWidth
                                                />
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 1, md: 1 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>支店</Typography>
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffBankBranch !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffBankBranch}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 2, md: 2 }}
                                                sx={{ display: "flex", alignItems: "center", mb: 2.5 }}
                                            >
                                                <Typography>普通口座</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 10, md: 10 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label="口座番号"
                                                    helperText="数字のみ入力してください"
                                                    value={staffData.staffBank.number}
                                                    onChange={handleStaffBankNumberChange}
                                                    onBlur={handleStaffBankNumberBlur}
                                                    error={staffDataError.staffBankNumber !== ""}
                                                    fullWidth
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffBankNumber !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffBankNumber}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                            mb: 1,
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label="口座名義 (カタカナ)"
                                            value={staffData.staffBank.holder}
                                            sx={{ width: "600px" }}
                                            onChange={handleStaffBankHolderChange}
                                            onBlur={handleStaffBankHolderBlur}
                                            error={staffDataError.staffBankHolder !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {staffDataError.staffBankHolder !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {staffDataError.staffBankHolder}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Accordion sx={{ width: "600px" }} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>詳細情報 (省略可)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDateFns}
                                                            adapterLocale={ja}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    width: "600px",
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    label={staffMoreData.staffJoinDate.title}
                                                                    value={staffMoreData.staffJoinDate.value}
                                                                    format="yyyy/MM/dd"
                                                                    onChange={handleStaffJoinDateChange}
                                                                    slotProps={{
                                                                        textField: {
                                                                            clearable: true,
                                                                            fullWidth: true,
                                                                        },
                                                                        calendarHeader: {
                                                                            format: "yyyy年MM月",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </LocalizationProvider>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl sx={{ width: "600px" }}>
                                                            <InputLabel>{staffMoreData.staffDivision.title}</InputLabel>
                                                            <Select
                                                                label={staffMoreData.staffDivision.title}
                                                                value={staffMoreData.staffDivision.value}
                                                                onChange={handleStaffDivisionChange}
                                                                sx={{ textAlign: "left" }}
                                                                fullWidth
                                                            >
                                                                {divisionData.map((division) => (
                                                                    <MenuItem
                                                                        key={division}
                                                                        value={division}
                                                                        sx={{
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {division}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl sx={{ width: "600px" }}>
                                                            <InputLabel>{staffMoreData.staffCategory.title}</InputLabel>
                                                            <Select
                                                                label={staffMoreData.staffCategory.title}
                                                                value={staffMoreData.staffCategory.value}
                                                                onChange={handleStaffCategoryChange}
                                                                sx={{ textAlign: "left" }}
                                                                fullWidth
                                                            >
                                                                {employeeData.map((employee) => (
                                                                    <MenuItem
                                                                        key={employee}
                                                                        value={employee}
                                                                        sx={{
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {employee}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl sx={{ width: "600px" }}>
                                                            <InputLabel>{staffMoreData.staffPosition.title}</InputLabel>
                                                            <Select
                                                                label={staffMoreData.staffPosition.title}
                                                                value={staffMoreData.staffPosition.value}
                                                                onChange={handleStaffPositionChange}
                                                                sx={{ textAlign: "left" }}
                                                                fullWidth
                                                            >
                                                                {positionData.map((position) => (
                                                                    <MenuItem
                                                                        key={position}
                                                                        value={position}
                                                                        sx={{
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {position}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDateFns}
                                                            adapterLocale={ja}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    width: "600px",
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    label={staffMoreData.staffConflictDate.title}
                                                                    value={staffMoreData.staffConflictDate.value}
                                                                    format="yyyy/MM/dd"
                                                                    onChange={handleStaffConflictDateChange}
                                                                    slotProps={{
                                                                        textField: {
                                                                            clearable: true,
                                                                            fullWidth: true,
                                                                        },
                                                                        calendarHeader: {
                                                                            format: "yyyy年MM月",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </LocalizationProvider>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <Grid2
                                                            container
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "600px",
                                                            }}
                                                        >
                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                <Typography textAlign="left">
                                                                    {staffMoreData.staffAgreementFlag.title}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                <RadioGroup
                                                                    row
                                                                    value={staffMoreData.staffAgreementFlag.value}
                                                                    onChange={handleStaffAgreementFlagChange}
                                                                    sx={{
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio />}
                                                                        label="該当する"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio />}
                                                                        label="該当しない"
                                                                    />
                                                                </RadioGroup>
                                                            </Grid2>
                                                        </Grid2>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            label={staffMoreData.staffDispatch.title}
                                                            value={staffMoreData.staffDispatch.value}
                                                            sx={{ width: "600px" }}
                                                            onClick={handleStaffDispatchOpenDialog}
                                                            InputProps={{
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {staffMoreData.staffDispatch.value !== "" ? (
                                                                            <IconButton
                                                                                onClick={handleStaffDispatchValueClear}
                                                                            >
                                                                                <HighlightOffIcon />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <IconButton
                                                                                onClick={handleStaffDispatchOpenDialog}
                                                                            >
                                                                                <FilterNoneIcon />
                                                                            </IconButton>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 1,
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDateFns}
                                                            adapterLocale={ja}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    width: "600px",
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    label="就業開始日"
                                                                    value={staffMoreData.staffDispatch.date}
                                                                    format="yyyy/MM/dd"
                                                                    onChange={handleStaffDispatchDateChange}
                                                                    slotProps={{
                                                                        textField: {
                                                                            clearable: true,
                                                                            fullWidth: true,
                                                                        },
                                                                        calendarHeader: {
                                                                            format: "yyyy年MM月",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </LocalizationProvider>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl sx={{ width: "600px" }}>
                                                            <InputLabel>
                                                                {staffMoreData.staffCommuting.title}
                                                            </InputLabel>
                                                            <Select
                                                                label={staffMoreData.staffCommuting.title}
                                                                value={staffMoreData.staffCommuting.value}
                                                                onChange={handleStaffCommutingChange}
                                                                sx={{ textAlign: "left" }}
                                                                fullWidth
                                                            >
                                                                {commutingData.map((commuting) => (
                                                                    <MenuItem
                                                                        key={commuting}
                                                                        value={commuting}
                                                                        sx={{
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {commuting}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography sx={{ width: "600px", textAlign: "left" }}>緊急連絡先</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <TextField
                                            variant="outlined"
                                            label={emergencyContactData.emergencyContactName.title}
                                            value={emergencyContactData.emergencyContactName.value}
                                            onChange={handleEmergencyContactNameChange}
                                            onBlur={handleEmergencyContactNameBlur}
                                            error={emergencyContactError.emergencyContactName !== ""}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactName !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactName}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={emergencyContactData.emergencyContactKana.title}
                                            value={emergencyContactData.emergencyContactKana.value}
                                            onChange={handleEmergencyContactKanaChange}
                                            onBlur={handleEmergencyContactKanaBlur}
                                            error={emergencyContactError.emergencyContactKana !== ""}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactKana !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactKana}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={emergencyContactData.emergencyContactPhone.title}
                                            value={emergencyContactData.emergencyContactPhone.value}
                                            helperText="数字とハイフンのみ入力してください"
                                            onChange={handleEmergencyContactPhoneChange}
                                            onBlur={handleEmergencyContactPhoneBlur}
                                            error={emergencyContactError.emergencyContactPhone !== ""}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactPhone !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactPhone}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={emergencyContactData.emergencyContactRelationship.title}
                                            value={emergencyContactData.emergencyContactRelationship.value}
                                            onChange={handleEmergencyContactRelationshipChange}
                                            onBlur={handleEmergencyContactRelationshipBlur}
                                            error={emergencyContactError.emergencyContactRelationship !== ""}
                                            sx={{ width: "600px" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactRelationship !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactRelationship}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                                            緊急連絡先の住所
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            variant="outlined"
                                            label="郵便番号"
                                            value={emergencyContactData.emergencyContactAddress.post}
                                            sx={{ width: "424px" }}
                                            onChange={handleEmergencyContactAddressPostChange}
                                            onBlur={handleEmergencyContactAddressPostBlur}
                                            helperText="数字のみ入力してください"
                                            error={emergencyContactError.emergencyContactAddressPost !== ""}
                                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        />
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            sx={{ width: "152px", height: "56px", ml: 3 }}
                                        >
                                            住所を検索する
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactAddressPost !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactAddressPost}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="都道府県"
                                            value={emergencyContactData.emergencyContactAddress.prefecture}
                                            sx={{ width: "288px" }}
                                            onChange={handleEmergencyContactAddressPrefectureChange}
                                            onBlur={handleEmergencyContactAddressPrefectureBlur}
                                            error={emergencyContactError.emergencyContactAddressPrefecture !== ""}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="市区町村"
                                            value={emergencyContactData.emergencyContactAddress.city}
                                            sx={{ width: "288px", ml: 3 }}
                                            onChange={handleEmergencyContactAddressCityChange}
                                            onBlur={handleEmergencyContactAddressCityBlur}
                                            error={emergencyContactError.emergencyContactAddressCity !== ""}
                                        />
                                    </Box>
                                    <Grid2
                                        container
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        <Grid2 sx={{ width: "288px" }}>
                                            {emergencyContactError.emergencyContactAddressPrefecture !== "" && (
                                                <Alert severity="error">
                                                    {emergencyContactError.emergencyContactAddressPrefecture}
                                                </Alert>
                                            )}
                                        </Grid2>

                                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                                            {emergencyContactError.emergencyContactAddressCity !== "" && (
                                                <Alert severity="error">
                                                    {emergencyContactError.emergencyContactAddressCity}
                                                </Alert>
                                            )}
                                        </Grid2>
                                    </Grid2>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="上記以降の住所"
                                            value={emergencyContactData.emergencyContactAddress.other}
                                            sx={{ width: "600px" }}
                                            onChange={handleEmergencyContactAddressOtherChange}
                                            onBlur={handleEmergencyContactAddressOtherBlur}
                                            error={emergencyContactError.emergencyContactAddressOther !== ""}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}
                                    >
                                        {emergencyContactError.emergencyContactAddressOther !== "" && (
                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                {emergencyContactError.emergencyContactAddressOther}
                                            </Alert>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            label="建物名・部屋番号"
                                            value={emergencyContactData.emergencyContactAddress.building}
                                            sx={{ width: "600px" }}
                                            onChange={handleEmergencyContactAddressBuildingChange}
                                        />
                                    </Box>

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <Typography textAlign="left">{spouseData.spouseFlag.title}</Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <RadioGroup
                                                    row
                                                    value={spouseData.spouseFlag.value}
                                                    onChange={handleSpouseFlagChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="有" />
                                                    <FormControlLabel value={false} control={<Radio />} label="無" />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {spouseData.spouseFlag.value === true && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                    mb: 1,
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={spouseData.spouseName.title}
                                                    value={spouseData.spouseName.value}
                                                    sx={{ width: "600px" }}
                                                    onChange={handleSpouseNameChange}
                                                    onBlur={handleSpouseNameBlur}
                                                    error={spouseError.spouseName !== ""}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                {spouseError.spouseName !== "" && (
                                                    <Alert severity="error" sx={{ width: "600px" }}>
                                                        {spouseError.spouseName}
                                                    </Alert>
                                                )}
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                    mb: 1,
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={spouseData.spouseKana.title}
                                                    value={spouseData.spouseKana.value}
                                                    sx={{ width: "600px" }}
                                                    onChange={handleSpouseKanaChange}
                                                    onBlur={handleSpouseKanaBlur}
                                                    error={spouseError.spouseKana !== ""}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                {spouseError.spouseKana !== "" && (
                                                    <Alert severity="error" sx={{ width: "600px" }}>
                                                        {spouseError.spouseKana}
                                                    </Alert>
                                                )}
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "600px",
                                                    }}
                                                >
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {spouseData.spouseGender.title}
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                        <RadioGroup
                                                            row
                                                            value={spouseData.spouseGender.value}
                                                            onChange={handleSpouseGenderChange}
                                                            sx={{ justifyContent: "space-between" }}
                                                        >
                                                            <FormControlLabel
                                                                value="male"
                                                                control={<Radio />}
                                                                label="男性"
                                                            />
                                                            <FormControlLabel
                                                                value="female"
                                                                control={<Radio />}
                                                                label="女性"
                                                            />
                                                        </RadioGroup>
                                                    </Grid2>
                                                </Grid2>
                                            </Box>

                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 3,
                                                    }}
                                                >
                                                    <Grid2
                                                        container
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "600px",
                                                        }}
                                                    >
                                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                                            <DesktopDatePicker
                                                                label={spouseData.spouseBirthday.title}
                                                                value={spouseData.spouseBirthday.value}
                                                                format="yyyy/MM/dd"
                                                                onChange={handleSpouseBirthdayChange}
                                                                slotProps={{
                                                                    textField: {
                                                                        error: spouseError.spouseBirthday !== "",
                                                                        clearable: true,
                                                                        onBlur: handleSpouseBirthdayBlur,
                                                                        sx: { width: "530px" },
                                                                    },
                                                                    calendarHeader: {
                                                                        format: "yyyy年MM月",
                                                                    },
                                                                }}
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                            <Typography textAlign="right">
                                                                {spouseData.spouseBirthday.value
                                                                    ? `${
                                                                          new Date().getFullYear() -
                                                                          spouseData.spouseBirthday.value.getFullYear()
                                                                      }歳`
                                                                    : "00歳"}
                                                            </Typography>
                                                        </Grid2>
                                                    </Grid2>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 1,
                                                    }}
                                                >
                                                    {spouseError.spouseBirthday !== "" && (
                                                        <Alert severity="error" sx={{ width: "600px" }}>
                                                            {spouseError.spouseBirthday}
                                                        </Alert>
                                                    )}
                                                </Box>
                                            </LocalizationProvider>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "600px",
                                                    }}
                                                >
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {spouseData.spouseTogether.title}
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                        <RadioGroup
                                                            row
                                                            value={spouseData.spouseTogether.value}
                                                            onChange={handleSpouseTogetherChange}
                                                            sx={{ justifyContent: "space-between" }}
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio />}
                                                                label="同居"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio />}
                                                                label="別居"
                                                            />
                                                        </RadioGroup>
                                                    </Grid2>
                                                </Grid2>
                                            </Box>

                                            {spouseData.spouseTogether.value === false && (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mb: 1,
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                                                            配偶者の住所
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            variant="outlined"
                                                            label="郵便番号"
                                                            value={spouseData.spouseAddress.post}
                                                            sx={{ width: "424px" }}
                                                            onChange={handleSpouseAddressPostChange}
                                                            onBlur={handleSpouseAddressPostBlur}
                                                            helperText="数字のみ入力してください"
                                                            error={spouseError.spouseAddressPost !== ""}
                                                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                                        />
                                                        <Button
                                                            variant="outlined"
                                                            size="large"
                                                            sx={{ width: "152px", height: "56px", ml: 3 }}
                                                        >
                                                            住所を検索する
                                                        </Button>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 1,
                                                        }}
                                                    >
                                                        {spouseError.spouseAddressPost !== "" && (
                                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                                {spouseError.spouseAddressPost}
                                                            </Alert>
                                                        )}
                                                    </Box>

                                                    <Box sx={{ mt: 1 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            label="都道府県"
                                                            value={spouseData.spouseAddress.prefecture}
                                                            sx={{ width: "288px" }}
                                                            onChange={handleSpouseAddressPrefectureChange}
                                                            onBlur={handleSpouseAddressPrefectureBlur}
                                                            error={spouseError.spouseAddressPrefecture !== ""}
                                                        />
                                                        <TextField
                                                            variant="outlined"
                                                            label="市区町村"
                                                            value={spouseData.spouseAddress.city}
                                                            sx={{ width: "288px", ml: 3 }}
                                                            onChange={handleSpouseAddressCityChange}
                                                            onBlur={handleSpouseAddressCityBlur}
                                                            error={spouseError.spouseAddressCity !== ""}
                                                        />
                                                    </Box>
                                                    <Grid2
                                                        container
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 1,
                                                        }}
                                                    >
                                                        <Grid2 sx={{ width: "288px" }}>
                                                            {spouseError.spouseAddressPrefecture !== "" && (
                                                                <Alert severity="error">
                                                                    {spouseError.spouseAddressPrefecture}
                                                                </Alert>
                                                            )}
                                                        </Grid2>

                                                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                                                            {spouseError.spouseAddressCity !== "" && (
                                                                <Alert severity="error">
                                                                    {spouseError.spouseAddressCity}
                                                                </Alert>
                                                            )}
                                                        </Grid2>
                                                    </Grid2>

                                                    <Box sx={{ mt: 1 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            label="上記以降の住所"
                                                            value={spouseData.spouseAddress.other}
                                                            sx={{ width: "600px" }}
                                                            onChange={handleSpouseAddressOtherChange}
                                                            onBlur={handleSpouseAddressOtherBlur}
                                                            error={spouseError.spouseAddressOther !== ""}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: 1,
                                                        }}
                                                    >
                                                        {spouseError.spouseAddressOther !== "" && (
                                                            <Alert severity="error" sx={{ width: "600px" }}>
                                                                {spouseError.spouseAddressOther}
                                                            </Alert>
                                                        )}
                                                    </Box>

                                                    <Box sx={{ mt: 1 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            label="建物名・部屋番号"
                                                            value={spouseData.spouseAddress.building}
                                                            sx={{ width: "600px" }}
                                                            onChange={handleSpouseAddressBuildingChange}
                                                        />
                                                    </Box>
                                                </>
                                            )}

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mb: 1,
                                                    mt: 3,
                                                }}
                                            >
                                                <Typography sx={{ width: "600px", textAlign: "left" }}>
                                                    {spouseData.spouseNationality.title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Grid2 container>
                                                    <Grid2
                                                        size={{ xs: 4, md: 4 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <RadioGroup
                                                            row
                                                            value={spouseData.spouseNationality.select}
                                                            onChange={handleSpouseNationalityChange}
                                                            sx={{ justifyContent: "space-between" }}
                                                        >
                                                            <FormControlLabel
                                                                value="japanese"
                                                                control={<Radio />}
                                                                label="日本"
                                                            />
                                                            <FormControlLabel
                                                                value="overseas"
                                                                control={<Radio />}
                                                                label="外国"
                                                            />
                                                        </RadioGroup>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 8, md: 8 }}>
                                                        <TextField
                                                            variant="outlined"
                                                            label="国名"
                                                            value={spouseData.spouseNationality.value}
                                                            sx={{ width: "390px" }}
                                                            onChange={handleSpouseCountryChange}
                                                            onBlur={handleSpouseCountryBlur}
                                                            error={spouseError.spouseNationality !== ""}
                                                            disabled={
                                                                spouseData.spouseNationality.select === "japanese"
                                                            }
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                {spouseError.spouseNationality !== "" && (
                                                    <Alert severity="error" sx={{ width: "600px" }}>
                                                        {spouseError.spouseNationality}
                                                    </Alert>
                                                )}
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <Typography textAlign="left">
                                                    {dependentData.dependentFlag.title}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <RadioGroup
                                                    row
                                                    value={dependentData.dependentFlag.value}
                                                    onChange={handleDependentFlagChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="有" />
                                                    <FormControlLabel value={false} control={<Radio />} label="無" />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {dependentData.dependentFlag.value === true && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "600px",
                                                    }}
                                                >
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {dependentData.dependentSpouseFlag.title}
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                        <RadioGroup
                                                            row
                                                            value={dependentData.dependentSpouseFlag.value}
                                                            onChange={handleDependentSpouseFlagChange}
                                                            sx={{ justifyContent: "space-between" }}
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio />}
                                                                label="有"
                                                                disabled={spouseData.spouseFlag.value === false}
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio />}
                                                                label="無"
                                                                disabled={spouseData.spouseFlag.value === false}
                                                            />
                                                        </RadioGroup>
                                                    </Grid2>
                                                </Grid2>
                                            </Box>

                                            {dependentData.dependentSpouseFlag.value === true && (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Card
                                                            sx={{
                                                                mt: 1,
                                                                width: "600px",
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            width: "600px",
                                                                        }}
                                                                    >
                                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography textAlign="left">
                                                                                    {
                                                                                        dependentData
                                                                                            .dependentSpouseItem.title
                                                                                    }
                                                                                </Typography>
                                                                                <IconButton sx={{ ml: 1 }}>
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                被扶養者になる場合は「該当」
                                                                                                <br />
                                                                                                被扶養者でなくなる場合は「非該当」
                                                                                                <br />
                                                                                                内容に変更がある場合は「変更」
                                                                                            </Typography>
                                                                                        }
                                                                                        placement="top"
                                                                                        sx={{
                                                                                            textAlign: "left",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            overflowWrap: "break-word",
                                                                                        }}
                                                                                    >
                                                                                        <InfoOutlinedIcon />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 7, md: 7 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Select
                                                                                value={
                                                                                    dependentData.dependentSpouseItem
                                                                                        .value
                                                                                }
                                                                                onChange={
                                                                                    handleDependentSpouseItemChange
                                                                                }
                                                                                sx={{ textAlign: "left" }}
                                                                                fullWidth
                                                                                error={
                                                                                    dependentError.dependentSpouseItem !==
                                                                                    ""
                                                                                }
                                                                            >
                                                                                <MenuItem
                                                                                    value="該当"
                                                                                    sx={{ textAlign: "left" }}
                                                                                >
                                                                                    該当
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    value="非該当"
                                                                                    sx={{ textAlign: "left" }}
                                                                                >
                                                                                    非該当
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    value="変更"
                                                                                    sx={{ textAlign: "left" }}
                                                                                >
                                                                                    変更
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    {dependentError.dependentSpouseItem !== "" && (
                                                                        <Alert severity="error" sx={{ width: "600px" }}>
                                                                            {dependentError.dependentSpouseItem}
                                                                        </Alert>
                                                                    )}
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            width: "600px",
                                                                        }}
                                                                    >
                                                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                                                            <Typography textAlign="left">
                                                                                {
                                                                                    dependentData.dependentSpouseMarried
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={
                                                                                    dependentData.dependentSpouseMarried
                                                                                        .value
                                                                                }
                                                                                onChange={
                                                                                    handleDependentSpouseMarriedChange
                                                                                }
                                                                                sx={{ justifyContent: "space-between" }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    value={true}
                                                                                    control={<Radio />}
                                                                                    label="有"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value={false}
                                                                                    control={<Radio />}
                                                                                    label="無"
                                                                                />
                                                                            </RadioGroup>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="outlined"
                                                                        label={
                                                                            dependentData.dependentSpouseNumber.title
                                                                        }
                                                                        value={
                                                                            dependentData.dependentSpouseNumber.value
                                                                        }
                                                                        helperText="数字のみ入力してください"
                                                                        onChange={handleDependentSpouseNumberChange}
                                                                        onBlur={handleDependentSpouseNumberBlur}
                                                                        error={
                                                                            dependentError.dependentSpouseNumber !== ""
                                                                        }
                                                                        sx={{ width: "600px" }}
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    {dependentError.dependentSpouseNumber !== "" && (
                                                                        <Alert severity="error" sx={{ width: "600px" }}>
                                                                            {dependentError.dependentSpouseNumber}
                                                                        </Alert>
                                                                    )}
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mb: 1,
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{ width: "600px", textAlign: "left" }}
                                                                    >
                                                                        {dependentData.dependentSpousePhone.title}
                                                                    </Typography>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2 size={{ xs: 6, md: 6 }}>
                                                                            <FormControl
                                                                                fullWidth
                                                                                error={
                                                                                    dependentError.dependentSpousePhoneSelect !==
                                                                                    ""
                                                                                }
                                                                            >
                                                                                <InputLabel>電話の種類</InputLabel>
                                                                                <Select
                                                                                    label="電話の種類"
                                                                                    value={
                                                                                        dependentData
                                                                                            .dependentSpousePhone.select
                                                                                    }
                                                                                    onChange={
                                                                                        handleDependentSpousePhoneSelectChange
                                                                                    }
                                                                                    sx={{ textAlign: "left" }}
                                                                                >
                                                                                    <MenuItem
                                                                                        value="home"
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        自宅
                                                                                    </MenuItem>
                                                                                    <MenuItem
                                                                                        value="mobile"
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        携帯
                                                                                    </MenuItem>
                                                                                    <MenuItem
                                                                                        value="work"
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        勤務先
                                                                                    </MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid2>
                                                                        <Grid2 size={{ xs: 6, md: 6 }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                label={
                                                                                    dependentData.dependentSpousePhone
                                                                                        .title
                                                                                }
                                                                                value={
                                                                                    dependentData.dependentSpousePhone
                                                                                        .value
                                                                                }
                                                                                onChange={
                                                                                    handleDependentSpousePhoneValueChange
                                                                                }
                                                                                onBlur={
                                                                                    handleDependentSpousePhoneValueBlur
                                                                                }
                                                                                error={
                                                                                    dependentError.dependentSpousePhoneValue !==
                                                                                    ""
                                                                                }
                                                                                helperText="数字とハイフンのみ入力してください"
                                                                                fullWidth
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Grid2
                                                                    container
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Grid2 sx={{ width: "272px" }}>
                                                                        {dependentError.dependentSpousePhoneSelect !==
                                                                            "" && (
                                                                            <Alert severity="error">
                                                                                {
                                                                                    dependentError.dependentSpousePhoneSelect
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Grid2>

                                                                    <Grid2 sx={{ width: "272px", ml: 3 }}>
                                                                        {dependentError.dependentSpousePhoneValue !==
                                                                            "" && (
                                                                            <Alert severity="error">
                                                                                {
                                                                                    dependentError.dependentSpousePhoneValue
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Grid2>
                                                                </Grid2>

                                                                {dependentData.dependentSpouseItem.value !== "変更" && (
                                                                    <>
                                                                        <LocalizationProvider
                                                                            dateAdapter={AdapterDateFns}
                                                                            adapterLocale={ja}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <DesktopDatePicker
                                                                                    label={
                                                                                        dependentData
                                                                                            .dependentSpouseItem
                                                                                            .value === "該当" ||
                                                                                        dependentData
                                                                                            .dependentSpouseItem
                                                                                            .value === ""
                                                                                            ? dependentData
                                                                                                  .dependentSpouseDate
                                                                                                  .title
                                                                                            : "被扶養者でなくなった日"
                                                                                    }
                                                                                    value={
                                                                                        dependentData
                                                                                            .dependentSpouseDate.value
                                                                                    }
                                                                                    format="yyyy/MM/dd"
                                                                                    onChange={
                                                                                        handleDependentSpouseDateChange
                                                                                    }
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            error:
                                                                                                dependentError.dependentSpouseDate !==
                                                                                                "",
                                                                                            clearable: true,
                                                                                            onBlur: handleDependentSpouseDateBlur,
                                                                                        },
                                                                                        calendarHeader: {
                                                                                            format: "yyyy年MM月",
                                                                                        },
                                                                                    }}
                                                                                    sx={{ width: "600px" }}
                                                                                />
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentError.dependentSpouseDate !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentError.dependentSpouseDate
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>
                                                                        </LocalizationProvider>
                                                                    </>
                                                                )}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            width: "600px",
                                                                        }}
                                                                    >
                                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography textAlign="left">
                                                                                    {
                                                                                        dependentData
                                                                                            .dependentSpouseReason.title
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 7, md: 7 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <FormControl
                                                                                fullWidth
                                                                                error={
                                                                                    dependentError.dependentSpouseReason !==
                                                                                    ""
                                                                                }
                                                                            >
                                                                                <Select
                                                                                    value={
                                                                                        dependentData
                                                                                            .dependentSpouseReason.value
                                                                                    }
                                                                                    onChange={
                                                                                        handleDependentSpouseReasonChange
                                                                                    }
                                                                                    sx={{ textAlign: "left" }}
                                                                                    fullWidth
                                                                                >
                                                                                    {dependentData.dependentSpouseItem
                                                                                        .value === "該当" ||
                                                                                    dependentData.dependentSpouseItem
                                                                                        .value === ""
                                                                                        ? spouseApplicableReason.map(
                                                                                              (reason) => (
                                                                                                  <MenuItem
                                                                                                      key={reason.value}
                                                                                                      value={
                                                                                                          reason.value
                                                                                                      }
                                                                                                      sx={{
                                                                                                          textAlign:
                                                                                                              "left",
                                                                                                      }}
                                                                                                  >
                                                                                                      {reason.title}
                                                                                                  </MenuItem>
                                                                                              )
                                                                                          )
                                                                                        : spouseNotApplicableReason.map(
                                                                                              (reason) => (
                                                                                                  <MenuItem
                                                                                                      key={reason.value}
                                                                                                      value={
                                                                                                          reason.value
                                                                                                      }
                                                                                                      sx={{
                                                                                                          textAlign:
                                                                                                              "left",
                                                                                                      }}
                                                                                                  >
                                                                                                      {reason.title}
                                                                                                  </MenuItem>
                                                                                              )
                                                                                          )}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    {dependentError.dependentSpouseReason !== "" && (
                                                                        <Alert severity="error" sx={{ width: "600px" }}>
                                                                            {dependentError.dependentSpouseReason}
                                                                        </Alert>
                                                                    )}
                                                                </Box>

                                                                {dependentData.dependentSpouseReason.value ===
                                                                    "その他" && (
                                                                    <>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                value={
                                                                                    dependentData.dependentSpouseReason
                                                                                        .note
                                                                                }
                                                                                onChange={
                                                                                    handleDependentSpouseReasonNoteChange
                                                                                }
                                                                                onBlur={
                                                                                    handleDependentSpouseReasonNoteBlur
                                                                                }
                                                                                error={
                                                                                    dependentError.dependentSpouseReasonNote !==
                                                                                    ""
                                                                                }
                                                                                sx={{ width: "600px" }}
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseReasonNote !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseReasonNote
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {dependentData.dependentSpouseReason.value ===
                                                                    "死亡" && (
                                                                    <>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDateFns}
                                                                                adapterLocale={ja}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        width: "600px",
                                                                                    }}
                                                                                >
                                                                                    <DesktopDatePicker
                                                                                        label="死亡した日"
                                                                                        value={
                                                                                            dependentData
                                                                                                .dependentSpouseReason
                                                                                                .deadDate
                                                                                        }
                                                                                        format="yyyy/MM/dd"
                                                                                        onChange={
                                                                                            handleDependentSpouseDeadDateChange
                                                                                        }
                                                                                        slotProps={{
                                                                                            textField: {
                                                                                                error:
                                                                                                    dependentError.dependentSpouseDeadDate !==
                                                                                                    "",
                                                                                                clearable: true,
                                                                                                onBlur: handleDependentSpouseDeadDateBlur,
                                                                                                fullWidth: true,
                                                                                            },
                                                                                            calendarHeader: {
                                                                                                format: "yyyy年MM月",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </LocalizationProvider>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseDeadDate !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseDeadDate
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                {dependentData.dependentSpouseItem.value !==
                                                                    "非該当" && (
                                                                    <>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 3,
                                                                            }}
                                                                        >
                                                                            <Grid2
                                                                                container
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    width: "600px",
                                                                                }}
                                                                            >
                                                                                <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <Typography textAlign="left">
                                                                                            {
                                                                                                dependentData
                                                                                                    .dependentSpouseBusiness
                                                                                                    .title
                                                                                            }
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid2>
                                                                                <Grid2
                                                                                    size={{ xs: 7, md: 7 }}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        error={
                                                                                            dependentError.dependentSpouseBusiness !==
                                                                                            ""
                                                                                        }
                                                                                        fullWidth
                                                                                    >
                                                                                        <Select
                                                                                            value={
                                                                                                dependentData
                                                                                                    .dependentSpouseBusiness
                                                                                                    .value
                                                                                            }
                                                                                            onChange={
                                                                                                handleDependentSpouseBusinessChange
                                                                                            }
                                                                                            sx={{ textAlign: "left" }}
                                                                                            fullWidth
                                                                                        >
                                                                                            <MenuItem
                                                                                                value="無職"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                無職
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                value="パート"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                パート
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                value="年金受給者"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                年金受給者
                                                                                            </MenuItem>
                                                                                            <MenuItem
                                                                                                value="その他"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                その他
                                                                                            </MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid2>
                                                                            </Grid2>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseBusiness !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseBusiness
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>

                                                                        {dependentData.dependentSpouseBusiness.value ===
                                                                            "その他" && (
                                                                            <>
                                                                                <Box sx={{ mt: 1 }}>
                                                                                    <TextField
                                                                                        value={
                                                                                            dependentData
                                                                                                .dependentSpouseBusiness
                                                                                                .note
                                                                                        }
                                                                                        onChange={
                                                                                            handleDependentSpouseBusinessNoteChange
                                                                                        }
                                                                                        onBlur={
                                                                                            handleDependentSpouseBusinessNoteBlur
                                                                                        }
                                                                                        error={
                                                                                            dependentError.dependentSpouseBusinessNote !==
                                                                                            ""
                                                                                        }
                                                                                        fullWidth
                                                                                    />
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 1,
                                                                                    }}
                                                                                >
                                                                                    {dependentError.dependentSpouseBusinessNote !==
                                                                                        "" && (
                                                                                        <Alert
                                                                                            severity="error"
                                                                                            sx={{ width: "600px" }}
                                                                                        >
                                                                                            {
                                                                                                dependentError.dependentSpouseBusinessNote
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Box>
                                                                            </>
                                                                        )}

                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 3,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="outlined"
                                                                                label={
                                                                                    dependentData.dependentSpouseIncome
                                                                                        .title
                                                                                }
                                                                                value={
                                                                                    dependentData.dependentSpouseIncome
                                                                                        .value
                                                                                }
                                                                                sx={{ width: "600px" }}
                                                                                helperText="数字のみ入力してください"
                                                                                onChange={
                                                                                    handleDependentSpouseIncomeChange
                                                                                }
                                                                                onBlur={handleDependentSpouseIncomeBlur}
                                                                                error={
                                                                                    dependentError.dependentSpouseIncome !==
                                                                                    ""
                                                                                }
                                                                                InputProps={{
                                                                                    inputMode: "numeric",
                                                                                    pattern: "[0-9]*",
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            円
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseIncome !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseIncome
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            width: "600px",
                                                                        }}
                                                                    >
                                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography textAlign="left">
                                                                                    {
                                                                                        dependentData
                                                                                            .dependentSpouseOverseas
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 7, md: 7 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <FormControl fullWidth>
                                                                                <Select
                                                                                    value={
                                                                                        dependentData
                                                                                            .dependentSpouseOverseas
                                                                                            .value
                                                                                    }
                                                                                    onChange={
                                                                                        handleDependentSpouseOverseasChange
                                                                                    }
                                                                                    sx={{ textAlign: "left" }}
                                                                                    fullWidth
                                                                                >
                                                                                    <MenuItem
                                                                                        value="該当"
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        該当
                                                                                    </MenuItem>
                                                                                    <MenuItem
                                                                                        value="非該当"
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        非該当
                                                                                    </MenuItem>
                                                                                    <MenuItem
                                                                                        value=""
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        選択を解除する
                                                                                    </MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>

                                                                {dependentData.dependentSpouseOverseas.value !== "" && (
                                                                    <>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDateFns}
                                                                                adapterLocale={ja}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        width: "600px",
                                                                                    }}
                                                                                >
                                                                                    <DesktopDatePicker
                                                                                        label={
                                                                                            dependentData
                                                                                                .dependentSpouseOverseas
                                                                                                .value === "該当"
                                                                                                ? dependentData
                                                                                                      .dependentSpouseOverseasDate
                                                                                                      .title
                                                                                                : "非該当となった日"
                                                                                        }
                                                                                        value={
                                                                                            dependentData
                                                                                                .dependentSpouseOverseasDate
                                                                                                .value
                                                                                        }
                                                                                        format="yyyy/MM/dd"
                                                                                        onChange={
                                                                                            handleDependentSpouseOverseasDateChange
                                                                                        }
                                                                                        slotProps={{
                                                                                            textField: {
                                                                                                error:
                                                                                                    dependentError.dependentSpouseOverseasDate !==
                                                                                                    "",
                                                                                                clearable: true,
                                                                                                onBlur: handleDependentSpouseOverseasDateBlur,
                                                                                                fullWidth: true,
                                                                                            },
                                                                                            calendarHeader: {
                                                                                                format: "yyyy年MM月",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </LocalizationProvider>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseOverseasDate !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseOverseasDate
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>

                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            <Grid2
                                                                                container
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    width: "600px",
                                                                                }}
                                                                            >
                                                                                <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <Typography textAlign="left">
                                                                                            {
                                                                                                dependentData
                                                                                                    .dependentSpouseOverseasReason
                                                                                                    .title
                                                                                            }
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid2>
                                                                                <Grid2
                                                                                    size={{ xs: 7, md: 7 }}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Select
                                                                                        value={
                                                                                            dependentData
                                                                                                .dependentSpouseOverseasReason
                                                                                                .value
                                                                                        }
                                                                                        onChange={
                                                                                            handleDependentSpouseOverseasReasonChange
                                                                                        }
                                                                                        sx={{ textAlign: "left" }}
                                                                                        fullWidth
                                                                                    >
                                                                                        {dependentData
                                                                                            .dependentSpouseOverseas
                                                                                            .value === "該当" ||
                                                                                        dependentData
                                                                                            .dependentSpouseOverseas
                                                                                            .value === ""
                                                                                            ? spouseOverseasApplicableReason.map(
                                                                                                  (reason) => (
                                                                                                      <MenuItem
                                                                                                          key={
                                                                                                              reason.value
                                                                                                          }
                                                                                                          value={
                                                                                                              reason.value
                                                                                                          }
                                                                                                          sx={{
                                                                                                              textAlign:
                                                                                                                  "left",
                                                                                                          }}
                                                                                                      >
                                                                                                          {reason.title}
                                                                                                      </MenuItem>
                                                                                                  )
                                                                                              )
                                                                                            : spouseOverseasNotApplicableReason.map(
                                                                                                  (reason) => (
                                                                                                      <MenuItem
                                                                                                          key={
                                                                                                              reason.value
                                                                                                          }
                                                                                                          value={
                                                                                                              reason.value
                                                                                                          }
                                                                                                          sx={{
                                                                                                              textAlign:
                                                                                                                  "left",
                                                                                                          }}
                                                                                                      >
                                                                                                          {reason.title}
                                                                                                      </MenuItem>
                                                                                                  )
                                                                                              )}
                                                                                    </Select>
                                                                                </Grid2>
                                                                            </Grid2>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentError.dependentSpouseOverseasReason !==
                                                                                "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentError.dependentSpouseOverseasReason
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>

                                                                        {dependentData.dependentSpouseOverseasReason
                                                                            .value === "その他" && (
                                                                            <>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 1,
                                                                                    }}
                                                                                >
                                                                                    <TextField
                                                                                        variant="outlined"
                                                                                        value={
                                                                                            dependentData
                                                                                                .dependentSpouseOverseasReason
                                                                                                .note
                                                                                        }
                                                                                        onChange={
                                                                                            handleDependentSpouseOverseasReasonNoteChange
                                                                                        }
                                                                                        onBlur={
                                                                                            handleDependentSpouseOverseasReasonNoteBlur
                                                                                        }
                                                                                        error={
                                                                                            dependentError.dependentSpouseOverseasReasonNote !==
                                                                                            ""
                                                                                        }
                                                                                        sx={{ width: "600px" }}
                                                                                    />
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 1,
                                                                                    }}
                                                                                >
                                                                                    {dependentError.dependentSpouseOverseasReasonNote !==
                                                                                        "" && (
                                                                                        <Alert
                                                                                            severity="error"
                                                                                            sx={{ width: "600px" }}
                                                                                        >
                                                                                            {
                                                                                                dependentError.dependentSpouseOverseasReasonNote
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                        {dependentData.dependentSpouseOverseasReason
                                                                            .value === "国内転入" && (
                                                                            <>
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDateFns}
                                                                                    adapterLocale={ja}
                                                                                >
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            width: "568px",
                                                                                        }}
                                                                                    >
                                                                                        <DesktopDatePicker
                                                                                            label="転入日"
                                                                                            value={
                                                                                                dependentData
                                                                                                    .dependentSpouseOverseasReason
                                                                                                    .date
                                                                                            }
                                                                                            format="yyyy/MM/dd"
                                                                                            onChange={
                                                                                                handleDependentSpouseOverseasReasonDateChange
                                                                                            }
                                                                                            slotProps={{
                                                                                                textField: {
                                                                                                    error:
                                                                                                        dependentError.dependentSpouseOverseasReasonDate !==
                                                                                                        "",
                                                                                                    clearable: true,
                                                                                                    onBlur: handleDependentSpouseOverseasReasonDateBlur,
                                                                                                    fullWidth: true,
                                                                                                },
                                                                                                calendarHeader: {
                                                                                                    format: "yyyy年MM月",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </LocalizationProvider>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 1,
                                                                                    }}
                                                                                >
                                                                                    {dependentError.dependentSpouseOverseasReasonNote !==
                                                                                        "" && (
                                                                                        <Alert
                                                                                            severity="error"
                                                                                            sx={{ width: "600px" }}
                                                                                        >
                                                                                            {
                                                                                                dependentError.dependentSpouseOverseasReasonNote
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    dependentData
                                                                                        .dependentSpouseMyNumberFront
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                value={
                                                                                    dependentData
                                                                                        .dependentSpouseMyNumberFront
                                                                                        .value === ""
                                                                                        ? "未アップロード"
                                                                                        : dependentData
                                                                                              .dependentSpouseMyNumberFront
                                                                                              .value
                                                                                }
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="large"
                                                                    >
                                                                        アップロードする
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseMyNumberFront
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        ファイルを確認する
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseMyNumberFront
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        削除する
                                                                    </Button>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    dependentData
                                                                                        .dependentSpouseMyNumberBack
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                value={
                                                                                    dependentData
                                                                                        .dependentSpouseMyNumberBack
                                                                                        .value === ""
                                                                                        ? "未アップロード"
                                                                                        : dependentData
                                                                                              .dependentSpouseMyNumberBack
                                                                                              .value
                                                                                }
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="large"
                                                                    >
                                                                        アップロードする
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseMyNumberBack
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        ファイルを確認する
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseMyNumberBack
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        削除する
                                                                    </Button>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    dependentData
                                                                                        .dependentSpouseRelationshipCertificate
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                value={
                                                                                    dependentData
                                                                                        .dependentSpouseRelationshipCertificate
                                                                                        .value === ""
                                                                                        ? "未アップロード"
                                                                                        : dependentData
                                                                                              .dependentSpouseRelationshipCertificate
                                                                                              .value
                                                                                }
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="large"
                                                                    >
                                                                        アップロードする
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData
                                                                                .dependentSpouseRelationshipCertificate
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        ファイルを確認する
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData
                                                                                .dependentSpouseRelationshipCertificate
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        削除する
                                                                    </Button>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    dependentData
                                                                                        .dependentSpouseResident.title
                                                                                }
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                value={
                                                                                    dependentData
                                                                                        .dependentSpouseResident
                                                                                        .value === ""
                                                                                        ? "未アップロード"
                                                                                        : dependentData
                                                                                              .dependentSpouseResident
                                                                                              .value
                                                                                }
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="large"
                                                                    >
                                                                        アップロードする
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseResident
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        ファイルを確認する
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData.dependentSpouseResident
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        削除する
                                                                    </Button>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 3,
                                                                    }}
                                                                >
                                                                    <Grid2
                                                                        container
                                                                        spacing={2}
                                                                        sx={{ width: "600px" }}
                                                                    >
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    dependentData
                                                                                        .dependentSpouseIncomeCertificate
                                                                                        .title
                                                                                }
                                                                            </Typography>
                                                                            <IconButton sx={{ ml: 1 }}>
                                                                                <Tooltip
                                                                                    title={
                                                                                        <Typography
                                                                                            variant="caption"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            就労している場合のみ
                                                                                        </Typography>
                                                                                    }
                                                                                    placement="top"
                                                                                    sx={{
                                                                                        textAlign: "left",
                                                                                        whiteSpace: "pre-wrap",
                                                                                        overflowWrap: "break-word",
                                                                                    }}
                                                                                >
                                                                                    <InfoOutlinedIcon />
                                                                                </Tooltip>
                                                                            </IconButton>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                value={
                                                                                    dependentData
                                                                                        .dependentSpouseIncomeCertificate
                                                                                        .value === ""
                                                                                        ? "未アップロード"
                                                                                        : dependentData
                                                                                              .dependentSpouseIncomeCertificate
                                                                                              .value
                                                                                }
                                                                                InputProps={{ readOnly: true }}
                                                                            />
                                                                        </Grid2>
                                                                    </Grid2>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        size="large"
                                                                    >
                                                                        アップロードする
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData
                                                                                .dependentSpouseIncomeCertificate
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        ファイルを確認する
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        size="large"
                                                                        sx={{ ml: 3 }}
                                                                        disabled={
                                                                            dependentData
                                                                                .dependentSpouseIncomeCertificate
                                                                                .value === ""
                                                                        }
                                                                    >
                                                                        削除する
                                                                    </Button>
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Box>
                                                </>
                                            )}

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "600px",
                                                    }}
                                                >
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {dependentOtherFlag.title}
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                        <RadioGroup
                                                            row
                                                            value={dependentOtherFlag.value}
                                                            onChange={handleDependentOtherFlagChange}
                                                            sx={{ justifyContent: "space-between" }}
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio />}
                                                                label="有"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio />}
                                                                label="無"
                                                            />
                                                        </RadioGroup>
                                                    </Grid2>
                                                </Grid2>
                                            </Box>

                                            {dependentOtherFlag.value === true && (
                                                <>
                                                    {dependentOtherData.map((item, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Card
                                                                sx={{
                                                                    mt: 1,
                                                                    mb: 3,
                                                                    width: "600px",
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Grid2 container spacing={2}>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography textAlign="left">
                                                                                被扶養者 {index + 1}
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 6, md: 6 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {index !== 0 && (
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        handleDependentOtherDelete(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <DeleteForeverIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </Grid2>
                                                                    </Grid2>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography textAlign="left">
                                                                                        {item.dependentOtherItem.title}
                                                                                    </Typography>
                                                                                    <IconButton sx={{ ml: 1 }}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <Typography
                                                                                                    variant="caption"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    被扶養者になる場合は「該当」
                                                                                                    <br />
                                                                                                    被扶養者でなくなる場合は「非該当」
                                                                                                    <br />
                                                                                                    内容に変更がある場合は「変更」
                                                                                                </Typography>
                                                                                            }
                                                                                            placement="top"
                                                                                            sx={{
                                                                                                textAlign: "left",
                                                                                                whiteSpace: "pre-wrap",
                                                                                                overflowWrap:
                                                                                                    "break-word",
                                                                                            }}
                                                                                        >
                                                                                            <InfoOutlinedIcon />
                                                                                        </Tooltip>
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 7, md: 7 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherItem !== ""
                                                                                    }
                                                                                >
                                                                                    <Select
                                                                                        value={
                                                                                            item.dependentOtherItem
                                                                                                .value
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleDependentOtherItemChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        sx={{ textAlign: "left" }}
                                                                                        fullWidth
                                                                                        error={
                                                                                            dependentOtherError[index]
                                                                                                ?.dependentOtherItem !==
                                                                                            ""
                                                                                        }
                                                                                    >
                                                                                        <MenuItem
                                                                                            value="該当"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            該当
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            value="非該当"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            非該当
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            value="変更"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            変更
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherItem !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherItem
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mt: 3,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={item.dependentOtherName.title}
                                                                            value={item.dependentOtherName.value}
                                                                            onChange={(e) =>
                                                                                handleDependentOtherNameChange(e, index)
                                                                            }
                                                                            onBlur={() =>
                                                                                handleDependentOtherNameBlur(index)
                                                                            }
                                                                            error={
                                                                                dependentOtherError[index]
                                                                                    ?.dependentOtherName !== ""
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherName !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherName
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mt: 3,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={item.dependentOtherKana.title}
                                                                            value={item.dependentOtherKana.value}
                                                                            onChange={(e) =>
                                                                                handleDependentOtherKanaChange(e, index)
                                                                            }
                                                                            onBlur={() =>
                                                                                handleDependentOtherKanaBlur(index)
                                                                            }
                                                                            error={
                                                                                dependentOtherError[index]
                                                                                    ?.dependentOtherKana !== ""
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherKana !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherKana
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>

                                                                    <LocalizationProvider
                                                                        dateAdapter={AdapterDateFns}
                                                                        adapterLocale={ja}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 3,
                                                                            }}
                                                                        >
                                                                            <DesktopDatePicker
                                                                                label={
                                                                                    item.dependentOtherBirthday.title
                                                                                }
                                                                                value={
                                                                                    item.dependentOtherBirthday.value
                                                                                }
                                                                                format="yyyy/MM/dd"
                                                                                onChange={(e) =>
                                                                                    handleDependentOtherBirthdayChange(
                                                                                        e,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        error:
                                                                                            dependentOtherError[index]
                                                                                                ?.dependentOtherBirthday !==
                                                                                            "",
                                                                                        clearable: true,
                                                                                        onBlur: () =>
                                                                                            handleDependentOtherBirthdayBlur(
                                                                                                index
                                                                                            ),
                                                                                        fullWidth: true,
                                                                                    },
                                                                                    calendarHeader: {
                                                                                        format: "yyyy年MM月",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                mt: 1,
                                                                            }}
                                                                        >
                                                                            {dependentOtherError[index]
                                                                                ?.dependentOtherBirthday !== "" && (
                                                                                <Alert
                                                                                    severity="error"
                                                                                    sx={{ width: "600px" }}
                                                                                >
                                                                                    {
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherBirthday
                                                                                    }
                                                                                </Alert>
                                                                            )}
                                                                        </Box>
                                                                    </LocalizationProvider>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                                <Typography textAlign="left">
                                                                                    {item.dependentOtherGender.title}
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <RadioGroup
                                                                                    row
                                                                                    value={
                                                                                        item.dependentOtherGender.value
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherGenderChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    sx={{
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="male"
                                                                                        control={<Radio />}
                                                                                        label="男性"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        value="female"
                                                                                        control={<Radio />}
                                                                                        label="女性"
                                                                                    />
                                                                                </RadioGroup>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography textAlign="left">
                                                                                        {
                                                                                            item
                                                                                                .dependentOtherRelationship
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 7, md: 7 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Select
                                                                                    value={
                                                                                        item.dependentOtherRelationship
                                                                                            .value
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherRelationshipChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    sx={{ textAlign: "left" }}
                                                                                    fullWidth
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherRelationship !==
                                                                                        ""
                                                                                    }
                                                                                >
                                                                                    {relationshipData.map((item) => (
                                                                                        <MenuItem
                                                                                            key={item.value}
                                                                                            value={item.value}
                                                                                        >
                                                                                            {item.title}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherRelationship !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherRelationship
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mt: 3,
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            label={item.dependentOtherNumber.title}
                                                                            value={item.dependentOtherNumber.value}
                                                                            onChange={(e) =>
                                                                                handleDependentOtherNumberChange(
                                                                                    e,
                                                                                    index
                                                                                )
                                                                            }
                                                                            onBlur={() =>
                                                                                handleDependentOtherNumberBlur(index)
                                                                            }
                                                                            error={
                                                                                dependentOtherError[index]
                                                                                    ?.dependentOtherNumber !== ""
                                                                            }
                                                                            helperText="数字のみ入力してください"
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherNumber !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherNumber
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                                <Typography textAlign="left">
                                                                                    {item.dependentOtherTogether.title}
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <RadioGroup
                                                                                    row
                                                                                    value={
                                                                                        item.dependentOtherTogether
                                                                                            .value
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherTogetherChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    sx={{
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value={true}
                                                                                        control={<Radio />}
                                                                                        label="同居"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        value={false}
                                                                                        control={<Radio />}
                                                                                        label="別居"
                                                                                    />
                                                                                </RadioGroup>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>

                                                                    {item.dependentOtherTogether.value === false && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mb: 1,
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        width: "600px",
                                                                                        textAlign: "left",
                                                                                    }}
                                                                                >
                                                                                    被扶養者の住所
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="郵便番号"
                                                                                    value={
                                                                                        item.dependentOtherAddress.post
                                                                                    }
                                                                                    sx={{ width: "392px" }}
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherAddressPostChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherAddressPostBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    helperText="数字のみ入力してください"
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherAddressPost !==
                                                                                        ""
                                                                                    }
                                                                                    InputProps={{
                                                                                        inputMode: "numeric",
                                                                                        pattern: "[0-9]*",
                                                                                    }}
                                                                                />
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    size="large"
                                                                                    sx={{
                                                                                        width: "152px",
                                                                                        height: "56px",
                                                                                        ml: 3,
                                                                                    }}
                                                                                >
                                                                                    住所を検索する
                                                                                </Button>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    ?.dependentOtherAddressPost !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                ?.dependentOtherAddressPost
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>

                                                                            <Box sx={{ mt: 1 }}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="都道府県"
                                                                                    value={
                                                                                        item.dependentOtherAddress
                                                                                            .prefecture
                                                                                    }
                                                                                    sx={{ width: "272px" }}
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherAddressPrefectureChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherAddressPrefectureBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherAddressPrefecture !==
                                                                                        ""
                                                                                    }
                                                                                />
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="市区町村"
                                                                                    value={
                                                                                        item.dependentOtherAddress.city
                                                                                    }
                                                                                    sx={{ width: "272px", ml: 3 }}
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherAddressCityChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherAddressCityBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherAddressCity !==
                                                                                        ""
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Grid2
                                                                                container
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                <Grid2 sx={{ width: "272px" }}>
                                                                                    {dependentOtherError[index]
                                                                                        ?.dependentOtherAddressPrefecture !==
                                                                                        "" && (
                                                                                        <Alert severity="error">
                                                                                            {
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ]
                                                                                                    .dependentOtherAddressPrefecture
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Grid2>

                                                                                <Grid2 sx={{ width: "272px", ml: 3 }}>
                                                                                    {dependentOtherError[index]
                                                                                        ?.dependentOtherAddressCity !==
                                                                                        "" && (
                                                                                        <Alert severity="error">
                                                                                            {
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ]
                                                                                                    .dependentOtherAddressCity
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Grid2>
                                                                            </Grid2>

                                                                            <Box sx={{ mt: 1 }}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="上記以降の住所"
                                                                                    value={
                                                                                        item.dependentOtherAddress.other
                                                                                    }
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherAddressOtherChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherAddressOtherBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherAddressOther !==
                                                                                        ""
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    ?.dependentOtherAddressOther !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherAddressOther
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>

                                                                            <Box sx={{ mt: 1 }}>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label="建物名・部屋番号"
                                                                                    value={
                                                                                        item.dependentOtherAddress
                                                                                            .building
                                                                                    }
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherAddressBuildingChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </>
                                                                    )}

                                                                    {item.dependentOtherItem.value !== "変更" && (
                                                                        <>
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDateFns}
                                                                                adapterLocale={ja}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 3,
                                                                                    }}
                                                                                >
                                                                                    <DesktopDatePicker
                                                                                        label={
                                                                                            item.dependentOtherItem
                                                                                                .value === "該当" ||
                                                                                            item.dependentOtherItem
                                                                                                .value === ""
                                                                                                ? item
                                                                                                      .dependentOtherDate
                                                                                                      .title
                                                                                                : "被扶養者でなくなった日"
                                                                                        }
                                                                                        value={
                                                                                            item.dependentOtherDate
                                                                                                .value
                                                                                        }
                                                                                        format="yyyy/MM/dd"
                                                                                        onChange={(e) =>
                                                                                            handleDependentOtherDateChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        slotProps={{
                                                                                            textField: {
                                                                                                error:
                                                                                                    dependentOtherError[
                                                                                                        index
                                                                                                    ]
                                                                                                        .dependentOtherDate !==
                                                                                                    "",
                                                                                                clearable: true,
                                                                                                onBlur: () =>
                                                                                                    handleDependentOtherDateBlur(
                                                                                                        index
                                                                                                    ),
                                                                                                fullWidth: true,
                                                                                            },
                                                                                            calendarHeader: {
                                                                                                format: "yyyy年MM月",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        mt: 1,
                                                                                    }}
                                                                                >
                                                                                    {dependentOtherError[index]
                                                                                        .dependentOtherDate !== "" && (
                                                                                        <Alert
                                                                                            severity="error"
                                                                                            sx={{ width: "600px" }}
                                                                                        >
                                                                                            {
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ].dependentOtherDate
                                                                                            }
                                                                                        </Alert>
                                                                                    )}
                                                                                </Box>
                                                                            </LocalizationProvider>
                                                                        </>
                                                                    )}
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography textAlign="left">
                                                                                        {
                                                                                            item.dependentOtherReason
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 7, md: 7 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Select
                                                                                    value={
                                                                                        item.dependentOtherReason.value
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherReasonChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    sx={{ textAlign: "left" }}
                                                                                    fullWidth
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherReason !==
                                                                                        ""
                                                                                    }
                                                                                >
                                                                                    {item.dependentOtherItem.value ===
                                                                                        "該当" ||
                                                                                    item.dependentOtherItem.value === ""
                                                                                        ? otherApplicableReason.map(
                                                                                              (reason) => (
                                                                                                  <MenuItem
                                                                                                      key={reason.value}
                                                                                                      value={
                                                                                                          reason.value
                                                                                                      }
                                                                                                      sx={{
                                                                                                          textAlign:
                                                                                                              "left",
                                                                                                      }}
                                                                                                  >
                                                                                                      {reason.value}
                                                                                                  </MenuItem>
                                                                                              )
                                                                                          )
                                                                                        : otherNotApplicableReason.map(
                                                                                              (reason) => (
                                                                                                  <MenuItem
                                                                                                      key={reason.value}
                                                                                                      value={
                                                                                                          reason.value
                                                                                                      }
                                                                                                      sx={{
                                                                                                          textAlign:
                                                                                                              "left",
                                                                                                      }}
                                                                                                  >
                                                                                                      {reason.value}
                                                                                                  </MenuItem>
                                                                                              )
                                                                                          )}
                                                                                </Select>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        {dependentOtherError[index]
                                                                            ?.dependentOtherReason !== "" && (
                                                                            <Alert
                                                                                severity="error"
                                                                                sx={{ width: "600px" }}
                                                                            >
                                                                                {
                                                                                    dependentOtherError[index]
                                                                                        ?.dependentOtherReason
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                    </Box>
                                                                    {item.dependentOtherReason.value === "その他" && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    mt: 1,
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item.dependentOtherReason.note
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherReasonNoteChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherReasonNoteBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            ?.dependentOtherReasonNote !==
                                                                                        ""
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    ?.dependentOtherReasonNote !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                ?.dependentOtherReasonNote
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                    {item.dependentOtherReason.value === "死亡" && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDateFns}
                                                                                    adapterLocale={ja}
                                                                                >
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            width: "600px",
                                                                                        }}
                                                                                    >
                                                                                        <DesktopDatePicker
                                                                                            label="死亡した日"
                                                                                            value={
                                                                                                item
                                                                                                    .dependentOtherReason
                                                                                                    .deadDate
                                                                                            }
                                                                                            format="yyyy/MM/dd"
                                                                                            onChange={(e) =>
                                                                                                handleDependentOtherReasonDeadDateChange(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            slotProps={{
                                                                                                textField: {
                                                                                                    error:
                                                                                                        dependentOtherError[
                                                                                                            index
                                                                                                        ]
                                                                                                            .dependentOtherReasonDeadDate !==
                                                                                                        "",
                                                                                                    clearable: true,
                                                                                                    onBlur: () =>
                                                                                                        handleDependentOtherReasonDeadDateBlur(
                                                                                                            index
                                                                                                        ),
                                                                                                    fullWidth: true,
                                                                                                },
                                                                                                calendarHeader: {
                                                                                                    format: "yyyy年MM月",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </LocalizationProvider>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    .dependentOtherReasonDeadDate !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherReasonDeadDate
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>
                                                                        </>
                                                                    )}

                                                                    {item.dependentOtherItem.value !== "非該当" && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <Grid2
                                                                                    container
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        width: "600px",
                                                                                    }}
                                                                                >
                                                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Typography textAlign="left">
                                                                                                {
                                                                                                    item
                                                                                                        .dependentOtherBusiness
                                                                                                        .title
                                                                                                }
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid2>
                                                                                    <Grid2
                                                                                        size={{ xs: 7, md: 7 }}
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            width: "600px",
                                                                                        }}
                                                                                    >
                                                                                        <FormControl
                                                                                            fullWidth
                                                                                            error={
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ]
                                                                                                    .dependentOtherBusiness !==
                                                                                                ""
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                value={
                                                                                                    item
                                                                                                        .dependentOtherBusiness
                                                                                                        .value
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    handleDependentOtherBusinessChange(
                                                                                                        e,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                                fullWidth
                                                                                            >
                                                                                                <MenuItem
                                                                                                    value="無職"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    無職
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value="パート"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    パート
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value="年金受給者"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    年金受給者
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value="小・中学生以下"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    小・中学生以下
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value="高・大学生"
                                                                                                    sx={{
                                                                                                        textAlign:
                                                                                                            "left",
                                                                                                    }}
                                                                                                >
                                                                                                    高・大学生
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid2>
                                                                                </Grid2>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    .dependentOtherBusiness !== "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherBusiness
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>

                                                                            {item.dependentOtherBusiness.value ===
                                                                                "高・大学生" && (
                                                                                <>
                                                                                    <Box sx={{ mt: 1 }}>
                                                                                        <TextField
                                                                                            value={
                                                                                                item
                                                                                                    .dependentOtherBusiness
                                                                                                    .note
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleDependentOtherBusinessNoteChange(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            onBlur={() =>
                                                                                                handleDependentOtherBusinessNoteBlur(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            error={
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ]
                                                                                                    .dependentOtherBusinessNote !==
                                                                                                ""
                                                                                            }
                                                                                            fullWidth
                                                                                            InputProps={{
                                                                                                inputMode: "numeric",
                                                                                                pattern: "[0-9]*",
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="end">
                                                                                                        年生
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            mt: 1,
                                                                                        }}
                                                                                    >
                                                                                        {dependentOtherError[index]
                                                                                            .dependentOtherBusinessNote !==
                                                                                            "" && (
                                                                                            <Alert
                                                                                                severity="error"
                                                                                                sx={{ width: "600px" }}
                                                                                            >
                                                                                                {
                                                                                                    dependentOtherError[
                                                                                                        index
                                                                                                    ]
                                                                                                        .dependentOtherBusinessNote
                                                                                                }
                                                                                            </Alert>
                                                                                        )}
                                                                                    </Box>
                                                                                </>
                                                                            )}

                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 3,
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    label={
                                                                                        item.dependentOtherIncome.title
                                                                                    }
                                                                                    value={
                                                                                        item.dependentOtherIncome.value
                                                                                    }
                                                                                    fullWidth
                                                                                    helperText="数字のみ入力してください"
                                                                                    onChange={(e) =>
                                                                                        handleDependentOtherIncomeChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleDependentOtherIncomeBlur(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        dependentOtherError[index]
                                                                                            .dependentOtherIncome !== ""
                                                                                    }
                                                                                    InputProps={{
                                                                                        inputMode: "numeric",
                                                                                        pattern: "[0-9]*",
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                円
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    .dependentOtherIncome !== "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherIncome
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>
                                                                        </>
                                                                    )}

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                width: "600px",
                                                                            }}
                                                                        >
                                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography textAlign="left">
                                                                                        {
                                                                                            item.dependentOtherOverseas
                                                                                                .title
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 7, md: 7 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        value={
                                                                                            item.dependentOtherOverseas
                                                                                                .value
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleDependentOtherOverseasChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        sx={{ textAlign: "left" }}
                                                                                        fullWidth
                                                                                    >
                                                                                        <MenuItem
                                                                                            value="該当"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            該当
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            value="非該当"
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            非該当
                                                                                        </MenuItem>
                                                                                        <MenuItem
                                                                                            value=""
                                                                                            sx={{ textAlign: "left" }}
                                                                                        >
                                                                                            選択を解除する
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>

                                                                    {item.dependentOtherOverseas.value !== "" && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDateFns}
                                                                                    adapterLocale={ja}
                                                                                >
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            width: "600px",
                                                                                        }}
                                                                                    >
                                                                                        <DesktopDatePicker
                                                                                            label={
                                                                                                item
                                                                                                    .dependentOtherOverseas
                                                                                                    .value === "該当"
                                                                                                    ? item
                                                                                                          .dependentOtherOverseasDate
                                                                                                          .title
                                                                                                    : "該当しなくなった日"
                                                                                            }
                                                                                            value={
                                                                                                item
                                                                                                    .dependentOtherOverseasDate
                                                                                                    .value
                                                                                            }
                                                                                            format="yyyy/MM/dd"
                                                                                            onChange={(e) =>
                                                                                                handleDependentOtherOverseasDateChange(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            slotProps={{
                                                                                                textField: {
                                                                                                    error:
                                                                                                        dependentOtherError[
                                                                                                            index
                                                                                                        ]
                                                                                                            ?.dependentOtherOverseasDate !==
                                                                                                        "",
                                                                                                    clearable: true,
                                                                                                    onBlur: () =>
                                                                                                        handleDependentOtherOverseasDateBlur(
                                                                                                            index
                                                                                                        ),
                                                                                                    fullWidth: true,
                                                                                                },
                                                                                                calendarHeader: {
                                                                                                    format: "yyyy年MM月",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </LocalizationProvider>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    .dependentOtherOverseasDate !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherOverseasDate
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>

                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                <Grid2
                                                                                    container
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        width: "600px",
                                                                                    }}
                                                                                >
                                                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Typography textAlign="left">
                                                                                                {
                                                                                                    item
                                                                                                        .dependentOtherOverseasReason
                                                                                                        .title
                                                                                                }
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Grid2>
                                                                                    <Grid2
                                                                                        size={{ xs: 7, md: 7 }}
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <Select
                                                                                            value={
                                                                                                item
                                                                                                    .dependentOtherOverseasReason
                                                                                                    .value
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleDependentOtherOverseasReasonChange(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            sx={{ textAlign: "left" }}
                                                                                            fullWidth
                                                                                        >
                                                                                            {item.dependentOtherOverseas
                                                                                                .value === "該当" ||
                                                                                            item.dependentOtherOverseas
                                                                                                .value === ""
                                                                                                ? otherOverseasApplicableReason.map(
                                                                                                      (reason) => (
                                                                                                          <MenuItem
                                                                                                              key={
                                                                                                                  reason.value
                                                                                                              }
                                                                                                              value={
                                                                                                                  reason.value
                                                                                                              }
                                                                                                              sx={{
                                                                                                                  textAlign:
                                                                                                                      "left",
                                                                                                              }}
                                                                                                          >
                                                                                                              {
                                                                                                                  reason.title
                                                                                                              }
                                                                                                          </MenuItem>
                                                                                                      )
                                                                                                  )
                                                                                                : otherOverseasNotApplicableReason.map(
                                                                                                      (reason) => (
                                                                                                          <MenuItem
                                                                                                              key={
                                                                                                                  reason.value
                                                                                                              }
                                                                                                              value={
                                                                                                                  reason.value
                                                                                                              }
                                                                                                              sx={{
                                                                                                                  textAlign:
                                                                                                                      "left",
                                                                                                              }}
                                                                                                          >
                                                                                                              {
                                                                                                                  reason.title
                                                                                                              }
                                                                                                          </MenuItem>
                                                                                                      )
                                                                                                  )}
                                                                                        </Select>
                                                                                    </Grid2>
                                                                                </Grid2>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    mt: 1,
                                                                                }}
                                                                            >
                                                                                {dependentOtherError[index]
                                                                                    .dependentOtherOverseasReason !==
                                                                                    "" && (
                                                                                    <Alert
                                                                                        severity="error"
                                                                                        sx={{ width: "600px" }}
                                                                                    >
                                                                                        {
                                                                                            dependentOtherError[index]
                                                                                                .dependentOtherOverseasReason
                                                                                        }
                                                                                    </Alert>
                                                                                )}
                                                                            </Box>

                                                                            {item.dependentOtherOverseasReason.value ===
                                                                                "その他" && (
                                                                                <>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            mt: 1,
                                                                                        }}
                                                                                    >
                                                                                        <TextField
                                                                                            variant="outlined"
                                                                                            value={
                                                                                                item
                                                                                                    .dependentOtherOverseasReason
                                                                                                    .note
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleDependentOtherOverseasReasonNoteChange(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            onBlur={() =>
                                                                                                handleDependentOtherOverseasReasonNoteBlur(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            error={
                                                                                                dependentOtherError[
                                                                                                    index
                                                                                                ]
                                                                                                    .dependentOtherOverseasReasonNote !==
                                                                                                ""
                                                                                            }
                                                                                            fullWidth
                                                                                        />
                                                                                    </Box>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            mt: 1,
                                                                                        }}
                                                                                    >
                                                                                        {dependentOtherError[index]
                                                                                            .dependentOtherOverseasReasonNote !==
                                                                                            "" && (
                                                                                            <Alert
                                                                                                severity="error"
                                                                                                sx={{ width: "600px" }}
                                                                                            >
                                                                                                {
                                                                                                    dependentOtherError[
                                                                                                        index
                                                                                                    ]
                                                                                                        .dependentOtherOverseasReasonNote
                                                                                                }
                                                                                            </Alert>
                                                                                        )}
                                                                                    </Box>
                                                                                </>
                                                                            )}
                                                                            {item.dependentOtherOverseasReason.value ===
                                                                                "国内転入" && (
                                                                                <>
                                                                                    <LocalizationProvider
                                                                                        dateAdapter={AdapterDateFns}
                                                                                        adapterLocale={ja}
                                                                                    >
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "center",
                                                                                                alignItems: "center",
                                                                                                width: "568px",
                                                                                            }}
                                                                                        >
                                                                                            <DesktopDatePicker
                                                                                                label="転入日"
                                                                                                value={
                                                                                                    item
                                                                                                        .dependentOtherOverseasReason
                                                                                                        .date
                                                                                                }
                                                                                                format="yyyy/MM/dd"
                                                                                                onChange={(e) =>
                                                                                                    handleDependentOtherOverseasReasonDateChange(
                                                                                                        e,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                slotProps={{
                                                                                                    textField: {
                                                                                                        error:
                                                                                                            dependentOtherError[
                                                                                                                index
                                                                                                            ]
                                                                                                                .dependentOtherOverseasReasonDate !==
                                                                                                            "",
                                                                                                        clearable: true,
                                                                                                        onBlur: () =>
                                                                                                            handleDependentOtherOverseasReasonDateBlur(
                                                                                                                index
                                                                                                            ),
                                                                                                        fullWidth: true,
                                                                                                    },
                                                                                                    calendarHeader: {
                                                                                                        format: "yyyy年MM月",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </LocalizationProvider>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            mt: 1,
                                                                                        }}
                                                                                    >
                                                                                        {dependentOtherError[index]
                                                                                            .dependentOtherOverseasReasonDate !==
                                                                                            "" && (
                                                                                            <Alert
                                                                                                severity="error"
                                                                                                sx={{ width: "600px" }}
                                                                                            >
                                                                                                {
                                                                                                    dependentOtherError[
                                                                                                        index
                                                                                                    ]
                                                                                                        .dependentOtherOverseasReasonDate
                                                                                                }
                                                                                            </Alert>
                                                                                        )}
                                                                                    </Box>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        item.dependentOtherMyNumberFront
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item.dependentOtherMyNumberFront
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherMyNumberFront
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherMyNumberFront
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherMyNumberFront
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        item.dependentOtherMyNumberBack
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item.dependentOtherMyNumberBack
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherMyNumberBack
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherMyNumberBack
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherMyNumberBack
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        item
                                                                                            .dependentOtherRelationshipCertificate
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item
                                                                                            .dependentOtherRelationshipCertificate
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherRelationshipCertificate
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item
                                                                                    .dependentOtherRelationshipCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item
                                                                                    .dependentOtherRelationshipCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {item.dependentOtherResident.title}
                                                                                </Typography>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item.dependentOtherResident
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherResident
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherResident.value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherResident.value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        item
                                                                                            .dependentOtherIncomeCertificate
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                                <IconButton sx={{ ml: 1 }}>
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                大学生以上かつ、就労している場合のみ
                                                                                            </Typography>
                                                                                        }
                                                                                        placement="top"
                                                                                        sx={{
                                                                                            textAlign: "left",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            overflowWrap: "break-word",
                                                                                        }}
                                                                                    >
                                                                                        <InfoOutlinedIcon />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item
                                                                                            .dependentOtherIncomeCertificate
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherIncomeCertificate
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherIncomeCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherIncomeCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 3,
                                                                        }}
                                                                    >
                                                                        <Grid2
                                                                            container
                                                                            spacing={2}
                                                                            sx={{ width: "600px" }}
                                                                        >
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        item
                                                                                            .dependentOtherStudentCertificate
                                                                                            .title
                                                                                    }
                                                                                </Typography>
                                                                                <IconButton sx={{ ml: 1 }}>
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                }}
                                                                                            >
                                                                                                大学生以上の場合のみ
                                                                                            </Typography>
                                                                                        }
                                                                                        placement="top"
                                                                                        sx={{
                                                                                            textAlign: "left",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            overflowWrap: "break-word",
                                                                                        }}
                                                                                    >
                                                                                        <InfoOutlinedIcon />
                                                                                    </Tooltip>
                                                                                </IconButton>
                                                                            </Grid2>
                                                                            <Grid2
                                                                                size={{ xs: 6, md: 6 }}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    value={
                                                                                        item
                                                                                            .dependentOtherStudentCertificate
                                                                                            .value === ""
                                                                                            ? "未アップロード"
                                                                                            : item
                                                                                                  .dependentOtherStudentCertificate
                                                                                                  .value
                                                                                    }
                                                                                    InputProps={{ readOnly: true }}
                                                                                />
                                                                            </Grid2>
                                                                        </Grid2>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            mt: 1,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="large"
                                                                        >
                                                                            アップロードする
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="info"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherStudentCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            ファイルを確認する
                                                                        </Button>
                                                                        <Button
                                                                            color="error"
                                                                            size="large"
                                                                            sx={{ ml: 3 }}
                                                                            disabled={
                                                                                item.dependentOtherStudentCertificate
                                                                                    .value === ""
                                                                            }
                                                                        >
                                                                            削除する
                                                                        </Button>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                        </Box>
                                                    ))}

                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="large"
                                                            onClick={handleDependentOtherAdd}
                                                            disabled={dependentOtherData.length >= 5}
                                                            sx={{ width: "400px" }}
                                                        >
                                                            被扶養者を追加する
                                                        </Button>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : tab === 1 ? (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffResume.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffResume.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffResume.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffResume.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffResume.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffJobHistory.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffJobHistory.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffJobHistory.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffJobHistory.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffJobHistory.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffMyNumberFront.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffMyNumberFront.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffMyNumberFront.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffMyNumberFront.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffMyNumberFront.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffMyNumberBack.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffMyNumberBack.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffMyNumberBack.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffMyNumberBack.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffMyNumberBack.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>
                                                    {staffUploadData.staffIdentificationFront.title}
                                                </Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffIdentificationFront.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffIdentificationFront.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffIdentificationFront.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffIdentificationFront.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffIdentificationBack.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffIdentificationBack.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffIdentificationBack.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffIdentificationBack.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffIdentificationBack.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffResident.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffResident.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffResident.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffResident.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffResident.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffWithholding.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffWithholding.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffWithholding.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffWithholding.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffWithholding.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffHealthCheck.title}</Typography>
                                                <IconButton sx={{ ml: 1 }}>
                                                    <Tooltip
                                                        title={
                                                            <Typography variant="caption" sx={{ textAlign: "left" }}>
                                                                直近3ヶ月以内に受けた場合のみ
                                                            </Typography>
                                                        }
                                                        placement="top"
                                                        sx={{
                                                            textAlign: "left",
                                                            whiteSpace: "pre-wrap",
                                                            overflowWrap: "break-word",
                                                        }}
                                                    >
                                                        <InfoOutlinedIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    value={
                                                        staffUploadData.staffHealthCheck.value === ""
                                                            ? "未アップロード"
                                                            : staffUploadData.staffHealthCheck.value
                                                    }
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large">
                                            アップロードする
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffHealthCheck.value === ""}
                                        >
                                            ファイルを確認する
                                        </Button>
                                        <Button
                                            color="error"
                                            size="large"
                                            sx={{ ml: 3 }}
                                            disabled={staffUploadData.staffHealthCheck.value === ""}
                                        >
                                            削除する
                                        </Button>
                                    </Box>

                                    {staffData.staffNationality.select !== "japanese" && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>
                                                            {staffUploadData.staffSpecialResidence.title}
                                                        </Typography>
                                                        <IconButton sx={{ ml: 1 }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ textAlign: "left" }}
                                                                    >
                                                                        外国人登録証明書でも可
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                                sx={{
                                                                    textAlign: "left",
                                                                    whiteSpace: "pre-wrap",
                                                                    overflowWrap: "break-word",
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffSpecialResidence.value === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffSpecialResidence.value
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffSpecialResidence.value === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffSpecialResidence.value === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>{staffUploadData.staffWorkVisa.title}</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffWorkVisa.value === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffWorkVisa.value
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffWorkVisa.value === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffWorkVisa.value === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>{staffUploadData.staffPassport.title}</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffPassport.value === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffPassport.value
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffPassport.value === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffPassport.value === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <Typography>{staffUploadData.staffCommute.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 6, md: 6 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                <RadioGroup
                                                    row
                                                    value={staffUploadData.staffCommute.select}
                                                    onChange={handleStaffCommuteSelectChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="利用する"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="利用しない"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {staffUploadData.staffCommute.select === true && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>運転免許証・表</Typography>
                                                        <IconButton sx={{ ml: 1 }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ textAlign: "left" }}
                                                                    >
                                                                        顔写真付き本人確認書類・表にて、運転免許証をアップロードしている場合は不要です
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                                sx={{
                                                                    textAlign: "left",
                                                                    whiteSpace: "pre-wrap",
                                                                    overflowWrap: "break-word",
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffCommute.driverLicenseFront === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffCommute.driverLicenseFront
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseFront === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseFront === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>運転免許証・裏</Typography>
                                                        <IconButton sx={{ ml: 1 }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ textAlign: "left" }}
                                                                    >
                                                                        顔写真付き本人確認書類・裏にて、運転免許証をアップロードしている場合は不要です
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                                sx={{
                                                                    textAlign: "left",
                                                                    whiteSpace: "pre-wrap",
                                                                    overflowWrap: "break-word",
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffCommute.driverLicenseBack === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffCommute.driverLicenseBack
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseBack === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseBack === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>車検証</Typography>
                                                        <IconButton sx={{ ml: 1 }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ textAlign: "left" }}
                                                                    >
                                                                        自動車・250CCを超えるバイクは車検証
                                                                        <br />
                                                                        250CC以下のバイクは軽自動車届出済証
                                                                        <br />
                                                                        原付バイクは標識交付証明書
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                                sx={{
                                                                    textAlign: "left",
                                                                    whiteSpace: "pre-wrap",
                                                                    overflowWrap: "break-word",
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffCommute.driverLicenseFront === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffCommute.driverLicenseFront
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseFront === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.driverLicenseFront === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>自賠責保険</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffCommute.compulsoryInsurance === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffCommute.compulsoryInsurance
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.compulsoryInsurance === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.compulsoryInsurance === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>任意保険</Typography>
                                                        <IconButton sx={{ ml: 1 }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ textAlign: "left" }}
                                                                    >
                                                                        対物賠償保険　　無制限
                                                                        <br />
                                                                        搭乗者傷害保険　500万円以上
                                                                        <br />
                                                                        人身傷害保険　　3,000万円以上
                                                                        <br />
                                                                        対物賠償保険　　2,000万円以上
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                                sx={{
                                                                    textAlign: "left",
                                                                    whiteSpace: "pre-wrap",
                                                                    overflowWrap: "break-word",
                                                                }}
                                                            >
                                                                <InfoOutlinedIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                                staffUploadData.staffCommute.optionalInsurance === ""
                                                                    ? "未アップロード"
                                                                    : staffUploadData.staffCommute.optionalInsurance
                                                            }
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.optionalInsurance === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={staffUploadData.staffCommute.optionalInsurance === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mb: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Typography textAlign="left" sx={{ width: "600px" }}>
                                            資格・免許
                                        </Typography>
                                    </Box>
                                    {staffLicenseData.map((license, i) => (
                                        <Box key={i}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <Grid2 container spacing={2} sx={{ width: "600px" }}>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <Typography>資格・免許 ({i + 1})</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, md: 6 }}
                                                        sx={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            label="資格・免許名"
                                                            value={license[i]?.title}
                                                            onChange={(e) => handleStaffLicenseChange(e, i)}
                                                            onBlur={() => handleStaffLicenseBlur(i)}
                                                            error={staffLicenseError[i]?.title !== ""}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => handleStaffLicenseDelete(i)}
                                                                        >
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 1,
                                                }}
                                            >
                                                <Button variant="outlined" color="primary" size="large">
                                                    アップロードする
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="info"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={license.value === ""}
                                                >
                                                    ファイルを確認する
                                                </Button>
                                                <Button
                                                    color="error"
                                                    size="large"
                                                    sx={{ ml: 3 }}
                                                    disabled={license.value === ""}
                                                >
                                                    削除する
                                                </Button>
                                            </Box>
                                        </Box>
                                    ))}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "400px" }}
                                            onClick={handleStaffLicenseAdd}
                                            disabled={staffLicenseData.length >= 5}
                                        >
                                            資格・免許を追加する
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <Typography textAlign="left">
                                                    {staffDomitoryData.staffDomitoryFlag.title}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <RadioGroup
                                                    row
                                                    value={staffDomitoryData.staffDomitoryFlag.value}
                                                    onChange={handleStaffDomitoryFlagChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="利用する"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="利用しない"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {staffDomitoryData.staffDomitoryFlag.value === true && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    label={staffDomitoryData.staffDomitoryName.title}
                                                    value={
                                                        staffDomitoryData.staffDomitoryName.value
                                                            ? `${staffDomitoryData.staffDomitoryName.value} ${staffDomitoryData.staffDomitoryNumber.value}`
                                                            : ""
                                                    }
                                                    sx={{ width: "600px" }}
                                                    onClick={handleStaffDomitoryNameChange}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {staffDomitoryData.staffDomitoryName.value !== "" ? (
                                                                    <IconButton onClick={handleStaffDomitoryNameClear}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton onClick={handleStaffDomitoryNameChange}>
                                                                        <FilterNoneIcon />
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: 3,
                                                }}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            width: "600px",
                                                        }}
                                                    >
                                                        <DesktopDatePicker
                                                            label={staffDomitoryData.staffDomitoryInDate.title}
                                                            value={staffDomitoryData.staffDomitoryInDate.value}
                                                            format="yyyy/MM/dd"
                                                            onChange={handleStaffDomitoryInDateChange}
                                                            slotProps={{
                                                                textField: {
                                                                    clearable: true,
                                                                    fullWidth: true,
                                                                },
                                                                calendarHeader: {
                                                                    format: "yyyy年MM月",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </LocalizationProvider>
                                            </Box>
                                        </>
                                    )}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Grid2
                                            container
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "600px",
                                            }}
                                        >
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <Typography textAlign="left">
                                                    {equip.staffEquipmentFlag.title}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <RadioGroup
                                                    row
                                                    value={equip.staffEquipmentFlag.value}
                                                    onChange={handleStaffEquipmentFlagChange}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="利用する"
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="利用しない"
                                                    />
                                                </RadioGroup>
                                            </Grid2>
                                        </Grid2>
                                    </Box>

                                    {equip.staffEquipmentFlag.value === true && (
                                        <>
                                            {equipmentData.map((equipment, i) => (
                                                <Box
                                                    key={i}
                                                    sx={{
                                                        mt: 3,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={staffEquipmentData[i]?.selected}
                                                        onChange={(e) => handleStaffEquipmentCheckboxChange(e, i)}
                                                    />
                                                    <TextField
                                                        variant="standard"
                                                        value={equipment.name}
                                                        sx={{ width: "225px" }}
                                                        InputProps={{ readOnly: true }}
                                                        onClick={(e) => {
                                                            const checkboxEvent = {
                                                                target: {
                                                                    checked: !staffEquipmentData[i]?.selected,
                                                                },
                                                            };
                                                            handleStaffEquipmentCheckboxChange(checkboxEvent, i);
                                                        }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        label="個数"
                                                        value={staffEquipmentData[i]?.value}
                                                        sx={{ width: "100px", mx: 2 }}
                                                        onChange={(e) => handleStaffEquipmentNumberChange(e, i)}
                                                        disabled={!staffEquipmentData[i]?.selected}
                                                    />
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={ja}
                                                    >
                                                        <DesktopDatePicker
                                                            label="借入日"
                                                            value={staffEquipmentData[i]?.inDate}
                                                            format="yyyy/MM/dd"
                                                            onChange={(e) => handleStaffEquipmentInDateChange(e, i)}
                                                            disabled={!staffEquipmentData[i]?.selected}
                                                            slotProps={{
                                                                textField: {
                                                                    clearable: true,
                                                                    fullWidth: true,
                                                                    disabled: !staffEquipmentData[i]?.selected,
                                                                },
                                                                calendarHeader: {
                                                                    format: "yyyy年MM月",
                                                                },
                                                            }}
                                                            sx={{ width: "200px" }}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "400px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddConfirm}
                            sx={{ width: "400px" }}
                        >
                            {request !== true ? "入力内容を確認する" : "本人へ入力を依頼する"}
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 10 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "400px" }}
                        >
                            スタッフ一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={domitoryOpen} onClose={handleDomitoryClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleDomitoryClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6">社宅を選択</Typography>

                    <MaterialReactTable
                        columns={columns}
                        data={dormitoryData}
                        columnFilterDisplayMode="popover"
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableHiding={false}
                        localization={MRT_Localization_JA}
                        positionToolbarAlertBanner={false}
                        initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                        muiPaginationProps={{ shape: "rounded", color: "primary" }}
                        paginationDisplayMode="pages"
                        positionGlobalFilter="left"
                        enableStickyHeader={true}
                        getRowId={(row) => `${row.name} ${row.number}`}
                        muiTablePaperProps={{
                            sx: { mt: 2 },
                            elevation: 0,
                        }}
                        muiTableContainerProps={{
                            sx: {
                                minHeight: "300px",
                                maxHeight: "300px",
                                overflow: "auto",
                            },
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => handleRowSelectionChange(row),
                            selected:
                                selectedDomitory?.name === row.original.name &&
                                selectedDomitory?.number === row.original.number,
                            sx: { cursor: "pointer" },
                        })}
                        state={{
                            rowSelection: selectedDomitory
                                ? { [`${selectedDomitory.name} ${selectedDomitory.number}`]: true }
                                : {},
                        }}
                    />

                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleAddDomitory}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        選択した社宅を利用する
                    </Button>

                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleDomitoryClose}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
                        スタッフ情報の入力内容を確認
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <Tabs value={openTab} onChange={handleOpenTabChange}>
                            <Tab label="基本情報" />
                            <Tab label="アップロード書類" />
                            <Tab label="社宅・備品" />
                        </Tabs>
                    </Box>

                    {openTab === 0 ? (
                        <>
                            {Object.entries(staffData).map(([key, data]) => (
                                <Box key={key}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{data.title}</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 8, md: 8 }}
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                        >
                                            {key === "staffBirthday" ? (
                                                <Typography>
                                                    {new Date(data.value).toLocaleDateString("ja-JP")} (
                                                    {Math.floor(
                                                        (new Date() - new Date(data.value)) /
                                                            (365.25 * 24 * 60 * 60 * 1000)
                                                    )}
                                                    歳)
                                                </Typography>
                                            ) : key === "staffAddress" ? (
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                    <br />
                                                    {data.prefecture}
                                                    {data.city}
                                                    {data.other}
                                                    <br />
                                                    {data.building}
                                                </Typography>
                                            ) : key === "staffResidentAddress" ? (
                                                data.ibid === true ? (
                                                    <Typography sx={{ textAlign: "left" }}>同上</Typography>
                                                ) : (
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                        <br />
                                                        {data.prefecture}
                                                        {data.city}
                                                        {data.other}
                                                        <br />
                                                        {data.building}
                                                    </Typography>
                                                )
                                            ) : key === "staffNationality" ? (
                                                <Typography>
                                                    {data.select === "japanese" ? "日本" : data.value}
                                                </Typography>
                                            ) : key === "staffGender" ? (
                                                <Typography>{data.select === "male" ? "男性" : "女性"}</Typography>
                                            ) : key === "staffBank" ? (
                                                <Box>
                                                    <Typography>
                                                        {data.bankName}
                                                        {data.select === "bank" ? "銀行" : "信用金庫"} {data.branch}
                                                        支店
                                                    </Typography>
                                                    <Typography>普通口座 {data.number}</Typography>
                                                    <Typography>{data.holder}</Typography>
                                                </Box>
                                            ) : key === "staffEmploymentInsuranceNumber" ? (
                                                <Typography>{data.value === "" ? "未入力" : data.value}</Typography>
                                            ) : key === "staffBasicPensionNumber" ? (
                                                <Typography>{data.value === "" ? "未入力" : data.value}</Typography>
                                            ) : (
                                                <Typography>{data.value}</Typography>
                                            )}
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}

                            <Typography fontWeight="bold" textAlign="center" sx={{ mt: 5, mb: 2 }}>
                                詳細情報
                            </Typography>
                            {Object.entries(staffMoreData)
                                .slice(0, 8)
                                .map(([key, data]) => (
                                    <Box key={key}>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 4, md: 4 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">{data.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 8, md: 8 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {key === "staffJoinDate" ? (
                                                    <Typography>
                                                        {new Date(data.value).toLocaleDateString("ja-JP")}
                                                    </Typography>
                                                ) : key === "staffDivision" ? (
                                                    <Typography>{data.value === "" ? "未選択" : data.value}</Typography>
                                                ) : key === "staffCategory" ? (
                                                    <Typography>{data.value === "" ? "未選択" : data.value}</Typography>
                                                ) : key === "staffPosition" ? (
                                                    <Typography>{data.value === "" ? "未選択" : data.value}</Typography>
                                                ) : key === "staffConflictDate" ? (
                                                    <Typography>
                                                        {data.value === null
                                                            ? "未選択"
                                                            : new Date(data.value).toLocaleDateString("ja-JP")}
                                                    </Typography>
                                                ) : key === "staffAgreementFlag" ? (
                                                    <Typography>
                                                        {data.value === true ? "該当する" : "該当しない"}
                                                    </Typography>
                                                ) : key === "staffDispatch" ? (
                                                    <Typography>
                                                        {data.value === "" ? "未選択" : data.value}
                                                        <br />
                                                        {data.date === null
                                                            ? "開始日未選択"
                                                            : new Date(data.date).toLocaleDateString("ja-JP")}
                                                    </Typography>
                                                ) : (
                                                    <Typography>{data.value}</Typography>
                                                )}
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </Box>
                                ))}

                            <Typography fontWeight="bold" textAlign="center" sx={{ mt: 5, mb: 2 }}>
                                緊急連絡先
                            </Typography>
                            {Object.entries(emergencyContactData).map(([key, data]) => (
                                <Box key={key}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{data.title}</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 8, md: 8 }}
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                        >
                                            {key === "emergencyContactAddress" ? (
                                                <>
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                        <br />
                                                        {data.prefecture}
                                                        {data.city}
                                                        {data.other}
                                                        <br />
                                                        {data.building}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography>{data.value}</Typography>
                                            )}
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}

                            <Box sx={{ mt: 5 }}>
                                {spouseData.spouseFlag.value === true ? (
                                    <>
                                        <Typography fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
                                            配偶者
                                        </Typography>
                                        {Object.entries(spouseData)
                                            .slice(1, 8)
                                            .map(([key, data]) => (
                                                <Box key={key}>
                                                    <Grid2 container spacing={2}>
                                                        <Grid2
                                                            size={{ xs: 4, md: 4 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {key === "spouseAddress" &&
                                                            spouseData.spouseTogether.value === true ? null : (
                                                                <Typography fontWeight="bold">{data.title}</Typography>
                                                            )}
                                                        </Grid2>
                                                        <Grid2
                                                            size={{ xs: 8, md: 8 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {key === "spouseBirthday" ? (
                                                                <Typography>
                                                                    {new Date(data.value).toLocaleDateString("ja-JP")}
                                                                </Typography>
                                                            ) : key === "spouseGender" ? (
                                                                <Typography>
                                                                    {data.select === "male" ? "男性" : "女性"}
                                                                </Typography>
                                                            ) : key === "spouseTogether" ? (
                                                                <Typography>
                                                                    {data.value === true ? "同居" : "別居"}
                                                                </Typography>
                                                            ) : key === "spouseAddress" ? (
                                                                <>
                                                                    {spouseData.spouseTogether.value === true ? null : (
                                                                        <Typography sx={{ textAlign: "left" }}>
                                                                            {"〒" +
                                                                                data.post.replace(
                                                                                    /(\d{3})(?=\d)/,
                                                                                    "$1-"
                                                                                )}
                                                                            <br />
                                                                            {data.prefecture}
                                                                            {data.city}
                                                                            {data.other}
                                                                            <br />
                                                                            {data.building}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            ) : key === "spouseNationality" ? (
                                                                <Typography>
                                                                    {data.select === "japanese" ? "日本" : data.value}
                                                                </Typography>
                                                            ) : (
                                                                <Typography>{data.value}</Typography>
                                                            )}
                                                        </Grid2>
                                                    </Grid2>
                                                    {key === "spouseTogether" &&
                                                    spouseData.spouseTogether.value === true ? null : (
                                                        <Divider sx={{ my: 1 }} />
                                                    )}
                                                </Box>
                                            ))}
                                    </>
                                ) : (
                                    <>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 4, md: 4 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">配偶者</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 8, md: 8 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography>なし</Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </>
                                )}
                            </Box>

                            <Box sx={{ mt: 5 }}>
                                {dependentData.dependentFlag.value === true ? (
                                    <>
                                        <Typography fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
                                            被扶養者届
                                        </Typography>
                                        {dependentData.dependentSpouseFlag.value === false ? (
                                            <>
                                                <Grid2 container spacing={2}>
                                                    <Grid2
                                                        size={{ xs: 4, md: 4 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">配偶者</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 8, md: 8 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography>なし</Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                            </>
                                        ) : (
                                            <>
                                                {Object.entries(dependentData)
                                                    .slice(1, 10)
                                                    .map(([key, data]) => (
                                                        <Box key={key}>
                                                            <Grid2 container spacing={2}>
                                                                <Grid2
                                                                    size={{ xs: 4, md: 4 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {key === "dependentSpouseDate" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                        "非該当" ? (
                                                                            <Typography fontWeight="bold">
                                                                                被扶養者でなくなった日
                                                                            </Typography>
                                                                        ) : dependentData.dependentSpouseItem.value ===
                                                                          "変更" ? null : (
                                                                            <Typography fontWeight="bold">
                                                                                {data.title}
                                                                            </Typography>
                                                                        )
                                                                    ) : key === "dependentSpouseBusiness" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography fontWeight="bold">
                                                                                {data.title}
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : key === "dependentSpouseIncome" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography fontWeight="bold">
                                                                                {data.title}
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : (
                                                                        <Typography fontWeight="bold">
                                                                            {data.title}
                                                                        </Typography>
                                                                    )}
                                                                </Grid2>
                                                                <Grid2
                                                                    size={{ xs: 8, md: 8 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {key === "dependentSpouseFlag" ? (
                                                                        <Typography>
                                                                            {data.value === true ? "有" : "無"}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseMarried" ? (
                                                                        <Typography>
                                                                            {data.value === true ? "有" : "無"}
                                                                        </Typography>
                                                                    ) : key === "dependentSpousePhone" ? (
                                                                        <Typography>
                                                                            {data.select === "home"
                                                                                ? "自宅"
                                                                                : data.select === "mobile"
                                                                                ? "携帯"
                                                                                : data.select === "work"
                                                                                ? "勤務先"
                                                                                : "その他"}{" "}
                                                                            {data.value}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseDate" ? (
                                                                        <Typography>
                                                                            {data.value === null
                                                                                ? ""
                                                                                : data.value.toLocaleDateString(
                                                                                      "ja-JP"
                                                                                  )}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseReason" ? (
                                                                        <>
                                                                            <Typography>
                                                                                {data.value}
                                                                                <br />
                                                                                {data.value === "その他"
                                                                                    ? `（${data.note}）`
                                                                                    : null}
                                                                                {data.value === "死亡"
                                                                                    ? data.deadDate === null
                                                                                        ? ""
                                                                                        : data.deadDate.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )
                                                                                    : null}
                                                                            </Typography>
                                                                        </>
                                                                    ) : key === "dependentSpouseBusiness" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <>
                                                                                <Typography>{data.value}</Typography>
                                                                                <Typography>
                                                                                    {data.value === "その他"
                                                                                        ? `（${data.note}）`
                                                                                        : null}
                                                                                </Typography>
                                                                            </>
                                                                        ) : null
                                                                    ) : key === "dependentSpouseIncome" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography>
                                                                                {data.value
                                                                                    .toString()
                                                                                    .replace(
                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                        ","
                                                                                    )}
                                                                                円
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : (
                                                                        <Typography>{data.value}</Typography>
                                                                    )}
                                                                </Grid2>
                                                            </Grid2>
                                                            {key === "dependentSpouseDate" ? (
                                                                dependentData.dependentSpouseDate.value ===
                                                                null ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : key === "dependentSpouseBusiness" ? (
                                                                dependentData.dependentSpouseBusiness.value ===
                                                                "" ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : key === "dependentSpouseIncome" ? (
                                                                dependentData.dependentSpouseIncome.value ===
                                                                "" ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : (
                                                                <Divider sx={{ my: 1 }} />
                                                            )}
                                                        </Box>
                                                    ))}
                                                <Grid2 container spacing={2}>
                                                    <Grid2 size={{ xs: 4, md: 4 }}>
                                                        <Typography fontWeight="bold" textAlign="right">
                                                            海外特例要件
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 8, md: 8 }}>
                                                        <Typography>
                                                            {dependentData.dependentSpouseOverseas.value === ""
                                                                ? "なし"
                                                                : dependentData.dependentSpouseOverseas.value}
                                                        </Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                                {(dependentData.dependentSpouseOverseas.value === "該当" ||
                                                    dependentData.dependentSpouseOverseas.value === "非該当") && (
                                                    <>
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 4, md: 4 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {dependentData.dependentSpouseOverseas.value ===
                                                                    "該当"
                                                                        ? "該当した日"
                                                                        : "非該当になった日"}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 8, md: 8 }}>
                                                                <Typography>
                                                                    {dependentData.dependentSpouseOverseas.value ===
                                                                        "該当" ||
                                                                    dependentData.dependentSpouseOverseas.value ===
                                                                        "非該当"
                                                                        ? dependentData.dependentSpouseDate.value ===
                                                                          null
                                                                            ? ""
                                                                            : dependentData.dependentSpouseDate.value.toLocaleDateString(
                                                                                  "ja-JP"
                                                                              )
                                                                        : null}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 4, md: 4 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {dependentData.dependentSpouseOverseasReason.title}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 8, md: 8 }}>
                                                                {dependentData.dependentSpouseOverseasReason.value ===
                                                                "その他" ? (
                                                                    <>
                                                                        <Typography>
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.value
                                                                            }
                                                                        </Typography>
                                                                        <Typography>
                                                                            (
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.note
                                                                            }
                                                                            )
                                                                        </Typography>
                                                                    </>
                                                                ) : dependentData.dependentSpouseOverseasReason
                                                                      .value === "国内転入" ? (
                                                                    <>
                                                                        <Typography>
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.value
                                                                            }
                                                                        </Typography>
                                                                        <Typography>
                                                                            (
                                                                            {dependentData.dependentSpouseOverseasReason
                                                                                .date === null
                                                                                ? null
                                                                                : dependentData.dependentSpouseOverseasReason.date.toLocaleDateString(
                                                                                      "ja-JP"
                                                                                  )}
                                                                            )
                                                                        </Typography>
                                                                    </>
                                                                ) : (
                                                                    <Typography>
                                                                        {
                                                                            dependentData.dependentSpouseOverseasReason
                                                                                .value
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />
                                                    </>
                                                )}
                                                {Object.entries(dependentData)
                                                    .slice(13, 18)
                                                    .map(([key, data]) => (
                                                        <Box key={key}>
                                                            <Grid2 container spacing={2}>
                                                                <Grid2
                                                                    size={{ xs: 4, md: 4 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Typography fontWeight="bold">
                                                                        {data.title}
                                                                    </Typography>
                                                                </Grid2>
                                                                <Grid2 size={{ xs: 8, md: 8 }}>
                                                                    <Typography>
                                                                        {data.value === ""
                                                                            ? "未アップロード"
                                                                            : "アップロード済み"}
                                                                    </Typography>
                                                                </Grid2>
                                                            </Grid2>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Box>
                                                    ))}
                                            </>
                                        )}
                                        {dependentOtherFlag.value === false ? (
                                            <>
                                                <Grid2 container spacing={2} sx={{ mt: 3 }}>
                                                    <Grid2
                                                        size={{ xs: 4, md: 4 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">被扶養者</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 8, md: 8 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography>なし</Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                            </>
                                        ) : (
                                            <>
                                                {dependentOtherData.map((data, i) => (
                                                    <Box key={i}>
                                                        <Grid2 container spacing={2} sx={{ mt: 3, mb: 2 }}>
                                                            <Grid2 size={{ xs: 4, md: 4 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {"被扶養者 (" + (i + 1) + ")"}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>

                                                        {Object.entries(data)
                                                            .slice(0, 13)
                                                            .map(([key, value]) => (
                                                                <Box key={key}>
                                                                    <Grid2 container spacing={2}>
                                                                        <Grid2
                                                                            size={{ xs: 4, md: 4 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {key === "dependentOtherDate" ? (
                                                                                data.dependentOtherItem.value ===
                                                                                "非該当" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        被扶養者でなくなった日
                                                                                    </Typography>
                                                                                ) : data.dependentOtherItem.value ===
                                                                                  "変更" ? null : (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                )
                                                                            ) : key === "dependentOtherAddress" ? (
                                                                                data.dependentOtherTogether.value ===
                                                                                false ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherBusiness" ? (
                                                                                data.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                data.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherIncome" ? (
                                                                                data.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                data.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : (
                                                                                <Typography fontWeight="bold">
                                                                                    {value.title}
                                                                                </Typography>
                                                                            )}
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 8, md: 8 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {key === "dependentOtherGender" ? (
                                                                                <Typography>
                                                                                    {value.value === "male"
                                                                                        ? "男性"
                                                                                        : "女性"}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherBirthday" ? (
                                                                                <Typography>
                                                                                    {value.value === null
                                                                                        ? ""
                                                                                        : value.value.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherRelationship" ? (
                                                                                <Typography>{value.value}</Typography>
                                                                            ) : key === "dependentOtherTogether" ? (
                                                                                <Typography>
                                                                                    {value.value === true
                                                                                        ? "同居"
                                                                                        : "別居"}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherAddress" ? (
                                                                                data.dependentOtherTogether.value ===
                                                                                false ? (
                                                                                    <Typography
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        {"〒" +
                                                                                            value.post.replace(
                                                                                                /(\d{3})(?=\d)/,
                                                                                                "$1-"
                                                                                            )}
                                                                                        <br />
                                                                                        {value.prefecture}
                                                                                        {value.city}
                                                                                        {value.other}
                                                                                        <br />
                                                                                        {value.building}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherDate" ? (
                                                                                <Typography>
                                                                                    {value.value === null
                                                                                        ? ""
                                                                                        : value.value.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherReason" ? (
                                                                                <>
                                                                                    {value.value === "死亡" ? (
                                                                                        value.deadDate === null ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                死亡
                                                                                                <br />
                                                                                                {value.deadDate.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : value.value === "その他" ? (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                            <br />({value.note})
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                        </Typography>
                                                                                    )}
                                                                                </>
                                                                            ) : key === "dependentOtherBusiness" ? (
                                                                                data.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                data.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <>
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                            <br />
                                                                                            {value.value ===
                                                                                            "高・大学生"
                                                                                                ? `（${value.note}年生）`
                                                                                                : null}
                                                                                        </Typography>
                                                                                    </>
                                                                                ) : null
                                                                            ) : key === "dependentOtherIncome" ? (
                                                                                data.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                data.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography>
                                                                                        {value.value
                                                                                            .toString()
                                                                                            .replace(
                                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                                ","
                                                                                            )}
                                                                                        円
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : (
                                                                                <Typography>{value.value}</Typography>
                                                                            )}
                                                                        </Grid2>
                                                                    </Grid2>
                                                                    {key === "dependentOtherAddress" ? (
                                                                        data.dependentOtherTogether.value ===
                                                                        true ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherDate" ? (
                                                                        data.dependentOtherDate.value ===
                                                                        null ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherBusiness" ? (
                                                                        data.dependentOtherBusiness.value ===
                                                                        "" ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherIncome" ? (
                                                                        data.dependentOtherIncome.value ===
                                                                        "" ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : (
                                                                        <Divider sx={{ my: 1 }} />
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 4, md: 4 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {data.dependentOtherOverseas.title}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 8, md: 8 }}>
                                                                <Typography>
                                                                    {data.dependentOtherOverseas.value === ""
                                                                        ? "なし"
                                                                        : data.dependentOtherOverseas.value}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />

                                                        {data.dependentOtherOverseas.value === "該当" ||
                                                        data.dependentOtherOverseas.value === "非該当" ? (
                                                            <>
                                                                {Object.entries(data)
                                                                    .slice(11, 16)
                                                                    .map(([key, value]) => (
                                                                        <Box key={key}>
                                                                            <Grid2 container spacing={2}>
                                                                                <Grid2
                                                                                    size={{ xs: 4, md: 4 }}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-end",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    {key ===
                                                                                    "dependentOtherOverseasDate" ? (
                                                                                        data.dependentOtherOverseas
                                                                                            .value === "非該当" ? (
                                                                                            <Typography fontWeight="bold">
                                                                                                該当しなくなった日
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            <Typography fontWeight="bold">
                                                                                                {value.title}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : (
                                                                                        <Typography fontWeight="bold">
                                                                                            {value.title}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid2>
                                                                                <Grid2 size={{ xs: 8, md: 8 }}>
                                                                                    {key ===
                                                                                    "dependentOtherOverseasDate" ? (
                                                                                        data.dependentOtherOverseasDate
                                                                                            .value === null ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                {value.value.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : key ===
                                                                                      "dependentOtherOverseasReason" ? (
                                                                                        data
                                                                                            .dependentOtherOverseasReason
                                                                                            .value === "その他" ? (
                                                                                            <>
                                                                                                <Typography>
                                                                                                    {value.value}
                                                                                                    <br />({value.note})
                                                                                                </Typography>
                                                                                            </>
                                                                                        ) : data
                                                                                              .dependentOtherOverseasReason
                                                                                              .value === "国内転入" ? (
                                                                                            <Typography>
                                                                                                {value.value}
                                                                                                <br />
                                                                                                {value.date.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                {value.value}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid2>
                                                                            </Grid2>
                                                                            <Divider sx={{ my: 1 }} />
                                                                        </Box>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {Object.entries(data)
                                                            .slice(16, 22)
                                                            .map(([key, data]) => (
                                                                <Box key={key}>
                                                                    <Grid2 container spacing={2}>
                                                                        <Grid2
                                                                            size={{ xs: 4, md: 4 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography fontWeight="bold">
                                                                                {data.title}
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                                                            <Typography>
                                                                                {data.value === ""
                                                                                    ? "未アップロード"
                                                                                    : "アップロード済み"}
                                                                            </Typography>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                    <Divider sx={{ my: 1 }} />
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 4, md: 4 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">被扶養者届</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 8, md: 8 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography>なし</Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </>
                                )}
                            </Box>
                        </>
                    ) : openTab === 1 ? (
                        <>
                            {Object.entries(staffUploadData)
                                .slice(0, 9)
                                .map(([key, value]) => (
                                    <Box key={key}>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 4, md: 4 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">{value.title}</Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 8, md: 8 }}>
                                                <Typography>
                                                    {value.value === "" ? "未アップロード" : "アップロード済み"}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </Box>
                                ))}
                            {staffData.staffNationality.select !== "japanese" &&
                                Object.entries(staffUploadData)
                                    .slice(9, 12)
                                    .map(([key, value]) => (
                                        <Box key={key}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">{value.title}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 8, md: 8 }}>
                                                    <Typography>
                                                        {value.value === "" ? "未アップロード" : "アップロード済み"}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                            {staffUploadData.staffCommute.select === true && (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">運転免許証・表</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffUploadData.staffCommute.driverLicenseFront === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">運転免許証・裏</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffUploadData.staffCommute.driverLicenseBack === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">車検証</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffUploadData.staffCommute.vehicleInspection === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">自賠責保険</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffUploadData.staffCommute.compulsoryInsurance === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">任意保険</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffUploadData.staffCommute.optionalInsurance === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}
                            {staffLicenseData.length > 0 && (
                                <>
                                    {staffLicenseData.map((license, i) => (
                                        <Box key={i}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">{license.title}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 8, md: 8 }}>
                                                    <Typography>
                                                        {license.value === "" ? "未アップロード" : "アップロード済み"}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {staffDomitoryData.staffDomitoryFlag.value === true ? (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>利用する</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅名・部屋番号</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffDomitoryData.staffDomitoryName.value}{" "}
                                                {staffDomitoryData.staffDomitoryNumber.value}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">入居日</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>
                                                {staffDomitoryData.staffDomitoryInDate.value === null
                                                    ? "未定"
                                                    : staffDomitoryData.staffDomitoryInDate.value.toLocaleDateString(
                                                          "ja-JP"
                                                      )}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            ) : (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>利用しない</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {equip.staffEquipmentFlag.value === true ? (
                                <>
                                    <Grid2 container spacing={2} sx={{ mt: 3 }}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">レンタル備品</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>利用する</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    {staffEquipmentData
                                        .filter((equip) => equip.selected)
                                        .map((equip, i) => (
                                            <Box key={i} sx={{ my: 1 }}>
                                                <Grid2 container spacing={2}>
                                                    <Grid2
                                                        size={{ xs: 4, md: 4 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">{equip.name}</Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 8, md: 8 }}>
                                                        <Typography>
                                                            {equip.value === "" ? "未定" : equip.value + "つ  "}
                                                            {equip.inDate === null
                                                                ? "借入日未定"
                                                                : "借入日 " + equip.inDate.toLocaleDateString("ja-JP")}
                                                        </Typography>
                                                    </Grid2>
                                                </Grid2>
                                            </Box>
                                        ))}
                                    {staffEquipmentData.length !== 0 && <Divider sx={{ my: 1 }} />}
                                </>
                            ) : (
                                <>
                                    <Grid2 container spacing={2} sx={{ mt: 3 }}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">レンタル備品</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 8, md: 8 }}>
                                            <Typography>利用しない</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}
                        </>
                    )}

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddRegister}
                            sx={{ width: "400px" }}
                        >
                            入力内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            入力画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" open={uploadOpen}>
                <DialogContent sx={{ mt: 2, minWidth: 300 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
                            スタッフ情報登録中...
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Typography color="text.secondary">{`${Math.round(uploadProgress)}%`}</Typography>
                        </Box>
                        <Box sx={{ width: "100%", mr: 1 }}>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        スタッフの登録が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleAddComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default StaffAdd;
