import { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import { Button, CircularProgress, Typography } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

const ResetComplete = ({ emailData }) => {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(async (userLogin) => {
            if (userLogin) {
                window.scrollTo(0, 0);
                navigate("/dispatch", { replace: true });
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return (
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>パスワード再設定用メール送信完了 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Container
                maxWidth="1280"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    maxWidth: 1280,
                }}
                disableGutters
            >
                <Box sx={{ mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">メールをご確認ください</Typography>
                </Box>

                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>パスワード再設定用メールを「{emailData}」宛に送信しました。</Typography>
                </Box>

                <Box sx={{ mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>お送りしたメールの内容に従い、パスワードを再設定してください。</Typography>
                </Box>

                <Button
                    type="submit"
                    variant="outlined"
                    size="large"
                    sx={{ width: 400 }}
                    onClick={() => navigate("/password-configure")}
                >
                    メールのURLをクリックした場合
                </Button>
            </Container>
        </>
    );
};

export default ResetComplete;
