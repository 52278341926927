import React, { useMemo } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Audit = ({ val }) => {
    const navigate = useNavigate();

    const auditData = [];

    const columns = useMemo(() => [{}], []);

    return (
        <>
            <Helmet>
                <title>監査対応一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <Typography variant="h6">監査対応一覧</Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography>準備中</Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography>
                            派遣先管理、スタッフ管理、社宅管理、応募者管理、派遣元責任者管理が実装完了次第、実装。
                        </Typography>
                    </Box>
                    {/*
                    <Box sx={{ mt: 2 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={auditData}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                        />
                    </Box>
                    */}
                </Container>
            </Box>
        </>
    );
};

export default Audit;
