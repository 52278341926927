import React from "react";
import { Container, Typography, Box } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Terms = ({ val }) => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>利用規約 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="h6">利用規約</Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography>準備中</Typography>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Terms;
