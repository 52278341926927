import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Divider,
    TextField,
    Alert,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogContentText,
    Grid2,
    InputLabel,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
    InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ja from "date-fns/locale/ja";

import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const DispatchEdit = ({ val }) => {
    const navigate = useNavigate();

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const [dispatchData, setDispatchData] = useState({
        dispatchName: { title: "派遣先企業名", value: "株式会社〇〇" },
        dispatchRepresentative: { title: "代表者の役職と氏名", value1: "代表取締役社長", value2: "山田 太郎" },
        dispatchAddress: {
            title: "住所",
            post: "1500000",
            prefecture: "東京都",
            city: "渋谷区",
            other: "代々木1-1-1",
            building: "",
        },
        acceptance: { title: "受入開始日", value: 1704946800000 },
        conflict: { title: "事業所抵触日", value: 1704946800000 },
        content: { title: "業務内容", value: "自動車製造に関するマシンオペレーター" },
        law4: { title: "令第4条第1項", value: "該当しない" },
        noRestrictions: { title: "派遣可能期間の制限を受けない業務に関する事項", value: "該当しない" },
        dayLabor: { title: "日雇派遣例外要件確認書類", value: false },
        flextime: { title: "フレックスタイム制の有無", value: false },
        shiftWork: { title: "交替制の有無", value: true },
        convenience: {
            title: "便宜供与",
            value: [
                { id: 1, value: "診断所", checked: true },
                { id: 2, value: "保育所", checked: false },
                { id: 3, value: "制服貸与", checked: false },
            ],
        },
        limited: {
            title: "派遣労働者の限定",
            value: [
                { id: 1, value: "無期雇用労働者", checked: true },
                { id: 2, value: "60歳以上の者", checked: false },
                { id: 3, value: "協定対象労働者", checked: false },
            ],
        },
        closingDate: { title: "勤怠の締日", value: "末日" },
        dispatchFee: { title: "派遣料金", value: "1500000" },
        dispatchSalary: { title: "給与区分", value: "月給制" },
        baseSalary: { title: "基本給", value: "1000000" },
        jobSalary: { title: "変動基本給", value: "100000" },
        hourSalary: { title: "1時間", value: "1500" },
        dailyCalculation: { title: "日々計算", decimal: "1", process: "切り上げ" },
        variousTotal: { title: "各合計", decimal: "0", process: "切り捨て" },
        total: { title: "合計", decimal: "0", process: "切り捨て" },
        division: { title: "管轄部署", value: "営業部" },
    });

    const [dispatchDataError, setDispatchDataError] = useState({
        dispatchName: "",
        dispatchRepresentative1: "",
        dispatchRepresentative2: "",
        dispatchAddressPost: "",
        dispatchAddressPrefecture: "",
        dispatchAddressCity: "",
        dispatchAddressOther: "",
        acceptance: "",
        conflict: "",
        content: "",
        closingDate: "",
        dispatchFee: "",
        dispatchSalary: "",
        baseSalary: "",
        jobSalary: "",
        hourSalary: "",
        dailyCalculationDecimal: "",
        dailyCalculationProcess: "",
        variousTotalDecimal: "",
        variousTotalProcess: "",
        totalDecimal: "",
        totalProcess: "",
        division: "",
    });

    const [convenienceData, setConvenienceData] = useState([
        { id: 1, value: "診断所" },
        { id: 2, value: "保育所" },
        { id: 3, value: "制服貸与" },
    ]);

    const [limitedData, setLimitedData] = useState([
        { id: 1, value: "無期雇用労働者" },
        { id: 2, value: "60歳以上の者" },
        { id: 3, value: "協定対象労働者" },
    ]);

    const [closingDateData, setClosingDateData] = useState(["末日", "25日", "20日", "15日"]);

    const [salaryData, setSalaryData] = useState(["時給制", "日給制", "月給制"]);

    const [processData, setProcessData] = useState(["四捨五入", "切り上げ", "切り捨て"]);

    const [validation, setValidation] = useState("");

    const handleDispatchNameChange = (e) => {
        setDispatchData({ ...dispatchData, dispatchName: { ...dispatchData.dispatchName, value: e.target.value } });
    };
    const handleDispatchNameBlur = () => {
        const value = dispatchData.dispatchName.value.replace(/　/g, " ").trim();
        setDispatchData({ ...dispatchData, dispatchName: { ...dispatchData.dispatchName, value: value } });
        setDispatchDataError({
            ...dispatchDataError,
            dispatchName: dispatchData.dispatchName.value === "" ? "派遣先企業名を入力してください" : "",
        });
    };

    const handleDispatchRepresentative1Change = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchRepresentative: { ...dispatchData.dispatchRepresentative, value1: e.target.value },
        });
    };
    const handleDispatchRepresentative1Blur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchRepresentative1:
                dispatchData.dispatchRepresentative.value1 === "" ? "代表の役職を入力してください" : "",
        });
    };

    const handleDispatchRepresentative2Change = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchRepresentative: { ...dispatchData.dispatchRepresentative, value2: e.target.value },
        });
    };
    const handleDispatchRepresentative2Blur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchRepresentative2:
                dispatchData.dispatchRepresentative.value2 === "" ? "代表の氏名を入力してください" : "",
        });
    };

    const handleDispatchAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, dispatchAddress: { ...dispatchData.dispatchAddress, post: value } });
    };
    const handleDispatchAddressPostBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchAddressPost: dispatchData.dispatchAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleDispatchAddressPrefectureChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchAddress: { ...dispatchData.dispatchAddress, prefecture: e.target.value },
        });
    };
    const handleDispatchAddressPrefectureBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchAddressPrefecture:
                dispatchData.dispatchAddress.prefecture === "" ? "都道府県を選択してください" : "",
        });
    };

    const handleDispatchAddressCityChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchAddress: { ...dispatchData.dispatchAddress, city: e.target.value },
        });
    };
    const handleDispatchAddressCityBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchAddressCity: dispatchData.dispatchAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleDispatchAddressOtherChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchAddress: { ...dispatchData.dispatchAddress, other: e.target.value },
        });
    };
    const handleDispatchAddressOtherBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchAddressOther: dispatchData.dispatchAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleDispatchAddressBuildingChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchAddress: { ...dispatchData.dispatchAddress, building: e.target.value },
        });
    };

    const handleDispatchAcceptanceChange = (value) => {
        setDispatchData({ ...dispatchData, acceptance: { ...dispatchData.acceptance, value: value } });
        setDispatchDataError({
            ...dispatchDataError,
            acceptance: value === null ? "受入開始日を選択してください" : "",
        });
    };
    const handleDispatchAcceptanceBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            acceptance: dispatchData.acceptance.value === null ? "受入開始日を選択してください" : "",
        });
    };

    const handleDispatchConflictChange = (value) => {
        setDispatchData({ ...dispatchData, conflict: { ...dispatchData.conflict, value: value } });
        setDispatchDataError({
            ...dispatchDataError,
            conflict: value === null ? "事業所抵触日を選択してください" : "",
        });
    };
    const handleDispatchConflictBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            conflict: dispatchData.conflict.value === null ? "事業所抵触日を選択してください" : "",
        });
    };

    const handleDispatchContentChange = (e) => {
        const value = e.target.value.replace(/\n/g, " ").trim();
        setDispatchData({ ...dispatchData, content: { ...dispatchData.content, value: value } });
    };
    const handleDispatchContentBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            content: dispatchData.content.value === "" ? "業務内容を入力してください" : "",
        });
    };

    const handleDispatchLaw4Change = (e) => {
        setDispatchData({ ...dispatchData, law4: { ...dispatchData.law4, value: e.target.value } });
    };

    const handleDispatchNoRestrictionsChange = (e) => {
        setDispatchData({ ...dispatchData, noRestrictions: { ...dispatchData.noRestrictions, value: e.target.value } });
    };

    const handleDispatchDayLaborChange = (e) => {
        setDispatchData({ ...dispatchData, dayLabor: { ...dispatchData.dayLabor, value: e.target.checked } });
    };

    const handleDispatchFlextimeChange = (e) => {
        setDispatchData({ ...dispatchData, flextime: { ...dispatchData.flextime, value: e.target.checked } });
    };

    const handleDispatchShiftWorkChange = (e) => {
        setDispatchData({ ...dispatchData, shiftWork: { ...dispatchData.shiftWork, value: e.target.checked } });
    };

    const handleDispatchConvenienceChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setDispatchData((prevState) => ({
                ...prevState,
                convenience: {
                    ...prevState.convenience,
                    value: prevState.convenience.value.map((item) =>
                        item.value === value ? { ...item, checked: true } : item
                    ),
                },
            }));
        } else {
            setDispatchData((prevState) => ({
                ...prevState,
                convenience: {
                    ...prevState.convenience,
                    value: prevState.convenience.value.map((item) =>
                        item.value === value ? { ...item, checked: false } : item
                    ),
                },
            }));
        }
    };

    const handleDispatchLimitedChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setDispatchData((prevState) => ({
                ...prevState,
                limited: {
                    ...prevState.limited,
                    value: prevState.limited.value.map((item) =>
                        item.value === value ? { ...item, checked: true } : item
                    ),
                },
            }));
        } else {
            setDispatchData((prevState) => ({
                ...prevState,
                limited: {
                    ...prevState.limited,
                    value: prevState.limited.value.map((item) =>
                        item.value === value ? { ...item, checked: false } : item
                    ),
                },
            }));
        }
    };

    const handleDispatchClosingDateChange = (e) => {
        setDispatchData({ ...dispatchData, closingDate: { ...dispatchData.closingDate, value: e.target.value } });
        setDispatchDataError({
            ...dispatchDataError,
            closingDate: e.target.value === "" ? "勤怠の締日を選択してください" : "",
        });
    };

    const handleDispatchDispatchFeeValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, dispatchFee: { ...dispatchData.dispatchFee, value: value } });
    };
    const handleDispatchDispatchFeeValueBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dispatchFee:
                dispatchData.dispatchFee.value === "" || dispatchData.dispatchFee.value === "0"
                    ? "派遣料金を入力してください"
                    : "",
        });
    };

    const handleDispatchDispatchSalaryChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dispatchSalary: { ...dispatchData.dispatchSalary, value: e.target.value },
            baseSalary: { ...dispatchData.baseSalary, value: "" },
            jobSalary: { ...dispatchData.jobSalary, value: "" },
            hourSalary: { ...dispatchData.hourSalary, value: "" },
        });
        setDispatchDataError({
            ...dispatchDataError,
            dispatchSalary: e.target.value === "" ? "給与区分を選択してください" : "",
            baseSalary: "",
            jobSalary: "",
            hourSalary: "",
        });
    };

    const handleDispatchBaseSalaryValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, baseSalary: { ...dispatchData.baseSalary, value: value } });
    };
    const handleDispatchBaseSalaryValueBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            baseSalary:
                dispatchData.baseSalary.value === "" || dispatchData.baseSalary.value === "0"
                    ? "基本給を入力してください"
                    : "",
        });
    };

    const handleDispatchJobSalaryValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, jobSalary: { ...dispatchData.jobSalary, value: value } });
    };
    const handleDispatchJobSalaryValueBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            jobSalary:
                dispatchData.jobSalary.value === "" || dispatchData.jobSalary.value === "0"
                    ? "変動基本給を入力してください"
                    : "",
        });
    };

    const handleDispatchHourSalaryValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, hourSalary: { ...dispatchData.hourSalary, value: value } });
    };
    const handleDispatchHourSalaryValueBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            hourSalary:
                dispatchData.hourSalary.value === "" || dispatchData.hourSalary.value === "0"
                    ? "1時間あたりの金額を入力してください"
                    : "",
        });
    };

    const handleDispatchDailyCalculationDecimalChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, dailyCalculation: { ...dispatchData.dailyCalculation, decimal: value } });
    };
    const handleDispatchDailyCalculationDecimalBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            dailyCalculationDecimal: dispatchData.dailyCalculation.decimal === "" ? "小数点を入力してください" : "",
        });
    };

    const handleDispatchDailyCalculationProcessChange = (e) => {
        setDispatchData({
            ...dispatchData,
            dailyCalculation: { ...dispatchData.dailyCalculation, process: e.target.value },
        });
        setDispatchDataError({
            ...dispatchDataError,
            dailyCalculationProcess: e.target.value === "" ? "端数処理を選択してください" : "",
        });
    };

    const handleDispatchVariousTotalDecimalChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, variousTotal: { ...dispatchData.variousTotal, decimal: value } });
    };
    const handleDispatchVariousTotalDecimalBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            variousTotalDecimal: dispatchData.variousTotal.decimal === "" ? "小数点を入力してください" : "",
        });
    };

    const handleDispatchVariousTotalProcessChange = (e) => {
        setDispatchData({
            ...dispatchData,
            variousTotal: { ...dispatchData.variousTotal, process: e.target.value },
        });
        setDispatchDataError({
            ...dispatchDataError,
            variousTotalProcess: e.target.value === "" ? "端数処理を選択してください" : "",
        });
    };

    const handleDispatchTotalDecimalChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDispatchData({ ...dispatchData, total: { ...dispatchData.total, decimal: value } });
    };
    const handleDispatchTotalDecimalBlur = () => {
        setDispatchDataError({
            ...dispatchDataError,
            totalDecimal: dispatchData.total.decimal === "" ? "小数点を入力してください" : "",
        });
    };

    const handleDispatchTotalProcessChange = (e) => {
        setDispatchData({
            ...dispatchData,
            total: { ...dispatchData.total, process: e.target.value },
        });
        setDispatchDataError({
            ...dispatchDataError,
            totalProcess: e.target.value === "" ? "端数処理を選択してください" : "",
        });
    };

    const handleDispatchDivisionChange = (e) => {
        setDispatchData({ ...dispatchData, division: { ...dispatchData.division, value: e.target.value } });
        setDispatchDataError({
            ...dispatchDataError,
            division: e.target.value === "" ? "管轄部署を選択してください" : "",
        });
    };

    const handleAddConfirm = () => {
        const dispatchDataErrors = {
            dispatchName: dispatchData.dispatchName.value === "" ? "派遣先企業名を入力してください" : "",
            dispatchRepresentative1:
                dispatchData.dispatchRepresentative.value1 === "" ? "代表の役職を入力してください" : "",
            dispatchRepresentative2:
                dispatchData.dispatchRepresentative.value2 === "" ? "代表の氏名を入力してください" : "",
            dispatchAddressPost: dispatchData.dispatchAddress.post === "" ? "郵便番号を入力してください" : "",
            dispatchAddressPrefecture:
                dispatchData.dispatchAddress.prefecture === "" ? "都道府県を入力してください" : "",
            dispatchAddressCity: dispatchData.dispatchAddress.city === "" ? "市区町村を入力してください" : "",
            dispatchAddressOther: dispatchData.dispatchAddress.other === "" ? "上記以降の住所を入力してください" : "",
            acceptance: dispatchData.acceptance.value === null ? "受入開始日を選択してください" : "",
            conflict: dispatchData.conflict.value === null ? "事業所抵触日を選択してください" : "",
            content: dispatchData.content.value === "" ? "業務内容を入力してください" : "",
            closingDate: dispatchData.closingDate.value === "" ? "勤怠の締日を選択してください" : "",
            dispatchFee: dispatchData.dispatchFee.value === "" ? "派遣料金を入力してください" : "",
            dispatchSalary: dispatchData.dispatchSalary.value === "" ? "給与区分を選択してください" : "",
            baseSalary:
                dispatchData.dispatchSalary.value !== "時給制" && dispatchData.baseSalary.value === ""
                    ? "基本給を入力してください"
                    : "",
            jobSalary:
                dispatchData.dispatchSalary.value === "月給制" && dispatchData.jobSalary.value === ""
                    ? "変動基本給を入力してください"
                    : "",
            hourSalary:
                dispatchData.dispatchSalary.value !== "日給制" && dispatchData.hourSalary.value === ""
                    ? "1時間の金額を入力してください"
                    : "",
            dailyCalculationDecimal: dispatchData.dailyCalculation.decimal === "" ? "小数点を入力してください" : "",
            dailyCalculationProcess: dispatchData.dailyCalculation.process === "" ? "端数処理を選択してください" : "",
            variousTotalDecimal: dispatchData.variousTotal.decimal === "" ? "小数点を入力してください" : "",
            variousTotalProcess: dispatchData.variousTotal.process === "" ? "端数処理を選択してください" : "",
            totalDecimal: dispatchData.total.decimal === "" ? "小数点を入力してください" : "",
            totalProcess: dispatchData.total.process === "" ? "端数処理を選択してください" : "",
            division: dispatchData.division.value === "" ? "管轄部署を選択してください" : "",
        };
        setDispatchDataError((prevState) => ({
            ...prevState,
            ...dispatchDataErrors,
        }));

        if (Object.values(dispatchDataErrors).some((error) => error !== "")) {
            setValidation("入力内容に不備があります");
        } else {
            setValidation("");
            setOpen(true);
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch");
    };

    const handleAddRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleAddComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/dispatch/detail");
    };

    return (
        <>
            <Helmet>
                <title>派遣先企業を編集 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">派遣先企業を編集</Typography>
                    </Box>

                    <Box>
                        <TextField
                            variant="outlined"
                            label={dispatchData.dispatchName.title}
                            value={dispatchData.dispatchName.value}
                            sx={{ width: "600px" }}
                            onChange={handleDispatchNameChange}
                            onBlur={handleDispatchNameBlur}
                            error={dispatchDataError.dispatchName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.dispatchName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.dispatchName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label="代表の役職"
                            value={dispatchData.dispatchRepresentative.value1}
                            sx={{ width: "288px" }}
                            onChange={handleDispatchRepresentative1Change}
                            onBlur={handleDispatchRepresentative1Blur}
                            error={dispatchDataError.dispatchRepresentative1 !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="代表の氏名"
                            value={dispatchData.dispatchRepresentative.value2}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleDispatchRepresentative2Change}
                            onBlur={handleDispatchRepresentative2Blur}
                            error={dispatchDataError.dispatchRepresentative2 !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {dispatchDataError.dispatchRepresentative1 !== "" && (
                                <Alert severity="error">{dispatchDataError.dispatchRepresentative1}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {dispatchDataError.dispatchRepresentative2 !== "" && (
                                <Alert severity="error">{dispatchDataError.dispatchRepresentative2}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>住所</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label="郵便番号"
                            value={dispatchData.dispatchAddress.post}
                            sx={{ width: "424px" }}
                            onChange={handleDispatchAddressPostChange}
                            onBlur={handleDispatchAddressPostBlur}
                            helperText="数字のみ入力してください"
                            error={dispatchDataError.dispatchAddressPost !== ""}
                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Button variant="outlined" size="large" sx={{ width: "152px", height: "56px", ml: 3 }}>
                            住所を検索する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.dispatchAddressPost !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.dispatchAddressPost}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="都道府県"
                            value={dispatchData.dispatchAddress.prefecture}
                            sx={{ width: "288px" }}
                            onChange={handleDispatchAddressPrefectureChange}
                            onBlur={handleDispatchAddressPrefectureBlur}
                            error={dispatchDataError.dispatchAddressPrefecture !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="市区町村"
                            value={dispatchData.dispatchAddress.city}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleDispatchAddressCityChange}
                            onBlur={handleDispatchAddressCityBlur}
                            error={dispatchDataError.dispatchAddressCity !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {dispatchDataError.dispatchAddressPrefecture !== "" && (
                                <Alert severity="error">{dispatchDataError.dispatchAddressPrefecture}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {dispatchDataError.dispatchAddressCity !== "" && (
                                <Alert severity="error">{dispatchDataError.dispatchAddressCity}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="上記以降の住所"
                            value={dispatchData.dispatchAddress.other}
                            sx={{ width: "600px" }}
                            onChange={handleDispatchAddressOtherChange}
                            onBlur={handleDispatchAddressOtherBlur}
                            error={dispatchDataError.dispatchAddressOther !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.dispatchAddressOther !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.dispatchAddressOther}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="建物名・部屋番号"
                            value={dispatchData.dispatchAddress.building}
                            sx={{ width: "600px" }}
                            onChange={handleDispatchAddressBuildingChange}
                        />
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Box sx={{ mt: 3 }}>
                            <DesktopDatePicker
                                label={dispatchData.acceptance.title}
                                value={dispatchData.acceptance.value}
                                format="yyyy/MM/dd"
                                onChange={handleDispatchAcceptanceChange}
                                slotProps={{
                                    textField: {
                                        error: dispatchDataError.acceptance !== "",
                                        clearable: true,
                                        onBlur: handleDispatchAcceptanceBlur,
                                        sx: { width: "600px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {dispatchDataError.acceptance !== "" && (
                                <Alert severity="error" sx={{ width: "600px" }}>
                                    {dispatchDataError.acceptance}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <DesktopDatePicker
                                label={dispatchData.conflict.title}
                                value={dispatchData.conflict.value}
                                format="yyyy/MM/dd"
                                onChange={handleDispatchConflictChange}
                                slotProps={{
                                    textField: {
                                        error: dispatchDataError.conflict !== "",
                                        clearable: true,
                                        onBlur: handleDispatchConflictBlur,
                                        sx: { width: "600px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {dispatchDataError.conflict !== "" && (
                                <Alert severity="error" sx={{ width: "600px" }}>
                                    {dispatchDataError.conflict}
                                </Alert>
                            )}
                        </Box>
                    </LocalizationProvider>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label={dispatchData.content.title}
                            value={dispatchData.content.value}
                            multiline
                            rows={3}
                            sx={{ width: "600px" }}
                            onChange={handleDispatchContentChange}
                            onBlur={handleDispatchContentBlur}
                            error={dispatchDataError.content !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.content !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.content}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>{dispatchData.law4.title}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row value={dispatchData.law4.value}>
                                    <FormControlLabel
                                        value="該当する"
                                        control={<Radio />}
                                        label="該当する"
                                        onChange={handleDispatchLaw4Change}
                                    />
                                    <FormControlLabel
                                        value="該当しない"
                                        control={<Radio />}
                                        label="該当しない"
                                        onChange={handleDispatchLaw4Change}
                                        sx={{ ml: 7.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dispatchData.noRestrictions.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row value={dispatchData.noRestrictions.value}>
                                    <FormControlLabel
                                        value="該当する"
                                        control={<Radio />}
                                        label="該当する"
                                        onChange={handleDispatchNoRestrictionsChange}
                                    />
                                    <FormControlLabel
                                        value="該当しない"
                                        control={<Radio />}
                                        label="該当しない"
                                        onChange={handleDispatchNoRestrictionsChange}
                                        sx={{ ml: 7.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dispatchData.dayLabor.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dispatchData.dayLabor.value}
                                        onChange={handleDispatchDayLaborChange}
                                    />
                                }
                                label="有"
                                sx={{ display: "flex", justifyContent: "left", alignItems: "center", height: "56px" }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dispatchData.flextime.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dispatchData.flextime.value}
                                        onChange={handleDispatchFlextimeChange}
                                    />
                                }
                                label="有"
                                sx={{ display: "flex", justifyContent: "left", alignItems: "center", height: "56px" }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dispatchData.shiftWork.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dispatchData.shiftWork.value}
                                        onChange={handleDispatchShiftWorkChange}
                                    />
                                }
                                label="有"
                                sx={{ display: "flex", justifyContent: "left", alignItems: "center", height: "56px" }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dispatchData.convenience.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            {convenienceData.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            checked={
                                                dispatchData.convenience.value.find((v) => v.id === item.id)?.checked ||
                                                false
                                            }
                                            onChange={handleDispatchConvenienceChange}
                                            value={item.value}
                                        />
                                    }
                                    label={item.value}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "56px",
                                        mr: 14,
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>{dispatchData.limited.title}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            {limitedData.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            checked={
                                                dispatchData.limited.value.find((v) => v.id === item.id)?.checked ||
                                                false
                                            }
                                            onChange={handleDispatchLimitedChange}
                                            value={item.value}
                                        />
                                    }
                                    label={item.value}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "56px",
                                        mr: 6,
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <FormControl sx={{ width: "600px" }} error={dispatchDataError.closingDate !== ""}>
                            <InputLabel>{dispatchData.closingDate.title}</InputLabel>
                            <Select
                                label={dispatchData.closingDate.title}
                                value={dispatchData.closingDate.value}
                                onChange={handleDispatchClosingDateChange}
                                sx={{ textAlign: "left" }}
                            >
                                {closingDateData.map((closingDate, index) => (
                                    <MenuItem key={index} value={closingDate} sx={{ textAlign: "left" }}>
                                        {closingDate}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.closingDate !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.closingDate}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label={dispatchData.dispatchFee.title}
                            value={dispatchData.dispatchFee.value}
                            sx={{ width: "600px" }}
                            helperText="数字のみ入力してください"
                            onChange={handleDispatchDispatchFeeValueChange}
                            onBlur={handleDispatchDispatchFeeValueBlur}
                            error={dispatchDataError.dispatchFee !== ""}
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">円 / 時間</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.dispatchFee !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.dispatchFee}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>社員への支払い</Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <FormControl sx={{ width: "600px" }} error={dispatchDataError.dispatchSalary !== ""}>
                            <InputLabel>{dispatchData.dispatchSalary.title}</InputLabel>
                            <Select
                                label={dispatchData.dispatchSalary.title}
                                value={dispatchData.dispatchSalary.value}
                                onChange={handleDispatchDispatchSalaryChange}
                                sx={{ textAlign: "left" }}
                            >
                                {salaryData.map((salary, index) => (
                                    <MenuItem key={index} value={salary} sx={{ textAlign: "left" }}>
                                        {salary}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dispatchDataError.dispatchSalary !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.dispatchSalary}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>内訳</Typography>
                    </Box>
                    {dispatchData.dispatchSalary.value === "月給制" ? (
                        <>
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label={dispatchData.baseSalary.title}
                                    value={dispatchData.baseSalary.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDispatchBaseSalaryValueChange}
                                    onBlur={handleDispatchBaseSalaryValueBlur}
                                    error={dispatchDataError.baseSalary !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dispatchDataError.baseSalary !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dispatchDataError.baseSalary}
                                    </Alert>
                                )}
                            </Box>

                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label={dispatchData.jobSalary.title}
                                    value={dispatchData.jobSalary.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDispatchJobSalaryValueChange}
                                    onBlur={handleDispatchJobSalaryValueBlur}
                                    error={dispatchDataError.jobSalary !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dispatchDataError.jobSalary !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dispatchDataError.jobSalary}
                                    </Alert>
                                )}
                            </Box>

                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label={dispatchData.hourSalary.title}
                                    value={dispatchData.hourSalary.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDispatchHourSalaryValueChange}
                                    onBlur={handleDispatchHourSalaryValueBlur}
                                    error={dispatchDataError.hourSalary !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dispatchDataError.hourSalary !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dispatchDataError.hourSalary}
                                    </Alert>
                                )}
                            </Box>
                        </>
                    ) : dispatchData.dispatchSalary.value === "時給制" ? (
                        <>
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label={dispatchData.hourSalary.title}
                                    value={dispatchData.hourSalary.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDispatchHourSalaryValueChange}
                                    onBlur={handleDispatchHourSalaryValueBlur}
                                    error={dispatchDataError.hourSalary !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dispatchDataError.hourSalary !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dispatchDataError.hourSalary}
                                    </Alert>
                                )}
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label={dispatchData.baseSalary.title}
                                    value={dispatchData.baseSalary.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDispatchBaseSalaryValueChange}
                                    onBlur={handleDispatchBaseSalaryValueBlur}
                                    error={dispatchDataError.baseSalary !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dispatchDataError.baseSalary !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dispatchDataError.baseSalary}
                                    </Alert>
                                )}
                            </Box>
                        </>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>請求処理</Typography>
                    </Box>
                    <Box sx={{ mt: 1, display: "flex", justifyContent: "center", alignItems: "top" }}>
                        <Typography textAlign="left" sx={{ width: "220px", pt: 2 }}>
                            日々計算
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="小数点"
                            value={dispatchData.dailyCalculation.decimal}
                            sx={{ width: "180px" }}
                            onChange={handleDispatchDailyCalculationDecimalChange}
                            onBlur={handleDispatchDailyCalculationDecimalBlur}
                            error={dispatchDataError.dailyCalculationDecimal !== ""}
                            helperText="数字のみ入力してください"
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">位</InputAdornment>,
                            }}
                        />
                        <FormControl
                            sx={{ width: "180px", ml: 3 }}
                            error={dispatchDataError.dailyCalculationProcess !== ""}
                        >
                            <InputLabel>端数処理</InputLabel>
                            <Select
                                label="端数処理"
                                value={dispatchData.dailyCalculation.process}
                                onChange={handleDispatchDailyCalculationProcessChange}
                                sx={{ textAlign: "left" }}
                            >
                                {processData.map((process, index) => (
                                    <MenuItem key={index} value={process} sx={{ textAlign: "left" }}>
                                        {process}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {(dispatchDataError.dailyCalculationDecimal !== "" ||
                            dispatchDataError.dailyCalculationProcess !== "") && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                日々計算の項目に不備があります
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ mt: 1, display: "flex", justifyContent: "center", alignItems: "top" }}>
                        <Typography textAlign="left" sx={{ width: "220px", pt: 2 }}>
                            各合計
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="小数点"
                            value={dispatchData.variousTotal.decimal}
                            sx={{ width: "180px" }}
                            onChange={handleDispatchVariousTotalDecimalChange}
                            onBlur={handleDispatchVariousTotalDecimalBlur}
                            error={dispatchDataError.variousTotalDecimal !== ""}
                            helperText="数字のみ入力してください"
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">位</InputAdornment>,
                            }}
                        />
                        <FormControl
                            sx={{ width: "180px", ml: 3 }}
                            error={dispatchDataError.variousTotalProcess !== ""}
                        >
                            <InputLabel>端数処理</InputLabel>
                            <Select
                                label="端数処理"
                                value={dispatchData.variousTotal.process}
                                onChange={handleDispatchVariousTotalProcessChange}
                                sx={{ textAlign: "left" }}
                            >
                                {processData.map((process, index) => (
                                    <MenuItem key={index} value={process} sx={{ textAlign: "left" }}>
                                        {process}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {(dispatchDataError.variousTotalDecimal !== "" ||
                            dispatchDataError.variousTotalProcess !== "") && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                各合計の項目に不備があります
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1, display: "flex", justifyContent: "center", alignItems: "top" }}>
                        <Typography textAlign="left" sx={{ width: "220px", pt: 2 }}>
                            総合計
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="小数点"
                            value={dispatchData.total.decimal}
                            sx={{ width: "180px" }}
                            onChange={handleDispatchTotalDecimalChange}
                            onBlur={handleDispatchTotalDecimalBlur}
                            error={dispatchDataError.totalDecimal !== ""}
                            helperText="数字のみ入力してください"
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">位</InputAdornment>,
                            }}
                        />
                        <FormControl sx={{ width: "180px", ml: 3 }} error={dispatchDataError.totalProcess !== ""}>
                            <InputLabel>端数処理</InputLabel>
                            <Select
                                label="端数処理"
                                value={dispatchData.total.process}
                                onChange={handleDispatchTotalProcessChange}
                                sx={{ textAlign: "left" }}
                            >
                                {processData.map((process, index) => (
                                    <MenuItem key={index} value={process} sx={{ textAlign: "left" }}>
                                        {process}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {(dispatchDataError.totalDecimal !== "" || dispatchDataError.totalProcess !== "") && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                総合計の項目に不備があります
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <FormControl sx={{ width: "600px" }} error={dispatchDataError.division !== ""}>
                            <InputLabel>{dispatchData.division.title}</InputLabel>
                            <Select
                                label={dispatchData.division.title}
                                value={dispatchData.division.value}
                                onChange={handleDispatchDivisionChange}
                                sx={{ textAlign: "left" }}
                            >
                                {divisionData.map((division, index) => (
                                    <MenuItem key={index} value={division} sx={{ textAlign: "left" }}>
                                        {division}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1, mb: 5 }}>
                        {dispatchDataError.division !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dispatchDataError.division}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleAddConfirm}
                        >
                            編集内容を確認する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            派遣先企業詳細へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        派遣先企業の編集内容を確認
                    </Typography>

                    {Object.entries(dispatchData)
                        .slice(0, 15)
                        .map(([key, data]) => (
                            <Box key={key}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        {key === "noRestrictions" ? (
                                            <Typography fontWeight="bold">
                                                派遣可能期間の制限を受けない
                                                <br />
                                                業務に関する事項
                                            </Typography>
                                        ) : (
                                            <Typography fontWeight="bold">{data.title}</Typography>
                                        )}
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        {key === "dispatchRepresentative" ? (
                                            <>
                                                <Typography>{data.value1}</Typography>
                                                <Typography sx={{ ml: 2 }}>{data.value2}</Typography>
                                            </>
                                        ) : key === "dispatchAddress" ? (
                                            <>
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                    <br />
                                                    {data.prefecture}
                                                    {data.city}
                                                    {data.other} {data.building}
                                                </Typography>
                                            </>
                                        ) : key === "acceptance" ? (
                                            <Typography>{new Date(data.value).toLocaleDateString("ja-JP")}</Typography>
                                        ) : key === "conflict" ? (
                                            <Typography>{new Date(data.value).toLocaleDateString("ja-JP")}</Typography>
                                        ) : key === "dayLabor" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "flextime" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "shiftWork" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "dispatchFee" ? (
                                            <Typography>
                                                {data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円 / 時間
                                            </Typography>
                                        ) : key === "convenience" ? (
                                            <>
                                                <Grid2 size={{ xs: 2, md: 2 }}>
                                                    <Typography>{dispatchData.convenience.value[0].value}</Typography>
                                                    <Typography>{dispatchData.convenience.value[1].value}</Typography>
                                                    <Typography>{dispatchData.convenience.value[2].value}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 2, md: 2 }}>
                                                    <Typography>
                                                        {dispatchData.convenience.value[0].checked === true
                                                            ? "有"
                                                            : "無"}
                                                    </Typography>
                                                    <Typography>
                                                        {dispatchData.convenience.value[1].checked === true
                                                            ? "有"
                                                            : "無"}
                                                    </Typography>
                                                    <Typography>
                                                        {dispatchData.convenience.value[2].checked === true
                                                            ? "有"
                                                            : "無"}
                                                    </Typography>
                                                </Grid2>
                                            </>
                                        ) : key === "limited" ? (
                                            <>
                                                <Grid2 size={{ xs: 3.5, md: 3.5 }}>
                                                    <Typography>{dispatchData.limited.value[0].value}</Typography>
                                                    <Typography>{dispatchData.limited.value[1].value}</Typography>
                                                    <Typography>{dispatchData.limited.value[2].value}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 3.5, md: 3.5 }}>
                                                    <Typography>
                                                        {dispatchData.limited.value[0].checked === true
                                                            ? "限定する"
                                                            : "限定しない"}
                                                    </Typography>
                                                    <Typography>
                                                        {dispatchData.limited.value[1].checked === true
                                                            ? "限定する"
                                                            : "限定しない"}
                                                    </Typography>
                                                    <Typography>
                                                        {dispatchData.limited.value[2].checked === true
                                                            ? "限定する"
                                                            : "限定しない"}
                                                    </Typography>
                                                </Grid2>
                                            </>
                                        ) : (
                                            <Typography>{data.value}</Typography>
                                        )}
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">社員支払条件</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Grid2 size={{ xs: 2.5, md: 2.5 }}>
                                <Typography textAlign="left">{dispatchData.dispatchSalary.title}</Typography>
                                <Typography textAlign="left" fontWeight="bold">
                                    内訳
                                </Typography>
                                {dispatchData.dispatchSalary.value === "月給制" ? (
                                    <>
                                        <Typography textAlign="left">{dispatchData.baseSalary.title}</Typography>
                                        <Typography textAlign="left">{dispatchData.jobSalary.title}</Typography>
                                        <Typography textAlign="left">{dispatchData.hourSalary.title}</Typography>
                                    </>
                                ) : dispatchData.dispatchSalary.value === "日給制" ? (
                                    <Typography textAlign="left">{dispatchData.baseSalary.title}</Typography>
                                ) : (
                                    <Typography textAlign="left">{dispatchData.hourSalary.title}</Typography>
                                )}
                            </Grid2>
                            <Grid2 size={{ xs: 2.2, md: 2.2 }}>
                                <Typography textAlign="left">{dispatchData.dispatchSalary.value}</Typography>
                                <Typography>
                                    <br />
                                </Typography>
                                {dispatchData.dispatchSalary.value === "月給制" ? (
                                    <>
                                        <Typography textAlign="right">
                                            {dispatchData.baseSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                        <Typography textAlign="right">
                                            {dispatchData.jobSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                        <Typography textAlign="right">
                                            {dispatchData.hourSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                    </>
                                ) : dispatchData.dispatchSalary.value === "日給制" ? (
                                    <Typography textAlign="right">
                                        {dispatchData.baseSalary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                ) : (
                                    <Typography textAlign="right">
                                        {dispatchData.hourSalary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                )}
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">請求処理</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Grid2 size={{ xs: 2.5, md: 2.5 }}>
                                <Typography textAlign="left">{dispatchData.dailyCalculation.title}</Typography>
                                <Typography textAlign="left">{dispatchData.variousTotal.title}</Typography>
                                <Typography textAlign="left">{dispatchData.total.title}</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 10.5, md: 10.5 }}>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.dailyCalculation.decimal}位
                                    {dispatchData.dailyCalculation.process}
                                </Typography>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.variousTotal.decimal}位{dispatchData.variousTotal.process}
                                </Typography>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.total.decimal}位{dispatchData.total.process}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dispatchData.division.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography textAlign="left">{dispatchData.division.value}</Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddRegister}
                            sx={{ width: "400px" }}
                        >
                            編集内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            編集画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        派遣先企業の編集が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleAddComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DispatchEdit;
