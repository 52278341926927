import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { teal } from "@mui/material/colors";

import { HelmetProvider } from "react-helmet-async";

import Header from "./components/Header";
import Terms from "./components/Terms";
import Manual from "./components/Manual";

import Login from "./views/common/Login";
import ResetPassword from "./views/common/Reset-password";
import ResetComplete from "./views/common/Reset-complete";
import PasswordConfigure from "./views/common/Password-configure";
import NoMatch from "./views/common/NoMatch";

import Dispatch from "./views/dispatch/Dispatch";
import DispatchAdd from "./views/dispatch/Dispatch-add";
import DispatchDetail from "./views/dispatch/Dispatch-detail";
import DispatchEdit from "./views/dispatch/Dispatch-edit";
import LocationAdd from "./views/dispatch/Location-add";
import LocationEdit from "./views/dispatch/Location-edit";
import ShiftDetail from "./views/dispatch/Shift-detail";
// import ShiftEdit from "./views/dispatch/Shift-edit";

import Staff from "./views/staff/Staff";
import StaffAdd from "./views/staff/Staff-add";
import StaffDetail from "./views/staff/Staff-detail";
import StaffEdit from "./views/staff/Staff-edit";
import Domitory from "./views/dormitory/Domitory";
import DomitoryAdd from "./views/dormitory/Domitory-add";
import DomitoryDetail from "./views/dormitory/Domitory-detail";
import DomitoryEdit from "./views/dormitory/Domitory-edit";

import Equipment from "./views/equipment/Equipment";

import Attendance from "./views/attendance/Attendance";

import Bill from "./views/bill/Bill";

import Apply from "./views/apply/Apply";
import ApplyDetail from "./views/apply/Apply-detail";
import ApplyAnalysis from "./views/apply/Apply-analysis";
import ApplyMedia from "./views/apply/Apply-media";
import ApplyMediaAdd from "./views/apply/Apply-media-add";
import ApplyMediaDetail from "./views/apply/Apply-media-detail";
import ApplyMediaEdit from "./views/apply/Apply-media-edit";

import Audit from "./views/audit/Audit";

import Dailypay from "./views/dailypay/Dailypay";

import Manager from "./views/manager/Manager";
import ManagerDetail from "./views/manager/Manager-detail";

import Company from "./views/company/Company";
import CompanyEdit from "./views/company/Company-edit";
import UserAdd from "./views/company/User-add";
import UserDetail from "./views/company/User-detail";
import UserEdit from "./views/company/User-edit";
import PasswordEdit from "./views/company/Password-edit";

function App() {
    const theme = createTheme({
        palette: {
            primary: teal,
            secondary: teal,
        },
    });

    const [val, setVal] = useState(false);
    const handleDataChange = (val) => {
        setVal(val);
    };

    const [email, setEmail] = useState("");
    const handleEmailData = (email) => {
        setEmail(email);
    };

    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
                    <Router>
                        <Header emitVal={handleDataChange} />
                        <main style={{ flex: 1 }}>
                            <Routes>
                                <Route path="/" element={<Login />} />
                                <Route path="/terms" element={<Terms val={val} />} />
                                <Route path="/manual" element={<Manual val={val} />} />
                                <Route path="/reset-password" element={<ResetPassword emailData={handleEmailData} />} />
                                <Route path="/reset-password/complete" element={<ResetComplete emailData={email} />} />
                                <Route path="/password-configure" element={<PasswordConfigure />} />
                                <Route path="/dispatch" element={<Dispatch val={val} />} />
                                <Route path="/dispatch/add" element={<DispatchAdd val={val} />} />
                                <Route path="/dispatch/detail" element={<DispatchDetail val={val} />} />
                                <Route path="/dispatch/detail/edit" element={<DispatchEdit val={val} />} />
                                <Route path="/dispatch/detail/location/add" element={<LocationAdd val={val} />} />
                                <Route path="/dispatch/detail/location/edit" element={<LocationEdit val={val} />} />
                                <Route path="/dispatch/detail/location/shift" element={<ShiftDetail val={val} />} />
                                <Route path="/staff" element={<Staff val={val} />} />
                                <Route path="/staff/add" element={<StaffAdd val={val} />} />
                                <Route path="/staff/detail" element={<StaffDetail val={val} />} />
                                <Route path="/staff/detail/edit" element={<StaffEdit val={val} />} />
                                <Route path="/dormitory" element={<Domitory val={val} />} />
                                <Route path="/dormitory/add" element={<DomitoryAdd val={val} />} />
                                <Route path="/dormitory/detail" element={<DomitoryDetail val={val} />} />
                                <Route path="/dormitory/detail/edit" element={<DomitoryEdit val={val} />} />
                                <Route path="/equipment" element={<Equipment val={val} />} />
                                <Route path="/attendance" element={<Attendance val={val} />} />
                                <Route path="/bill" element={<Bill val={val} />} />
                                <Route path="/apply" element={<Apply val={val} />} />
                                <Route path="/apply/detail" element={<ApplyDetail val={val} />} />
                                <Route path="/apply/analysis" element={<ApplyAnalysis val={val} />} />
                                <Route path="/apply/media" element={<ApplyMedia val={val} />} />
                                <Route path="/apply/media/add" element={<ApplyMediaAdd val={val} />} />
                                <Route path="/apply/media/detail" element={<ApplyMediaDetail val={val} />} />
                                <Route path="/apply/media/detail/edit" element={<ApplyMediaEdit val={val} />} />
                                <Route path="/audit" element={<Audit val={val} />} />
                                <Route path="/dailypay" element={<Dailypay val={val} />} />
                                <Route path="/manager" element={<Manager val={val} />} />
                                <Route path="/manager/detail" element={<ManagerDetail val={val} />} />
                                <Route path="/company" element={<Company val={val} />} />
                                <Route path="/company/edit" element={<CompanyEdit val={val} />} />
                                <Route path="/user-add" element={<UserAdd val={val} />} />
                                <Route path="/user/detail" element={<UserDetail val={val} />} />
                                <Route path="/user/detail/edit" element={<UserEdit val={val} />} />
                                <Route path="/password-edit" element={<PasswordEdit val={val} />} />
                                <Route path="*" element={<NoMatch val={val} />} />
                            </Routes>
                        </main>
                    </Router>
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
