import React, { useMemo, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputAdornment,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
} from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import { Helmet } from "react-helmet-async";

const Equipment = ({ val }) => {
    const [equipmentData, setEquipmentData] = useState([
        { name: "自転車", amountFlag: true, amount: 200, section: "レンタル", contractor: "レンティック" },
        { name: "寝具一式", amountFlag: true, amount: 150, section: "レンタル", contractor: "業者A" },
        { name: "テレビ", amountFlag: true, amount: 50, section: "レンタル", contractor: "レンティック" },
        { name: "電子レンジ", amountFlag: true, amount: 300, section: "レンタル", contractor: "業者A" },
        { name: "冷蔵庫", amountFlag: true, amount: 100, section: "レンタル", contractor: "レンティック" },
        { name: "洗濯機", amountFlag: true, amount: 80, section: "レンタル", contractor: "業者A" },
        { name: "ガスコンロ", amountFlag: false, amount: 60, section: "レンタル", contractor: "レンティック" },
        { name: "ローテーブル", amountFlag: false, amount: 120, section: "レンタル", contractor: "業者A" },
        { name: "カーテン135cm", amountFlag: null, amount: 1800, section: "購入", contractor: "レンティック" },
        { name: "カーテン200cm", amountFlag: null, amount: 2400, section: "購入", contractor: "業者A" },
        { name: "レース135cm", amountFlag: null, amount: 1400, section: "購入", contractor: "レンティック" },
    ]);

    const columns = useMemo(
        () => [
            {
                header: "備品",
                accessorKey: "name",
                muiTableBodyCellProps: { sx: { maxWidth: "300px" } },
            },
            {
                header: "金額",
                accessorKey: "amount",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    return (
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                        (cell.row.original.amountFlag === true
                            ? "円 / 日"
                            : cell.row.original.amountFlag === false
                            ? "円 / 月"
                            : "円")
                    );
                },
            },
            {
                header: "区分",
                accessorKey: "section",
                muiTableBodyCellProps: { sx: { maxWidth: "200px" } },
            },
            {
                header: "業者",
                accessorKey: "contractor",
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <Button color="primary" onClick={handleEditEquipment}>
                        編集する
                    </Button>
                ),
                muiTableBodyCellProps: { sx: { width: "150px" } },
            },
        ],
        []
    );

    const contractorData = [
        { name: "レンティック" },
        { name: "業者A" },
        { name: "業者B" },
        { name: "業者C" },
        { name: "業者D" },
        { name: "業者E" },
        { name: "業者F" },
        { name: "業者G" },
        { name: "業者H" },
        { name: "業者I" },
    ];

    const contractorColumns = [
        { header: "業者", accessorKey: "name" },
        {
            header: "操作",
            accessorKey: "action",
            Cell: () => (
                <Button color="primary" onClick={handleEditContractor}>
                    編集する
                </Button>
            ),
            muiTableBodyCellProps: { sx: { width: "150px" } },
        },
    ];

    const [addContractorData, setAddContractorData] = useState("");
    const [addContractorError, setAddContractorError] = useState("");

    const [editContractorData, setEditContractorData] = useState("レンティック");
    const [editContractorError, setEditContractorError] = useState("");

    const [openContractor, setOpenContractor] = useState(false);

    const [addContractorOpen, setAddContractorOpen] = useState(false);
    const [addContractorCompleteOpen, setAddContractorCompleteOpen] = useState(false);

    const [editContractorOpen, setEditContractorOpen] = useState(false);
    const [editContractorCompleteOpen, setEditContractorCompleteOpen] = useState(false);

    const handleOpenContractor = () => {
        setOpenContractor(true);
    };

    const handleCloseContractor = () => {
        setOpenContractor(false);
    };

    const handleAddContractor = () => {
        setOpenContractor(false);
        setAddContractorOpen(true);
    };

    const handleCloseContractorAdd = () => {
        setAddContractorOpen(false);
        setOpenContractor(true);
    };

    const handleAddContractorComplete = () => {
        if (addContractorData === "") {
            setAddContractorError("業者名を入力してください");
        } else {
            setAddContractorCompleteOpen(true);
        }
    };

    const handleCloseContractorAddComplete = () => {
        setAddContractorCompleteOpen(false);
        setAddContractorData("");
        setAddContractorError("");
        setAddContractorOpen(false);
    };

    const handleAddContractorChange = (e) => {
        setAddContractorData(e.target.value);
    };

    const handleAddContractorBlur = () => {
        if (addContractorData === "") {
            setAddContractorError("業者名を入力してください");
        } else {
            setAddContractorError("");
        }
    };

    const handleEditContractor = () => {
        setOpenContractor(false);
        setEditContractorOpen(true);
    };

    const handleCloseEditContractor = () => {
        setEditContractorOpen(false);
        setOpenContractor(true);
    };

    const handleEditContractorChange = (e) => {
        setEditContractorData(e.target.value);
    };

    const handleEditContractorBlur = () => {
        if (editContractorData === "") {
            setEditContractorError("業者名を入力してください");
        } else {
            setEditContractorError("");
        }
    };

    const handleEditContractorComplete = () => {
        if (editContractorData === "") {
            setEditContractorError("業者名を入力してください");
        } else {
            setEditContractorCompleteOpen(true);
        }
    };

    const handleCloseEditContractorComplete = () => {
        setEditContractorCompleteOpen(false);
        setEditContractorError("");
        setEditContractorOpen(false);
    };

    const [addEquipmentOpen, setAddEquipmentOpen] = useState(false);
    const [addEquipmentCompleteOpen, setAddEquipmentCompleteOpen] = useState(false);

    const [editEquipmentOpen, setEditEquipmentOpen] = useState(false);
    const [editEquipmentCompleteOpen, setEditEquipmentCompleteOpen] = useState(false);

    const [addEquipmentData, setAddEquipmentData] = useState({
        name: "",
        amountFlag: true,
        amount: "",
        section: "rental",
        contractor: "",
    });

    const [addEquipmentError, setAddEquipmentError] = useState({
        name: "",
        amount: "",
        section: "",
        contractor: "",
    });

    const [editEquipmentData, setEditEquipmentData] = useState({
        name: "自転車",
        amountFlag: true,
        amount: "200",
        section: "rental",
        contractor: "レンティック",
    });

    const [editEquipmentError, setEditEquipmentError] = useState({
        name: "",
        amount: "",
        section: "",
        contractor: "",
    });

    const handleEquipmentAdd = () => {
        setAddEquipmentOpen(true);
    };

    const handleCloseEquipmentAdd = () => {
        setAddEquipmentOpen(false);
    };

    const handleAddEquipmentSectionChange = (e) => {
        if (e.target.value === "rental") {
            setAddEquipmentData({
                ...addEquipmentData,
                section: e.target.value,
            });
        } else {
            setAddEquipmentData({
                ...addEquipmentData,
                section: e.target.value,
            });
            setAddEquipmentError({
                ...addEquipmentError,
                section: "",
            });
        }
    };

    const handleAddEquipmentNameChange = (e) => {
        setAddEquipmentData({ ...addEquipmentData, name: e.target.value });
    };

    const handleAddEquipmentNameBlur = () => {
        if (addEquipmentData.name === "") {
            setAddEquipmentError({ ...addEquipmentError, name: "備品名を入力してください" });
        } else {
            setAddEquipmentError({ ...addEquipmentError, name: "" });
        }
    };

    const handleAddEquipmentAmountFlagChange = (e) => {
        const value = e.target.value === "true";
        setAddEquipmentData({ ...addEquipmentData, amountFlag: value });
    };

    const handleAddEquipmentAmountChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setAddEquipmentData({ ...addEquipmentData, amount: value });
    };

    const handleAddEquipmentAmountBlur = () => {
        if (addEquipmentData.amount === "") {
            setAddEquipmentError({ ...addEquipmentError, amount: "金額を入力してください" });
        } else {
            setAddEquipmentError({ ...addEquipmentError, amount: "" });
        }
    };

    const handleAddEquipmentContractorChange = (e) => {
        setAddEquipmentData({ ...addEquipmentData, contractor: e.target.value });
        setAddEquipmentError({ ...addEquipmentError, contractor: "" });
    };

    const handleEquipmentAddComplete = () => {
        const newErrors = {};
        if (addEquipmentData.name === "") {
            newErrors.name = "備品名を入力してください";
        }
        if (addEquipmentData.amount === "") {
            newErrors.amount = "金額を入力してください";
        }
        if (addEquipmentData.contractor === "") {
            newErrors.contractor = "業者を選択してください";
        }
        setAddEquipmentError({ ...addEquipmentError, ...newErrors });

        if (Object.keys(newErrors).length === 0) {
            setAddEquipmentOpen(false);
            setAddEquipmentCompleteOpen(true);
        }
    };

    const handleCloseEquipmentAddComplete = () => {
        setAddEquipmentCompleteOpen(false);
    };

    const handleEditEquipment = () => {
        setEditEquipmentOpen(true);
    };

    const handleCloseEquipmentEdit = () => {
        setEditEquipmentOpen(false);
    };

    const handleEquipmentEditSectionChange = (e) => {
        if (e.target.value === "rental") {
            setEditEquipmentData({
                ...editEquipmentData,
                section: e.target.value,
            });
        } else {
            setEditEquipmentData({
                ...editEquipmentData,
                section: e.target.value,
            });
            setEditEquipmentError({
                ...editEquipmentError,
                section: "",
            });
        }
    };

    const handleEquipmentEditNameChange = (e) => {
        setEditEquipmentData({ ...editEquipmentData, name: e.target.value });
    };

    const handleEquipmentEditNameBlur = () => {
        if (editEquipmentData.name === "") {
            setEditEquipmentError({ ...editEquipmentError, name: "備品名を入力してください" });
        } else {
            setEditEquipmentError({ ...editEquipmentError, name: "" });
        }
    };

    const handleEditEquipmentAmountFlagChange = (e) => {
        const value = e.target.value === "true";
        setEditEquipmentData({ ...editEquipmentData, amountFlag: value });
    };

    const handleEquipmentEditAmountChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setEditEquipmentData({ ...editEquipmentData, amount: value });
    };

    const handleEquipmentEditAmountBlur = () => {
        if (editEquipmentData.amount === "") {
            setEditEquipmentError({ ...editEquipmentError, amount: "金額を入力してください" });
        } else {
            setEditEquipmentError({ ...editEquipmentError, amount: "" });
        }
    };

    const handleEquipmentEditContractorChange = (e) => {
        setEditEquipmentData({ ...editEquipmentData, contractor: e.target.value });
        setEditEquipmentError({ ...editEquipmentError, contractor: "" });
    };

    const handleEquipmentEditComplete = () => {
        const newErrors = {};
        if (editEquipmentData.name === "") {
            newErrors.name = "備品名を入力してください";
        }
        if (editEquipmentData.amount === "") {
            newErrors.amount = "金額を入力してください";
        }
        if (editEquipmentData.contractor === "") {
            newErrors.contractor = "業者を選択してください";
        }
        setEditEquipmentError({ ...editEquipmentError, ...newErrors });

        if (Object.keys(newErrors).length === 0) {
            setEditEquipmentOpen(false);
            setEditEquipmentCompleteOpen(true);
        }
    };

    const handleCloseEquipmentEditComplete = () => {
        setEditEquipmentCompleteOpen(false);
    };

    return (
        <>
            <Helmet>
                <title>レンタル備品一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">レンタル備品一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleEquipmentAdd}>
                                レンタル備品を追加する
                            </Button>
                            <IconButton sx={{ ml: 2 }} onClick={handleOpenContractor}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={equipmentData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                        />
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={openContractor} onClose={handleCloseContractor}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleCloseContractor}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
                        業者一覧
                    </Typography>

                    <MaterialReactTable
                        columns={contractorColumns}
                        data={contractorData}
                        columnFilterDisplayMode="popover"
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableHiding={false}
                        enableTopToolbar={false}
                        localization={MRT_Localization_JA}
                        initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                        muiPaginationProps={{ shape: "rounded", color: "primary" }}
                        paginationDisplayMode="pages"
                        positionGlobalFilter="left"
                        muiTablePaperProps={{ elevation: 0 }}
                        muiTableBodyRowProps={{ hover: false }}
                        muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                    />

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleAddContractor}
                            sx={{ width: "400px", mt: 3 }}
                        >
                            業者を追加する
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "400px", mt: 5 }}
                            onClick={handleCloseContractor}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={addContractorOpen} onClose={handleCloseContractorAdd}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleCloseContractorAdd}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" textAlign="center" sx={{ width: "400px", mb: 2 }}>
                                業者を追加
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                            <TextField
                                variant="outlined"
                                sx={{ width: "400px" }}
                                label="業者名"
                                value={addContractorData}
                                onChange={handleAddContractorChange}
                                onBlur={handleAddContractorBlur}
                                error={addContractorError !== ""}
                            />
                        </Box>

                        {addContractorError !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mb: 1 }}>
                                {addContractorError}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleAddContractorComplete}
                            sx={{ width: "400px", mt: 5 }}
                        >
                            業者を登録する
                        </Button>

                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleCloseContractorAdd}
                            sx={{ width: "400px", mt: 5 }}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={addContractorCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        業者の追加が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleCloseContractorAddComplete}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={editContractorOpen} onClose={handleCloseEditContractor}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleCloseEditContractor}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" textAlign="center" sx={{ width: "400px", mb: 2 }}>
                                業者を編集
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                            <TextField
                                variant="outlined"
                                sx={{ width: "400px" }}
                                label="業者名"
                                value={editContractorData}
                                onChange={handleEditContractorChange}
                                onBlur={handleEditContractorBlur}
                                error={editContractorError !== ""}
                            />
                        </Box>

                        {editContractorError !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mb: 1 }}>
                                {editContractorError}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleEditContractorComplete}
                            sx={{ width: "400px", my: 5 }}
                        >
                            業者を更新する
                        </Button>

                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleCloseEditContractor}
                            sx={{ width: "400px" }}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={editContractorCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        業者の編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleCloseEditContractorComplete}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addEquipmentOpen} onClose={handleCloseEquipmentAdd}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleCloseEquipmentAdd}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                        備品を追加
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <FormControl sx={{ width: "400px" }} error={addEquipmentError.contractor !== ""}>
                            <InputLabel>業者</InputLabel>
                            <Select
                                label="業者"
                                value={addEquipmentData.contractor}
                                onChange={handleAddEquipmentContractorChange}
                                sx={{ textAlign: "left" }}
                            >
                                {contractorData.map((contractor, index) => (
                                    <MenuItem key={index} value={contractor.name} sx={{ textAlign: "left" }}>
                                        {contractor.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {addEquipmentError.contractor !== "" && (
                        <Alert severity="error" sx={{ width: "400px" }}>
                            {addEquipmentError.contractor}
                        </Alert>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "400px" }}
                            label="備品名"
                            value={addEquipmentData.name}
                            onChange={handleAddEquipmentNameChange}
                            onBlur={handleAddEquipmentNameBlur}
                            error={addEquipmentError.name !== ""}
                        />
                    </Box>
                    {addEquipmentError.name !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mb: 3 }}>
                            {addEquipmentError.name}
                        </Alert>
                    )}

                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>金額</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <RadioGroup
                                row
                                value={addEquipmentData.amountFlag}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="1日"
                                    onChange={handleAddEquipmentAmountFlagChange}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="月額"
                                    onChange={handleAddEquipmentAmountFlagChange}
                                />
                            </RadioGroup>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "400px" }}
                            label={addEquipmentData.amountFlag ? "1日" : "月額"}
                            value={addEquipmentData.amount}
                            onChange={handleAddEquipmentAmountChange}
                            onBlur={handleAddEquipmentAmountBlur}
                            error={addEquipmentError.amount !== ""}
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />
                    </Box>
                    {addEquipmentError.amount !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mb: 3 }}>
                            {addEquipmentError.amount}
                        </Alert>
                    )}

                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>区分</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <RadioGroup row value={addEquipmentData.section} sx={{ justifyContent: "space-between" }}>
                                <FormControlLabel
                                    value="rental"
                                    control={<Radio />}
                                    label="レンタル"
                                    onChange={handleAddEquipmentSectionChange}
                                />
                                <FormControlLabel
                                    value="purchase"
                                    control={<Radio />}
                                    label="購入"
                                    onChange={handleAddEquipmentSectionChange}
                                />
                            </RadioGroup>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleEquipmentAddComplete}
                        >
                            備品を登録する
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "400px" }}
                            onClick={handleCloseEquipmentAdd}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={addEquipmentCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        備品の追加が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleCloseEquipmentAddComplete}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={editEquipmentOpen} onClose={handleCloseEquipmentEdit}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleCloseEquipmentEdit}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
                        備品を編集
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <FormControl sx={{ width: "400px" }} error={editEquipmentError.contractor !== ""}>
                            <InputLabel>業者</InputLabel>
                            <Select
                                label="業者"
                                value={editEquipmentData.contractor}
                                onChange={handleEquipmentEditContractorChange}
                                sx={{ textAlign: "left" }}
                            >
                                {contractorData.map((contractor, index) => (
                                    <MenuItem key={index} value={contractor.name} sx={{ textAlign: "left" }}>
                                        {contractor.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {editEquipmentError.contractor !== "" && (
                        <Alert severity="error" sx={{ width: "400px" }}>
                            {editEquipmentError.contractor}
                        </Alert>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "400px" }}
                            label="備品名"
                            value={editEquipmentData.name}
                            onChange={handleEquipmentEditNameChange}
                            onBlur={handleEquipmentEditNameBlur}
                            error={editEquipmentError.name !== ""}
                        />
                    </Box>
                    {editEquipmentError.name !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mb: 3 }}>
                            {editEquipmentError.name}
                        </Alert>
                    )}

                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>金額</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <RadioGroup
                                row
                                value={editEquipmentData.amountFlag}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="1日"
                                    onChange={handleEditEquipmentAmountFlagChange}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="月額"
                                    onChange={handleEditEquipmentAmountFlagChange}
                                />
                            </RadioGroup>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: 1 }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "400px" }}
                            label={editEquipmentData.amountFlag ? "1日" : "月額"}
                            value={editEquipmentData.amount}
                            onChange={handleEquipmentEditAmountChange}
                            onBlur={handleEquipmentEditAmountBlur}
                            error={editEquipmentError.amount !== ""}
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />
                    </Box>
                    {editEquipmentError.amount !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mb: 3 }}>
                            {editEquipmentError.amount}
                        </Alert>
                    )}

                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>区分</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <RadioGroup row value={editEquipmentData.section} sx={{ justifyContent: "space-between" }}>
                                <FormControlLabel
                                    value="rental"
                                    control={<Radio />}
                                    label="レンタル"
                                    onChange={handleEquipmentEditSectionChange}
                                />
                                <FormControlLabel
                                    value="purchase"
                                    control={<Radio />}
                                    label="購入"
                                    onChange={handleEquipmentEditSectionChange}
                                />
                            </RadioGroup>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleEquipmentEditComplete}
                        >
                            備品を更新する
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "400px" }}
                            onClick={handleCloseEquipmentEdit}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={editEquipmentCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        備品の更新が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleCloseEquipmentEditComplete}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Equipment;
