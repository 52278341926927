import React, { useState, useMemo } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Divider,
    TextField,
    Alert,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogContentText,
    Grid2,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputAdornment,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const DomitoryAdd = ({ val }) => {
    const navigate = useNavigate();

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const [staffData, setStaffData] = useState([
        { name: "立花 響", gender: "女性", age: 18 },
        { name: "風鳴 翼", gender: "女性", age: 18 },
        { name: "天羽 奏", gender: "女性", age: 18 },
        { name: "雪音 クリス", gender: "女性", age: 18 },
        { name: "マリア・カデンツァヴナ・イヴ", gender: "女性", age: 18 },
        { name: "桐ヶ谷 和人", gender: "男性", age: 18 },
        { name: "結城 明日奈", gender: "女性", age: 18 },
        { name: "朝田 詩乃", gender: "女性", age: 17 },
        { name: "桐ヶ谷 直葉", gender: "女性", age: 16 },
    ]);

    const [genderData] = useState(["男性", "女性"]);

    const columns = useMemo(
        () => [
            {
                header: "氏名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { minWidth: "290px", maxWidth: "290px" } },
            },
            {
                header: "性別",
                accessorKey: "gender",
                muiTableHeadCellProps: { sx: { minWidth: "120px", maxWidth: "120px" } },
                muiTableBodyCellProps: { sx: { minWidth: "120px", maxWidth: "120px" } },
                filterVariant: "select",
                filterSelectOptions: genderData,
            },
            {
                header: "年齢",
                accessorKey: "age",
                muiTableHeadCellProps: { sx: { minWidth: "120px", maxWidth: "120px" } },
                muiTableBodyCellProps: { sx: { minWidth: "120px", maxWidth: "120px" } },
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
        ],
        []
    );

    const [dormitoryData, setDormitoryData] = useState({
        dormitoryName: { title: "社宅名", value: "" },
        dormitoryNumber: { title: "部屋番号", value: "" },
        dormitoryAddress: {
            title: "住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
        },
        rentCost: { title: "家賃", value: "" },
        rentAreaFee: { title: "共益費", value: "" },
        parking: { title: "駐車場", judge: "有", value: "" },
        occupancy: { title: "入居区分", judge: "入居中", value: "" },
        division: { title: "管轄部署", value: "" },
        note: { title: "備考", value: "" },
    });

    const [additionalCost, setAdditionalCost] = useState([{ title: "", value: "" }]);

    const [dormitoryDataError, setDormitoryDataError] = useState({
        dormitoryName: "",
        dormitoryNumber: "",
        dormitoryAddressPost: "",
        dormitoryAddressPrefecture: "",
        dormitoryAddressCity: "",
        dormitoryAddressOther: "",
        rentCost: "",
        rentAreaFee: "",
        parkingValue: "",
        occupancyValue: "",
        division: "",
    });

    const [additionalCostError, setAdditionalCostError] = useState([{ title: "", value: "" }]);

    const [validation, setValidation] = useState("");

    const handleDormitoryNameChange = (e) => {
        setDormitoryData({
            ...dormitoryData,
            dormitoryName: { ...dormitoryData.dormitoryName, value: e.target.value },
        });
    };
    const handleDormitoryNameBlur = () => {
        const value = dormitoryData.dormitoryName.value.replace(/　/g, " ").trim();
        setDormitoryData({ ...dormitoryData, dormitoryName: { ...dormitoryData.dormitoryName, value: value } });
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryName: dormitoryData.dormitoryName.value === "" ? "社宅名を入力してください" : "",
        });
    };

    const handleDormitoryNumberChange = (e) => {
        setDormitoryData({
            ...dormitoryData,
            dormitoryNumber: { ...dormitoryData.dormitoryNumber, value: e.target.value },
        });
    };
    const handleDormitoryNumberBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryNumber: dormitoryData.dormitoryNumber.value === "" ? "部屋番号を入力してください" : "",
        });
    };

    const handleDormitoryAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDormitoryData({ ...dormitoryData, dormitoryAddress: { ...dormitoryData.dormitoryAddress, post: value } });
    };
    const handleDormitoryAddressPostBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryAddressPost: dormitoryData.dormitoryAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleDormitoryAddressPrefectureChange = (e) => {
        setDormitoryData({
            ...dormitoryData,
            dormitoryAddress: { ...dormitoryData.dormitoryAddress, prefecture: e.target.value },
        });
    };
    const handleDormitoryAddressPrefectureBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryAddressPrefecture:
                dormitoryData.dormitoryAddress.prefecture === "" ? "都道府県を選択してください" : "",
        });
    };

    const handleDormitoryAddressCityChange = (e) => {
        setDormitoryData({
            ...dormitoryData,
            dormitoryAddress: { ...dormitoryData.dormitoryAddress, city: e.target.value },
        });
    };
    const handleDormitoryAddressCityBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryAddressCity: dormitoryData.dormitoryAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleDormitoryAddressOtherChange = (e) => {
        setDormitoryData({
            ...dormitoryData,
            dormitoryAddress: { ...dormitoryData.dormitoryAddress, other: e.target.value },
        });
    };
    const handleDormitoryAddressOtherBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            dormitoryAddressOther:
                dormitoryData.dormitoryAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleRentCostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDormitoryData({ ...dormitoryData, rentCost: { ...dormitoryData.rentCost, value: value } });
    };
    const handleRentCostBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            rentCost: dormitoryData.rentCost.value === "" ? "家賃を入力してください" : "",
        });
    };

    const handleRentAreaFeeChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDormitoryData({ ...dormitoryData, rentAreaFee: { ...dormitoryData.rentAreaFee, value: value } });
    };
    const handleRentAreaFeeBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            rentAreaFee: dormitoryData.rentAreaFee.value === "" ? "共益費を入力してください" : "",
        });
    };

    const handleAdditionalCostTitleChange = (index, e) => {
        setAdditionalCost((prev) => {
            const newData = [...prev];
            newData[index].title = e.target.value;
            return newData;
        });
    };
    const handleAdditionalCostTitleBlur = (index) => {
        setAdditionalCostError((prev) => {
            const newData = [...prev];
            newData[index].title = additionalCost[index].title === "" ? "項目名を入力してください" : "";
            return newData;
        });
    };

    const handleAdditionalCostValueChange = (index, e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setAdditionalCost((prev) => {
            const newData = [...prev];
            newData[index].value = value;
            return newData;
        });
    };
    const handleAdditionalCostValueBlur = (index) => {
        setAdditionalCostError((prev) => {
            const newData = [...prev];
            newData[index].value = additionalCost[index].value === "" ? "金額を入力してください" : "";
            return newData;
        });
    };

    const handleAdditionalCostDelete = (index) => {
        setAdditionalCost((prev) => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });

        setAdditionalCostError((prev) => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
    };

    const handleAdditionalCostAdd = () => {
        if (additionalCost.length < 5) {
            setAdditionalCost((prev) => [...prev, { title: "", value: "" }]);
            setAdditionalCostError((prev) => [...prev, { title: "", value: "" }]);
        }
    };

    const handleDormitoryParkingChange = (e) => {
        if (e.target.value === "有") {
            setDormitoryData({ ...dormitoryData, parking: { ...dormitoryData.parking, judge: e.target.value } });
        } else {
            setDormitoryData({
                ...dormitoryData,
                parking: { ...dormitoryData.parking, judge: e.target.value, value: "" },
            });
            setDormitoryDataError({
                ...dormitoryDataError,
                parkingValue: "",
            });
        }
    };

    const handleDormitoryParkingValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setDormitoryData({ ...dormitoryData, parking: { ...dormitoryData.parking, value: value } });
    };
    const handleDormitoryParkingValueBlur = () => {
        setDormitoryDataError({
            ...dormitoryDataError,
            parkingValue:
                dormitoryData.parking.value === "" || dormitoryData.parking.value === "0"
                    ? "台数を入力してください"
                    : "",
        });
    };

    const [selectedStaff, setSelectedStaff] = useState(null);

    const handleDormitoryOccupancyChange = (e) => {
        if (e.target.value === "入居中") {
            setDormitoryData({ ...dormitoryData, occupancy: { ...dormitoryData.occupancy, judge: e.target.value } });
        } else {
            setDormitoryData({
                ...dormitoryData,
                occupancy: { ...dormitoryData.occupancy, judge: e.target.value, value: "" },
            });
            setDormitoryDataError({
                ...dormitoryDataError,
                occupancyValue: "",
            });
            setSelectedStaff(null);
        }
    };

    const [occupancyOpen, setOccupancyOpen] = useState(false);
    const handleDormitoryOccupancyValueChange = (e) => {
        setOccupancyOpen(true);
    };

    const handleOccupancyClose = () => {
        setOccupancyOpen(false);
    };

    const handleRowSelectionChange = (row) => {
        const isSelected = selectedStaff?.name === row.original.name;
        if (isSelected) {
            setSelectedStaff(null);
        } else {
            setSelectedStaff(row.original);
        }
    };

    const handleDormitoryOccupancyValueClear = (e) => {
        e.stopPropagation();
        setDormitoryData({ ...dormitoryData, occupancy: { ...dormitoryData.occupancy, value: "" } });
        setDormitoryDataError({
            ...dormitoryDataError,
            occupancyValue: "",
        });
    };

    const handleAddStaff = () => {
        setOccupancyOpen(false);
        if (selectedStaff) {
            setDormitoryData({
                ...dormitoryData,
                occupancy: {
                    ...dormitoryData.occupancy,
                    value: selectedStaff.name,
                },
            });
            setDormitoryDataError({
                ...dormitoryDataError,
                occupancyValue: "",
            });
        }
    };

    const handleDormitoryDivisionChange = (e) => {
        setDormitoryData({ ...dormitoryData, division: { ...dormitoryData.division, value: e.target.value } });
        setDormitoryDataError({
            ...dormitoryDataError,
            division: "",
        });
    };

    const handleDormitoryNoteChange = (e) => {
        setDormitoryData({ ...dormitoryData, note: { ...dormitoryData.note, value: e.target.value } });
    };

    const handleAddConfirm = () => {
        dormitoryDataError.dormitoryName = dormitoryData.dormitoryName.value === "" ? "社宅名を入力してください" : "";
        dormitoryDataError.dormitoryNumber =
            dormitoryData.dormitoryNumber.value === "" ? "部屋番号を入力してください" : "";
        dormitoryDataError.dormitoryAddressPost =
            dormitoryData.dormitoryAddress.post === "" ? "郵便番号を入力してください" : "";
        dormitoryDataError.dormitoryAddressPrefecture =
            dormitoryData.dormitoryAddress.prefecture === "" ? "都道府県を選択してください" : "";
        dormitoryDataError.dormitoryAddressCity =
            dormitoryData.dormitoryAddress.city === "" ? "市区町村を入力してください" : "";
        dormitoryDataError.dormitoryAddressOther =
            dormitoryData.dormitoryAddress.other === "" ? "上記以降の住所を入力してください" : "";
        dormitoryDataError.rentCost = dormitoryData.rentCost.value === "" ? "家賃を入力してください" : "";
        dormitoryDataError.rentAreaFee = dormitoryData.rentAreaFee.value === "" ? "共益費を入力してください" : "";
        dormitoryDataError.division = dormitoryData.division.value === "" ? "管轄部署を選択してください" : "";
        if (additionalCost.length > 0) {
            additionalCostError.forEach((item, index) => {
                additionalCostError[index].title = additionalCost[index].title === "" ? "項目名を入力してください" : "";
                additionalCostError[index].value = additionalCost[index].value === "" ? "金額を入力してください" : "";
            });
        }

        if (dormitoryData.parking.judge === "有") {
            dormitoryDataError.parkingValue =
                dormitoryData.parking.value === "" || dormitoryData.parking.value === "0"
                    ? "台数を入力してください"
                    : "";
        }
        if (dormitoryData.occupancy.judge === "入居中") {
            dormitoryDataError.occupancyValue = dormitoryData.occupancy.value === "" ? "入居者を選択してください" : "";
        }

        if (
            dormitoryDataError.dormitoryName === "" &&
            dormitoryDataError.dormitoryNumber === "" &&
            dormitoryDataError.dormitoryAddressPost === "" &&
            dormitoryDataError.dormitoryAddressPrefecture === "" &&
            dormitoryDataError.dormitoryAddressCity === "" &&
            dormitoryDataError.dormitoryAddressOther === "" &&
            dormitoryDataError.rentCost === "" &&
            dormitoryDataError.rentAreaFee === "" &&
            dormitoryDataError.parkingValue === "" &&
            dormitoryDataError.occupancyValue === "" &&
            dormitoryDataError.division === "" &&
            additionalCostError.every((item) => item.title === "" && item.value === "")
        ) {
            setValidation("");
            setOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dormitory");
    };

    const handleAddRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleAddComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/dormitory/detail");
    };

    return (
        <>
            <Helmet>
                <title>社宅を追加 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">社宅を追加</Typography>
                    </Box>

                    <Box>
                        <TextField
                            variant="outlined"
                            label={dormitoryData.dormitoryName.title}
                            value={dormitoryData.dormitoryName.value}
                            sx={{ width: "600px" }}
                            onChange={handleDormitoryNameChange}
                            onBlur={handleDormitoryNameBlur}
                            error={dormitoryDataError.dormitoryName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.dormitoryName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.dormitoryName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label={dormitoryData.dormitoryNumber.title}
                            value={dormitoryData.dormitoryNumber.value}
                            sx={{ width: "600px" }}
                            onChange={handleDormitoryNumberChange}
                            onBlur={handleDormitoryNumberBlur}
                            error={dormitoryDataError.dormitoryNumber !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.dormitoryNumber !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.dormitoryNumber}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>住所</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label="郵便番号"
                            value={dormitoryData.dormitoryAddress.post}
                            sx={{ width: "424px" }}
                            onChange={handleDormitoryAddressPostChange}
                            onBlur={handleDormitoryAddressPostBlur}
                            helperText="数字のみ入力してください"
                            error={dormitoryDataError.dormitoryAddressPost !== ""}
                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Button variant="outlined" size="large" sx={{ width: "152px", height: "56px", ml: 3 }}>
                            住所を検索する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.dormitoryAddressPost !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.dormitoryAddressPost}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="都道府県"
                            value={dormitoryData.dormitoryAddress.prefecture}
                            sx={{ width: "288px" }}
                            onChange={handleDormitoryAddressPrefectureChange}
                            onBlur={handleDormitoryAddressPrefectureBlur}
                            error={dormitoryDataError.dormitoryAddressPrefecture !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="市区町村"
                            value={dormitoryData.dormitoryAddress.city}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleDormitoryAddressCityChange}
                            onBlur={handleDormitoryAddressCityBlur}
                            error={dormitoryDataError.dormitoryAddressCity !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {dormitoryDataError.dormitoryAddressPrefecture !== "" && (
                                <Alert severity="error">{dormitoryDataError.dormitoryAddressPrefecture}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {dormitoryDataError.dormitoryAddressCity !== "" && (
                                <Alert severity="error">{dormitoryDataError.dormitoryAddressCity}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="上記以降の住所"
                            value={dormitoryData.dormitoryAddress.other}
                            sx={{ width: "600px" }}
                            onChange={handleDormitoryAddressOtherChange}
                            onBlur={handleDormitoryAddressOtherBlur}
                            error={dormitoryDataError.dormitoryAddressOther !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.dormitoryAddressOther !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.dormitoryAddressOther}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>月額費用</Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label={dormitoryData.rentCost.title}
                            value={dormitoryData.rentCost.value}
                            sx={{ width: "600px" }}
                            helperText="数字のみ入力してください"
                            onChange={handleRentCostChange}
                            onBlur={handleRentCostBlur}
                            error={dormitoryDataError.rentCost !== ""}
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.rentCost !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.rentCost}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label={dormitoryData.rentAreaFee.title}
                            value={dormitoryData.rentAreaFee.value}
                            sx={{ width: "600px" }}
                            helperText="数字のみ入力してください"
                            onChange={handleRentAreaFeeChange}
                            onBlur={handleRentAreaFeeBlur}
                            error={dormitoryDataError.rentAreaFee !== ""}
                            InputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.rentAreaFee !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.rentAreaFee}
                            </Alert>
                        )}
                    </Box>

                    {additionalCost.map((item, index) => (
                        <Box
                            key={index}
                            sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", mx: 2, mt: 1 }}
                        >
                            <TextField
                                variant="outlined"
                                label="項目名"
                                value={item.title}
                                onChange={(e) => handleAdditionalCostTitleChange(index, e)}
                                onBlur={() => handleAdditionalCostTitleBlur(index)}
                                sx={{ width: "260px", mr: 2 }}
                                error={additionalCostError[index]?.title !== ""}
                            />
                            <TextField
                                variant="outlined"
                                label="金額"
                                value={item.value}
                                onChange={(e) => handleAdditionalCostValueChange(index, e)}
                                onBlur={() => handleAdditionalCostValueBlur(index)}
                                helperText="数字のみ入力してください"
                                sx={{ width: "260px" }}
                                error={additionalCostError[index]?.value !== ""}
                                InputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                                }}
                            />
                            <IconButton onClick={() => handleAdditionalCostDelete(index)} size="large">
                                <DeleteForeverIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    ))}
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {additionalCostError.some((item) => item.title !== "" || item.value !== "") && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                追加の月額費用に不備があります
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "400px" }}
                            onClick={handleAdditionalCostAdd}
                            disabled={additionalCost.length >= 5}
                        >
                            費用の項目を追加する
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dormitoryData.parking.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row value={dormitoryData.parking.judge}>
                                    <FormControlLabel
                                        value="有"
                                        control={<Radio />}
                                        label="有"
                                        onChange={handleDormitoryParkingChange}
                                    />
                                    <FormControlLabel
                                        value="無"
                                        control={<Radio />}
                                        label="無"
                                        onChange={handleDormitoryParkingChange}
                                        sx={{ ml: 7.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    {dormitoryData.parking.judge === "有" && (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label="台数"
                                    value={dormitoryData.parking.value}
                                    sx={{ width: "600px" }}
                                    helperText="数字のみ入力してください"
                                    onChange={handleDormitoryParkingValueChange}
                                    onBlur={handleDormitoryParkingValueBlur}
                                    error={dormitoryDataError.parkingValue !== ""}
                                    InputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        endAdornment: <InputAdornment position="end">台</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dormitoryDataError.parkingValue !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dormitoryDataError.parkingValue}
                                    </Alert>
                                )}
                            </Box>
                        </>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {dormitoryData.occupancy.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row value={dormitoryData.occupancy.judge}>
                                    <FormControlLabel
                                        value="入居中"
                                        control={<Radio />}
                                        label="入居中"
                                        onChange={handleDormitoryOccupancyChange}
                                    />
                                    <FormControlLabel
                                        value="未入居"
                                        control={<Radio />}
                                        label="未入居"
                                        onChange={handleDormitoryOccupancyChange}
                                        sx={{ ml: 3.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    {dormitoryData.occupancy.judge === "入居中" && (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                <TextField
                                    variant="outlined"
                                    label="入居者"
                                    value={dormitoryData.occupancy.value}
                                    sx={{ width: "600px" }}
                                    onClick={handleDormitoryOccupancyValueChange}
                                    error={dormitoryDataError.occupancyValue !== ""}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {dormitoryData.occupancy.value !== "" ? (
                                                    <IconButton onClick={handleDormitoryOccupancyValueClear}>
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={handleDormitoryOccupancyValueChange}>
                                                        <FilterNoneIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                {dormitoryDataError.occupancyValue !== "" && (
                                    <Alert severity="error" sx={{ width: "600px" }}>
                                        {dormitoryDataError.occupancyValue}
                                    </Alert>
                                )}
                            </Box>
                        </>
                    )}

                    <Box sx={{ mt: 3 }}>
                        <FormControl sx={{ width: "600px" }} error={dormitoryDataError.division !== ""}>
                            <InputLabel>{dormitoryData.division.title}</InputLabel>
                            <Select
                                label={dormitoryData.division.title}
                                value={dormitoryData.division.value}
                                onChange={handleDormitoryDivisionChange}
                                sx={{ textAlign: "left" }}
                            >
                                {divisionData.map((division, index) => (
                                    <MenuItem key={index} value={division} sx={{ textAlign: "left" }}>
                                        {division}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {dormitoryDataError.division !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {dormitoryDataError.division}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: 5 }}>
                        <TextField
                            variant="outlined"
                            label="備考"
                            value={dormitoryData.note.value}
                            sx={{ width: "600px" }}
                            onChange={handleDormitoryNoteChange}
                            multiline
                            rows={5}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleAddConfirm}
                        >
                            入力内容を確認する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            社宅一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={occupancyOpen} onClose={handleOccupancyClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleOccupancyClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6">入居するスタッフを選択</Typography>

                    <MaterialReactTable
                        columns={columns}
                        data={staffData}
                        columnFilterDisplayMode="popover"
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableHiding={false}
                        localization={MRT_Localization_JA}
                        positionToolbarAlertBanner={false}
                        initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                        muiPaginationProps={{ shape: "rounded", color: "primary" }}
                        paginationDisplayMode="pages"
                        positionGlobalFilter="left"
                        enableStickyHeader={true}
                        getRowId={(row) => row.name}
                        muiTablePaperProps={{
                            sx: { mt: 2 },
                            elevation: 0,
                        }}
                        muiTableContainerProps={{
                            sx: {
                                minHeight: "300px",
                                maxHeight: "300px",
                                overflow: "auto",
                            },
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => handleRowSelectionChange(row),
                            selected: selectedStaff?.name === row.original.name,
                            sx: { cursor: "pointer" },
                        })}
                        state={{
                            rowSelection: selectedStaff ? { [selectedStaff.name]: true } : {},
                        }}
                    />

                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleAddStaff}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        選択したスタッフを追加する
                    </Button>

                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleOccupancyClose}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        社宅の入力内容を確認
                    </Typography>

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">社宅名・部屋番号</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.dormitoryName.value} {dormitoryData.dormitoryNumber.value}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.dormitoryAddress.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {"〒" + dormitoryData.dormitoryAddress.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                <br />
                                {dormitoryData.dormitoryAddress.prefecture}
                                {dormitoryData.dormitoryAddress.city}
                                {dormitoryData.dormitoryAddress.other}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">月額費用</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Grid2 size={{ xs: 4, md: 4 }}>
                                <Typography>{dormitoryData.rentCost.title}</Typography>
                                <Typography>{dormitoryData.rentAreaFee.title}</Typography>
                                {additionalCost.map((item, index) => (
                                    <Typography key={index}>{item.title}</Typography>
                                ))}
                            </Grid2>
                            <Grid2>
                                <Typography textAlign="right">
                                    {dormitoryData.rentCost.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                </Typography>
                                <Typography textAlign="right">
                                    {dormitoryData.rentAreaFee.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                </Typography>

                                {additionalCost.map((item, index) => (
                                    <Typography key={index} textAlign="right">
                                        {item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                    </Typography>
                                ))}
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.parking.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.parking.judge === "有" ? dormitoryData.parking.value + "台" : "無"}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.occupancy.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.occupancy.judge === "入居中" ? "入居中" : "未入居"}　
                                {dormitoryData.occupancy.judge === "入居中" ? dormitoryData.occupancy.value : ""}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.division.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                }}
                            >
                                {dormitoryData.division.value}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.note.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Box sx={{ width: "550px" }}>
                                {dormitoryData.note.value === "" ? (
                                    <Typography sx={{ textAlign: "left" }}>なし</Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            whiteSpace: "pre-wrap",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {dormitoryData.note.value}
                                    </Typography>
                                )}
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddRegister}
                            sx={{ width: "400px" }}
                        >
                            入力内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            入力画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        社宅の登録が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleAddComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DomitoryAdd;
