import React, { useMemo, useState } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Apply = ({ val }) => {
    const navigate = useNavigate();

    const applyData = [
        {
            name: "桐ヶ谷 和人",
            furi: "キリガヤ カズト",
            address: "東京都新宿区西新宿三丁目3番13号 西新宿水間ビル6F",
            age: 18,
            gender: "男性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "佐藤 太郎",
            furi: "サトウ タロウ",
            address: "東京都渋谷区道玄坂2丁目",
            age: 25,
            gender: "男性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "鈴木 花子",
            furi: "スズキ ハナコ",
            address: "東京都港区六本木1丁目",
            age: 22,
            gender: "女性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "田中 一郎",
            furi: "タナカ イチロウ",
            address: "東京都中央区銀座5丁目",
            age: 30,
            gender: "男性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "山田 美咲",
            furi: "ヤマダ ミサキ",
            address: "東京都新宿区歌舞伎町1丁目",
            age: 28,
            gender: "女性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "高橋 健",
            furi: "タカハシ ケン",
            address: "東京都豊島区池袋2丁目",
            age: 26,
            gender: "男性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "中村 直樹",
            furi: "ナカムラ ナオキ",
            address: "東京都台東区上野公園",
            age: 24,
            gender: "男性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "小林 由美",
            furi: "コバヤシ ユミ",
            address: "東京都江東区豊洲3丁目",
            age: 21,
            gender: "女性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "加藤 亮",
            furi: "カトウ リョウ",
            address: "東京都品川区大井町",
            age: 29,
            gender: "男性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "松本 佳子",
            furi: "マツモト ヨシコ",
            address: "東京都目黒区自由が丘",
            age: 27,
            gender: "女性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "井上 俊介",
            furi: "イノウエ シュンスケ",
            address: "東京都世田谷区三軒茶屋",
            age: 23,
            gender: "男性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "渡辺 美紀",
            furi: "ワタナベ ミキ",
            address: "東京都杉並区高円寺",
            age: 20,
            gender: "女性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "山本 健太",
            furi: "ヤマモト ケンタ",
            address: "東京都中野区中野",
            age: 31,
            gender: "男性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "福田 由紀",
            furi: "フクダ ユキ",
            address: "東京都練馬区石神井公園",
            age: 19,
            gender: "女性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "岡田 直美",
            furi: "オカダ ナオミ",
            address: "東京都足立区北千住",
            age: 32,
            gender: "女性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "石井 大輔",
            furi: "イシイ ダイスケ",
            address: "東京都江戸川区葛西",
            age: 34,
            gender: "男性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "村上 さやか",
            furi: "ムラカミ サヤカ",
            address: "東京都八王子市",
            age: 26,
            gender: "女性",
            media: "TikTok",
            status: "不採用",
        },
        {
            name: "西田 亮太",
            furi: "ニシダ リョウタ",
            address: "東京都立川市",
            age: 30,
            gender: "男性",
            media: "求人サイト",
            status: "受付",
        },
        {
            name: "藤田 美和",
            furi: "フジタ ミワ",
            address: "東京都町田市",
            age: 29,
            gender: "女性",
            media: "YouTube",
            status: "採用",
        },
        {
            name: "長谷川 直樹",
            furi: "ハセガワ ナオキ",
            address: "東京都調布市",
            age: 22,
            gender: "男性",
            media: "TikTok",
            status: "不採用",
        },
    ];

    const [genderData, setGenderData] = useState(["男性", "女性"]);

    const [mediaData, setMediaData] = useState(["YouTube", "TikTok", "求人サイト"]);

    const [statusData, setStatusData] = useState(["採用", "不採用", "受付"]);

    const handleApplyDetail = (name) => {
        window.scrollTo(0, 0);
        //navigate(`/apply/${name}`);
        navigate("/apply/detail");
    };

    const handleAnalysis = () => {
        window.scrollTo(0, 0);
        navigate("/apply/analysis");
    };

    const handleMedia = () => {
        window.scrollTo(0, 0);
        navigate("/apply/media");
    };

    const columns = useMemo(
        () => [
            {
                header: "応募者",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "応募者フリガナ",
                accessorKey: "furi",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "住所",
                accessorKey: "address",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "400px", minWidth: "400px" } },
            },
            {
                header: "年齢",
                accessorKey: "age",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
            {
                header: "性別",
                accessorKey: "gender",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: genderData,
            },
            {
                header: "応募媒体",
                accessorKey: "media",
                filterVariant: "select",
                filterSelectOptions: mediaData,
            },
            {
                header: "ステータス",
                accessorKey: "status",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: statusData,
            },
        ],
        []
    );

    return (
        <>
            <Helmet>
                <title>応募者一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 7, md: 7 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">応募者一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 5, md: 5 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleAnalysis}>
                                応募者分析
                            </Button>

                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                sx={{ ml: 5 }}
                                onClick={handleMedia}
                            >
                                応募媒体一覧
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={applyData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleApplyDetail(row.original.name),
                                sx: { cursor: "pointer" },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{
                                shape: "rounded",
                                color: "primary",
                            }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Apply;
