import React, { useState } from "react";
import { Container, Typography, Box, Button, Grid2, Divider } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const DormitoryDetail = ({ val }) => {
    const navigate = useNavigate();

    const [dormitoryData, setDormitoryData] = useState({
        dormitoryName: { title: "社宅名", value: "社宅A" },
        dormitoryNumber: { title: "部屋番号", value: "3001" },
        dormitoryAddress: {
            title: "住所",
            post: "1000000",
            prefecture: "東京都",
            city: "千代田区",
            other: "1-1-1",
        },
        rentCost: { title: "家賃", value: "100000" },
        rentAreaFee: { title: "共益費", value: "50000" },
        parking: { title: "駐車場", judge: "有", value: "2" },
        occupancy: { title: "入居区分", judge: "入居中", value: "桐ヶ谷 和人" },
        note: { title: "備考", value: "俺はキリト、今日もアスナはいないんだ。" },
    });

    const [additionalCost, setAdditionalCost] = useState([
        { title: "駐車場代", value: "60000" },
        { title: "24時間安心サポート", value: "50000" },
    ]);

    const handleEdit = () => {
        window.scrollTo(0, 0);
        navigate("/dormitory/detail/edit");
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dormitory");
    };

    return (
        <>
            <Helmet>
                <title>社宅詳細 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">社宅詳細</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleEdit}>
                                社宅を編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">
                                {dormitoryData.dormitoryName.title}・{dormitoryData.dormitoryNumber.title}
                            </Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.dormitoryName.value} {dormitoryData.dormitoryNumber.value}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.dormitoryAddress.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {"〒" + dormitoryData.dormitoryAddress.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                <br />
                                {dormitoryData.dormitoryAddress.prefecture}
                                {dormitoryData.dormitoryAddress.city}
                                {dormitoryData.dormitoryAddress.other}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">月額費用</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", width: "400px" }}>
                                <Box sx={{ display: "flex", mb: 1 }}>
                                    <Typography textAlign="left" sx={{ width: "150px" }}>
                                        {dormitoryData.rentCost.title}
                                    </Typography>
                                    <Typography textAlign="right" sx={{ width: "100px" }}>
                                        {dormitoryData.rentCost.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                </Box>

                                <Box sx={{ display: "flex", mb: 1 }}>
                                    <Typography textAlign="left" sx={{ width: "150px" }}>
                                        {dormitoryData.rentAreaFee.title}
                                    </Typography>
                                    <Typography textAlign="right" sx={{ width: "100px" }}>
                                        {dormitoryData.rentAreaFee.value
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                </Box>

                                {additionalCost.map((item, index) => (
                                    <Box key={index} sx={{ display: "flex", mb: 1 }}>
                                        <Typography textAlign="left" sx={{ width: "150px" }}>
                                            {item.title}
                                        </Typography>
                                        <Typography textAlign="right" sx={{ width: "100px" }}>
                                            {item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.parking.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.parking.judge === "有" ? dormitoryData.parking.value + "台" : "無"}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.occupancy.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography sx={{ textAlign: "left" }}>
                                {dormitoryData.occupancy.judge === "入居中" ? "入居中" : "未入居"}　
                                {dormitoryData.occupancy.judge === "入居中" ? dormitoryData.occupancy.value : ""}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dormitoryData.note.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Box sx={{ width: "550px" }}>
                                {dormitoryData.note.value === "" ? (
                                    <Typography sx={{ textAlign: "left" }}>なし</Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            textAlign: "left",
                                            whiteSpace: "pre-wrap",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {dormitoryData.note.value}
                                    </Typography>
                                )}
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            社宅一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DormitoryDetail;
