import React, { useState } from "react";
import { Container, Typography, Box, Button, Grid2, Divider } from "@mui/material";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ShiftDetail = ({ val }) => {
    const navigate = useNavigate();

    const [shiftData, setShiftData] = useState([]);

    const handleShiftEdit = () => {
        // window.scrollTo(0, 0);
        // navigate("/dispatch/detail/location/shift/edit");
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch/detail");
    };

    return (
        <>
            <Helmet>
                <title>シフト表 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container sx={{ mb: 3 }}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">製造部 第1〇〇部〇〇課のシフト表</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleShiftEdit}>
                                シフト表を編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <Typography>
                            準備中
                            <br />
                            派遣先管理、スタッフ管理、社宅管理、応募者管理、派遣元責任者管理が実装完了次第、実装。
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 5, mb: 10 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            派遣先企業詳細へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ShiftDetail;
