import { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import {
    Button,
    CircularProgress,
    Typography,
    TextField,
    Alert,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [open, setOpen] = useState(false);

    const handleSubmit = async () => {
        if (password === "") {
            setErrorMessage("パスワードを入力してください");
        } else if (confirmPassword === "") {
            setErrorMessage("再入力パスワードを入力してください");
        } else if (password === confirmPassword) {
            // パスワード更新の処理を追加
            setOpen(true);
        } else {
            setErrorMessage("パスワードが一致しません");
        }
    };

    const handleClose = () => {
        navigate("/");
    };

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(async (userLogin) => {
            if (userLogin) {
                window.scrollTo(0, 0);
                navigate("/home", { replace: true });
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return (
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>パスワード再設定 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Container
                maxWidth="1280"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    maxWidth: 1280,
                }}
                disableGutters
            >
                <Box sx={{ mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">パスワード再設定</Typography>
                </Box>

                <Box>
                    <TextField
                        label="新しいパスワード"
                        value={password}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        autoFocus
                        sx={{ width: 400 }}
                        // onKeyPress={handleKeyPress}
                    />
                </Box>
                <Alert sx={{ my: 2, width: 400 }} severity="info">
                    半角英数を含む8文字以上で設定してください。
                </Alert>
                <Box>
                    <TextField
                        label="新しいパスワードを再入力"
                        value={confirmPassword}
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ width: 400 }}
                        // onKeyPress={handleKeyPress}
                    />
                </Box>
                <Box sx={{ mt: 1 }}>
                    {errorMessage !== "" && (
                        <Alert severity="error" sx={{ width: "400px" }}>
                            {errorMessage}
                        </Alert>
                    )}
                </Box>

                <Box sx={{ mb: 5 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleSubmit}
                    >
                        パスワードを設定する
                    </Button>
                </Box>

                <Button type="submit" variant="outlined" size="large" sx={{ width: 400 }} onClick={() => navigate("/")}>
                    ログイン画面へ戻る
                </Button>
            </Container>

            <Dialog open={open}>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        パスワードの設定が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleClose}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ResetPassword;
