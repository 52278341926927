import React, { useMemo } from "react";
import { Container, Typography, Box, Tooltip } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Manager = ({ val }) => {
    const navigate = useNavigate();

    const managerData = [
        {
            name: "高坂 穂乃果",
            nofPeople: 100,
        },
        {
            name: "南 ことり",
            nofPeople: 50,
        },
        {
            name: "園田 海未",
            nofPeople: 75,
        },
        {
            name: "星空 凛",
            nofPeople: 30,
        },
        {
            name: "小泉 花陽",
            nofPeople: 60,
        },
        {
            name: "西木野 真姫",
            nofPeople: 80,
        },
        {
            name: "絢瀬 絵里",
            nofPeople: 40,
        },
        {
            name: "東條 希",
            nofPeople: 20,
        },
        {
            name: "矢澤 にこ",
            nofPeople: 90,
        },
    ];

    const columns = useMemo(
        () => [
            {
                header: "派遣元責任者",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "派遣人数",
                accessorKey: "nofPeople",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                Cell: ({ cell }) => cell.getValue().toLocaleString(),
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
        ],
        []
    );

    const handleManagerDetail = (id) => {
        window.scrollTo(0, 0);
        //navigate(`/manager/${id}`);
        navigate("/manager/detail");
    };

    return (
        <>
            <Helmet>
                <title>派遣元責任者一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <Typography variant="h6">派遣元責任者一覧</Typography>
                    </Box>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={managerData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleManagerDetail(row.original.id),
                                sx: { cursor: "pointer" },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Manager;
