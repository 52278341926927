import React, { useState, useMemo } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Divider,
    Tabs,
    Tab,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Alert,
    Tooltip,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    InputLabel,
    Select,
    List,
    ListItemAvatar,
    ListItemText,
    ListItem,
    Avatar,
    Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import StarIcon from "@mui/icons-material/Star";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ja from "date-fns/locale/ja";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const StaffDetail = ({ val }) => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const [companyData, setCompanyData] = useState([
        { name: "HRマネージドサービス株式会社", companyID: "aaaaa" },
        { name: "派遣先企業株式会社", companyID: "bbbbb" },
        { name: "はけんさききぎょう株式会社", companyID: "ccccc" },
    ]);

    const [trialData, setTrialData] = useState([
        "無",
        "有 (入社から7日間)",
        "有 (入社から14日間)",
        "有 (入社から30日間)",
    ]);

    const [yearData, setYearData] = useState(["2026", "2025", "2024"]);

    const [staffData, setStaffData] = useState({
        staffName: { title: "氏名", value: "桐ヶ谷 和人" },
        staffKana: { title: "フリガナ", value: "キリガヤ カズト" },
        staffGender: { title: "性別", value: "male" },
        staffBirthday: { title: "生年月日", value: "1998/01/01" },
        staffPhone: { title: "電話番号", value: "090-1234-5678" },
        staffMail: { title: "メールアドレス", value: "k-kirigaya@hr-ms.jp" },
        staffAddress: {
            title: "住所",
            post: "100-8111",
            prefecture: "東京都",
            city: "千代田区",
            other: "千代田1-1",
            building: "皇居",
        },
        staffResidentAddress: {
            title: "住民票住所",
            ibid: true,
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        staffNationality: { title: "国籍", select: "japanese", value: "" },
        staffEmploymentInsuranceNumber: { title: "雇用保険被保険者番号", value: "1234-567890-1" },
        staffBasicPensionNumber: { title: "基礎年金番号", value: "9876-543210" },
        staffBank: {
            title: "銀行口座",
            bankName: "三菱UFJ",
            select: "bank",
            branch: "千代田",
            number: "1234567",
            holder: "キリガヤ カズト",
        },
        staffEnrollment: { title: "在籍区分", value: "就業中" },
    });

    const [staffMoreData, setStaffMoreData] = useState({
        staffJoinDate: { title: "入社日", value: "2024/01/01" },
        staffDivision: { title: "所属部署", value: "営業部" },
        staffCategory: { title: "社員区分", value: "エキスパート社員" },
        staffPosition: { title: "職制区分", value: "エキスパート社員" },
        staffConflictDate: { title: "個人抵触日", value: null },
        staffAgreementFlag: { title: "協定対象労働者", value: true },
        staffDispatch: { title: "就業先企業", value: "株式会社AAA", date: "2024/01/01" },
        staffCommuting: { title: "通勤手段", value: "自転車" },
        staffDispatchHistory: {
            title: "就業履歴",
            value: [
                {
                    company: "株式会社AAA",
                    startDate: "2024/01/01",
                    endDate: null,
                },
                {
                    company: "株式会社BBB",
                    startDate: "2023/09/01",
                    endDate: "2023/12/20",
                },
            ],
        },
        staffBetweenFlag: { title: "転籍", value: false },
        staffBetweenDate: { title: "転籍日", value: null },
        staffBetweenCompany: { title: "転籍先", value: "" },
        staffRetirementFlag: { title: "退職", value: false },
        staffRetirementDate: { title: "退職日", value: null },
    });
    const [staffMoreDataError, setStaffMoreDataError] = useState({
        staffRetirementDate: "",
        staffBetweenDate: "",
        staffBetweenCompany: "",
    });

    const [emergencyContactData, setEmergencyContactData] = useState({
        emergencyContactName: { title: "緊急連絡先氏名", value: "桐ヶ谷 直葉" },
        emergencyContactKana: { title: "緊急連絡先フリガナ", value: "キリガヤ スグハ" },
        emergencyContactPhone: { title: "緊急連絡先電話番号", value: "090-1234-5678" },
        emergencyContactRelationship: { title: "本人との関係", value: "妹" },
        emergencyContactAddress: {
            title: "緊急連絡先住所",
            post: "1008111",
            prefecture: "東京都",
            city: "千代田区",
            other: "千代田1-1",
            building: "皇居",
        },
    });

    const [spouseData, setSpouseData] = useState({
        spouseFlag: { title: "配偶者", value: true },
        spouseName: { title: "配偶者の氏名", value: "結城 明日奈" },
        spouseKana: { title: "配偶者のフリガナ", value: "ユウキ アスナ" },
        spouseBirthday: { title: "配偶者の生年月日", value: "1998/01/01" },
        spouseGender: { title: "配偶者の性別", value: "female" },
        spouseTogether: { title: "同居区分", value: true },
        spouseAddress: {
            title: "配偶者の住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        spouseNationality: { title: "配偶者の国籍", select: "japanese", value: "" },
    });

    const [dependentData, setDependentData] = useState({
        dependentFlag: { title: "被扶養者届", value: true },
        dependentSpouseFlag: { title: "配偶者", value: true },
        dependentSpouseItem: { title: "項目", value: "該当" },
        dependentSpouseMarried: { title: "婚姻届の有無", value: true },
        dependentSpouseNumber: { title: "個人番号または基礎年金番号", value: "1234-567890-1" },
        dependentSpousePhone: { title: "電話番号", select: "携帯", value: "090-9876-5432" },
        dependentSpouseDate: { title: "被扶養者になった日", value: "2024/01/01" },
        dependentSpouseReason: { title: "理由", value: "婚姻", note: "", deadDate: null },
        dependentSpouseBusiness: { title: "職業", value: "パート", note: "" },
        dependentSpouseIncome: { title: "年収", value: "125000" },
        dependentSpouseOverseas: { title: "海外特例要件", value: "" },
        dependentSpouseOverseasDate: { title: "該当した日", value: null },
        dependentSpouseOverseasReason: {
            title: "理由",
            value: "",
            note: "",
            date: null,
        },
        dependentSpouseMyNumberFront: { title: "マイナンバー・表", value: "" },
        dependentSpouseMyNumberBack: { title: "マイナンバー・裏", value: "" },
        dependentSpouseRelationshipCertificate: { title: "続柄証明書類", value: "" },
        dependentSpouseResident: { title: "住民票", value: "" },
        dependentSpouseIncomeCertificate: { title: "収入証明書", value: "" },
    });

    const [dependentOtherFlag, setDependentOtherFlag] = useState({
        title: "被扶養者",
        value: false,
    });

    const [dependentOtherData, setDependentOtherData] = useState([
        {
            dependentOtherItem: { title: "項目", value: "" },
            dependentOtherName: { title: "氏名", value: "" },
            dependentOtherKana: { title: "フリガナ", value: "" },
            dependentOtherGender: { title: "性別", value: "female" },
            dependentOtherBirthday: { title: "生年月日", value: null },
            dependentOtherRelationship: { title: "続柄", value: "", note: "" },
            dependentOtherNumber: {
                title: "個人番号または基礎年金番号",
                value: "",
            },
            dependentOtherTogether: { title: "同居区分", value: true },
            dependentOtherAddress: {
                title: "住所",
                post: "",
                prefecture: "",
                city: "",
                other: "",
                building: "",
            },
            dependentOtherDate: { title: "被扶養者になった日", value: null },
            dependentOtherReason: {
                title: "理由",
                value: "",
                note: "",
                deadDate: null,
            },
            dependentOtherBusiness: { title: "職業", value: "", note: "" },
            dependentOtherIncome: { title: "年収", value: "" },
            dependentOtherOverseas: { title: "海外特例要件", value: "" },
            dependentOtherOverseasDate: { title: "該当した日", value: null },
            dependentOtherOverseasReason: {
                title: "理由",
                value: "",
                note: "",
                date: null,
            },
            dependentOtherMyNumberFront: { title: "マイナンバー・表", value: "" },
            dependentOtherMyNumberBack: { title: "マイナンバー・裏", value: "" },
            dependentOtherRelationshipCertificate: {
                title: "続柄証明書類",
                value: "",
            },
            dependentOtherResident: { title: "住民票", value: "" },
            dependentOtherIncomeCertificate: { title: "収入証明書", value: "" },
            dependentOtherStudentCertificate: { title: "学生証", value: "" },
        },
    ]);

    const [staffUploadData, setStaffUploadData] = useState({
        staffResume: { title: "履歴書", value: "" },
        staffJobHistory: { title: "職務経歴書", value: "" },
        staffMyNumberFront: { title: "マイナンバー・表", value: "" },
        staffMyNumberBack: { title: "マイナンバー・裏", value: "" },
        staffIdentificationFront: { title: "顔写真付き本人確認書類・表", value: "" },
        staffIdentificationBack: { title: "顔写真付き本人確認書類・裏", value: "" },
        staffResident: { title: "住民票", value: "" },
        staffWithholding: { title: "源泉徴収票", value: "" },
        // staffResidentTax: { title: "住民税", value: "" },
        staffHealthCheck: { title: "健康診断書", value: "" },
        staffSpecialResidence: { title: "特別永住者証明書", value: "" },
        staffWorkVisa: { title: "在留カードまたは就労ビザ", value: "" },
        staffPassport: { title: "パスポート", value: "" },
        staffCommute: {
            title: "自転車・バイク通勤",
            select: false,
            driverLicenseFront: "",
            driverLicenseBack: "",
            vehicleInspection: "",
            compulsoryInsurance: "",
            optionalInsurance: "",
        },
    });

    const [staffLicenseData, setStaffLicenseData] = useState([{ title: "フォークリフト", value: "" }]);

    const [staffJoinDocData, setStaffJoinDocData] = useState({
        pledge: {
            title: "入社誓約書兼給与振込同意書",
            value: "",
            judge: true,
            consentDate: "2024/1/1 00:00:00",
            createDate: "2023/12/31 00:00:00",
        },
        condition: {
            title: "就業条件明示書",
            value: [
                {
                    company: "HRマネージドサービス株式会社",
                    createDate: "2023/12/31 00:00:00",
                    consentDate: "2024/1/1 00:00:00",
                },
                {
                    company: "派遣先企業株式会社",
                    createDate: "2022/12/31 00:00:00",
                    consentDate: "2022/1/1 00:00:00",
                },
                {
                    company: "はけんさききぎょう株式会社",
                    createDate: "2019/12/31 00:00:00",
                    consentDate: "2020/1/1 00:00:00",
                },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        contract: {
            title: "雇用契約書",
            value: [
                //     {
                //         createDate: "2023/12/31 00:00:00",
                //         consentDate: "2024/1/1 00:00:00",
                //     },
                //     {
                //         createDate: "2022/12/31 00:00:00",
                //         consentDate: "2022/1/1 00:00:00",
                //     },
                //     {
                //         createDate: "2019/12/31 00:00:00",
                //         consentDate: "2020/1/1 00:00:00",
                //     },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        personal: {
            title: "個人情報取扱い同意書",
            value: "url",
            judge: false,
            consentDate: null,
            createDate: "2024/12/31 00:00:00",
        },
        guarantee: { title: "身元保証書", value: "", judge: false, consentDate: null, createDate: null },
        commute: {
            title: "通勤手当申請書",
            value: [
                // {
                //     home: "自宅",
                //     company: "HRマネージドサービス株式会社",
                //     createDate: "2023/12/31 00:00:00",
                //     consentDate: "2024/1/1 00:00:00",
                // },
                // {
                //     home: "自宅",
                //     company: "派遣先企業株式会社",
                //     createDate: "2022/12/31 00:00:00",
                //     consentDate: "2022/1/1 00:00:00",
                // },
                // {
                //     home: "社宅A 3001",
                //     company: "はけんさききぎょう株式会社",
                //     createDate: "2019/12/31 00:00:00",
                //     consentDate: "2020/1/1 00:00:00",
                // },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        dormitoryApplication: {
            title: "社宅入居願",
            value: [
                {
                    dormitory: "社宅A",
                    number: "3001",
                    createDate: "2023/12/31 00:00:00",
                    consentDate: "2024/1/1 00:00:00",
                },
                {
                    dormitory: "社宅B",
                    number: "3002",
                    createDate: "2022/12/31 00:00:00",
                    consentDate: "2022/1/1 00:00:00",
                },
                {
                    dormitory: "社宅C",
                    number: "3003",
                    createDate: "2019/12/31 00:00:00",
                    consentDate: "2020/1/1 00:00:00",
                },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        dormitoryUsePledge: {
            title: "社宅使用誓約書",
            value: [
                {
                    dormitory: "社宅A",
                    number: "3001",
                    createDate: "2023/12/31 00:00:00",
                    consentDate: "2024/1/1 00:00:00",
                },
                {
                    dormitory: "社宅B",
                    number: "3002",
                    createDate: "2022/12/31 00:00:00",
                    consentDate: "2022/1/1 00:00:00",
                },
                {
                    dormitory: "社宅C",
                    number: "3003",
                    createDate: "2019/12/31 00:00:00",
                    consentDate: "2020/1/1 00:00:00",
                },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        dormitoryEEPledge: {
            title: "社宅入退去誓約書",
            value: [
                {
                    dormitory: "社宅A",
                    number: "3001",
                    createDate: "2023/12/31 00:00:00",
                    consentDate: "2024/1/1 00:00:00",
                },
                {
                    dormitory: "社宅B",
                    number: "3002",
                    createDate: "2022/12/31 00:00:00",
                    consentDate: "2022/1/1 00:00:00",
                },
                {
                    dormitory: "社宅C",
                    number: "3003",
                    createDate: "2019/12/31 00:00:00",
                    consentDate: "2020/1/1 00:00:00",
                },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        dormitoryCheck: {
            title: "入居時確認表",
            value: [
                {
                    dormitory: "社宅A",
                    number: "3001",
                    createDate: "2023/12/31 00:00:00",
                    consentDate: "2024/1/1 00:00:00",
                },
                {
                    dormitory: "社宅B",
                    number: "3002",
                    createDate: "2022/12/31 00:00:00",
                    consentDate: "2022/1/1 00:00:00",
                },
                {
                    dormitory: "社宅C",
                    number: "3003",
                    createDate: "2019/12/31 00:00:00",
                    consentDate: "2020/1/1 00:00:00",
                },
            ],
            judge: false,
            consentDate: null,
            createDate: null,
        },
        dailypay: { title: "日払い利用申請書", value: "", judge: false, consentDate: null, createDate: null },
        assigned: { title: "赴任旅費領収書", judge: false, receiptDate: null },
    });

    const [conditionCreateData, setConditionCreateData] = useState({
        contractStartDate: null,
        contractEndDate: null,
        periodFlag: false,
        updateFlag: false,
        position: "",
        dispatchStartDate: null,
        dispatchEndDate: null,
        dispatchName: "",
        dispatchLocation: "",
        dispatchAddress: "",
        departmentName: "",
        departmentPosition: "",
        departmentPhone: "",
        departmentCommander: "",
        departmentCommanderPosition: "",
        content: "",
        law4: false,
        duty: false,
        agreement: true,
        agreementEndDate: null,
        workTime: [
            {
                start: null,
                end: null,
                breakTime: [
                    {
                        start: null,
                        end: null,
                    },
                ],
            },
        ],
        shiftWork: false,
        baseSalary: "",
        jobSalary: "",
        allowance: [
            {
                title: "",
                value: "",
            },
        ],
        commuteFee: true,
        wageRevision: false,
        bonus: false,
        retirementFee: false,
        troubleDivision: "",
        troublePosition: "",
        troubleName: "",
        troublePhone: "",
        troubleDestinationDepartment: "",
        troubleDestinationDepartmentPosition: "",
        troubleDestinationDepartmentName: "",
        troubleDestinationDepartmentPhone: "",
        noRestrictions: false,
        noRestrictionsContent: "",
        conflict: false,
        conflictDate: null,
        staffConflict: false,
        staffConflictDate: null,
        dispatchAmount: "",
        staffName: "",
        staffAddress: "",
        consentDate: null,
    });
    const [conditionCreateDataError, setConditionCreateDataError] = useState({
        contractStartDate: "",
        contractEndDate: "",
        contractDate: "",
        position: "",
        dispatchStartDate: "",
        dispatchEndDate: "",
        dispatchDate: "",
        dispatchName: "",
        dispatchLocation: "",
        dispatchAddress: "",
        departmentName: "",
        departmentPosition: "",
        departmentPhone: "",
        departmentCommander: "",
        departmentCommanderPosition: "",
        content: "",
        agreementEndDate: "",
        workTime: [
            {
                start: "",
                end: "",
                breakTime: [
                    {
                        start: "",
                        end: "",
                    },
                ],
            },
        ],
        baseSalary: "",
        jobSalary: "",
        allowance: [
            {
                title: "",
                value: "",
            },
        ],
        troubleDivision: "",
        troublePosition: "",
        troubleName: "",
        troublePhone: "",
        troubleDestinationDepartment: "",
        troubleDestinationDepartmentPosition: "",
        troubleDestinationDepartmentName: "",
        troubleDestinationDepartmentPhone: "",
        noRestrictionsContent: "",
        conflictDate: "",
        staffConflictDate: "",
        dispatchAmount: "",
        staffName: "",
        staffAddress: "",
        consentDate: "",
    });

    const [contractCreateData, setContractCreateData] = useState({
        contractFirstDate: null,
        contractStartDate: null,
        contractEndDate: null,
        periodFlag: false,
        updateFlag: false,
        division: "",
        category: "",
        position: "",
        trial: "",
        dispatchName: "",
        dispatchAddress: "",
        content: "",
        workTime: [
            {
                start: null,
                end: null,
                breakTime: [
                    {
                        start: null,
                        end: null,
                    },
                ],
            },
        ],
        shiftWork: false,
        flextime: false,
        baseSalary: "",
        jobSalary: "",
        allowance: [
            {
                title: "",
                value: "",
            },
        ],
        commuteFee: true,
        wageRevision: false,
        bonus: false,
        retirementFee: false,
        staffName: "",
        staffAddress: "",
        consentDate: null,
    });
    const [contractCreateDataError, setContractCreateDataError] = useState({
        contractFirstDate: "",
        contractStartDate: "",
        contractEndDate: "",
        contractDate: "",
        division: "",
        category: "",
        position: "",
        trial: "",
        dispatchName: "",
        dispatchAddress: "",
        content: "",
        workTime: [
            {
                start: "",
                end: "",
                breakTime: [
                    {
                        start: "",
                        end: "",
                    },
                ],
            },
        ],
        baseSalary: "",
        jobSalary: "",
        allowance: [
            {
                title: "",
                value: "",
            },
        ],
        staffName: "",
        staffAddress: "",
        consentDate: "",
    });

    const [staffDomitoryData, setStaffDomitoryData] = useState({
        staffDomitoryFlag: { title: "社宅利用", value: false },
        staffDomitoryName: { title: "社宅名", value: "" },
        staffDomitoryNumber: { title: "部屋番号", value: "" },
        staffDomitoryInDate: { title: "入居日", value: null },
        staffDomitoryOutDate: { title: "退去日", value: null },
    });

    const [equip, setEquip] = useState({
        staffEquipmentFlag: { title: "レンタル備品", value: true },
        staffEquipmentContractor: { title: "業者", value: "レンティック" },
    });

    const [staffEquipmentData, setStaffEquipmentData] = useState([
        {
            selected: true,
            name: "自転車",
            amount: 200,
            section: "レンタル",
            contractor: "レンティック",
            value: "1",
            inDate: "2024/1/1",
        },
        {
            selected: false,
            name: "寝具一式",
            amount: 150,
            section: "レンタル",
            contractor: "業者A",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "テレビ",
            amount: 50,
            section: "レンタル",
            contractor: "レンティック",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "電子レンジ",
            amount: 300,
            section: "レンタル",
            contractor: "業者A",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "冷蔵庫",
            amount: 100,
            section: "レンタル",
            contractor: "レンティック",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "洗濯機",
            amount: 80,
            section: "レンタル",
            contractor: "業者A",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "ガスコンロ",
            amount: 60,
            section: "レンタル",
            contractor: "レンティック",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "ローテーブル",
            amount: 120,
            section: "レンタル",
            contractor: "業者A",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "カーテン135cm",
            amount: 1800,
            section: "購入",
            contractor: "レンティック",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "カーテン200cm",
            amount: 2400,
            section: "購入",
            contractor: "業者A",
            value: "",
            inDate: null,
        },
        {
            selected: false,
            name: "レース135cm",
            amount: 1400,
            section: "購入",
            contractor: "レンティック",
            value: "",
            inDate: null,
        },
    ]);

    const [equipCalculationListData, setEquipCalculationListData] = useState([
        {
            year: "2024",
            month: "11",
            amount: "3000",
        },
    ]);
    const [equipCalculationData, setEquipCalculationData] = useState({
        year: "2024",
        month: "11",
        equipment: [
            {
                name: "自転車",
                amountFlag: true,
                amount: "30",
                value: "1",
                inDate: "2024/11/1",
                outDate: "2024/11/30",
            },
        ],
    });

    const conditionColumns = useMemo(
        () => [
            {
                header: "就業先企業",
                accessorKey: "company",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableHeadCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleConditionMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const contractColumns = useMemo(
        () => [
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleContractMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const commuteColumns = useMemo(
        () => [
            {
                header: "自宅または社宅名",
                accessorKey: "home",
                muiTableHeadCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "就業先企業",
                accessorKey: "company",
                muiTableHeadCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleCommuteMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const dormitoryApplicationColumns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "dormitory",
                muiTableHeadCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableHeadCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleDormitoryApplicationMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const dormitoryUsePledgeColumns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "dormitory",
                muiTableHeadCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableHeadCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleDormitoryUsePledgeMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const dormitoryEEPledgeColumns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "dormitory",
                muiTableHeadCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableHeadCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleDormitoryEEPledgeMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const dormitoryCheckColumns = useMemo(
        () => [
            {
                header: "社宅名",
                accessorKey: "dormitory",
                muiTableHeadCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
            },
            {
                header: "部屋番号",
                accessorKey: "number",
                muiTableHeadCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "作成日時",
                accessorKey: "createDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "同意日時",
                accessorKey: "consentDate",
                muiTableHeadCellProps: { sx: { width: "200px", minWidth: "200px" } },
                muiTableBodyCellProps: { sx: { width: "200px", minWidth: "200px" } },
            },
            {
                header: "操作",
                accessorKey: "action",
                Cell: () => (
                    <>
                        <Button color="primary" onClick={handleDormitoryCheckMenu}>
                            メニューを開く
                        </Button>
                    </>
                ),
                muiTableHeadCellProps: { sx: { width: "150px", minWidth: "150px" } },
                muiTableBodyCellProps: { sx: { width: "150px", minWidth: "150px" } },
            },
        ],
        []
    );

    const [anchorEl, setAnchorEl] = useState(null);

    const [pledgeDialog, setPledgeDialog] = useState(false);
    const [pledgeCompleteDialog, setPledgeCompleteDialog] = useState(false);
    const [conditionDialog, setConditionDialog] = useState(false);
    const [conditionMenu, setConditionMenu] = useState(false);
    const [conditionCreateDialog, setConditionCreateDialog] = useState(false);
    const [conditionCompleteDialog, setConditionCompleteDialog] = useState(false);
    const [conditionURLDialog, setConditionURLDialog] = useState(false);
    const [conditionDeleteDialog, setConditionDeleteDialog] = useState(false);
    const [conditionDeleteCompleteDialog, setConditionDeleteCompleteDialog] = useState(false);
    const [contractDialog, setContractDialog] = useState(false);
    const [contractMenu, setContractMenu] = useState(false);
    const [contractCreateDialog, setContractCreateDialog] = useState(false);
    const [contractCompleteDialog, setContractCompleteDialog] = useState(false);
    const [contractURLDialog, setContractURLDialog] = useState(false);
    const [contractDeleteDialog, setContractDeleteDialog] = useState(false);
    const [contractDeleteCompleteDialog, setContractDeleteCompleteDialog] = useState(false);
    const [personalDialog, setPersonalDialog] = useState(false);
    const [personalCompleteDialog, setPersonalCompleteDialog] = useState(false);
    const [guaranteeDialog, setGuaranteeDialog] = useState(false);
    const [guaranteeCompleteDialog, setGuaranteeCompleteDialog] = useState(false);
    const [commuteDialog, setCommuteDialog] = useState(false);
    const [commuteMenu, setCommuteMenu] = useState(false);
    const [commuteCreateDialog, setCommuteCreateDialog] = useState(false);
    const [commuteCompleteDialog, setCommuteCompleteDialog] = useState(false);
    const [commuteEditDialog, setCommuteEditDialog] = useState(false);
    const [commuteURLDialog, setCommuteURLDialog] = useState(false);
    const [commuteDeleteDialog, setCommuteDeleteDialog] = useState(false);
    const [commuteDeleteCompleteDialog, setCommuteDeleteCompleteDialog] = useState(false);
    const [dormitoryApplicationDialog, setDormitoryApplicationDialog] = useState(false);
    const [dormitoryApplicationMenu, setDormitoryApplicationMenu] = useState(false);
    const [dormitoryApplicationCompleteDialog, setDormitoryApplicationCompleteDialog] = useState(false);
    const [dormitoryApplicationURLDialog, setDormitoryApplicationURLDialog] = useState(false);
    const [dormitoryApplicationDeleteDialog, setDormitoryApplicationDeleteDialog] = useState(false);
    const [dormitoryApplicationDeleteCompleteDialog, setDormitoryApplicationDeleteCompleteDialog] = useState(false);
    const [dormitoryUsePledgeDialog, setDormitoryUsePledgeDialog] = useState(false);
    const [dormitoryUsePledgeMenu, setDormitoryUsePledgeMenu] = useState(false);
    const [dormitoryUsePledgeCompleteDialog, setDormitoryUsePledgeCompleteDialog] = useState(false);
    const [dormitoryUsePledgeURLDialog, setDormitoryUsePledgeURLDialog] = useState(false);
    const [dormitoryUsePledgeDeleteDialog, setDormitoryUsePledgeDeleteDialog] = useState(false);
    const [dormitoryUsePledgeDeleteCompleteDialog, setDormitoryUsePledgeDeleteCompleteDialog] = useState(false);
    const [dormitoryEEPledgeDialog, setDormitoryEEPledgeDialog] = useState(false);
    const [dormitoryEEPledgeMenu, setDormitoryEEPledgeMenu] = useState(false);
    const [dormitoryEEPledgeCompleteDialog, setDormitoryEEPledgeCompleteDialog] = useState(false);
    const [dormitoryEEPledgeURLDialog, setDormitoryEEPledgeURLDialog] = useState(false);
    const [dormitoryEEPledgeDeleteDialog, setDormitoryEEPledgeDeleteDialog] = useState(false);
    const [dormitoryEEPledgeDeleteCompleteDialog, setDormitoryEEPledgeDeleteCompleteDialog] = useState(false);
    const [dormitoryCheckDialog, setDormitoryCheckDialog] = useState(false);
    const [dormitoryCheckMenu, setDormitoryCheckMenu] = useState(false);
    const [dormitoryCheckCreateDialog, setDormitoryCheckCreateDialog] = useState(false);
    const [dormitoryCheckCompleteDialog, setDormitoryCheckCompleteDialog] = useState(false);
    const [dormitoryCheckEditDialog, setDormitoryCheckEditDialog] = useState(false);
    const [dormitoryCheckURLDialog, setDormitoryCheckURLDialog] = useState(false);
    const [dormitoryCheckDeleteDialog, setDormitoryCheckDeleteDialog] = useState(false);
    const [dormitoryCheckDeleteCompleteDialog, setDormitoryCheckDeleteCompleteDialog] = useState(false);
    const [dailypayDialog, setDailypayDialog] = useState(false);
    const [dailypayCompleteDialog, setDailypayCompleteDialog] = useState(false);
    const [assignedDialog, setAssignedDialog] = useState(false);
    const [assignedCompleteDialog, setAssignedCompleteDialog] = useState(false);
    const [retirementDialog, setRetirementDialog] = useState(false);
    const [retirementCompleteDialog, setRetirementCompleteDialog] = useState(false);
    const [betweenDialog, setBetweenDialog] = useState(false);
    const [betweenCompleteDialog, setBetweenCompleteDialog] = useState(false);
    const [dispatchHistoryDialog, setDispatchHistoryDialog] = useState(false);

    const [conditionValidation, setConditionValidation] = useState("");
    const [contractValidation, setContractValidation] = useState("");

    const retirementDialogOpen = () => {
        setRetirementDialog(true);
    };
    const retirementDialogClose = () => {
        setStaffMoreData({
            ...staffMoreData,
            staffRetirementDate: { ...staffMoreData.staffRetirementDate, value: null },
        });
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffRetirementDate: "",
        });
        setRetirementDialog(false);
    };
    const handleRetirementDateChange = (value) => {
        setStaffMoreData({
            ...staffMoreData,
            staffRetirementDate: { ...staffMoreData.staffRetirementDate, value: value },
        });
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffRetirementDate: value === null ? "退職日を選択してください" : "",
        });
    };
    const handleRetirementDateBlur = () => {
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffRetirementDate: staffMoreData.staffRetirementDate.value === null ? "退職日を選択してください" : "",
        });
    };
    const handleRetireExecute = () => {
        if (staffMoreData.staffRetirementDate.value === null) {
            setStaffMoreDataError({
                ...staffMoreDataError,
                staffRetirementDate: "退職日を選択してください",
            });
        } else {
            setRetirementDialog(false);
            setRetirementCompleteDialog(true);
        }
    };
    const handleretirementURLcopy = () => {
        console.log("URLをコピー処理を追加");
    };
    const retirementCompleteDialogClose = () => {
        setRetirementCompleteDialog(false);
        // 画面リロード処理を追加
        //window.location.reload();
    };

    const betweenDialogOpen = () => {
        setBetweenDialog(true);
    };
    const betweenDialogClose = () => {
        setStaffMoreData({
            ...staffMoreData,
            staffBetweenDate: { ...staffMoreData.staffBetweenDate, value: null },
            staffBetweenCompany: { ...staffMoreData.staffBetweenCompany, value: "" },
        });
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffBetweenDate: "",
            staffBetweenCompany: "",
        });
        setBetweenDialog(false);
    };
    const handleBetweenDateChange = (value) => {
        setStaffMoreData({
            ...staffMoreData,
            staffBetweenDate: { ...staffMoreData.staffBetweenDate, value: value },
        });
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffBetweenDate: value === null ? "転籍日を選択してください" : "",
        });
    };
    const handleBetweenDateBlur = () => {
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffBetweenDate: staffMoreData.staffBetweenDate.value === null ? "転籍日を選択してください" : "",
        });
    };
    const handleBetweenCompanyChange = (event) => {
        const value = event.target.value;
        setStaffMoreData({
            ...staffMoreData,
            staffBetweenCompany: { ...staffMoreData.staffBetweenCompany, value: value },
        });
        setStaffMoreDataError({
            ...staffMoreDataError,
            staffBetweenCompany: value === "" ? "転籍先を選択してください" : "",
        });
    };
    const handleBetweenExecute = () => {
        if (staffMoreData.staffBetweenDate.value !== null && staffMoreData.staffBetweenCompany.value !== "") {
            // 基本情報、緊急連絡先、配偶者、被扶養者情報・アップロード書類をコピーする処理を追加companyIDを追加することを忘れないこと
            setBetweenDialog(false);
            setBetweenCompleteDialog(true);
        } else {
            setStaffMoreDataError({
                ...staffMoreDataError,
                staffBetweenDate: staffMoreData.staffBetweenDate.value === null ? "転籍日を選択してください" : "",
                staffBetweenCompany: staffMoreData.staffBetweenCompany.value === "" ? "転籍先を選択してください" : "",
            });
        }
    };
    const handleBetweenURLcopy = () => {
        console.log("URLをコピー処理を追加");
    };
    const betweenCompleteDialogClose = () => {
        setBetweenCompleteDialog(false);
        // 画面リロード処理を追加
        //window.location.reload();
    };

    const handleDispatchHistory = () => {
        setDispatchHistoryDialog(true);
    };
    const dispatchHistoryDialogClose = () => {
        setDispatchHistoryDialog(false);
    };

    const handleDocDetail = (key) => {
        if (key === "pledge") {
            setPledgeDialog(true);
        } else if (key === "condition") {
            setConditionDialog(true);
        } else if (key === "contract") {
            setContractDialog(true);
        } else if (key === "personal") {
            setPersonalDialog(true);
        } else if (key === "guarantee") {
            setGuaranteeDialog(true);
        } else if (key === "commute") {
            setCommuteDialog(true);
        } else if (key === "dormitoryApplication") {
            setDormitoryApplicationDialog(true);
        } else if (key === "dormitoryUsePledge") {
            setDormitoryUsePledgeDialog(true);
        } else if (key === "dormitoryEEPledge") {
            setDormitoryEEPledgeDialog(true);
        } else if (key === "dormitoryCheck") {
            setDormitoryCheckDialog(true);
        } else if (key === "dailypay") {
            setDailypayDialog(true);
        } else if (key === "assigned") {
            if (!staffJoinDocData.assigned.judge) {
                setAssignedDialog(true);
            }
        }
    };

    const pledgeDialogClose = () => {
        setPledgeDialog(false);
    };
    const pledgeCompleteDialogClose = () => {
        setPledgeCompleteDialog(false);
    };

    const conditionDialogClose = () => {
        setConditionDialog(false);
    };
    const conditionMenuClose = () => {
        setAnchorEl(null);
        setConditionMenu(false);
    };
    const conditionCreateDialogClose = () => {
        setConditionCreateDialog(false);
        setConditionDialog(true);
    };
    const conditionURLDialogClose = () => {
        setConditionURLDialog(false);
        setConditionDialog(true);
    };
    const conditionDeleteDialogClose = () => {
        setConditionDeleteDialog(false);
        setConditionDialog(true);
    };
    const conditionCompleteDialogClose = () => {
        setConditionCompleteDialog(false);
    };

    const contractDialogClose = () => {
        setContractDialog(false);
    };
    const contractMenuClose = () => {
        setAnchorEl(null);
        setContractMenu(false);
    };
    const contractCreateDialogClose = () => {
        setContractCreateDialog(false);
        setContractDialog(true);
    };
    const contractURLDialogClose = () => {
        setContractURLDialog(false);
        setContractDialog(true);
    };
    const contractDeleteDialogClose = () => {
        setContractDeleteDialog(false);
        setContractDialog(true);
    };
    const contractCompleteDialogClose = () => {
        setContractCompleteDialog(false);
    };

    const personalDialogClose = () => {
        setPersonalDialog(false);
    };
    const personalCompleteDialogClose = () => {
        setPersonalCompleteDialog(false);
    };

    const guaranteeDialogClose = () => {
        setGuaranteeDialog(false);
    };
    const guaranteeCompleteDialogClose = () => {
        setGuaranteeCompleteDialog(false);
    };

    const commuteDialogClose = () => {
        setCommuteDialog(false);
    };

    const dormitoryApplicationDialogClose = () => {
        setDormitoryApplicationDialog(false);
    };
    const dormitoryApplicationMenuClose = () => {
        setAnchorEl(null);
        setDormitoryApplicationMenu(false);
    };
    const dormitoryApplicationURLDialogClose = () => {
        setDormitoryApplicationURLDialog(false);
        setDormitoryApplicationDialog(true);
    };
    const dormitoryApplicationDeleteDialogClose = () => {
        setDormitoryApplicationDeleteDialog(false);
        setDormitoryApplicationDialog(true);
    };
    const dormitoryApplicationCompleteDialogClose = () => {
        setDormitoryApplicationCompleteDialog(false);
    };

    const dormitoryUsePledgeDialogClose = () => {
        setDormitoryUsePledgeDialog(false);
    };
    const dormitoryUsePledgeMenuClose = () => {
        setAnchorEl(null);
        setDormitoryUsePledgeMenu(false);
    };
    const dormitoryUsePledgeURLDialogClose = () => {
        setDormitoryUsePledgeURLDialog(false);
        setDormitoryUsePledgeDialog(true);
    };
    const dormitoryUsePledgeDeleteDialogClose = () => {
        setDormitoryUsePledgeDeleteDialog(false);
        setDormitoryUsePledgeDialog(true);
    };
    const dormitoryUsePledgeCompleteDialogClose = () => {
        setDormitoryUsePledgeCompleteDialog(false);
    };

    const dormitoryEEPledgeDialogClose = () => {
        setDormitoryEEPledgeDialog(false);
    };
    const dormitoryEEPledgeMenuClose = () => {
        setAnchorEl(null);
        setDormitoryEEPledgeMenu(false);
    };
    const dormitoryEEPledgeURLDialogClose = () => {
        setDormitoryEEPledgeURLDialog(false);
        setDormitoryEEPledgeDialog(true);
    };
    const dormitoryEEPledgeDeleteDialogClose = () => {
        setDormitoryEEPledgeDeleteDialog(false);
        setDormitoryEEPledgeDialog(true);
    };
    const dormitoryEEPledgeCompleteDialogClose = () => {
        setDormitoryEEPledgeCompleteDialog(false);
    };

    const dormitoryCheckDialogClose = () => {
        setDormitoryCheckDialog(false);
    };
    const dormitoryCheckMenuClose = () => {
        setAnchorEl(null);
        setDormitoryCheckMenu(false);
    };
    const dormitoryCheckURLDialogClose = () => {
        setDormitoryCheckURLDialog(false);
        setDormitoryCheckDialog(true);
    };
    const dormitoryCheckDeleteDialogClose = () => {
        setDormitoryCheckDeleteDialog(false);
        setDormitoryCheckDialog(true);
    };
    const dormitoryCheckCompleteDialogClose = () => {
        setDormitoryCheckCompleteDialog(false);
    };

    const dailypayDialogClose = () => {
        setDailypayDialog(false);
    };
    const dailypayCompleteDialogClose = () => {
        setDailypayCompleteDialog(false);
    };
    const assignedDialogClose = () => {
        setAssignedDialog(false);
    };
    const assignedCompleteDialogClose = () => {
        setAssignedCompleteDialog(false);
    };

    const handlePledgeCreate = () => {
        console.log("作成処理を追加");
        setPledgeDialog(false);
        setPledgeCompleteDialog(true);
    };

    const handleConditionCreate = () => {
        setConditionDialog(false);
        setConditionCreateDialog(true);
    };
    const handleConditionMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setConditionMenu(true);
    };
    const handleConditionURL = () => {
        setAnchorEl(null);
        setConditionMenu(false);
        setConditionDialog(false);
        setConditionURLDialog(true);
    };
    const handleConditionDelete = () => {
        setAnchorEl(null);
        setConditionMenu(false);
        setConditionDialog(false);
        setConditionDeleteDialog(true);
    };
    const handleConditionDeleteComplete = () => {
        setConditionDeleteDialog(false);
        setConditionDeleteCompleteDialog(true);
    };
    const conditionDeleteCompleteDialogClose = () => {
        setConditionDeleteCompleteDialog(false);
        setConditionDialog(true);
    };

    const handleContractCreate = () => {
        setContractDialog(false);
        setContractCreateDialog(true);
    };
    const handleContractMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setContractMenu(true);
    };
    const handleContractURL = () => {
        setAnchorEl(null);
        setContractMenu(false);
        setContractDialog(false);
        setContractURLDialog(true);
    };
    const handleContractDelete = () => {
        setAnchorEl(null);
        setContractMenu(false);
        setContractDialog(false);
        setContractDeleteDialog(true);
    };
    const handleContractDeleteComplete = () => {
        setContractDeleteDialog(false);
        setContractDeleteCompleteDialog(true);
    };
    const contractDeleteCompleteDialogClose = () => {
        setContractDeleteCompleteDialog(false);
        setContractDialog(true);
    };

    const handlePersonalCreate = () => {
        console.log("作成処理を追加");
        setPersonalDialog(false);
        setPersonalCompleteDialog(true);
    };
    const handleGuaranteeCreate = () => {
        console.log("作成処理を追加");
        setGuaranteeDialog(false);
        setGuaranteeCompleteDialog(true);
    };

    const handleCommuteCreate = () => {
        setCommuteDialog(false);
        setCommuteCreateDialog(true);
    };
    const handleCommuteMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setCommuteMenu(true);
    };
    const handleCommuteEdit = () => {
        setAnchorEl(null);
        setCommuteMenu(false);
        setCommuteDialog(false);
        setCommuteEditDialog(true);
    };
    const handleCommuteURL = () => {
        setAnchorEl(null);
        setCommuteMenu(false);
        setCommuteDialog(false);
        setCommuteURLDialog(true);
    };
    const handleCommuteDelete = () => {
        setAnchorEl(null);
        setCommuteMenu(false);
        setCommuteDialog(false);
        setCommuteDeleteDialog(true);
    };
    const handleCommuteDeleteComplete = () => {
        setCommuteDeleteDialog(false);
        setCommuteDeleteCompleteDialog(true);
    };
    const commuteDeleteCompleteDialogClose = () => {
        setCommuteDeleteCompleteDialog(false);
        setCommuteDialog(true);
    };

    const handleDormitoryApplicationCreate = () => {
        setDormitoryApplicationDialog(false);
        setDormitoryApplicationCompleteDialog(true);
    };
    const handleDormitoryApplicationMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDormitoryApplicationMenu(true);
    };
    const handleDormitoryApplicationURL = () => {
        setAnchorEl(null);
        setDormitoryApplicationMenu(false);
        setDormitoryApplicationDialog(false);
        setDormitoryApplicationURLDialog(true);
    };
    const handleDormitoryApplicationDelete = () => {
        setAnchorEl(null);
        setDormitoryApplicationMenu(false);
        setDormitoryApplicationDialog(false);
        setDormitoryApplicationDeleteDialog(true);
    };
    const handleDormitoryApplicationDeleteComplete = () => {
        setDormitoryApplicationDeleteDialog(false);
        setDormitoryApplicationDeleteCompleteDialog(true);
    };
    const dormitoryApplicationDeleteCompleteDialogClose = () => {
        setDormitoryApplicationDeleteCompleteDialog(false);
        setDormitoryApplicationDialog(true);
    };

    const handleDormitoryUsePledgeCreate = () => {
        setDormitoryUsePledgeDialog(false);
        setDormitoryUsePledgeCompleteDialog(true);
    };
    const handleDormitoryUsePledgeMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDormitoryUsePledgeMenu(true);
    };
    const handleDormitoryUsePledgeURL = () => {
        setAnchorEl(null);
        setDormitoryUsePledgeMenu(false);
        setDormitoryUsePledgeDialog(false);
        setDormitoryUsePledgeURLDialog(true);
    };
    const handleDormitoryUsePledgeDelete = () => {
        setAnchorEl(null);
        setDormitoryUsePledgeMenu(false);
        setDormitoryUsePledgeDialog(false);
        setDormitoryUsePledgeDeleteDialog(true);
    };
    const handleDormitoryUsePledgeDeleteComplete = () => {
        setDormitoryUsePledgeDeleteDialog(false);
        setDormitoryUsePledgeDeleteCompleteDialog(true);
    };
    const dormitoryUsePledgeDeleteCompleteDialogClose = () => {
        setDormitoryUsePledgeDeleteCompleteDialog(false);
        setDormitoryUsePledgeDialog(true);
    };

    const handleDormitoryEEPledgeCreate = () => {
        setDormitoryEEPledgeDialog(false);
        setDormitoryEEPledgeCompleteDialog(true);
    };
    const handleDormitoryEEPledgeMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDormitoryEEPledgeMenu(true);
    };
    const handleDormitoryEEPledgeURL = () => {
        setAnchorEl(null);
        setDormitoryEEPledgeMenu(false);
        setDormitoryEEPledgeDialog(false);
        setDormitoryEEPledgeURLDialog(true);
    };
    const handleDormitoryEEPledgeDelete = () => {
        setAnchorEl(null);
        setDormitoryEEPledgeMenu(false);
        setDormitoryEEPledgeDialog(false);
        setDormitoryEEPledgeDeleteDialog(true);
    };
    const handleDormitoryEEPledgeDeleteComplete = () => {
        setDormitoryEEPledgeDeleteDialog(false);
        setDormitoryEEPledgeDeleteCompleteDialog(true);
    };
    const dormitoryEEPledgeDeleteCompleteDialogClose = () => {
        setDormitoryEEPledgeDeleteCompleteDialog(false);
        setDormitoryEEPledgeDialog(true);
    };

    const handleDormitoryCheckCreate = () => {
        setDormitoryCheckDialog(false);
        setDormitoryCheckCreateDialog(true);
    };
    const handleDormitoryCheckMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDormitoryCheckMenu(true);
    };
    const handleDormitoryCheckEdit = () => {
        setAnchorEl(null);
        setDormitoryCheckMenu(false);
        setDormitoryCheckDialog(false);
        setDormitoryCheckEditDialog(true);
    };
    const handleDormitoryCheckURL = () => {
        setAnchorEl(null);
        setDormitoryCheckMenu(false);
        setDormitoryCheckDialog(false);
        setDormitoryCheckURLDialog(true);
    };
    const handleDormitoryCheckDelete = () => {
        setAnchorEl(null);
        setDormitoryCheckMenu(false);
        setDormitoryCheckDialog(false);
        setDormitoryCheckDeleteDialog(true);
    };
    const handleDormitoryCheckDeleteComplete = () => {
        setDormitoryCheckDeleteDialog(false);
        setDormitoryCheckDeleteCompleteDialog(true);
    };
    const dormitoryCheckDeleteCompleteDialogClose = () => {
        setDormitoryCheckDeleteCompleteDialog(false);
        setDormitoryCheckDialog(true);
    };

    const handleDailypayCreate = () => {
        console.log("作成処理を追加");
        setDailypayDialog(false);
        setDailypayCompleteDialog(true);
    };

    const handleassignedSwitched = () => {
        setStaffJoinDocData({
            ...staffJoinDocData,
            assigned: { ...staffJoinDocData.assigned, judge: true, receiptDate: new Date().toISOString() },
        });
        console.log("切り替え処理を追加");

        setAssignedDialog(false);
        setAssignedCompleteDialog(true);
    };

    const handleConditionDispatchNameChange = (event) => {
        setConditionCreateData({
            ...conditionCreateData,
            dispatchName: event.target.value,
        });
    };
    const handleConditionDispatchNameBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            dispatchName: conditionCreateData.dispatchName === "" ? "派遣先企業を選択してください" : "",
        });
    };
    const handleConditionDispatchNameClear = () => {
        setConditionCreateData({
            ...conditionCreateData,
            dispatchName: "",
        });
    };

    const handleConditionContractStartDateChange = (value) => {
        setConditionCreateData({
            ...conditionCreateData,
            contractStartDate: value,
        });
        setConditionCreateDataError({
            ...conditionCreateDataError,
            contractStartDate: "",
            contractDate: "",
        });
    };
    const handleConditionContractStartDateBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            contractStartDate: conditionCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "",
            contractDate: conditionCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "",
        });
    };

    const handleConditionContractEndDateChange = (value) => {
        setConditionCreateData({
            ...conditionCreateData,
            contractEndDate: value,
        });
        setConditionCreateDataError({
            ...conditionCreateDataError,
            contractEndDate: "",
            contractDate: "",
        });
    };
    const handleConditionContractEndDateBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            contractEndDate: conditionCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "",
            contractDate: conditionCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "",
        });
    };

    const handleConditionPeriodFlagChange = (event) => {
        const value = event.target.value === "true";
        setConditionCreateData({
            ...conditionCreateData,
            periodFlag: value,
        });
    };

    const handleConditionUpdateFlagChange = (event) => {
        const value = event.target.value === "true";
        setConditionCreateData({
            ...conditionCreateData,
            updateFlag: value,
        });
    };

    const handleConditionDispatchStartDateChange = (value) => {
        setConditionCreateData({
            ...conditionCreateData,
            dispatchStartDate: value,
        });
        setConditionCreateDataError({
            ...conditionCreateDataError,
            dispatchStartDate: "",
            dispatchDate: "",
        });
    };
    const handleConditionDispatchStartDateBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            dispatchStartDate: conditionCreateData.dispatchStartDate === null ? "派遣期間を選択してください" : "",
            dispatchDate: conditionCreateData.dispatchStartDate === null ? "派遣期間を選択してください" : "",
        });
    };

    const handleConditionDispatchEndDateChange = (value) => {
        setConditionCreateData({
            ...conditionCreateData,
            dispatchEndDate: value,
        });
        setConditionCreateDataError({
            ...conditionCreateDataError,
            dispatchEndDate: "",
            dispatchDate: "",
        });
    };
    const handleConditionDispatchEndDateBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            dispatchEndDate: conditionCreateData.dispatchEndDate === null ? "派遣期間を選択してください" : "",
            dispatchDate: conditionCreateData.dispatchEndDate === null ? "派遣期間を選択してください" : "",
        });
    };

    const handleConditionCommuteFeeChange = (event) => {
        setConditionCreateData({
            ...conditionCreateData,
            commuteFee: event.target.value,
        });
    };

    const handleConditionWageRevisionChange = (event) => {
        setConditionCreateData({
            ...conditionCreateData,
            wageRevision: event.target.value,
        });
    };

    const handleConditionBonusChange = (event) => {
        setConditionCreateData({
            ...conditionCreateData,
            bonus: event.target.value,
        });
    };

    const handleConditionRetirementFeeChange = (event) => {
        setConditionCreateData({
            ...conditionCreateData,
            retirementFee: event.target.value,
        });
    };

    const handleConditionConsentDateChange = (value) => {
        setConditionCreateData({
            ...conditionCreateData,
            consentDate: value,
        });
        setConditionCreateDataError({
            ...conditionCreateDataError,
            consentDate: "",
        });
    };
    const handleConditionConsentDateBlur = () => {
        setConditionCreateDataError({
            ...conditionCreateDataError,
            consentDate: conditionCreateData.consentDate === null ? "作成日を選択してください" : "",
        });
    };

    const handleConditionCreateComplete = () => {
        // conditionCreateDataError.dispatchName = conditionCreateData.dispatchName === "" ? "派遣先企業を選択してください" : "";
        conditionCreateDataError.contractStartDate =
            conditionCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "";
        conditionCreateDataError.contractEndDate =
            conditionCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "";
        conditionCreateDataError.contractDate =
            conditionCreateData.contractStartDate === null && conditionCreateData.contractEndDate === null
                ? "雇用契約期間を選択してください"
                : "";
        conditionCreateDataError.dispatchStartDate =
            conditionCreateData.dispatchStartDate === null ? "派遣期間を選択してください" : "";
        conditionCreateDataError.dispatchEndDate =
            conditionCreateData.dispatchEndDate === null ? "派遣期間を選択してください" : "";
        conditionCreateDataError.dispatchDate =
            conditionCreateData.dispatchStartDate === null && conditionCreateData.dispatchEndDate === null
                ? "派遣期間を選択してください"
                : "";
        conditionCreateDataError.consentDate =
            conditionCreateData.consentDate === null ? "作成日を選択してください" : "";

        if (
            // conditionCreateDataError.dispatchName === "" &&
            conditionCreateDataError.contractStartDate === "" &&
            conditionCreateDataError.contractEndDate === "" &&
            conditionCreateDataError.contractDate === "" &&
            conditionCreateDataError.dispatchStartDate === "" &&
            conditionCreateDataError.dispatchEndDate === "" &&
            conditionCreateDataError.dispatchDate === "" &&
            conditionCreateDataError.consentDate === ""
        ) {
            setConditionValidation("");
            setConditionCreateDialog(false);
            setConditionCompleteDialog(true);
        } else {
            setConditionValidation("入力内容に不備があります");
        }
    };

    const handleContractDispatchNameChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            dispatchName: event.target.value,
        });
    };
    const handleContractDispatchNameBlur = () => {
        setContractCreateDataError({
            ...contractCreateDataError,
            dispatchName: contractCreateData.dispatchName === "" ? "派遣先企業を選択してください" : "",
        });
    };
    const handleContractDispatchNameClear = () => {
        setContractCreateData({
            ...contractCreateData,
            dispatchName: "",
        });
    };

    const handleContractContractStartDateChange = (value) => {
        setContractCreateData({
            ...contractCreateData,
            contractStartDate: value,
        });
        setContractCreateDataError({
            ...contractCreateDataError,
            contractStartDate: "",
            contractDate: "",
        });
    };
    const handleContractContractStartDateBlur = () => {
        setContractCreateDataError({
            ...contractCreateDataError,
            contractStartDate: contractCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "",
            contractDate: contractCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "",
        });
    };

    const handleContractContractEndDateChange = (value) => {
        setContractCreateData({
            ...contractCreateData,
            contractEndDate: value,
        });
        setContractCreateDataError({
            ...contractCreateDataError,
            contractEndDate: "",
            contractDate: "",
        });
    };
    const handleContractContractEndDateBlur = () => {
        setContractCreateDataError({
            ...contractCreateDataError,
            contractEndDate: contractCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "",
            contractDate: contractCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "",
        });
    };

    const handleContractPeriodFlagChange = (event) => {
        const value = event.target.value === "true";
        setContractCreateData({
            ...contractCreateData,
            periodFlag: value,
        });
    };

    const handleContractUpdateFlagChange = (event) => {
        const value = event.target.value === "true";
        setContractCreateData({
            ...contractCreateData,
            updateFlag: value,
        });
    };

    const handleContractTrialChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            trial: event.target.value,
        });
    };

    const handleContractCommuteFeeChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            commuteFee: event.target.value,
        });
    };

    const handleContractWageRevisionChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            wageRevision: event.target.value,
        });
    };

    const handleContractBonusChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            bonus: event.target.value,
        });
    };

    const handleContractRetirementFeeChange = (event) => {
        setContractCreateData({
            ...contractCreateData,
            retirementFee: event.target.value,
        });
    };

    const handleContractConsentDateChange = (value) => {
        setContractCreateData({
            ...contractCreateData,
            consentDate: value,
        });
        setContractCreateDataError({
            ...contractCreateDataError,
            consentDate: "",
        });
    };
    const handleContractConsentDateBlur = () => {
        setContractCreateDataError({
            ...contractCreateDataError,
            consentDate: contractCreateData.consentDate === null ? "作成日を選択してください" : "",
        });
    };

    const handleContractCreateComplete = () => {
        // contractCreateDataError.dispatchName = contractCreateData.dispatchName === "" ? "派遣先企業を選択してください" : "";
        contractCreateDataError.contractStartDate =
            contractCreateData.contractStartDate === null ? "雇用契約期間を選択してください" : "";
        contractCreateDataError.contractEndDate =
            contractCreateData.contractEndDate === null ? "雇用契約期間を選択してください" : "";
        contractCreateDataError.contractDate =
            contractCreateData.contractStartDate === null && contractCreateData.contractEndDate === null
                ? "雇用契約期間を選択してください"
                : "";
        contractCreateDataError.trial = contractCreateData.trial === "" ? "試用期間を選択してください" : "";
        contractCreateDataError.consentDate = contractCreateData.consentDate === null ? "作成日を選択してください" : "";

        if (
            // contractCreateDataError.dispatchName === "" &&
            contractCreateDataError.contractStartDate === "" &&
            contractCreateDataError.contractEndDate === "" &&
            contractCreateDataError.contractDate === "" &&
            contractCreateDataError.consentDate === ""
        ) {
            setContractValidation("");
            setContractCreateDialog(false);
            setContractCompleteDialog(true);
        } else {
            setContractValidation("入力内容に不備があります");
        }
    };

    const [dormitoryCalculationDialog, setDormitoryCalculationDialog] = useState(false);
    const handleDormitoryCalculationDialogOpen = () => {
        setDormitoryCalculationDialog(true);
    };
    const handleDormitoryCalculationDialogClose = () => {
        setDormitoryCalculationDialog(false);
    };

    const [equipCalculationDialog, setEquipCalculationDialog] = useState(false);
    const handleEquipCalculationDialogOpen = () => {
        setEquipCalculationDialog(true);
    };
    const handleEquipCalculationDialogClose = () => {
        setEquipCalculationDialog(false);
    };

    const handleEquipCalculationListChange = (event) => {
        setEquipCalculationListData({
            ...equipCalculationListData,
            year: event.target.value,
        });
    };

    const [equipCalculationCreateDialog, setEquipCalculationCreateDialog] = useState(false);
    const [equipCalculationCreateCompleteDialog, setEquipCalculationCreateCompleteDialog] = useState(false);
    const handleEquipCalculationCreateDialogOpen = () => {
        setEquipCalculationDialog(false);
        setEquipCalculationCreateDialog(true);
    };
    const handleEquipCalculationCreateDialogClose = () => {
        setEquipCalculationCreateDialog(false);
        setEquipCalculationDialog(true);
    };

    const handleEquipCalculationCreate = () => {
        setEquipCalculationCreateDialog(false);
        setEquipCalculationCreateCompleteDialog(true);
    };
    const handleEquipCalculationCreateCompleteDialogClose = () => {
        setEquipCalculationCreateCompleteDialog(false);
        setEquipCalculationDialog(true);
    };

    const [equipCalculationDetailDialog, setEquipCalculationDetailDialog] = useState(false);
    const handleEquipCalculationDetailDialogOpen = () => {
        setEquipCalculationDialog(false);
        setEquipCalculationDetailDialog(true);
    };
    const handleEquipCalculationDetailDialogClose = () => {
        setEquipCalculationDetailDialog(false);
        setEquipCalculationDialog(true);
    };
    const handleEquipCalculationDetail = () => {
        setEquipCalculationDialog(true);
    };

    const handleEdit = () => {
        window.scrollTo(0, 0);
        navigate("/staff/detail/edit");
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/staff");
    };

    return (
        <>
            <Helmet>
                <title>スタッフ情報 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">スタッフ情報</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleEdit}>
                                スタッフ情報を編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3, mb: 3 }}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label="基本情報" />
                            <Tab label="アップロード書類" />
                            <Tab label="入社書類" />
                            <Tab label="社宅・備品" />
                        </Tabs>
                    </Box>

                    {tab === 0 ? (
                        <>
                            {Object.entries(staffData).map(([key, item]) => (
                                <Box key={key}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{item.title}</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            {key === "staffAddress" ? (
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {"〒" + item.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                    <br />
                                                    {item.prefecture}
                                                    {item.city}
                                                    {item.other}
                                                    <br />
                                                    {item.building}
                                                </Typography>
                                            ) : key === "staffResidentAddress" ? (
                                                item.ibid ? (
                                                    <Typography textAlign="left">同上</Typography>
                                                ) : (
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {"〒" + item.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                        <br />
                                                        {item.prefecture}
                                                        {item.city}
                                                        {item.other}
                                                        <br />
                                                        {item.building}
                                                    </Typography>
                                                )
                                            ) : key === "staffNationality" ? (
                                                <Typography textAlign="left">
                                                    {item.select === "japanese" ? "日本" : item.value}
                                                </Typography>
                                            ) : key === "staffGender" ? (
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {item.value === "male" ? "男性" : "女性"}
                                                </Typography>
                                            ) : key === "staffBirthday" ? (
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {new Date(item.value).toLocaleDateString("ja-JP")} (
                                                    {Math.floor(
                                                        (new Date() - new Date(item.value)) /
                                                            (365.25 * 24 * 60 * 60 * 1000)
                                                    )}
                                                    歳)
                                                </Typography>
                                            ) : key === "staffBank" ? (
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {item.bankName}
                                                    {item.select === "bank" ? "銀行" : "信用金庫"}
                                                    <br />
                                                    {item.branch}支店
                                                    <br />
                                                    普通口座 {item.number}
                                                    <br />
                                                    {item.holder}
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ textAlign: "left" }}>{item.value}</Typography>
                                            )}
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}

                            <Typography fontWeight="bold" textAlign="center" sx={{ mt: 5, mb: 2 }}>
                                詳細情報
                            </Typography>
                            {Object.entries(staffMoreData)
                                .slice(0, 8)
                                .map(([key, item]) => (
                                    <Box key={key}>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 5, md: 5 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">{item.title}</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 7, md: 7 }}
                                                sx={{ display: "flex", alignItems: "center" }}
                                            >
                                                {key === "staffConflictDate" ? (
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {item.value === null ? "なし" : item.value}
                                                    </Typography>
                                                ) : key === "staffAgreementFlag" ? (
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {item.value ? "該当する" : "該当しない"}
                                                    </Typography>
                                                ) : key === "staffDispatch" ? (
                                                    <>
                                                        <Grid2 size={{ xs: 5, md: 5 }}>
                                                            <Typography sx={{ textAlign: "left" }}>
                                                                {item.value === "" ? "なし" : item.value}
                                                                <br />
                                                                {staffMoreData.staffDispatch.date !== null
                                                                    ? new Date(
                                                                          staffMoreData.staffDispatch.date
                                                                      ).toLocaleDateString("ja-JP")
                                                                    : ""}
                                                            </Typography>
                                                        </Grid2>
                                                        <Grid2>
                                                            {staffMoreData.staffDispatchHistory.value.length <
                                                            1 ? null : (
                                                                <Button
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={handleDispatchHistory}
                                                                >
                                                                    <Typography variant="body2">
                                                                        就業履歴を見る
                                                                    </Typography>
                                                                </Button>
                                                            )}
                                                        </Grid2>
                                                    </>
                                                ) : (
                                                    <Typography sx={{ textAlign: "left" }}>{item.value}</Typography>
                                                )}
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </Box>
                                ))}

                            <Typography fontWeight="bold" textAlign="center" sx={{ mt: 5, mb: 2 }}>
                                緊急連絡先
                            </Typography>
                            {Object.entries(emergencyContactData).map(([key, item]) => (
                                <Box key={key}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{item.title}</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            {key === "emergencyContactAddress" ? (
                                                <>
                                                    <Typography sx={{ textAlign: "left" }}>
                                                        {"〒" + item.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                        <br />
                                                        {item.prefecture}
                                                        {item.city}
                                                        {item.other}
                                                        <br />
                                                        {item.building}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography sx={{ textAlign: "left" }}>{item.value}</Typography>
                                            )}
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}

                            <Box sx={{ mt: 5 }}>
                                {spouseData.spouseFlag.value === true ? (
                                    <>
                                        <Typography fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
                                            配偶者
                                        </Typography>
                                        {Object.entries(spouseData)
                                            .slice(1, 8)
                                            .map(([key, item]) => (
                                                <Box key={key}>
                                                    <Grid2 container spacing={2}>
                                                        <Grid2
                                                            size={{ xs: 5, md: 5 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {key === "spouseAddress" &&
                                                            spouseData.spouseTogether.value === true ? null : (
                                                                <Typography fontWeight="bold">{item.title}</Typography>
                                                            )}
                                                        </Grid2>
                                                        <Grid2
                                                            size={{ xs: 7, md: 7 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {key === "spouseBirthday" ? (
                                                                <Typography>
                                                                    {new Date(item.value).toLocaleDateString("ja-JP")} (
                                                                    {Math.floor(
                                                                        (new Date() - new Date(item.value)) /
                                                                            (365.25 * 24 * 60 * 60 * 1000)
                                                                    )}
                                                                    歳)
                                                                </Typography>
                                                            ) : key === "spouseGender" ? (
                                                                <Typography>
                                                                    {item.select === "male" ? "男性" : "女性"}
                                                                </Typography>
                                                            ) : key === "spouseTogether" ? (
                                                                <Typography>
                                                                    {item.value === true ? "同居" : "別居"}
                                                                </Typography>
                                                            ) : key === "spouseAddress" ? (
                                                                <>
                                                                    {spouseData.spouseTogether.value ? null : (
                                                                        <Typography sx={{ textAlign: "left" }}>
                                                                            {"〒" +
                                                                                item.post.replace(
                                                                                    /(\d{3})(?=\d)/,
                                                                                    "$1-"
                                                                                )}
                                                                            <br />
                                                                            {item.prefecture}
                                                                            {item.city}
                                                                            {item.other}
                                                                            <br />
                                                                            {item.building}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            ) : key === "spouseNationality" ? (
                                                                <Typography>
                                                                    {item.select === "japanese" ? "日本" : item.value}
                                                                </Typography>
                                                            ) : (
                                                                <Typography>{item.value}</Typography>
                                                            )}
                                                        </Grid2>
                                                    </Grid2>
                                                    {key === "spouseTogether" &&
                                                    spouseData.spouseTogether.value === true ? null : (
                                                        <Divider sx={{ my: 1 }} />
                                                    )}
                                                </Box>
                                            ))}
                                    </>
                                ) : (
                                    <>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 5, md: 5 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">配偶者</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 7, md: 7 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography>なし</Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </>
                                )}
                            </Box>

                            <Box sx={{ mt: 5 }}>
                                {dependentData.dependentFlag.value === true ? (
                                    <>
                                        <Typography fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
                                            被扶養者届
                                        </Typography>
                                        {dependentData.dependentSpouseFlag.value === false ? (
                                            <>
                                                <Grid2 container spacing={2}>
                                                    <Grid2
                                                        size={{ xs: 5, md: 5 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">配偶者</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 7, md: 7 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography>なし</Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                            </>
                                        ) : (
                                            <>
                                                {Object.entries(dependentData)
                                                    .slice(1, 10)
                                                    .map(([key, item]) => (
                                                        <Box key={key}>
                                                            <Grid2 container spacing={2}>
                                                                <Grid2
                                                                    size={{ xs: 5, md: 5 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {key === "dependentSpouseDate" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                        "非該当" ? (
                                                                            <Typography fontWeight="bold">
                                                                                被扶養者でなくなった日
                                                                            </Typography>
                                                                        ) : dependentData.dependentSpouseItem.value ===
                                                                          "変更" ? null : (
                                                                            <Typography fontWeight="bold">
                                                                                {item.title}
                                                                            </Typography>
                                                                        )
                                                                    ) : key === "dependentSpouseBusiness" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography fontWeight="bold">
                                                                                {item.title}
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : key === "dependentSpouseIncome" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography fontWeight="bold">
                                                                                {item.title}
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : (
                                                                        <Typography fontWeight="bold">
                                                                            {item.title}
                                                                        </Typography>
                                                                    )}
                                                                </Grid2>
                                                                <Grid2
                                                                    size={{ xs: 7, md: 7 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {key === "dependentSpouseFlag" ? (
                                                                        <Typography>
                                                                            {item.value === true ? "有" : "無"}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseMarried" ? (
                                                                        <Typography>
                                                                            {item.value === true ? "有" : "無"}
                                                                        </Typography>
                                                                    ) : key === "dependentSpousePhone" ? (
                                                                        <Typography>
                                                                            {item.select === "home"
                                                                                ? "自宅"
                                                                                : item.select === "mobile"
                                                                                ? "携帯"
                                                                                : item.select === "work"
                                                                                ? "勤務先"
                                                                                : "その他"}{" "}
                                                                            {item.value}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseDate" ? (
                                                                        <Typography>
                                                                            {item.value === null ? "" : item.value}
                                                                        </Typography>
                                                                    ) : key === "dependentSpouseReason" ? (
                                                                        <>
                                                                            <Typography>
                                                                                {item.value}
                                                                                <br />
                                                                                {item.value === "その他"
                                                                                    ? `（${item.note}）`
                                                                                    : null}
                                                                                {item.value === "死亡"
                                                                                    ? item.deadDate === null
                                                                                        ? ""
                                                                                        : item.deadDate.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )
                                                                                    : null}
                                                                            </Typography>
                                                                        </>
                                                                    ) : key === "dependentSpouseBusiness" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <>
                                                                                <Typography>{item.value}</Typography>
                                                                                <Typography>
                                                                                    {item.value === "その他"
                                                                                        ? `（${item.note}）`
                                                                                        : null}
                                                                                </Typography>
                                                                            </>
                                                                        ) : null
                                                                    ) : key === "dependentSpouseIncome" ? (
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "該当" ||
                                                                        dependentData.dependentSpouseItem.value ===
                                                                            "変更" ? (
                                                                            <Typography>
                                                                                {item.value
                                                                                    .toString()
                                                                                    .replace(
                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                        ","
                                                                                    )}
                                                                                円
                                                                            </Typography>
                                                                        ) : null
                                                                    ) : (
                                                                        <Typography>{item.value}</Typography>
                                                                    )}
                                                                </Grid2>
                                                            </Grid2>
                                                            {key === "dependentSpouseDate" ? (
                                                                dependentData.dependentSpouseDate.value ===
                                                                null ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : key === "dependentSpouseBusiness" ? (
                                                                dependentData.dependentSpouseBusiness.value ===
                                                                "" ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : key === "dependentSpouseIncome" ? (
                                                                dependentData.dependentSpouseIncome.value ===
                                                                "" ? null : (
                                                                    <Divider sx={{ my: 1 }} />
                                                                )
                                                            ) : (
                                                                <Divider sx={{ my: 1 }} />
                                                            )}
                                                        </Box>
                                                    ))}
                                                <Grid2 container spacing={2}>
                                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                                        <Typography fontWeight="bold" textAlign="right">
                                                            海外特例要件
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {dependentData.dependentSpouseOverseas.value === ""
                                                                ? "なし"
                                                                : dependentData.dependentSpouseOverseas.value}
                                                        </Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                                {(dependentData.dependentSpouseOverseas.value === "該当" ||
                                                    dependentData.dependentSpouseOverseas.value === "非該当") && (
                                                    <>
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {dependentData.dependentSpouseOverseas.value ===
                                                                    "該当"
                                                                        ? "該当した日"
                                                                        : "非該当になった日"}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                <Typography>
                                                                    {dependentData.dependentSpouseOverseas.value ===
                                                                        "該当" ||
                                                                    dependentData.dependentSpouseOverseas.value ===
                                                                        "非該当"
                                                                        ? dependentData.dependentSpouseDate.value ===
                                                                          null
                                                                            ? ""
                                                                            : dependentData.dependentSpouseDate.value.toLocaleDateString(
                                                                                  "ja-JP"
                                                                              )
                                                                        : null}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {dependentData.dependentSpouseOverseasReason.title}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                {dependentData.dependentSpouseOverseasReason.value ===
                                                                "その他" ? (
                                                                    <>
                                                                        <Typography>
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.value
                                                                            }
                                                                        </Typography>
                                                                        <Typography>
                                                                            (
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.note
                                                                            }
                                                                            )
                                                                        </Typography>
                                                                    </>
                                                                ) : dependentData.dependentSpouseOverseasReason
                                                                      .value === "国内転入" ? (
                                                                    <>
                                                                        <Typography>
                                                                            {
                                                                                dependentData
                                                                                    .dependentSpouseOverseasReason.value
                                                                            }
                                                                        </Typography>
                                                                        <Typography>
                                                                            (
                                                                            {dependentData.dependentSpouseOverseasReason
                                                                                .date === null
                                                                                ? null
                                                                                : dependentData.dependentSpouseOverseasReason.date.toLocaleDateString(
                                                                                      "ja-JP"
                                                                                  )}
                                                                            )
                                                                        </Typography>
                                                                    </>
                                                                ) : (
                                                                    <Typography>
                                                                        {
                                                                            dependentData.dependentSpouseOverseasReason
                                                                                .value
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />
                                                    </>
                                                )}
                                                {Object.entries(dependentData)
                                                    .slice(13, 18)
                                                    .map(([key, item]) => (
                                                        <Box key={key}>
                                                            <Grid2 container spacing={2}>
                                                                <Grid2
                                                                    size={{ xs: 5, md: 5 }}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Typography fontWeight="bold">
                                                                        {item.title}
                                                                    </Typography>
                                                                </Grid2>
                                                                <Grid2 size={{ xs: 7, md: 7 }}>
                                                                    <Typography textAlign="left">
                                                                        {item.value === ""
                                                                            ? "未アップロード"
                                                                            : "アップロード済み"}
                                                                    </Typography>
                                                                </Grid2>
                                                            </Grid2>
                                                            <Divider sx={{ my: 1 }} />
                                                        </Box>
                                                    ))}
                                            </>
                                        )}
                                        {dependentOtherFlag.value === false ? (
                                            <>
                                                <Grid2 container spacing={2} sx={{ mt: 3 }}>
                                                    <Grid2
                                                        size={{ xs: 5, md: 5 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">被扶養者</Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 7, md: 7 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography>なし</Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                            </>
                                        ) : (
                                            <>
                                                {dependentOtherData.map((item, i) => (
                                                    <Box key={i}>
                                                        <Grid2 container spacing={2} sx={{ mt: 3, mb: 2 }}>
                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {"被扶養者 (" + (i + 1) + ")"}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>

                                                        {Object.entries(item)
                                                            .slice(0, 13)
                                                            .map(([key, value]) => (
                                                                <Box key={key}>
                                                                    <Grid2 container spacing={2}>
                                                                        <Grid2
                                                                            size={{ xs: 5, md: 5 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {key === "dependentOtherDate" ? (
                                                                                item.dependentOtherItem.value ===
                                                                                "非該当" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        被扶養者でなくなった日
                                                                                    </Typography>
                                                                                ) : item.dependentOtherItem.value ===
                                                                                  "変更" ? null : (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                )
                                                                            ) : key === "dependentOtherAddress" ? (
                                                                                item.dependentOtherTogether.value ===
                                                                                false ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherBusiness" ? (
                                                                                item.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                item.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherIncome" ? (
                                                                                item.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                item.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography fontWeight="bold">
                                                                                        {value.title}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : (
                                                                                <Typography fontWeight="bold">
                                                                                    {value.title}
                                                                                </Typography>
                                                                            )}
                                                                        </Grid2>
                                                                        <Grid2
                                                                            size={{ xs: 7, md: 7 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {key === "dependentOtherGender" ? (
                                                                                <Typography>
                                                                                    {value.value === "male"
                                                                                        ? "男性"
                                                                                        : "女性"}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherBirthday" ? (
                                                                                <Typography>
                                                                                    {value.value === null
                                                                                        ? ""
                                                                                        : value.value.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherRelationship" ? (
                                                                                value.value === "その他" ? (
                                                                                    <>
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                            <br />({value.note})
                                                                                        </Typography>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography>
                                                                                        {value.value}
                                                                                    </Typography>
                                                                                )
                                                                            ) : key === "dependentOtherTogether" ? (
                                                                                <Typography>
                                                                                    {value.value === true
                                                                                        ? "同居"
                                                                                        : "別居"}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherAddress" ? (
                                                                                item.dependentOtherTogether.value ===
                                                                                false ? (
                                                                                    <Typography
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        {"〒" +
                                                                                            value.post.replace(
                                                                                                /(\d{3})(?=\d)/,
                                                                                                "$1-"
                                                                                            )}
                                                                                        <br />
                                                                                        {value.prefecture}
                                                                                        {value.city}
                                                                                        {value.other}
                                                                                        <br />
                                                                                        {value.building}
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : key === "dependentOtherDate" ? (
                                                                                <Typography>
                                                                                    {value.value === null
                                                                                        ? ""
                                                                                        : value.value.toLocaleDateString(
                                                                                              "ja-JP"
                                                                                          )}
                                                                                </Typography>
                                                                            ) : key === "dependentOtherReason" ? (
                                                                                <>
                                                                                    {value.value === "死亡" ? (
                                                                                        value.deadDate === null ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                死亡
                                                                                                <br />
                                                                                                {value.deadDate.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : value.value === "その他" ? (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                            <br />({value.note})
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                        </Typography>
                                                                                    )}
                                                                                </>
                                                                            ) : key === "dependentOtherBusiness" ? (
                                                                                item.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                item.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <>
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                            <br />
                                                                                            {value.value ===
                                                                                            "高・大学生"
                                                                                                ? `（${value.note}年生）`
                                                                                                : null}
                                                                                        </Typography>
                                                                                    </>
                                                                                ) : null
                                                                            ) : key === "dependentOtherIncome" ? (
                                                                                item.dependentOtherItem.value ===
                                                                                    "該当" ||
                                                                                item.dependentOtherItem.value ===
                                                                                    "変更" ? (
                                                                                    <Typography>
                                                                                        {value.value
                                                                                            .toString()
                                                                                            .replace(
                                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                                ","
                                                                                            )}
                                                                                        円
                                                                                    </Typography>
                                                                                ) : null
                                                                            ) : (
                                                                                <Typography>{value.value}</Typography>
                                                                            )}
                                                                        </Grid2>
                                                                    </Grid2>
                                                                    {key === "dependentOtherAddress" ? (
                                                                        item.dependentOtherTogether.value ===
                                                                        true ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherDate" ? (
                                                                        item.dependentOtherDate.value ===
                                                                        null ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherBusiness" ? (
                                                                        item.dependentOtherBusiness.value ===
                                                                        "" ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : key === "dependentOtherIncome" ? (
                                                                        item.dependentOtherIncome.value ===
                                                                        "" ? null : (
                                                                            <Divider sx={{ my: 1 }} />
                                                                        )
                                                                    ) : (
                                                                        <Divider sx={{ my: 1 }} />
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        <Grid2 container spacing={2}>
                                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                                <Typography fontWeight="bold" textAlign="right">
                                                                    {item.dependentOtherOverseas.title}
                                                                </Typography>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                                <Typography textAlign="left">
                                                                    {item.dependentOtherOverseas.value === ""
                                                                        ? "なし"
                                                                        : item.dependentOtherOverseas.value}
                                                                </Typography>
                                                            </Grid2>
                                                        </Grid2>
                                                        <Divider sx={{ my: 1 }} />

                                                        {item.dependentOtherOverseas.value === "該当" ||
                                                        item.dependentOtherOverseas.value === "非該当" ? (
                                                            <>
                                                                {Object.entries(item)
                                                                    .slice(11, 16)
                                                                    .map(([key, value]) => (
                                                                        <Box key={key}>
                                                                            <Grid2 container spacing={2}>
                                                                                <Grid2
                                                                                    size={{ xs: 5, md: 5 }}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "flex-end",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    {key ===
                                                                                    "dependentOtherOverseasDate" ? (
                                                                                        item.dependentOtherOverseas
                                                                                            .value === "非該当" ? (
                                                                                            <Typography fontWeight="bold">
                                                                                                該当しなくなった日
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            <Typography fontWeight="bold">
                                                                                                {value.title}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : (
                                                                                        <Typography fontWeight="bold">
                                                                                            {value.title}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid2>
                                                                                <Grid2 size={{ xs: 7, md: 7 }}>
                                                                                    {key ===
                                                                                    "dependentOtherOverseasDate" ? (
                                                                                        item.dependentOtherOverseasDate
                                                                                            .value === null ? (
                                                                                            ""
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                {value.value.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : key ===
                                                                                      "dependentOtherOverseasReason" ? (
                                                                                        item
                                                                                            .dependentOtherOverseasReason
                                                                                            .value === "その他" ? (
                                                                                            <>
                                                                                                <Typography>
                                                                                                    {value.value}
                                                                                                    <br />({value.note})
                                                                                                </Typography>
                                                                                            </>
                                                                                        ) : item
                                                                                              .dependentOtherOverseasReason
                                                                                              .value === "国内転入" ? (
                                                                                            <Typography>
                                                                                                {value.value}
                                                                                                <br />
                                                                                                {value.date.toLocaleDateString(
                                                                                                    "ja-JP"
                                                                                                )}
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                {value.value}
                                                                                            </Typography>
                                                                                        )
                                                                                    ) : (
                                                                                        <Typography>
                                                                                            {value.value}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Grid2>
                                                                            </Grid2>
                                                                            <Divider sx={{ my: 1 }} />
                                                                        </Box>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {Object.entries(item)
                                                            .slice(16, 22)
                                                            .map(([key, value]) => (
                                                                <Box key={key}>
                                                                    <Grid2 container spacing={2}>
                                                                        <Grid2
                                                                            size={{ xs: 5, md: 5 }}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography fontWeight="bold">
                                                                                {value.title}
                                                                            </Typography>
                                                                        </Grid2>
                                                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                                                            <Typography textAlign="left">
                                                                                {value.value === ""
                                                                                    ? "未アップロード"
                                                                                    : "アップロード済み"}
                                                                            </Typography>
                                                                        </Grid2>
                                                                    </Grid2>
                                                                    <Divider sx={{ my: 1 }} />
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 5, md: 5 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">被扶養者届</Typography>
                                            </Grid2>
                                            <Grid2
                                                size={{ xs: 7, md: 7 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography>なし</Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </>
                                )}
                            </Box>

                            {staffMoreData.staffRetirementFlag.value && (
                                <Box sx={{ mt: 5 }}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">退職日</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 7, md: 7 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography>
                                                {staffMoreData.staffRetirementDate.value === null
                                                    ? ""
                                                    : staffMoreData.staffRetirementDate.value.toLocaleDateString(
                                                          "ja-JP"
                                                      )}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            )}
                            {staffMoreData.staffBetweenFlag.value && (
                                <Box sx={{ mt: 5 }}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">転籍日</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 7, md: 7 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography>
                                                {staffMoreData.staffBetweenDate.value === null
                                                    ? ""
                                                    : staffMoreData.staffBetweenDate.value.toLocaleDateString("ja-JP")}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">転籍先</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 7, md: 7 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography>{staffMoreData.staffBetweenCompany.value}</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            )}
                        </>
                    ) : tab === 1 ? (
                        <>
                            {Object.entries(staffUploadData)
                                .slice(0, 9)
                                .map(([key, item]) => (
                                    <Box key={key}>
                                        <Grid2 container spacing={2}>
                                            <Grid2
                                                size={{ xs: 5, md: 5 }}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography fontWeight="bold">{item.title}</Typography>
                                            </Grid2>
                                            <Grid2 size={{ xs: 7, md: 7 }}>
                                                <Typography textAlign="left">
                                                    {item.value === "" ? "未アップロード" : "アップロード済み"}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Divider sx={{ my: 1 }} />
                                    </Box>
                                ))}
                            {staffData.staffNationality.select !== "japanese" &&
                                Object.entries(staffUploadData)
                                    .slice(9, 12)
                                    .map(([key, item]) => (
                                        <Box key={key}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 5, md: 5 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">{item.title}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 7, md: 7 }}>
                                                    <Typography textAlign="left">
                                                        {item.value === "" ? "未アップロード" : "アップロード済み"}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                            {staffUploadData.staffCommute.select && (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">運転免許証・表</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffUploadData.staffCommute.driverLicenseFront === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">運転免許証・裏</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffUploadData.staffCommute.driverLicenseBack === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">車検証</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffUploadData.staffCommute.vehicleInspection === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">自賠責保険</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffUploadData.staffCommute.compulsoryInsurance === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">任意保険</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffUploadData.staffCommute.optionalInsurance === ""
                                                    ? "未アップロード"
                                                    : "アップロード済み"}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}
                            {staffLicenseData.length > 0 && (
                                <>
                                    {staffLicenseData.map((license, i) => (
                                        <Box key={i}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 5, md: 5 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">{license.title}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 7, md: 7 }}>
                                                    <Typography textAlign="left">
                                                        {license.value === "" ? "未アップロード" : "アップロード済み"}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </>
                            )}
                        </>
                    ) : tab === 2 ? (
                        <>
                            {Object.entries(staffJoinDocData).map(([key, item]) => (
                                <Box key={key}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">{item.title}</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }} sx={{ display: "flex", alignItems: "center" }}>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                {key === "pledge" ||
                                                key === "condition" ||
                                                key === "contract" ||
                                                key === "personal" ||
                                                key === "guarantee" ||
                                                key === "commute" ||
                                                key === "dormitoryApplication" ||
                                                key === "dormitoryUsePledge" ||
                                                key === "dormitoryEEPledge" ||
                                                key === "dormitoryCheck" ||
                                                key === "dailypay" ? (
                                                    item.judge ? (
                                                        <Typography textAlign="left">
                                                            同意済み {item.date === null ? "" : item.consentDate}
                                                        </Typography>
                                                    ) : Array.isArray(item.value) ? (
                                                        item.value.length !== 0 ? (
                                                            <Typography textAlign="left">
                                                                作成済み{" "}
                                                                {Array.isArray(item.value) && item.value.length > 0
                                                                    ? item.value[0].createDate === null
                                                                        ? ""
                                                                        : item.value[0].createDate
                                                                    : ""}
                                                            </Typography>
                                                        ) : (
                                                            <Typography textAlign="left">未作成</Typography>
                                                        )
                                                    ) : item.value !== "" ? (
                                                        <Typography textAlign="left">
                                                            作成済み {item.createDate === null ? "" : item.createDate}
                                                        </Typography>
                                                    ) : (
                                                        <Typography textAlign="left">未作成</Typography>
                                                    )
                                                ) : key === "assigned" ? (
                                                    item.judge ? (
                                                        <Typography textAlign="left">
                                                            受領済み {item.receiptDate === null ? "" : item.receiptDate}
                                                        </Typography>
                                                    ) : (
                                                        <Typography textAlign="left">未受領</Typography>
                                                    )
                                                ) : (
                                                    <Typography textAlign="left"></Typography>
                                                )}
                                            </Grid2>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleDocDetail(key)}
                                                >
                                                    {key === "assigned" ? (
                                                        !item.judge && (
                                                            <Typography variant="body2">
                                                                受領済みに切り替える
                                                            </Typography>
                                                        )
                                                    ) : (
                                                        <Typography variant="body2">詳細を見る</Typography>
                                                    )}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}
                        </>
                    ) : (
                        <>
                            {staffDomitoryData.staffDomitoryFlag.value === true ? (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }} sx={{ display: "flex", alignItems: "center" }}>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <Typography textAlign="left">利用する</Typography>
                                            </Grid2>
                                            <Grid2>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleDormitoryCalculationDialogOpen}
                                                >
                                                    個人負担額を計算する
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅名・部屋番号</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffDomitoryData.staffDomitoryName.value}{" "}
                                                {staffDomitoryData.staffDomitoryNumber.value}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">入居日</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">
                                                {staffDomitoryData.staffDomitoryInDate.value === null
                                                    ? "未定"
                                                    : staffDomitoryData.staffDomitoryInDate.value.toLocaleDateString(
                                                          "ja-JP"
                                                      )}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            ) : (
                                <>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">社宅</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">利用しない</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}

                            {equip.staffEquipmentFlag.value === true ? (
                                <>
                                    <Grid2 container spacing={2} sx={{ mt: 3, mb: 3 }}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">レンタル備品</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }} sx={{ display: "flex", alignItems: "center" }}>
                                            <Grid2 size={{ xs: 5, md: 5 }}>
                                                <Typography textAlign="left">利用する</Typography>
                                            </Grid2>
                                            <Grid2>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleEquipCalculationDialogOpen}
                                                >
                                                    {equip.staffEquipmentFlag && (
                                                        <Typography variant="body2">個人負担額を計算する</Typography>
                                                    )}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>

                                    {staffEquipmentData
                                        .filter((equip) => equip.selected)
                                        .map((equip, i) => (
                                            <Box key={i}>
                                                <Grid2 container spacing={2}>
                                                    <Grid2
                                                        size={{ xs: 5, md: 5 }}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold">{equip.name}</Typography>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                                        <Typography textAlign="left">
                                                            {equip.value === "" ? "未定" : equip.value + "つ"}
                                                            <br />
                                                            {equip.inDate === null
                                                                ? "借入日未定"
                                                                : "借入日 " + equip.inDate}
                                                        </Typography>
                                                    </Grid2>
                                                </Grid2>
                                                <Divider sx={{ my: 1 }} />
                                            </Box>
                                        ))}
                                </>
                            ) : (
                                <>
                                    <Grid2 container spacing={2} sx={{ mt: 3 }}>
                                        <Grid2
                                            size={{ xs: 5, md: 5 }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography fontWeight="bold">レンタル備品</Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 7, md: 7 }}>
                                            <Typography textAlign="left">利用しない</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </>
                            )}
                        </>
                    )}

                    {(!staffMoreData.staffBetweenFlag.value || !staffMoreData.staffRetirementFlag.value) && (
                        <>
                            <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {staffMoreData.staffRetirementFlag.value === false && (
                                    <Button
                                        variant="outlined"
                                        color="info"
                                        size="large"
                                        onClick={betweenDialogOpen}
                                        sx={{ width: "400px" }}
                                    >
                                        転籍処理を行う
                                    </Button>
                                )}
                            </Box>

                            <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {staffMoreData.staffRetirementFlag.value === false && (
                                    <Button color="error" size="large" onClick={retirementDialogOpen}>
                                        退職処理を行う
                                    </Button>
                                )}
                            </Box>
                        </>
                    )}

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            スタッフ一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog open={retirementDialog} onClose={retirementDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={retirementDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography textAlign="center" variant="h6" sx={{ mt: 3 }}>
                        退職処理を実行します、よろしいですか？
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Box sx={{ mt: 3 }}>
                            <DesktopDatePicker
                                label="退職日"
                                value={staffMoreData.staffRetirementDate.value}
                                format="yyyy/MM/dd"
                                onChange={handleRetirementDateChange}
                                slotProps={{
                                    textField: {
                                        error: staffMoreDataError.staffRetirementDate !== "",
                                        clearable: true,
                                        onBlur: handleRetirementDateBlur,
                                        sx: { width: "400px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {staffMoreDataError.staffRetirementDate !== "" && (
                                <Alert severity="error" sx={{ width: "400px" }}>
                                    {staffMoreDataError.staffRetirementDate}
                                </Alert>
                            )}
                        </Box>
                    </LocalizationProvider>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            onClick={handleRetireExecute}
                            sx={{ width: "200px" }}
                        >
                            実行する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={retirementDialogClose}
                            sx={{ width: "200px", ml: 2 }}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={retirementCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography textAlign="center" variant="h6" sx={{ mt: 3 }}>
                        退職処理が完了しました。
                    </Typography>
                    <Typography textAlign="center">退職書類をスタッフへ送付し、署名をもらってください。</Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ mt: 5, width: "400px" }}
                        onClick={handleretirementURLcopy}
                    >
                        URLをコピーする
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ mt: 5, width: "200px" }}
                        onClick={retirementCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={betweenDialog} onClose={betweenDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={betweenDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography textAlign="center" variant="h6" sx={{ mt: 3 }}>
                        転籍処理を実行します、よろしいですか？
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Box sx={{ mt: 3 }}>
                            <DesktopDatePicker
                                label="転籍日"
                                value={staffMoreData.staffBetweenDate.value}
                                format="yyyy/MM/dd"
                                onChange={handleBetweenDateChange}
                                slotProps={{
                                    textField: {
                                        error: staffMoreDataError.staffBetweenDate !== "",
                                        clearable: true,
                                        onBlur: handleBetweenDateBlur,
                                        sx: { width: "400px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {staffMoreDataError.staffBetweenDate !== "" && (
                                <Alert severity="error" sx={{ width: "400px" }}>
                                    {staffMoreDataError.staffBetweenDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <FormControl sx={{ width: "400px" }} error={staffMoreDataError.staffBetweenCompany !== ""}>
                                <InputLabel>{staffMoreData.staffBetweenCompany.title}</InputLabel>
                                <Select
                                    label={staffMoreData.staffBetweenCompany.title}
                                    value={staffMoreData.staffBetweenCompany.value}
                                    onChange={handleBetweenCompanyChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    {companyData.map((company, index) => (
                                        <MenuItem key={index} value={company.name} sx={{ textAlign: "left" }}>
                                            {company.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {staffMoreDataError.staffBetweenCompany !== "" && (
                                <Alert severity="error" sx={{ width: "400px" }}>
                                    {staffMoreDataError.staffBetweenCompany}
                                </Alert>
                            )}
                        </Box>
                    </LocalizationProvider>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="info"
                            size="large"
                            onClick={handleBetweenExecute}
                            sx={{ width: "200px" }}
                        >
                            実行する
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            size="large"
                            onClick={betweenDialogClose}
                            sx={{ width: "200px", ml: 2 }}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={betweenCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography textAlign="center" variant="h6" sx={{ mt: 3 }}>
                        転籍処理が完了しました。
                    </Typography>
                    <Typography textAlign="center">転籍書類をスタッフへ送付し、署名をもらってください。</Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ mt: 5, width: "400px" }}
                        onClick={handleBetweenURLcopy}
                    >
                        URLをコピーする
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ mt: 5, width: "200px" }}
                        onClick={betweenCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="xs" open={dispatchHistoryDialog} onClose={dispatchHistoryDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={dispatchHistoryDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        就業履歴
                    </Typography>

                    <List sx={{ width: "350px" }}>
                        {staffMoreData.staffDispatchHistory.value.map((history, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                    // onClick={handleDispatchHistoryEdit}
                                    >
                                        <EditCalendarIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>{index === 0 ? <StarIcon color="primary" /> : <PersonOffIcon />}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={history.company}
                                    secondary={
                                        history.startDate + " 〜 " + (history.endDate === null ? "" : history.endDate)
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "200px", mt: 5 }}
                        onClick={dispatchHistoryDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={pledgeDialog} onClose={pledgeDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={pledgeDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        入社誓約書兼給与振込同意書
                    </Typography>
                    {staffJoinDocData.pledge.judge ? (
                        <>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                作成済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.pledge.createDate}</Typography>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                同意済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.pledge.consentDate}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                                    確認する
                                </Button>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                                    ダウンロード
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {staffJoinDocData.pledge.value !== "" && (
                                <>
                                    <Typography textAlign="center" sx={{ mt: 3 }}>
                                        作成済み
                                    </Typography>
                                    <Typography textAlign="center">{staffJoinDocData.pledge.createDate}</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                                            確認する
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            ダウンロード
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px" }}
                                        >
                                            QRコードを表示する
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            URLをコピーする
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px", my: 5 }}
                                onClick={handlePledgeCreate}
                            >
                                入社誓約書兼給与振込同意書を作成する
                            </Button>
                        </>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={pledgeDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={pledgeCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        入社誓約書兼給与振込同意書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Typography textAlign="center" sx={{ mt: 3 }}>
                        不備がある場合、スタッフ情報を編集後、再度作成してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={pledgeCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={conditionDialog} onClose={conditionDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={conditionDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2 container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Grid2
                            size={{ xs: 6, md: 6 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">就業条件明示書一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleConditionCreate}>
                                就業条件明示書を作成する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MaterialReactTable
                            columns={conditionColumns}
                            data={staffJoinDocData.condition.value}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableSorting={false}
                            enableTopToolbar={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                            muiTableBodyRowProps={{ hover: false }}
                            paginationDisplayMode="pages"
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={conditionDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu open={conditionMenu} onClose={conditionMenuClose} anchorEl={anchorEl}>
                <MenuItem>確認する</MenuItem>
                <MenuItem>ダウンロードする</MenuItem>
                <MenuItem onClick={handleConditionURL}>送付用URLを表示する</MenuItem>
                <MenuItem onClick={handleConditionDelete}>削除する</MenuItem>
                <Divider />
                <MenuItem onClick={conditionMenuClose}>メニューを閉じる</MenuItem>
            </Menu>

            <Dialog fullWidth maxWidth="lg" open={conditionCreateDialog} onClose={conditionCreateDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={conditionCreateDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Typography textAlign="center" variant="h6">
                            就業条件明示書作成
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                disabled={staffJoinDocData.condition.value.length <= 1}
                            >
                                前回のデータを引き継ぐ
                            </Button>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            <TextField
                                variant="outlined"
                                label="派遣先企業"
                                value={conditionCreateData.dispatchName}
                                onChange={handleConditionDispatchNameChange}
                                onBlur={handleConditionDispatchNameBlur}
                                error={conditionCreateDataError.dispatchName !== ""}
                                sx={{ width: "450px" }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {conditionCreateData.dispatchName !== "" ? (
                                                <IconButton onClick={handleConditionDispatchNameClear}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={handleConditionDispatchNameChange}>
                                                    <FilterNoneIcon />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {conditionCreateDataError.dispatchName !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {conditionCreateDataError.dispatchName}
                                </Alert>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                                mb: 1,
                            }}
                        >
                            <Typography textAlign="left" sx={{ width: "440px" }}>
                                雇用契約期間
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DesktopDatePicker
                                label="開始日"
                                value={conditionCreateData.contractStartDate}
                                format="yyyy/MM/dd"
                                onChange={handleConditionContractStartDateChange}
                                slotProps={{
                                    textField: {
                                        error: conditionCreateDataError.contractStartDate !== "",
                                        clearable: true,
                                        onBlur: handleConditionContractStartDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                            <Typography textAlign="center" sx={{ mx: 2 }}>
                                〜
                            </Typography>
                            <DesktopDatePicker
                                label="終了日"
                                value={conditionCreateData.contractEndDate}
                                format="yyyy/MM/dd"
                                onChange={handleConditionContractEndDateChange}
                                slotProps={{
                                    textField: {
                                        error: conditionCreateDataError.contractEndDate !== "",
                                        clearable: true,
                                        onBlur: handleConditionContractEndDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {conditionCreateDataError.contractDate !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {conditionCreateDataError.contractDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">期間の定め</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={conditionCreateData.periodFlag}
                                        onChange={handleConditionPeriodFlagChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        {conditionCreateData.periodFlag && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                                <Grid2
                                    container
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "450px",
                                    }}
                                >
                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                        <Typography textAlign="left">更新の有無</Typography>
                                    </Grid2>
                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                        <RadioGroup
                                            row
                                            value={conditionCreateData.updateFlag}
                                            onChange={handleConditionUpdateFlagChange}
                                            sx={{ justifyContent: "space-between" }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label="更新する場合がある"
                                            />
                                            <FormControlLabel value={false} control={<Radio />} label="無" />
                                        </RadioGroup>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                                mb: 1,
                            }}
                        >
                            <Typography textAlign="left" sx={{ width: "440px" }}>
                                派遣期間
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DesktopDatePicker
                                label="開始日"
                                value={conditionCreateData.dispatchStartDate}
                                format="yyyy/MM/dd"
                                onChange={handleConditionDispatchStartDateChange}
                                slotProps={{
                                    textField: {
                                        error: conditionCreateDataError.dispatchStartDate !== "",
                                        clearable: true,
                                        onBlur: handleConditionDispatchStartDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                            <Typography textAlign="center" sx={{ mx: 2 }}>
                                〜
                            </Typography>
                            <DesktopDatePicker
                                label="終了日"
                                value={conditionCreateData.dispatchEndDate}
                                format="yyyy/MM/dd"
                                onChange={handleConditionDispatchEndDateChange}
                                slotProps={{
                                    textField: {
                                        error: conditionCreateDataError.dispatchEndDate !== "",
                                        clearable: true,
                                        onBlur: handleConditionDispatchEndDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {conditionCreateDataError.dispatchDate !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {conditionCreateDataError.dispatchDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">通勤手当</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={conditionCreateData.commuteFee}
                                        onChange={handleConditionCommuteFeeChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">賃金改定</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={conditionCreateData.wageRevision}
                                        onChange={handleConditionWageRevisionChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">賞与</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={conditionCreateData.bonus}
                                        onChange={handleConditionBonusChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">退職金</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={conditionCreateData.retirementFee}
                                        onChange={handleConditionRetirementFeeChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                            }}
                        >
                            <DesktopDatePicker
                                label="発行日"
                                value={conditionCreateData.consentDate}
                                format="yyyy/MM/dd"
                                onChange={handleConditionConsentDateChange}
                                slotProps={{
                                    textField: {
                                        error: conditionCreateDataError.consentDate !== "",
                                        clearable: true,
                                        onBlur: handleConditionConsentDateBlur,
                                        sx: { width: "450px" },
                                        helperText: "スタッフが署名する日を選択してください",
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1, mb: 5 }}>
                            {conditionCreateDataError.consentDate !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {conditionCreateDataError.consentDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                            {conditionValidation !== "" && (
                                <Alert severity="error" sx={{ width: "400px" }}>
                                    {conditionValidation}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px" }}
                                onClick={handleConditionCreateComplete}
                            >
                                就業条件明示書を作成する
                            </Button>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                sx={{ width: "400px" }}
                                onClick={conditionCreateDialogClose}
                            >
                                閉じる
                            </Button>
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
            </Dialog>

            <Dialog open={conditionCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        就業条件明示書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            ダウンロード
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={conditionCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={conditionURLDialog} onClose={conditionURLDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={conditionURLDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        就業条件明示書 送付用URL
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={conditionURLDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={conditionDeleteDialog} onClose={conditionDeleteDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={conditionDeleteDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">就業条件明示書を削除します、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={handleConditionDeleteComplete}
                        >
                            削除する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={conditionDeleteDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={conditionDeleteCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        就業条件明示書を削除しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={conditionDeleteCompleteDialogClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={contractDialog} onClose={contractDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={contractDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2 container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Grid2
                            size={{ xs: 6, md: 6 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">雇用契約書一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleContractCreate}>
                                雇用契約書を作成する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MaterialReactTable
                            columns={contractColumns}
                            data={staffJoinDocData.contract.value}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableSorting={false}
                            enableTopToolbar={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                            muiTableBodyRowProps={{ hover: false }}
                            paginationDisplayMode="pages"
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={contractDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu open={contractMenu} onClose={contractMenuClose} anchorEl={anchorEl}>
                <MenuItem>確認する</MenuItem>
                <MenuItem>ダウンロードする</MenuItem>
                <MenuItem onClick={handleContractURL}>送付用URLを表示する</MenuItem>
                <MenuItem onClick={handleContractDelete}>削除する</MenuItem>
                <Divider />
                <MenuItem onClick={contractMenuClose}>メニューを閉じる</MenuItem>
            </Menu>

            <Dialog fullWidth maxWidth="lg" open={contractCreateDialog} onClose={contractCreateDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={contractCreateDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Typography textAlign="center" variant="h6">
                            雇用契約書作成
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                disabled={staffJoinDocData.contract.value.length <= 1}
                            >
                                前回のデータを引き継ぐ
                            </Button>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            <TextField
                                variant="outlined"
                                label="派遣先企業"
                                value={contractCreateData.dispatchName}
                                onChange={handleContractDispatchNameChange}
                                onBlur={handleContractDispatchNameBlur}
                                error={contractCreateDataError.dispatchName !== ""}
                                sx={{ width: "450px" }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {contractCreateData.dispatchName !== "" ? (
                                                <IconButton onClick={handleContractDispatchNameClear}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={handleContractDispatchNameChange}>
                                                    <FilterNoneIcon />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {contractCreateDataError.dispatchName !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {contractCreateDataError.dispatchName}
                                </Alert>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                                mb: 1,
                            }}
                        >
                            <Typography textAlign="left" sx={{ width: "440px" }}>
                                雇用契約期間
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DesktopDatePicker
                                label="開始日"
                                value={contractCreateData.contractStartDate}
                                format="yyyy/MM/dd"
                                onChange={handleContractContractStartDateChange}
                                slotProps={{
                                    textField: {
                                        error: contractCreateDataError.contractStartDate !== "",
                                        clearable: true,
                                        onBlur: handleContractContractStartDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                            <Typography textAlign="center" sx={{ mx: 2 }}>
                                〜
                            </Typography>
                            <DesktopDatePicker
                                label="終了日"
                                value={contractCreateData.contractEndDate}
                                format="yyyy/MM/dd"
                                onChange={handleContractContractEndDateChange}
                                slotProps={{
                                    textField: {
                                        error: contractCreateDataError.contractEndDate !== "",
                                        clearable: true,
                                        onBlur: handleContractContractEndDateBlur,
                                        sx: { width: "200px" },
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            {contractCreateDataError.contractDate !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {contractCreateDataError.contractDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">期間の定め</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={contractCreateData.periodFlag}
                                        onChange={handleContractPeriodFlagChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        {contractCreateData.periodFlag && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                                <Grid2
                                    container
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "450px",
                                    }}
                                >
                                    <Grid2 size={{ xs: 5, md: 5 }}>
                                        <Typography textAlign="left">更新の有無</Typography>
                                    </Grid2>
                                    <Grid2 size={{ xs: 7, md: 7 }}>
                                        <RadioGroup
                                            row
                                            value={contractCreateData.updateFlag}
                                            onChange={handleContractUpdateFlagChange}
                                            sx={{ justifyContent: "space-between" }}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label="更新する場合がある"
                                            />
                                            <FormControlLabel value={false} control={<Radio />} label="無" />
                                        </RadioGroup>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        )}

                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <FormControl sx={{ width: "450px" }}>
                                <InputLabel>試用期間</InputLabel>
                                <Select
                                    label="試用期間"
                                    value={contractCreateData.trial}
                                    onChange={handleContractTrialChange}
                                    sx={{ textAlign: "left" }}
                                    fullWidth
                                >
                                    {trialData.map((trial) => (
                                        <MenuItem
                                            key={trial}
                                            value={trial}
                                            sx={{
                                                textAlign: "left",
                                            }}
                                        >
                                            {trial}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">通勤手当</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={contractCreateData.commuteFee}
                                        onChange={handleContractCommuteFeeChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">賃金改定</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={contractCreateData.wageRevision}
                                        onChange={handleContractWageRevisionChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">賞与</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={contractCreateData.bonus}
                                        onChange={handleContractBonusChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                            <Grid2
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "450px",
                                }}
                            >
                                <Grid2 size={{ xs: 5, md: 5 }}>
                                    <Typography textAlign="left">退職金</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 7, md: 7 }}>
                                    <RadioGroup
                                        row
                                        value={contractCreateData.retirementFee}
                                        onChange={handleContractRetirementFeeChange}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="有" />
                                        <FormControlLabel value={false} control={<Radio />} label="無" />
                                    </RadioGroup>
                                </Grid2>
                            </Grid2>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 3,
                            }}
                        >
                            <DesktopDatePicker
                                label="発行日"
                                value={contractCreateData.consentDate}
                                format="yyyy/MM/dd"
                                onChange={handleContractConsentDateChange}
                                slotProps={{
                                    textField: {
                                        error: contractCreateDataError.consentDate !== "",
                                        clearable: true,
                                        onBlur: handleContractConsentDateBlur,
                                        sx: { width: "450px" },
                                        helperText: "スタッフが署名する日を選択してください",
                                    },
                                    calendarHeader: {
                                        format: "yyyy年MM月",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1, mb: 5 }}>
                            {contractCreateDataError.consentDate !== "" && (
                                <Alert severity="error" sx={{ width: "450px" }}>
                                    {contractCreateDataError.consentDate}
                                </Alert>
                            )}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                            {contractValidation !== "" && (
                                <Alert severity="error" sx={{ width: "400px" }}>
                                    {contractValidation}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px" }}
                                onClick={handleContractCreateComplete}
                            >
                                雇用契約書を作成する
                            </Button>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                sx={{ width: "400px" }}
                                onClick={contractCreateDialogClose}
                            >
                                閉じる
                            </Button>
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
            </Dialog>

            <Dialog open={contractCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        雇用契約書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            ダウンロード
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={contractCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={contractURLDialog} onClose={contractURLDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={contractURLDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        雇用契約書 送付用URL
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={contractURLDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={contractDeleteDialog} onClose={contractDeleteDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={contractDeleteDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">雇用契約書を削除します、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={handleContractDeleteComplete}
                        >
                            削除する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={contractDeleteDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={contractDeleteCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        雇用契約書を削除しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={contractDeleteCompleteDialogClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={personalDialog} onClose={personalDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={personalDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        個人情報取扱い同意書
                    </Typography>
                    {staffJoinDocData.personal.judge ? (
                        <>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                作成済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.pledge.createDate}</Typography>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                同意済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.pledge.consentDate}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                                    確認する
                                </Button>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                                    ダウンロード
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {staffJoinDocData.personal.value !== "" && (
                                <>
                                    <Typography textAlign="center" sx={{ mt: 3 }}>
                                        作成済み
                                    </Typography>
                                    <Typography textAlign="center">{staffJoinDocData.pledge.createDate}</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                                            確認する
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            ダウンロード
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px" }}
                                        >
                                            QRコードを表示する
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            URLをコピーする
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px", my: 5 }}
                                onClick={handlePersonalCreate}
                            >
                                個人情報取扱い同意書を作成する
                            </Button>
                        </>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={personalDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={personalCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        個人情報取扱い同意書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Typography textAlign="center" sx={{ mt: 3 }}>
                        不備がある場合、スタッフ情報を編集後、再度作成してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={personalCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={guaranteeDialog} onClose={guaranteeDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={guaranteeDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        身元保証書
                    </Typography>
                    {staffJoinDocData.guarantee.judge ? (
                        <>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                作成済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.guarantee.createDate}</Typography>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                同意済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.guarantee.consentDate}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                                    確認する
                                </Button>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                                    ダウンロード
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {staffJoinDocData.guarantee.value !== "" && (
                                <>
                                    <Typography textAlign="center" sx={{ mt: 3 }}>
                                        作成済み
                                    </Typography>
                                    <Typography textAlign="center">{staffJoinDocData.guarantee.createDate}</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                                            確認する
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            ダウンロード
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px" }}
                                        >
                                            QRコードを表示する
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            URLをコピーする
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px", my: 5 }}
                                onClick={handleGuaranteeCreate}
                            >
                                身元保証書を作成する
                            </Button>
                        </>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={guaranteeDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={guaranteeCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        身元保証書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Typography textAlign="center" sx={{ mt: 3 }}>
                        不備がある場合、スタッフ情報を編集後、再度作成してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={guaranteeCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={commuteDialog} onClose={commuteDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={commuteDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        通勤手当申請書
                    </Typography>
                    <Typography textAlign="center" sx={{ mt: 3, mb: 5 }}>
                        就業規則の関係上、作成不可です。要相談
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={commuteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={dormitoryApplicationDialog} onClose={dormitoryApplicationDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryApplicationDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2 container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Grid2
                            size={{ xs: 6, md: 6 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">社宅入居願一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={handleDormitoryApplicationCreate}
                            >
                                社宅入居願を作成する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MaterialReactTable
                            columns={dormitoryApplicationColumns}
                            data={staffJoinDocData.dormitoryApplication.value}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableSorting={false}
                            enableTopToolbar={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                            muiTableBodyRowProps={{ hover: false }}
                            paginationDisplayMode="pages"
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={dormitoryApplicationDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu open={dormitoryApplicationMenu} onClose={dormitoryApplicationMenuClose} anchorEl={anchorEl}>
                <MenuItem>確認する</MenuItem>
                <MenuItem>ダウンロードする</MenuItem>
                <MenuItem onClick={handleDormitoryApplicationURL}>送付用URLを表示する</MenuItem>
                <MenuItem onClick={handleDormitoryApplicationDelete}>削除する</MenuItem>
                <Divider />
                <MenuItem onClick={dormitoryApplicationMenuClose}>メニューを閉じる</MenuItem>
            </Menu>

            <Dialog open={dormitoryApplicationCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅入居願を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            ダウンロード
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                            mb: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryApplicationCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryApplicationURLDialog} onClose={dormitoryApplicationURLDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryApplicationURLDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅入居願 送付用URL
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryApplicationURLDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryApplicationDeleteDialog} onClose={dormitoryApplicationDeleteDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryApplicationDeleteDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">社宅入居願を削除します、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={handleDormitoryApplicationDeleteComplete}
                        >
                            削除する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={dormitoryApplicationDeleteDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryApplicationDeleteCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        社宅入居願を削除しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryApplicationDeleteCompleteDialogClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={dormitoryUsePledgeDialog} onClose={dormitoryUsePledgeDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={dormitoryUsePledgeDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2 container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Grid2
                            size={{ xs: 6, md: 6 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">社宅使用誓約書一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={handleDormitoryUsePledgeCreate}
                            >
                                社宅使用誓約書を作成する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MaterialReactTable
                            columns={dormitoryUsePledgeColumns}
                            data={staffJoinDocData.dormitoryUsePledge.value}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableSorting={false}
                            enableTopToolbar={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                            muiTableBodyRowProps={{ hover: false }}
                            paginationDisplayMode="pages"
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={dormitoryUsePledgeDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu open={dormitoryUsePledgeMenu} onClose={dormitoryUsePledgeMenuClose} anchorEl={anchorEl}>
                <MenuItem>確認する</MenuItem>
                <MenuItem>ダウンロードする</MenuItem>
                <MenuItem onClick={handleDormitoryUsePledgeURL}>送付用URLを表示する</MenuItem>
                <MenuItem onClick={handleDormitoryUsePledgeDelete}>削除する</MenuItem>
                <Divider />
                <MenuItem onClick={dormitoryUsePledgeMenuClose}>メニューを閉じる</MenuItem>
            </Menu>

            <Dialog open={dormitoryUsePledgeCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅使用誓約書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            ダウンロード
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                            mb: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryUsePledgeCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryUsePledgeURLDialog} onClose={dormitoryUsePledgeURLDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryUsePledgeURLDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅使用誓約書 送付用URL
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryUsePledgeURLDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryUsePledgeDeleteDialog} onClose={dormitoryUsePledgeDeleteDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryUsePledgeDeleteDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">社宅使用誓約書を削除します、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={handleDormitoryUsePledgeDeleteComplete}
                        >
                            削除する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={dormitoryUsePledgeDeleteDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryUsePledgeDeleteCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        社宅使用誓約書を削除しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryUsePledgeDeleteCompleteDialogClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={dormitoryEEPledgeDialog} onClose={dormitoryEEPledgeDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={dormitoryEEPledgeDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2 container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Grid2
                            size={{ xs: 6, md: 6 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">社宅入退去誓約書一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={handleDormitoryEEPledgeCreate}
                            >
                                社宅入退去誓約書を作成する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MaterialReactTable
                            columns={dormitoryEEPledgeColumns}
                            data={staffJoinDocData.dormitoryEEPledge.value}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            enableSorting={false}
                            enableTopToolbar={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                            muiTableBodyRowProps={{ hover: false }}
                            paginationDisplayMode="pages"
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={dormitoryEEPledgeDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu open={dormitoryEEPledgeMenu} onClose={dormitoryEEPledgeMenuClose} anchorEl={anchorEl}>
                <MenuItem>確認する</MenuItem>
                <MenuItem>ダウンロードする</MenuItem>
                <MenuItem onClick={handleDormitoryEEPledgeURL}>送付用URLを表示する</MenuItem>
                <MenuItem onClick={handleDormitoryEEPledgeDelete}>削除する</MenuItem>
                <Divider />
                <MenuItem onClick={dormitoryEEPledgeMenuClose}>メニューを閉じる</MenuItem>
            </Menu>

            <Dialog open={dormitoryEEPledgeCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅入退去誓約書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 5,
                        }}
                    >
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            ダウンロード
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 3,
                            mb: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryEEPledgeCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryEEPledgeURLDialog} onClose={dormitoryEEPledgeURLDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryEEPledgeURLDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅入退去誓約書 送付用URL
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            QRコードを表示する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryEEPledgeURLDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryEEPledgeDeleteDialog} onClose={dormitoryEEPledgeDeleteDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={dormitoryEEPledgeDeleteDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">社宅入退去誓約書を削除します、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={handleDormitoryEEPledgeDeleteComplete}
                        >
                            削除する
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={dormitoryEEPledgeDeleteDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryEEPledgeDeleteCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        社宅入退去誓約書を削除しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryEEPledgeDeleteCompleteDialogClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="md" open={dormitoryCheckDialog} onClose={dormitoryCheckDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={dormitoryCheckDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        スタッフ入力依頼の機能を実装するタイミングで作成可能になります。
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dormitoryCheckDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dailypayDialog} onClose={dailypayDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={dailypayDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        日払い利用申請書
                    </Typography>
                    {staffJoinDocData.dailypay.judge ? (
                        <>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                作成済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.dailypay.createDate}</Typography>
                            <Typography textAlign="center" sx={{ mt: 3 }}>
                                同意済み
                            </Typography>
                            <Typography textAlign="center">{staffJoinDocData.dailypay.consentDate}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 5 }}>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                                    確認する
                                </Button>
                                <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                                    ダウンロード
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {staffJoinDocData.dailypay.value !== "" && (
                                <>
                                    <Typography textAlign="center" sx={{ mt: 3 }}>
                                        作成済み
                                    </Typography>
                                    <Typography textAlign="center">{staffJoinDocData.dailypay.createDate}</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button variant="outlined" color="primary" size="large" sx={{ width: "200px" }}>
                                            確認する
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            ダウンロード
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px" }}
                                        >
                                            QRコードを表示する
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "200px", ml: 2 }}
                                        >
                                            URLをコピーする
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{ width: "400px", my: 5 }}
                                onClick={handleDailypayCreate}
                            >
                                日払い利用申請書を作成する
                            </Button>
                        </>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dailypayDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dailypayCompleteDialog}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        日払い利用申請書を作成しました。
                    </Typography>
                    <Typography textAlign="center">
                        ファイルを確認後、問題なければスタッフへURLを送付してください。
                    </Typography>

                    <Typography textAlign="center" sx={{ mt: 3 }}>
                        不備がある場合、スタッフ情報を編集後、再度作成してください。
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 5,
                        }}
                    >
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px" }}>
                            確認する
                        </Button>
                        <Button variant="contained" color="primary" size="large" sx={{ width: "200px", ml: 2 }}>
                            URLをコピーする
                        </Button>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={dailypayCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={assignedDialog} onClose={assignedDialogClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={assignedDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center">赴任旅費領収書を受領済みに切り替えます、よろしいですか？</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "200px" }}
                            onClick={assignedDialogClose}
                        >
                            キャンセル
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ width: "200px", ml: 2 }}
                            onClick={handleassignedSwitched}
                        >
                            切り替える
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={assignedCompleteDialog} onClose={assignedCompleteDialogClose}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" sx={{ mb: 5 }}>
                        赴任旅費領収書を受領済みに切り替えました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px" }}
                        onClick={assignedCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={dormitoryCalculationDialog} onClose={handleDormitoryCalculationDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleDormitoryCalculationDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        社宅個人負担額一覧
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={equipCalculationDialog} onClose={handleEquipCalculationDialogClose}>
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleEquipCalculationDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ mb: 3, display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 6, md: 6 }}>
                            <Typography textAlign="center" variant="h6">
                                レンタル備品個人負担額一覧
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 6 }} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={handleEquipCalculationCreateDialogOpen}
                            >
                                新規作成
                            </Button>
                        </Grid2>
                    </Grid2>

                    {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <FormControl sx={{ width: "300px" }}>
                            <InputLabel>年度</InputLabel>
                            <Select
                                label="年度"
                                value={equipCalculationListData.year || ""}
                                onChange={handleEquipCalculationListChange}
                                sx={{ textAlign: "left" }}
                                fullWidth
                            >
                                {yearData.map((year) => (
                                    <MenuItem
                                        key={year}
                                        value={year}
                                        sx={{
                                            textAlign: "left",
                                        }}
                                    >
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box> */}

                    <Box sx={{ mt: 3 }}>
                        {Array.isArray(equipCalculationListData) &&
                            equipCalculationListData.map((equipCalculation, index) => (
                                <Box key={index}>
                                    <Grid2
                                        container
                                        spacing={2}
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}
                                    >
                                        <Grid2 size={{ xs: 4, md: 4 }}>
                                            <Typography key={index}>
                                                {equipCalculation.year}/{equipCalculation.month}
                                            </Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 4, md: 4 }}>
                                            個人負担額：{Number(equipCalculation.amount).toLocaleString()}円
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <Button
                                                variant="text"
                                                color="primary"
                                                onClick={handleEquipCalculationDetailDialogOpen}
                                            >
                                                詳細を見る
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                    <Divider />
                                </Box>
                            ))}
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={equipCalculationCreateDialog}
                onClose={handleEquipCalculationCreateDialogClose}
            >
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleEquipCalculationCreateDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 2, md: 2 }}>
                            <Typography fontWeight="bold">控除</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 3, md: 3 }}>
                            <Typography fontWeight="bold">備品名</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 3, md: 3 }}>
                            <Typography fontWeight="bold">金額</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography fontWeight="bold">控除額</Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 2, md: 2 }}>
                            <Checkbox checked={true} />
                        </Grid2>
                        <Grid2 size={{ xs: 3, md: 3 }}>
                            <Typography>自転車</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 3, md: 3 }}>
                            <Typography>1つ 30円 / 日</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>10,000円</Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <Typography fontWeight="bold" textAlign="right">
                                合計
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>10,000円</Typography>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleEquipCalculationCreate}
                        >
                            作成する
                        </Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleEquipCalculationCreateDialogClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={equipCalculationCreateCompleteDialog}
                onClose={handleEquipCalculationCreateCompleteDialogClose}
            >
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
                    <Typography textAlign="center" variant="h6">
                        レンタル備品個人負担額を作成しました。
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEquipCalculationCreateCompleteDialogClose}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={equipCalculationDetailDialog}
                onClose={handleEquipCalculationDetailDialogClose}
            >
                <DialogTitle>
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleEquipCalculationDetailDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 3 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
                        <Typography textAlign="center" variant="h6">
                            レンタル備品個人負担額詳細
                        </Typography>
                    </Box>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography fontWeight="bold" textAlign="center">
                                備品名
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography fontWeight="bold" textAlign="center">
                                金額
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography fontWeight="bold" textAlign="center">
                                控除額
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography>自転車</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography textAlign="center">1つ 30円 / 日</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography textAlign="right">3,000円</Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />
                    <Grid2
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid2 size={{ xs: 8, md: 8 }}>
                            <Typography fontWeight="bold" textAlign="right">
                                合計
                            </Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 4, md: 4 }}>
                            <Typography textAlign="right">3,000円</Typography>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleEquipCalculationDetailDialogClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default StaffDetail;
