import { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import { Button, CircularProgress, Typography, TextField, Alert } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

const ResetPassword = ({ emailData }) => {
    const [loading, setLoading] = useState(true);

    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    // const handleKeyPress = (event) => {
    //     if (event.key === "Enter") {
    //         handleSubmit();
    //     }
    // };

    const handleSubmit = async () => {
        //     if (email === "" || password === "") {
        //         setErrorMessage("メールアドレス・パスワードを入力してください");
        //         return;
        //     } else {
        //         await signInWithEmailAndPassword(auth, email, password)
        //             .then(() => {
        //                 navigate("/", { replace: true });
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //                 setErrorMessage("メールアドレスまたはパスワードが間違っています");
        //             });
        //     }
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("メールアドレスの形式が正しくありません");
        } else {
            emailData(email);
            window.scrollTo(0, 0);
            navigate("/reset-password/complete");
        }
    };

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(async (userLogin) => {
            if (userLogin) {
                window.scrollTo(0, 0);
                navigate("/home", { replace: true });
            }
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return (
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>パスワード再設定 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Container
                maxWidth="1280"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    maxWidth: 1280,
                }}
                disableGutters
            >
                <Box sx={{ mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">パスワード再設定</Typography>
                </Box>

                <Box sx={{ mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>登録されているメールアドレスへパスワード再設定用メールを送信します。</Typography>
                </Box>

                <Box>
                    <TextField
                        label="メールアドレス"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoFocus
                        sx={{ width: 400 }}
                        // onKeyPress={handleKeyPress}
                    />
                </Box>
                <Box sx={{ mb: 2 }}>
                    {errorMessage !== "" && (
                        <Alert severity="error" sx={{ width: 400, mt: 1 }}>
                            {errorMessage}
                        </Alert>
                    )}
                </Box>

                <Box sx={{ mb: 5 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleSubmit}
                    >
                        送信する
                    </Button>
                </Box>

                <Button type="submit" variant="outlined" size="large" sx={{ width: 400 }} onClick={() => navigate("/")}>
                    ログイン画面へ戻る
                </Button>
            </Container>
        </>
    );
};

export default ResetPassword;
