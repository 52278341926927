import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    Alert,
    Dialog,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const PasswordEdit = ({ val }) => {
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("12345");

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [errorMessage1, setErrorMessage1] = useState("");
    const [errorMessage2, setErrorMessage2] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleNewPasswordConfirmChange = (e) => {
        setNewPasswordConfirm(e.target.value);
    };

    const handleSubmit = () => {
        if (password === "") {
            setErrorMessage1("現在のパスワードを入力してください");
        } else {
            setErrorMessage1("");
        }

        if (password !== passwordConfirm) {
            setErrorMessage1("現在のパスワードが一致しません");
        } else if (newPassword === "") {
            setErrorMessage2("新しいパスワードを入力してください");
        } else if (newPasswordConfirm === "") {
            setErrorMessage2("新しいパスワードを再入力してください");
        } else if (newPassword === newPasswordConfirm) {
            // パスワード更新の処理を追加
            setErrorMessage2("");
            setOpen(true);
        } else {
            setErrorMessage2("新しいパスワードが一致しません");
        }
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/mypage");
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        window.scrollTo(0, 0);
        navigate("/mypage");
    };

    return (
        <>
            <Helmet>
                <title>パスワード変更 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">パスワードを変更</Typography>
                    </Box>

                    <Box>
                        <TextField
                            label="現在のパスワード"
                            autoFocus
                            value={password}
                            type="password"
                            onChange={handlePasswordChange}
                            sx={{ width: 600 }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {errorMessage1 !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {errorMessage1}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 10 }}>
                        <TextField
                            label="新しいパスワード"
                            value={newPassword}
                            type="password"
                            onChange={handleNewPasswordChange}
                            sx={{ width: 600 }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Alert sx={{ my: 2, width: 600 }} severity="info">
                            半角英数を含む8文字以上で設定してください。
                        </Alert>
                    </Box>

                    <Box>
                        <TextField
                            label="新しいパスワードを再入力"
                            value={newPasswordConfirm}
                            type="password"
                            onChange={handleNewPasswordConfirmChange}
                            sx={{ width: 600 }}
                            // onKeyPress={handleKeyPress}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {errorMessage2 !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {errorMessage2}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleSubmit}
                        >
                            新しいパスワードに変更する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            マイページへ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog open={open}>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        パスワードの変更が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleClose}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PasswordEdit;
