import React, { useMemo } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ApplyAnalysis = ({ val }) => {
    const navigate = useNavigate();

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/apply");
    };

    return (
        <>
            <Helmet>
                <title>応募者分析 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="h6">応募者分析</Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography>準備中</Typography>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleReturn}
                            sx={{ width: "400px" }}
                        >
                            応募者一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ApplyAnalysis;
