import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Divider,
    TextField,
    Alert,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogContentText,
    Grid2,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputAdornment,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ApplyMediaEdit = ({ val }) => {
    const navigate = useNavigate();

    const [applyMediaData, setApplyMediaData] = useState({
        mediaName: { title: "媒体名", type: "テキストフィールド (単行)", value: "YouTube" },
        mediaApplyName: { title: "氏名", type: "テキストフィールド (単行)", value: "" },
        mediaApplyFuri: { title: "フリガナ", type: "テキストフィールド (単行)", value: "" },
        mediaApplyCompany: {
            title: "住所",
            type: "テキストフィールド (単行)",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        mediaApplyBirthday: { title: "生年月日", type: "テキストフィールド (単行)", value: "" },
        mediaApplyGender: { title: "性別", type: "ラジオボタン", value: "" },
        mediaApplyPhone: { title: "電話番号", type: "テキストフィールド (単行)", value: "" },
        mediaApplyEmail: { title: "メールアドレス", type: "テキストフィールド (単行)", value: "" },
        mediaEmploymentNumber: { title: "就業状況", type: "ラジオボタン", value: "" },
        mediaAddition: { title: "追加入力項目", value: [] },
        mediaStatus: { title: "受付状況", type: "ラジオボタン", value: "受付中" },
    });

    const [mediaAdditionData, setMediaAdditionData] = useState([
        { title: "本人確認書類", type: "ファイルアップロード", value: "" },
        { title: "履歴書", type: "ファイルアップロード", value: "" },
    ]);

    const [mediaAddisionSelectData, setMediaAddisionSelectData] = useState([
        "テキストフィールド (単行)",
        "テキストフィールド (複数行)",
        "プルダウン",
        "ファイルアップロード",
    ]);

    const [applyMediaDataError, setApplyMediaDataError] = useState({
        mediaName: "",
        mediaAddition: "",
        mediaAdditionTitle: "",
        mediaAdditionType: "",
        mediaAdditionValue: "",
    });

    const [validation, setValidation] = useState("");

    const handleMediaNameChange = (e) => {
        setApplyMediaData({ ...applyMediaData, mediaName: { ...applyMediaData.mediaName, value: e.target.value } });
    };
    const handleMediaNameBlur = () => {
        const value = applyMediaData.mediaName.value.replace(/　/g, " ").trim();
        setApplyMediaData({ ...applyMediaData, mediaName: { ...applyMediaData.mediaName, value: value } });
        setApplyMediaDataError({
            ...applyMediaDataError,
            mediaName: applyMediaData.mediaName.value === "" ? "媒体名を入力してください" : "",
        });
    };

    const handleMediaAdditionChange = (index) => (e) => {
        const { value } = e.target;
        setMediaAdditionData((prevData) => {
            return prevData.map((item, i) => {
                if (i === index) {
                    return { ...item, title: value };
                }
                return item;
            });
        });
    };
    const handleMediaAdditionBlur = (index) => () => {
        if (mediaAdditionData[index].title.trim() === "") {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionTitle: "error",
                mediaAddition: "追加入力項目に不備があります",
            }));
        } else {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionTitle: "",
                mediaAddition: "",
            }));
        }
    };

    const handleMediaAdditionTypeChange = (index) => (e) => {
        const { value } = e.target;
        setMediaAdditionData((prevData) => {
            return prevData.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        type: value,
                        value: value === "プルダウン" ? ["", ""] : "",
                    };
                }
                return item;
            });
        });
    };
    const handleMediaAdditionTypeBlur = (index) => () => {
        if (mediaAdditionData[index].type.trim() === "") {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionType: "error",
                mediaAddition: "追加入力項目に不備があります",
            }));
        } else {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionType: "",
                mediaAddition: "",
            }));
        }
    };

    const handleMediaAdditionDelete = (index) => {
        setMediaAdditionData((prevData) => prevData.filter((_, i) => i !== index));
    };

    const handleAdditionAdd = () => {
        if (mediaAdditionData.length < 10) {
            setMediaAdditionData([...mediaAdditionData, { title: "", type: "", value: [] }]);
        }
    };

    const handleMediaAdditionValueChange = (index, valueIndex) => (e) => {
        const newValue = e.target.value;

        setMediaAdditionData((prevData) => {
            return prevData.map((item, i) => {
                if (i === index) {
                    const newValues = [...item.value];
                    newValues[valueIndex] = newValue;
                    return { ...item, value: newValues };
                }
                return item;
            });
        });
    };
    const handleMediaAdditionValueBlur = (index, choiceIndex) => () => {
        if (mediaAdditionData[index].value[choiceIndex].trim() === "") {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionValue: "error",
                mediaAddition: "追加入力項目に不備があります",
            }));
        } else {
            setApplyMediaDataError((prev) => ({
                ...prev,
                mediaAdditionValue: "",
                mediaAddition: "",
            }));
        }
    };

    const handleAdditionChoiceAdd = (index) => {
        if (mediaAdditionData[index].value.length < 4) {
            setMediaAdditionData((prevData) => {
                return prevData.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            value: [...item.value, ""],
                        };
                    }
                    return item;
                });
            });
        }
    };

    const handleAdditionChoiceDelete = (mediaIndex, choiceIndex) => {
        setMediaAdditionData((prevData) => {
            return prevData.map((item, i) => {
                if (i === mediaIndex) {
                    const newValues = [...item.value];
                    newValues.splice(choiceIndex, 1);
                    return { ...item, value: newValues };
                }
                return item;
            });
        });
    };

    const chunk = (arr, size) => {
        if (arr.length === 3) {
            return [arr.slice(0, 2), arr.slice(2)];
        }
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
    };

    const handleMediaStatusChange = (e) => {
        setApplyMediaData({ ...applyMediaData, mediaStatus: { ...applyMediaData.mediaStatus, value: e.target.value } });
    };

    const handleEditConfirm = () => {
        applyMediaDataError.mediaName = applyMediaData.mediaName.value === "" ? "媒体名を入力してください" : "";
        const hasEmptyTitle = mediaAdditionData.some((item) => item.title === "");
        const hasEmptyType = mediaAdditionData.some((item) => item.type === "");
        const hasEmptyValue = mediaAdditionData.some(
            (item) =>
                item.type === "プルダウン" && Array.isArray(item.value) && item.value.some((value) => !value?.trim())
        );
        if (hasEmptyTitle) {
            applyMediaDataError.mediaAdditionTitle = "error";
        }
        if (hasEmptyType) {
            applyMediaDataError.mediaAdditionType = "error";
        }
        if (hasEmptyValue) {
            applyMediaDataError.mediaAdditionValue = "error";
        }
        if (hasEmptyTitle || hasEmptyType || hasEmptyValue) {
            applyMediaDataError.mediaAddition = "追加入力項目に不備があります";
        }

        if (applyMediaDataError.mediaName === "" && applyMediaDataError.mediaAddition === "") {
            setValidation("");
            setOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/apply/media");
    };

    const handleEditRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleAddComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/apply/media/detail");
    };

    return (
        <>
            <Helmet>
                <title>応募媒体を編集 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">応募媒体を編集</Typography>
                    </Box>

                    <Box>
                        <TextField
                            variant="outlined"
                            label={applyMediaData.mediaName.title}
                            value={applyMediaData.mediaName.value}
                            sx={{ width: "600px" }}
                            onChange={handleMediaNameChange}
                            onBlur={handleMediaNameBlur}
                            error={applyMediaDataError.mediaName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {applyMediaDataError.mediaName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {applyMediaDataError.mediaName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>必須項目</Typography>
                    </Box>
                    <Box sx={{ mx: 64 }}>
                        {Object.entries(applyMediaData)
                            .slice(1, -2)
                            .map(([key, data]) => (
                                <Box key={key} sx={{ width: "100%", textAlign: "left" }}>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{data.title}</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 8, md: 8 }}
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                        >
                                            <Typography>{data.type}</Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />
                                </Box>
                            ))}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>追加入力項目</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {mediaAdditionData.map((data, index) => (
                            <Box key={index}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    <TextField
                                        label="項目名"
                                        value={data.title}
                                        onChange={handleMediaAdditionChange(index)}
                                        onBlur={handleMediaAdditionBlur(index)}
                                        sx={{ width: "260px" }}
                                        error={applyMediaDataError.mediaAdditionTitle !== ""}
                                    />
                                    <FormControl sx={{ width: "260px" }}>
                                        <Select
                                            value={data.type}
                                            onChange={handleMediaAdditionTypeChange(index)}
                                            onBlur={handleMediaAdditionTypeBlur(index)}
                                            sx={{ textAlign: "left" }}
                                            error={applyMediaDataError.mediaAdditionType !== ""}
                                        >
                                            {mediaAddisionSelectData.map((type, i) => (
                                                <MenuItem key={i} value={type} sx={{ textAlign: "left" }}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton onClick={() => handleMediaAdditionDelete(index)} size="large">
                                        <DeleteForeverIcon fontSize="large" />
                                    </IconButton>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 1,
                                        mb: 2,
                                    }}
                                >
                                    {data.type === "プルダウン" && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: "100%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {Array.isArray(data.value) &&
                                                chunk(data.value, 2).map((pair, groupIndex) => (
                                                    <Box
                                                        key={groupIndex}
                                                        sx={{
                                                            display: "flex",
                                                            gap: 2,
                                                            justifyContent: "center",
                                                            ...(data.value.length === 3 &&
                                                                groupIndex === 1 && {
                                                                    justifyContent: "flex-start",
                                                                    width: "600px",
                                                                }),
                                                        }}
                                                    >
                                                        {pair.map((choiceValue, pairIndex) => {
                                                            const choiceIndex =
                                                                data.value.length === 3 && groupIndex === 1
                                                                    ? 2
                                                                    : groupIndex * 2 + pairIndex;
                                                            return (
                                                                <TextField
                                                                    key={choiceIndex}
                                                                    label={`選択肢${choiceIndex + 1}`}
                                                                    value={choiceValue || ""}
                                                                    onChange={handleMediaAdditionValueChange(
                                                                        index,
                                                                        choiceIndex
                                                                    )}
                                                                    onBlur={handleMediaAdditionValueBlur(
                                                                        index,
                                                                        choiceIndex
                                                                    )}
                                                                    error={
                                                                        applyMediaDataError.mediaAdditionValue !== ""
                                                                    }
                                                                    sx={{ width: "290px" }}
                                                                    InputProps={{
                                                                        endAdornment:
                                                                            choiceIndex >= 2 ? (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            handleAdditionChoiceDelete(
                                                                                                index,
                                                                                                choiceIndex
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <HighlightOffIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ) : null,
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </Box>
                                                ))}
                                            <IconButton
                                                onClick={() => handleAdditionChoiceAdd(index)}
                                                size="large"
                                                disabled={mediaAdditionData[index].value.length >= 4}
                                            >
                                                <AddCircleOutlineIcon fontSize="large" />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ width: "400px" }}
                            disabled={mediaAdditionData.length >= 10}
                            onClick={handleAdditionAdd}
                        >
                            入力項目を追加する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {applyMediaDataError.mediaAddition !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {applyMediaDataError.mediaAddition}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {applyMediaData.mediaStatus.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row defaultValue="受付中">
                                    <FormControlLabel
                                        value="受付中"
                                        control={<Radio />}
                                        label="受付中"
                                        onChange={handleMediaStatusChange}
                                    />
                                    <FormControlLabel
                                        value="受付停止"
                                        control={<Radio />}
                                        label="受付停止"
                                        onChange={handleMediaStatusChange}
                                        sx={{ ml: 7.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleEditConfirm}
                        >
                            編集内容を確認する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            応募媒体詳細へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        応募媒体の編集内容を確認
                    </Typography>

                    <Box sx={{ mx: 25 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaName.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaName.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "430px", textAlign: "left" }}>必須項目</Typography>
                    </Box>

                    {Object.entries(applyMediaData)
                        .slice(1, -2)
                        .map(([key, data]) => (
                            <Box key={key} sx={{ mx: 25 }}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">{data.title}</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        <Typography>{data.type}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "430px", textAlign: "left" }}>追加入力項目</Typography>
                    </Box>

                    {mediaAdditionData.map((data, index) => (
                        <Box key={index} sx={{ mx: 25 }}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 4, md: 4 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 8, md: 8 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <Typography>{data.type}</Typography>
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    <Box sx={{ mx: 25, mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaStatus.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaStatus.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleEditRegister}
                            sx={{ width: "400px" }}
                        >
                            編集内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            編集画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        応募媒体の編集が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleAddComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ApplyMediaEdit;
