import React, { useMemo, useState } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip, IconButton } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import SettingsIcon from "@mui/icons-material/Settings";

import { Helmet } from "react-helmet-async";

const Staff = ({ val }) => {
    const navigate = useNavigate();

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const staffData = [
        {
            name: "桐ヶ谷 和人",
            gender: "男性",
            age: 18,
            employment:
                "株式会社めっちゃ名前長い企業がちゃんと三点リーダーで表示されるかテストしています。マウスオーバーすると、すべて表示されます。",
            enrollment: "就業中",
            division: "営業部",
        },
        { name: "結城 明日奈", gender: "女性", age: 18, employment: "企業A", enrollment: "就業中", division: "営業部" },
        { name: "朝田 詩乃", gender: "女性", age: 17, employment: "企業B", enrollment: "就業中", division: "営業部" },
        { name: "桐ヶ谷 直葉", gender: "女性", age: 16, employment: "企業C", enrollment: "離職中", division: "営業部" },
        { name: "ユイ", gender: "女性", age: 10, employment: "企業D", enrollment: "就業中", division: "営業部" },
        { name: "リーファ", gender: "女性", age: 16, employment: "企業E", enrollment: "退職済", division: "営業部" },
        { name: "シリカ", gender: "女性", age: 14, employment: "企業F", enrollment: "就業中", division: "営業部" },
        { name: "リズベット", gender: "女性", age: 18, employment: "企業G", enrollment: "就業中", division: "営業部" },
        { name: "クライン", gender: "男性", age: 24, employment: "企業H", enrollment: "就業中", division: "営業部" },
        { name: "エギル", gender: "男性", age: 27, employment: "企業I", enrollment: "就業中", division: "営業部" },
        { name: "シノン", gender: "女性", age: 17, employment: "企業J", enrollment: "就業中", division: "営業部" },
        { name: "ユウキ", gender: "女性", age: 19, employment: "企業K", enrollment: "就業中", division: "営業部" },
        { name: "アリス", gender: "女性", age: 20, employment: "企業L", enrollment: "就業中", division: "営業部" },
        { name: "ユージオ", gender: "男性", age: 20, employment: "企業M", enrollment: "就業中", division: "営業部" },
        { name: "ティーゼ", gender: "女性", age: 16, employment: "企業N", enrollment: "就業中", division: "営業部" },
        { name: "ロニエ", gender: "女性", age: 16, employment: "企業O", enrollment: "就業中", division: "営業部" },
        { name: "セルカ", gender: "女性", age: 12, employment: "企業P", enrollment: "就業中", division: "営業部" },
        {
            name: "カーディナル",
            gender: "女性",
            age: 200,
            employment: "企業Q",
            enrollment: "就業中",
            division: "営業部",
        },
        { name: "アスナ", gender: "女性", age: 18, employment: "企業R", enrollment: "退職済", division: "営業部" },
        { name: "キリト", gender: "男性", age: 18, employment: "企業S", enrollment: "退職済", division: "営業部" },
    ];

    const columns = useMemo(
        () => [
            {
                header: "氏名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "性別",
                accessorKey: "gender",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
            },
            {
                header: "年齢",
                accessorKey: "age",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                Cell: ({ cell }) => cell.getValue().toLocaleString(),
            },
            {
                header: "就業先企業",
                accessorKey: "employment",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "在籍区分",
                accessorKey: "enrollment",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: [...new Set(staffData.map((item) => item.enrollment))],
            },
            {
                header: "所属部署",
                accessorKey: "division",
                muiTableBodyCellProps: { sx: { maxWidth: "200px" } },
                filterVariant: "select",
                filterSelectOptions: [...new Set(staffData.map((item) => item.division))],
            },
        ],
        []
    );

    const handleStaffAdd = () => {
        window.scrollTo(0, 0);
        navigate("/staff/add");
    };

    const handleStaffDetail = (id) => {
        window.scrollTo(0, 0);
        //navigate(`/staff/${id}`);
        navigate("/staff/detail");
    };

    return (
        <>
            <Helmet>
                <title>スタッフ一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">スタッフ一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleStaffAdd}>
                                スタッフを追加する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={staffData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleStaffDetail(row.original.id),
                                sx: {
                                    cursor: "pointer",
                                    backgroundColor: row.original.enrollment === "退職済" ? "#d3d3d3" : "inherit",
                                },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Staff;
