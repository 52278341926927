import React, { useState, useMemo } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    Alert,
    Grid2,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const LocationAdd = ({ val }) => {
    const navigate = useNavigate();

    const [locationData, setLocationData] = useState({
        locationName: { title: "事業所 / 工場名", value: "" },
        locationAddress: {
            title: "住所",
            post: "",
            prefecture: "",
            city: "",
            other: "",
        },
    });
    const [locationDataError, setLocationDataError] = useState({
        locationName: "",
        locationAddressPost: "",
        locationAddressPrefecture: "",
        locationAddressCity: "",
        locationAddressOther: "",
        locationDepartmentName: "",
        locationDepartmentPost: "",
        locationDepartmentPhone: "",
    });

    const [addDepartment, setAddDepartment] = useState([
        {
            departmentName: "",
            departmentPosition: "",
            departmentPhone: "",
            departmentCommander: "",
            departmentCommanderPhone: "",
            departmentCommanderPosition: [{ value: "" }],
            departmentCommanderName: [{ value: "" }],
            departmentPerson: "",
            departmentPersonPhone: "",
            departmentPersonPosition: "",
            departmentPersonName: "",
            departmentTrouble: "",
            departmentTroublePhone: "",
            departmentTroublePosition: "",
            departmentTroubleName: "",
            departmentShift: [],
        },
    ]);
    const [addDepartmentError, setAddDepartmentError] = useState([
        {
            departmentName: "",
            departmentPosition: "",
            departmentPhone: "",
            departmentCommander: "",
            departmentCommanderPhone: "",
            departmentCommanderPosition: [{ value: "" }],
            departmentCommanderName: [{ value: "" }],
            departmentPerson: "",
            departmentPersonPhone: "",
            departmentPersonPosition: "",
            departmentPersonName: "",
            departmentTrouble: "",
            departmentTroublePhone: "",
            departmentTroublePosition: "",
            departmentTroubleName: "",
            departmentShift: "",
        },
    ]);

    const [workTimeData, setWorkTimeData] = useState([
        {
            name: "日勤",
            startHour: "09",
            startMinute: "00",
            endHour: "18",
            endMinute: "00",
            breakTime: [
                {
                    startHour: "12",
                    startMinute: "00",
                    endHour: "13",
                    endMinute: "00",
                },
            ],
        },
        {
            name: "夜勤",
            startHour: "18",
            startMinute: "00",
            endHour: "09",
            endMinute: "00",
            breakTime: [
                {
                    startHour: "00",
                    startMinute: "00",
                    endHour: "01",
                    endMinute: "00",
                },
                {
                    startHour: "04",
                    startMinute: "00",
                    endHour: "05",
                    endMinute: "00",
                },
            ],
        },
    ]);

    const workTimeColumns = useMemo(
        () => [
            {
                header: "シフト名",
                accessorKey: "name",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "勤務時間",
                accessorKey: "workTime",
                Cell: ({ row }) => {
                    return `${row.original.startHour}:${row.original.startMinute} 〜 ${row.original.endHour}:${row.original.endMinute}`;
                },
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "休憩時間",
                accessorKey: "breakTime",
                Cell: ({ cell }) => (
                    <Tooltip
                        title={cell
                            .getValue()
                            .map(
                                (breakTime) =>
                                    `${breakTime.startHour}:${breakTime.startMinute} 〜 ${breakTime.endHour}:${breakTime.endMinute}`
                            )
                            .join(", ")}
                        placement="top"
                        arrow
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell
                                .getValue()
                                .map(
                                    (breakTime) =>
                                        `${breakTime.startHour}:${breakTime.startMinute} 〜 ${breakTime.endHour}:${breakTime.endMinute}`
                                )
                                .join(", ")}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
        ],
        []
    );

    const [validation, setValidation] = useState("");

    const handleLocationNameChange = (e) => {
        setLocationData({ ...locationData, locationName: { ...locationData.locationName, value: e.target.value } });
    };
    const handleLocationNameBlur = () => {
        setLocationDataError({
            ...locationDataError,
            locationName: locationData.locationName.value === "" ? "事業所 / 工場名を入力してください" : "",
        });
    };

    const handleLocationAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setLocationData({ ...locationData, locationAddress: { ...locationData.locationAddress, post: value } });
    };
    const handleLocationAddressPostBlur = () => {
        setLocationDataError({
            ...locationDataError,
            locationAddressPost: locationData.locationAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleLocationAddressPrefectureChange = (e) => {
        setLocationData({
            ...locationData,
            locationAddress: { ...locationData.locationAddress, prefecture: e.target.value },
        });
    };
    const handleLocationAddressPrefectureBlur = () => {
        setLocationDataError({
            ...locationDataError,
            locationAddressPrefecture:
                locationData.locationAddress.prefecture === "" ? "都道府県を選択してください" : "",
        });
    };

    const handleLocationAddressCityChange = (e) => {
        setLocationData({
            ...locationData,
            locationAddress: { ...locationData.locationAddress, city: e.target.value },
        });
    };
    const handleLocationAddressCityBlur = () => {
        setLocationDataError({
            ...locationDataError,
            locationAddressCity: locationData.locationAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleLocationAddressOtherChange = (e) => {
        setLocationData({
            ...locationData,
            locationAddress: { ...locationData.locationAddress, other: e.target.value },
        });
    };
    const handleLocationAddressOtherBlur = () => {
        setLocationDataError({
            ...locationDataError,
            locationAddressOther: locationData.locationAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleLocationDepartmentNameChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentName: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentNameBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentName: addDepartment[i].departmentName === "" ? "部署名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentPositionChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPosition: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPositionBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPosition: addDepartment[i].departmentPosition === "" ? "部署長の役職を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentPhoneChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPhone: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPhoneBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPhone: addDepartment[i].departmentPhone === "" ? "電話番号を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentCommanderChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentCommander: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentCommanderBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentCommander:
                    addDepartment[i].departmentCommander === "" ? "指揮命令者の部署名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentCommanderPhoneChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentCommanderPhone: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentCommanderPhoneBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentCommanderPhone:
                    addDepartment[i].departmentCommanderPhone === "" ? "指揮命令者の電話番号を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentCommanderPositionChange = (e, i, j) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = {
                ...newAddDepartment[i],
                departmentCommanderPosition: [...newAddDepartment[i].departmentCommanderPosition],
            };
            newAddDepartment[i].departmentCommanderPosition[j] = { value: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentCommanderPositionBlur = (i, j) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentCommanderPosition: [...newErrors[i].departmentCommanderPosition],
            };
            newErrors[i].departmentCommanderPosition[j] = {
                ...newErrors[i].departmentCommanderPosition[j],
                value:
                    addDepartment[i].departmentCommanderPosition[j].value === ""
                        ? "指揮命令者の役職を入力してください"
                        : "",
            };
            return newErrors;
        });
    };

    const handleAddLocationDepartmentCommanderPosition = (i) => {
        if (addDepartment[i].departmentCommanderPosition.length < 5) {
            setAddDepartment((prev) => {
                const newAddDepartment = [...prev];
                newAddDepartment[i].departmentCommanderPosition.push({ value: "" });
                return newAddDepartment;
            });
            setAddDepartmentError((prev) => {
                const newErrors = [...prev];
                newErrors[i].departmentCommanderPosition.push({ value: "" });
                return newErrors;
            });
        }
    };

    const handleLocationDepartmentCommanderPositionDelete = (i, j) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i].departmentCommanderPosition.splice(j, 1);
            return newAddDepartment;
        });
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i].departmentCommanderPosition.splice(j, 1);
            return newErrors;
        });
    };

    const handleLocationDepartmentCommanderNameChange = (e, i, j) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = {
                ...newAddDepartment[i],
                departmentCommanderName: [...newAddDepartment[i].departmentCommanderName],
            };
            newAddDepartment[i].departmentCommanderName[j] = { value: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentCommanderNameBlur = (i, j) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentCommanderName: [...newErrors[i].departmentCommanderName],
            };
            newErrors[i].departmentCommanderName[j] = {
                ...newErrors[i].departmentCommanderName[j],
                value:
                    addDepartment[i].departmentCommanderName[j].value === ""
                        ? "指揮命令者の氏名を入力してください"
                        : "",
            };
            return newErrors;
        });
    };

    const handleAddLocationDepartmentCommanderName = (i) => {
        if (addDepartment[i].departmentCommanderName.length < 5) {
            setAddDepartment((prev) => {
                const newAddDepartment = [...prev];
                newAddDepartment[i].departmentCommanderName.push({ value: "" });
                return newAddDepartment;
            });
            setAddDepartmentError((prev) => {
                const newErrors = [...prev];
                newErrors[i].departmentCommanderName.push({ value: "" });
                return newErrors;
            });
        }
    };

    const handleLocationDepartmentCommanderNameDelete = (i, j) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i].departmentCommanderName.splice(j, 1);
            return newAddDepartment;
        });
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i].departmentCommanderName.splice(j, 1);
            return newErrors;
        });
    };

    const handleLocationDepartmentPersonChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPerson: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPersonBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPerson: addDepartment[i].departmentPerson === "" ? "派遣先の部署名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentPersonPhoneChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPersonPhone: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPersonPhoneBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPersonPhone:
                    addDepartment[i].departmentPersonPhone === "" ? "派遣先の電話番号を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentPersonPositionChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPersonPosition: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPersonPositionBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPersonPosition:
                    addDepartment[i].departmentPersonPosition === "" ? "派遣先の役職を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentPersonNameChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentPersonName: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentPersonNameBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentPersonName:
                    addDepartment[i].departmentPersonName === "" ? "派遣先の氏名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentTroubleChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentTrouble: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentTroubleBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentTrouble:
                    addDepartment[i].departmentTrouble === "" ? "苦情申し出先の部署名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentTroublePhoneChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentTroublePhone: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentTroublePhoneBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentTroublePhone:
                    addDepartment[i].departmentTroublePhone === "" ? "苦情申し出先の電話番号を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentTroublePositionChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentTroublePosition: e.target.value };
            return newAddDepartment;
        });
    };
    const handleLocationDepartmentTroublePositionBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentTroublePosition:
                    addDepartment[i].departmentTroublePosition === "" ? "苦情申し出先の役職を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleLocationDepartmentTroubleNameChange = (e, i) => {
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = { ...newAddDepartment[i], departmentTroubleName: e.target.value };
            return newAddDepartment;
        });
    };

    const handleLocationDepartmentTroubleNameBlur = (i) => {
        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentTroubleName:
                    addDepartment[i].departmentTroubleName === "" ? "苦情申し出先の氏名を入力してください" : "",
            };
            return newErrors;
        });
    };

    const handleDeleteDepartment = (i) => {
        if (addDepartment.length > 1) {
            setAddDepartment((prev) => {
                const newAddDepartment = [...prev];
                newAddDepartment.splice(i, 1);
                return newAddDepartment;
            });
            setAddDepartmentError((prev) => {
                const newErrors = [...prev];
                newErrors.splice(i, 1);
                return newErrors;
            });
        }
    };

    const handleAddDepartment = () => {
        if (addDepartment.length < 10) {
            setAddDepartment([
                ...addDepartment,
                {
                    departmentName: "",
                    departmentPosition: "",
                    departmentPhone: "",
                    departmentCommander: "",
                    departmentCommanderPhone: "",
                    departmentCommanderPosition: [{ value: "" }],
                    departmentCommanderName: [{ value: "" }],
                    departmentPerson: "",
                    departmentPersonPhone: "",
                    departmentPersonPosition: "",
                    departmentPersonName: "",
                    departmentTrouble: "",
                    departmentTroublePhone: "",
                    departmentTroublePosition: "",
                    departmentTroubleName: "",
                    departmentShift: [],
                },
            ]);
            setAddDepartmentError([
                ...addDepartmentError,
                {
                    departmentName: "",
                    departmentPosition: "",
                    departmentPhone: "",
                    departmentCommander: "",
                    departmentCommanderPhone: "",
                    departmentCommanderPosition: [{ value: "" }],
                    departmentCommanderName: [{ value: "" }],
                    departmentPerson: "",
                    departmentPersonPhone: "",
                    departmentPersonPosition: "",
                    departmentPersonName: "",
                    departmentTrouble: "",
                    departmentTroublePhone: "",
                    departmentTroublePosition: "",
                    departmentTroubleName: "",
                    departmentShift: "",
                },
            ]);
        }
    };

    const [shiftOpen, setShiftOpen] = useState(false);
    const [selectedDepartmentIndex, setSelectedDepartmentIndex] = useState(0);
    const handleDepartmentShiftOpen = (i) => {
        setSelectedDepartmentIndex(i);
        setShiftOpen(true);
    };

    const handleDepartmentShiftClose = () => {
        setShiftOpen(false);
    };

    const [selectedShifts, setSelectedShifts] = useState([]);

    const handleDepartmentShiftRowSelectionChange = (row, i) => {
        const isSelected = addDepartment[i].departmentShift.some((shift) => shift.value === row.original.name);

        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            if (isSelected) {
                newAddDepartment[i] = {
                    ...newAddDepartment[i],
                    departmentShift: newAddDepartment[i].departmentShift.filter(
                        (shift) => shift.value !== row.original.name
                    ),
                };
            } else {
                newAddDepartment[i] = {
                    ...newAddDepartment[i],
                    departmentShift: [...newAddDepartment[i].departmentShift, { value: row.original.name }],
                };
            }
            return newAddDepartment;
        });

        setSelectedShifts((prev) => {
            if (isSelected) {
                return prev.filter((shift) => shift.name !== row.original.name);
            }
            return [...prev, row.original];
        });

        setAddDepartmentError((prev) => {
            const newErrors = [...prev];
            newErrors[i] = {
                ...newErrors[i],
                departmentShift: "",
            };
            return newErrors;
        });
    };

    const handleDepartmentShiftValueClear = (e, i) => {
        e.stopPropagation();
        setAddDepartment((prev) => {
            const newAddDepartment = [...prev];
            newAddDepartment[i] = {
                ...newAddDepartment[i],
                departmentShift: [],
            };
            return newAddDepartment;
        });
        setSelectedShifts([]);
    };

    const handleAddConfirm = () => {
        const newLocationDataError = {
            locationName: locationData.locationName.value.trim() === "" ? "事業所 / 工場名を入力してください" : "",
            locationAddressPost: locationData.locationAddress.post.trim() === "" ? "郵便番号を入力してください" : "",
            locationAddressPrefecture:
                locationData.locationAddress.prefecture.trim() === "" ? "都道府県を入力してください" : "",
            locationAddressCity: locationData.locationAddress.city.trim() === "" ? "市区町村を入力してください" : "",
            locationAddressOther:
                locationData.locationAddress.other.trim() === "" ? "上記以降の住所を入力してください" : "",
        };

        const newAddDepartmentError = addDepartment.map((department) => ({
            departmentName: department.departmentName.trim() === "" ? "部署名を入力してください" : "",
            departmentPosition: department.departmentPosition.trim() === "" ? "部署長の役職を入力してください" : "",
            departmentPhone: department.departmentPhone.trim() === "" ? "電話番号を入力してください" : "",
            departmentCommander:
                department.departmentCommander.trim() === "" ? "指揮命令者の部署名を入力してください" : "",
            departmentCommanderPhone:
                department.departmentCommanderPhone.trim() === "" ? "指揮命令者の電話番号を入力してください" : "",
            departmentCommanderPosition: department.departmentCommanderPosition.map((pos) => ({
                value: pos.value.trim() === "" ? "指揮命令者の役職を入力してください" : "",
            })),
            departmentCommanderName: department.departmentCommanderName.map((name) => ({
                value: name.value.trim() === "" ? "指揮命令者の氏名を入力してください" : "",
            })),
            departmentPerson: department.departmentPerson.trim() === "" ? "派遣先の部署名を入力してください" : "",
            departmentPersonPhone:
                department.departmentPersonPhone.trim() === "" ? "派遣先の電話番号を入力してください" : "",
            departmentPersonPosition:
                department.departmentPersonPosition.trim() === "" ? "派遣先の役職を入力してください" : "",
            departmentPersonName: department.departmentPersonName.trim() === "" ? "派遣先の氏名を入力してください" : "",
            departmentTrouble:
                department.departmentTrouble.trim() === "" ? "苦情申し出先の部署名を入力してください" : "",
            departmentTroublePhone:
                department.departmentTroublePhone.trim() === "" ? "苦情申し出先の電話番号を入力してください" : "",
            departmentTroublePosition:
                department.departmentTroublePosition.trim() === "" ? "苦情申し出先の役職を入力してください" : "",
            departmentTroubleName:
                department.departmentTroubleName.trim() === "" ? "苦情申し出先の氏名を入力してください" : "",
            departmentShift: department.departmentShift.length === 0 ? "シフトを選択してください" : "",
        }));

        setLocationDataError(newLocationDataError);
        setAddDepartmentError(newAddDepartmentError);

        const hasLocationError = Object.values(newLocationDataError).some((error) => error !== "");
        const hasDepartmentError = newAddDepartmentError.some((department) =>
            Object.entries(department).some(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.some((item) => item.value !== "");
                }
                return value !== "";
            })
        );

        if (!hasLocationError && !hasDepartmentError) {
            setValidation("");
            setOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch/detail");
    };

    const handleAddRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleAddComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/dispatch/detail");
    };

    return (
        <>
            <Helmet>
                <title>事業所 / 工場追加 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">事業所 / 工場を追加</Typography>
                    </Box>

                    <Box>
                        <TextField
                            variant="outlined"
                            label={locationData.locationName.title}
                            value={locationData.locationName.value}
                            sx={{ width: "600px" }}
                            onChange={handleLocationNameChange}
                            onBlur={handleLocationNameBlur}
                            error={locationDataError.locationName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {locationDataError.locationName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {locationDataError.locationName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>住所</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label="郵便番号"
                            value={locationData.locationAddress.post}
                            sx={{ width: "424px" }}
                            onChange={handleLocationAddressPostChange}
                            onBlur={handleLocationAddressPostBlur}
                            helperText="数字のみ入力してください"
                            error={locationDataError.locationAddressPost !== ""}
                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Button variant="outlined" size="large" sx={{ width: "152px", height: "56px", ml: 3 }}>
                            住所を検索する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {locationDataError.locationAddressPost !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {locationDataError.locationAddressPost}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="都道府県"
                            value={locationData.locationAddress.prefecture}
                            sx={{ width: "288px" }}
                            onChange={handleLocationAddressPrefectureChange}
                            onBlur={handleLocationAddressPrefectureBlur}
                            error={locationDataError.locationAddressPrefecture !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="市区町村"
                            value={locationData.locationAddress.city}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleLocationAddressCityChange}
                            onBlur={handleLocationAddressCityBlur}
                            error={locationDataError.locationAddressCity !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {locationDataError.locationAddressPrefecture !== "" && (
                                <Alert severity="error">{locationDataError.locationAddressPrefecture}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {locationDataError.locationAddressCity !== "" && (
                                <Alert severity="error">{locationDataError.locationAddressCity}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="上記以降の住所"
                            value={locationData.locationAddress.other}
                            sx={{ width: "600px" }}
                            onChange={handleLocationAddressOtherChange}
                            onBlur={handleLocationAddressOtherBlur}
                            error={locationDataError.locationAddressOther !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {locationDataError.locationAddressOther !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {locationDataError.locationAddressOther}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>部署情報</Typography>
                    </Box>
                    {addDepartment.map((department, i) => (
                        <Box key={i} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                            <Accordion sx={{ width: "600px" }} defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{department.departmentName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        variant="outlined"
                                        label="部署名"
                                        value={department.departmentName}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentNameChange(e, i)}
                                        onBlur={() => handleLocationDepartmentNameBlur(i)}
                                        error={addDepartmentError[i]?.departmentName !== ""}
                                    />
                                    {addDepartmentError[i]?.departmentName !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentName}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="部署長の役職"
                                        value={department.departmentPost}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPositionChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPositionBlur(i)}
                                        error={addDepartmentError[i]?.departmentPosition !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentPosition !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPosition}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="電話番号"
                                        value={department.departmentPhone}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPhoneChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPhoneBlur(i)}
                                        error={addDepartmentError[i]?.departmentPhone !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentPhone !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPhone}
                                        </Alert>
                                    )}

                                    <Typography textAlign="left" sx={{ mt: 3 }}>
                                        指揮命令者
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label="指揮命令者の部署名"
                                        value={department.departmentCommander}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentCommanderChange(e, i)}
                                        onBlur={() => handleLocationDepartmentCommanderBlur(i)}
                                        error={addDepartmentError[i]?.departmentCommander !== ""}
                                        sx={{ mt: 1 }}
                                    />
                                    {addDepartmentError[i]?.departmentCommander !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentCommander}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="指揮命令者の電話番号"
                                        value={department.departmentCommanderPhone}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentCommanderPhoneChange(e, i)}
                                        onBlur={() => handleLocationDepartmentCommanderPhoneBlur(i)}
                                        error={addDepartmentError[i]?.departmentCommanderPhone !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentCommanderPhone !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentCommanderPhone}
                                        </Alert>
                                    )}
                                    {addDepartment[i].departmentCommanderPosition.map((position, j) => (
                                        <Box key={j}>
                                            <TextField
                                                variant="outlined"
                                                label={`指揮命令者の役職${j + 1}`}
                                                fullWidth
                                                value={position.value}
                                                onChange={(e) =>
                                                    handleLocationDepartmentCommanderPositionChange(e, i, j)
                                                }
                                                onBlur={() => handleLocationDepartmentCommanderPositionBlur(i, j)}
                                                error={
                                                    addDepartmentError[i]?.departmentCommanderPosition[j]?.value !== ""
                                                }
                                                sx={{ mt: 3 }}
                                                InputProps={{
                                                    endAdornment: j !== 0 && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleLocationDepartmentCommanderPositionDelete(
                                                                        i,
                                                                        j
                                                                    )
                                                                }
                                                            >
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {addDepartmentError[i]?.departmentCommanderPosition[j]?.value !== "" && (
                                                <Alert sx={{ mt: 1 }} severity="error">
                                                    {addDepartmentError[i]?.departmentCommanderPosition[j]?.value}
                                                </Alert>
                                            )}
                                        </Box>
                                    ))}
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        sx={{ mt: 3, width: "400px" }}
                                        onClick={() => handleAddLocationDepartmentCommanderPosition(i)}
                                        disabled={addDepartment[i].departmentCommanderPosition.length >= 5}
                                    >
                                        指揮命令者の役職を追加する
                                    </Button>

                                    {addDepartment[i].departmentCommanderName.map((name, j) => (
                                        <Box key={j}>
                                            <TextField
                                                variant="outlined"
                                                label={`指揮命令者の氏名${j + 1}`}
                                                fullWidth
                                                value={name.value}
                                                onChange={(e) => handleLocationDepartmentCommanderNameChange(e, i, j)}
                                                onBlur={() => handleLocationDepartmentCommanderNameBlur(i, j)}
                                                error={addDepartmentError[i]?.departmentCommanderName[j]?.value !== ""}
                                                sx={{ mt: 3 }}
                                                InputProps={{
                                                    endAdornment: j !== 0 && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleLocationDepartmentCommanderNameDelete(i, j)
                                                                }
                                                            >
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {addDepartmentError[i]?.departmentCommanderName[j]?.value !== "" && (
                                                <Alert sx={{ mt: 1 }} severity="error">
                                                    {addDepartmentError[i]?.departmentCommanderName[j]?.value}
                                                </Alert>
                                            )}
                                        </Box>
                                    ))}
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        sx={{ mt: 3, width: "400px" }}
                                        onClick={() => handleAddLocationDepartmentCommanderName(i)}
                                        disabled={addDepartment[i].departmentCommanderName.length >= 5}
                                    >
                                        指揮命令者を追加する
                                    </Button>

                                    <Typography textAlign="left" sx={{ mt: 3 }}>
                                        派遣先責任者
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label="派遣先責任者の部署名"
                                        value={department.departmentPerson}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPersonChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPersonBlur(i)}
                                        error={addDepartmentError[i]?.departmentPerson !== ""}
                                        sx={{ mt: 1 }}
                                    />
                                    {addDepartmentError[i]?.departmentPerson !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPerson}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="派遣先責任者の電話番号"
                                        value={department.departmentPersonPhone}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPersonPhoneChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPersonPhoneBlur(i)}
                                        error={addDepartmentError[i]?.departmentPersonPhone !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentPersonPhone !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPersonPhone}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="派遣先責任者の役職"
                                        value={department.departmentPersonPosition}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPersonPositionChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPersonPositionBlur(i)}
                                        error={addDepartmentError[i]?.departmentPersonPosition !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentPersonPosition !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPersonPosition}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="派遣先責任者の氏名"
                                        value={department.departmentPersonName}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentPersonNameChange(e, i)}
                                        onBlur={() => handleLocationDepartmentPersonNameBlur(i)}
                                        error={addDepartmentError[i]?.departmentPersonName !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentPersonName !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentPersonName}
                                        </Alert>
                                    )}

                                    <Typography textAlign="left" sx={{ mt: 3 }}>
                                        苦情申し出先
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label="苦情申し出先の部署名"
                                        value={department.departmentTrouble}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentTroubleChange(e, i)}
                                        onBlur={() => handleLocationDepartmentTroubleBlur(i)}
                                        error={addDepartmentError[i]?.departmentTrouble !== ""}
                                        sx={{ mt: 1 }}
                                    />
                                    {addDepartmentError[i]?.departmentTrouble !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentTrouble}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="苦情申し出先の電話番号"
                                        value={department.departmentTroublePhone}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentTroublePhoneChange(e, i)}
                                        onBlur={() => handleLocationDepartmentTroublePhoneBlur(i)}
                                        error={addDepartmentError[i]?.departmentTroublePhone !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentTroublePhone !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentTroublePhone}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="苦情申し出先の役職"
                                        value={department.departmentTroublePosition}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentTroublePositionChange(e, i)}
                                        onBlur={() => handleLocationDepartmentTroublePositionBlur(i)}
                                        error={addDepartmentError[i]?.departmentTroublePosition !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentTroublePosition !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentTroublePosition}
                                        </Alert>
                                    )}

                                    <TextField
                                        variant="outlined"
                                        label="苦情申し出先の氏名"
                                        value={department.departmentTroubleName}
                                        fullWidth
                                        onChange={(e) => handleLocationDepartmentTroubleNameChange(e, i)}
                                        onBlur={() => handleLocationDepartmentTroubleNameBlur(i)}
                                        error={addDepartmentError[i]?.departmentTroubleName !== ""}
                                        sx={{ mt: 3 }}
                                    />
                                    {addDepartmentError[i]?.departmentTroubleName !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentTroubleName}
                                        </Alert>
                                    )}

                                    <Typography textAlign="left" sx={{ mt: 3 }}>
                                        シフト
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        label="シフト名"
                                        value={department.departmentShift.map((shift) => shift.value).join(", ")}
                                        fullWidth
                                        onClick={() => handleDepartmentShiftOpen(i)}
                                        error={addDepartmentError[i]?.departmentShift !== ""}
                                        sx={{ mt: 1 }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {department.departmentShift.length > 0 ? (
                                                        <IconButton
                                                            onClick={(e) => handleDepartmentShiftValueClear(e, i)}
                                                        >
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={() => handleDepartmentShiftOpen(i)}>
                                                            <FilterNoneIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {addDepartmentError[i]?.departmentShift !== "" && (
                                        <Alert sx={{ mt: 1 }} severity="error">
                                            {addDepartmentError[i]?.departmentShift}
                                        </Alert>
                                    )}

                                    {addDepartment.length > 1 && (
                                        <Button
                                            size="large"
                                            color="error"
                                            sx={{ mt: 5 }}
                                            onClick={() => handleDeleteDepartment(i)}
                                        >
                                            部署を削除する
                                        </Button>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    ))}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{ width: "400px" }}
                            onClick={handleAddDepartment}
                            disabled={addDepartment.length >= 10}
                        >
                            部署を追加する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddConfirm}
                            sx={{ width: "600px" }}
                        >
                            入力内容を確認する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 10 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            派遣先企業詳細へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={shiftOpen} onClose={handleDepartmentShiftClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleDepartmentShiftClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6">シフトを選択</Typography>

                    <MaterialReactTable
                        columns={workTimeColumns}
                        data={workTimeData}
                        columnFilterDisplayMode="popover"
                        enableColumnActions={false}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableHiding={false}
                        enableTopToolbar={false}
                        enableRowSelection
                        localization={MRT_Localization_JA}
                        initialState={{ pagination: { pageIndex: 0, pageSize: 10 } }}
                        muiPaginationProps={{ shape: "rounded", color: "primary" }}
                        paginationDisplayMode="pages"
                        positionGlobalFilter="left"
                        positionToolbarAlertBanner="bottom"
                        enableStickyHeader={true}
                        getRowId={(row) => row.name}
                        muiTablePaperProps={{
                            sx: { mt: 2 },
                            elevation: 0,
                        }}
                        muiTableContainerProps={{
                            sx: {
                                minHeight: "300px",
                                maxHeight: "300px",
                                overflow: "auto",
                            },
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => handleDepartmentShiftRowSelectionChange(row, selectedDepartmentIndex),
                            selected: addDepartment[selectedDepartmentIndex].departmentShift.some(
                                (shift) => shift.value === row.original.name
                            ),
                            sx: { cursor: "pointer" },
                        })}
                        state={{
                            rowSelection: addDepartment[selectedDepartmentIndex].departmentShift.reduce(
                                (acc, shift) => {
                                    acc[shift.value] = true;
                                    return acc;
                                },
                                {}
                            ),
                        }}
                    />

                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleDepartmentShiftClose}
                        sx={{ mt: 5, width: "400px" }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        事業所 / 工場の入力内容を確認
                    </Typography>

                    {Object.entries(locationData).map(([key, data]) => (
                        <Box key={key}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 4, md: 4 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 8, md: 8 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    {key === "locationAddress" ? (
                                        <>
                                            <Typography sx={{ textAlign: "left" }}>
                                                {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                <br />
                                                {data.prefecture}
                                                {data.city}
                                                {data.other} {data.building}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography>{data.value}</Typography>
                                    )}
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    {addDepartment.map((department, i) => (
                        <Accordion key={i} expanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{department.departmentName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">部署長の役職</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPersonPosition}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">電話番号</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPersonPhone}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />

                                <Typography sx={{ mt: 3 }}>指揮命令者</Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">部署名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentCommander}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">電話番号</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentCommanderPhone}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">役職</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>
                                            {department.departmentCommanderPosition
                                                .map((position) => position.value)
                                                .join(", ")}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">氏名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>
                                            {department.departmentCommanderName.map((name) => name.value).join(", ")}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />

                                <Typography sx={{ mt: 3 }}>派遣元責任者</Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">部署名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPerson}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">電話番号</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPersonPhone}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">役職</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPersonPosition}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">氏名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentPersonName}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />

                                <Typography sx={{ mt: 3 }}>苦情申し出先</Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">部署名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentTrouble}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">電話番号</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentTroublePhone}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">役職</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentTroublePosition}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography fontWeight="bold">氏名</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{department.departmentTroubleName}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddRegister}
                            sx={{ width: "400px" }}
                        >
                            入力内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            入力画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        事業所 / 工場の登録が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleAddComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default LocationAdd;
