import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBgUveoOIcV_5EIPKiKN9WgsDSsm75aPZI",
    authDomain: "hy-system-demo.firebaseapp.com",
    projectId: "hy-system-demo",
    storageBucket: "hy-system-demo.appspot.com",
    messagingSenderId: "854347927179",
    appId: "1:854347927179:web:789b3f24dc8b136027ce8e",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
