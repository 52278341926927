import React, { useMemo, useState } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip, IconButton, Popper, Alert } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ApplyMedia = ({ val }) => {
    const navigate = useNavigate();

    const applyMediaData = [
        {
            name: "YouTube",
            number: 18,
            status: "受付中",
            url: "https://stg-hr-system.hr-ms.jp/apply/fqn83wvh55it",
        },
        {
            name: "TikTok",
            number: 40,
            status: "受付中",
            url: "https://stg-hr-system.hr-ms.jp/apply/jiefj192jfa9",
        },
        {
            name: "自社求人サイト",
            number: 18,
            status: "受付中",
            url: "https://stg-hr-system.hr-ms.jp/apply/ieu00siai901",
        },
        {
            name: "株式会社有料職業紹介A",
            number: 18,
            status: "受付中",
            url: "https://stg-hr-system.hr-ms.jp/apply/faseaksksiw0",
        },
    ];

    const [mediaData, setMediaData] = useState(["YouTube", "TikTok", "自社求人サイト", "株式会社有料職業紹介A"]);

    const [statusData, setStatusData] = useState(["受付", "受付停止"]);

    const columns = useMemo(
        () => [
            {
                header: "媒体名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
                filterVariant: "select",
                filterSelectOptions: mediaData,
            },
            {
                header: "応募者数",
                accessorKey: "number",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
            {
                header: "受付状況",
                accessorKey: "status",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: statusData,
            },
            {
                header: "応募フォームURL",
                accessorKey: "url",
                Cell: ({ cell }) => (
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                        <IconButton onClick={(e) => handleCopyUrl(e, cell.getValue())}>
                            <ContentCopyIcon sx={{ cursor: "pointer" }} />
                        </IconButton>
                    </Box>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "400px", minWidth: "400px" } },
            },
        ],
        []
    );

    const handleApplyDetail = (name) => {
        window.scrollTo(0, 0);
        //navigate(`/apply/${name}`);
        navigate("/apply/media/detail");
    };

    const handleAddMedia = () => {
        window.scrollTo(0, 0);
        navigate("/apply/media/add");
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleCopyUrl = (e, url) => {
        e.stopPropagation();
        navigator.clipboard.writeText(url);

        setAnchorEl(e.target);

        setTimeout(() => {
            setAnchorEl(null);
        }, 2000);
    };

    const handleReturn = () => {
        navigate("/apply");
    };

    return (
        <>
            <Helmet>
                <title>応募者一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">応募媒体一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleAddMedia}>
                                応募媒体を追加する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box>
                        <MaterialReactTable
                            columns={columns}
                            data={applyMediaData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleApplyDetail(row.original.name),
                                sx: { cursor: "pointer" },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{
                                shape: "rounded",
                                color: "primary",
                            }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                            muiTableBodyProps={{ sx: { "& .MuiTableCell-root": { py: 1 } } }}
                        />
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            応募者一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Popper open={open} anchorEl={anchorEl} placement="top">
                <Alert sx={{ mb: 2 }} severity="success">
                    URLをコピーしました
                </Alert>
            </Popper>
        </>
    );
};

export default ApplyMedia;
