import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,
    Checkbox,
    TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ApplyDetail = ({ val }) => {
    const navigate = useNavigate();

    const [applyMediaData, setApplyMediaData] = useState({
        mediaName: { title: "媒体名", type: "テキストフィールド (単行)", value: "YouTube" },
        mediaApplyName: { title: "氏名", type: "テキストフィールド (単行)", value: "桐ヶ谷 和人" },
        mediaApplyFuri: { title: "フリガナ", type: "テキストフィールド (単行)", value: "キリガヤ カズト" },
        mediaApplyAddress: {
            title: "住所",
            type: "テキストフィールド (単行)",
            post: "1600072",
            prefecture: "東京都",
            city: "新宿区",
            other: "西新宿三丁目3-13",
            building: "西新宿水間ビル6F",
        },
        mediaApplyBirthday: { title: "生年月日", type: "テキストフィールド (単行)", value: "1990/01/01" },
        mediaApplyGender: { title: "性別", type: "ラジオボタン", value: "男性" },
        mediaApplyPhone: { title: "電話番号", type: "テキストフィールド (単行)", value: "090-1234-5678" },
        mediaApplyEmail: { title: "メールアドレス", type: "テキストフィールド (単行)", value: "k-kirigaya@hr-ms.jp" },
        mediaEmploymentNumber: { title: "就業状況", type: "ラジオボタン", value: "離職中" },
        mediaAddition: { title: "追加入力項目", value: [] },
        mediaApplyStatus: { title: "ステータス", value: "受付" },
    });

    const [mediaAdditionData, setMediaAdditionData] = useState([
        { title: "本人確認書類", type: "ファイルアップロード", value: "aaa" },
        { title: "履歴書", type: "ファイルアップロード", value: "" },
    ]);

    const [status, setStatus] = useState("採用");
    const [isChecked, setIsChecked] = useState(true);
    const [reason, setReason] = useState("");

    const handleEditStatus = () => {
        window.scrollTo(0, 0);
        navigate("/apply/status");
    };
    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/apply");
    };

    const [open, setOpen] = useState(false);
    const handleEditOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleIsCheckedChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    const handleStatusConfirm = () => {
        setOpen(false);

        if (status === "採用") {
            applyMediaData.mediaApplyStatus.value = "採用";
            setStatus("採用");
            if (isChecked) {
                // スタッフへデータをコピーする処理
            }
        } else {
            applyMediaData.mediaApplyStatus.value = "不採用";
            setStatus("不採用");
        }

        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleEditComplete = () => {
        setCompleteOpen(false);
    };

    return (
        <>
            <Helmet>
                <title>応募者詳細 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">応募者詳細</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleEditOpen}>
                                ステータスを変更する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaName.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaName.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    {Object.entries(applyMediaData)
                        .slice(1, -3)
                        .map(([key, data]) => (
                            <Box key={key} sx={{}}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">{data.title}</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        {key === "mediaApplyAddress" ? (
                                            <Typography sx={{ textAlign: "left" }}>
                                                {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                <br />
                                                {data.prefecture}
                                                {data.city}
                                                {data.other} {data.building}
                                            </Typography>
                                        ) : (
                                            <Typography>{data.value}</Typography>
                                        )}
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    {mediaAdditionData.length !== 0 &&
                        mediaAdditionData.map((data, index) => (
                            <Box key={index} sx={{}}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">{data.title}</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        {data.type === "ファイルアップロード" ? (
                                            data.value !== "" ? (
                                                <Button color="primary">ファイルを確認する</Button>
                                            ) : (
                                                <Typography>未アップロード</Typography>
                                            )
                                        ) : (
                                            <Typography>{data.value}</Typography>
                                        )}
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    <Box sx={{ mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaApplyStatus.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaApplyStatus.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                        {status === "不採用" && (
                            <>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">理由</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                overflowWrap: "break-word",
                                                whiteSpace: "pre-wrap",
                                            }}
                                        >
                                            {reason}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            応募者一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        応募者のステータスを変更
                    </Typography>
                    <Typography sx={{ width: "400px", textAlign: "left" }}>ステータス</Typography>
                    <FormControl>
                        <RadioGroup row defaultValue="採用" sx={{ gap: 5 }}>
                            <FormControlLabel
                                value="採用"
                                control={<Radio />}
                                label="採用"
                                onChange={handleStatusChange}
                            />
                            <FormControlLabel
                                value="不採用"
                                control={<Radio />}
                                label="不採用"
                                onChange={handleStatusChange}
                            />
                        </RadioGroup>
                    </FormControl>

                    {status === "採用" ? (
                        <FormControlLabel
                            control={
                                <Checkbox checked={isChecked} onChange={handleIsCheckedChange} value="isChecked" />
                            }
                            label="スタッフとして情報を登録する"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "56px",
                                mt: 2,
                            }}
                        />
                    ) : (
                        <TextField
                            label="理由"
                            value={reason}
                            onChange={handleReasonChange}
                            sx={{ width: "400px", mt: 2 }}
                            multiline
                            rows={4}
                        />
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleStatusConfirm}
                        sx={{ width: "400px", mt: 5, mb: 5 }}
                    >
                        ステータスを変更する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={handleClose}
                        sx={{ width: "400px", mb: 2 }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        応募者のステータスを変更しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleEditComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ApplyDetail;
