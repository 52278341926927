import React, { useMemo, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    DialogContentText,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const DispatchDetail = ({ val }) => {
    const navigate = useNavigate();

    const [dispatchData, setDispatchData] = useState({
        dispatchName: { title: "派遣先企業名", value: "株式会社〇〇" },
        dispatchRepresentative: { title: "代表者の役職と氏名", value1: "代表取締役社長", value2: "山田 太郎" },
        dispatchAddress: {
            title: "住所",
            post: "1500000",
            prefecture: "東京都",
            city: "渋谷区",
            other: "代々木1-1-1",
            building: "",
        },
        acceptance: { title: "受入開始日", value: 1704946800000 },
        conflict: { title: "事業所抵触日", value: 1704946800000 },
        content: { title: "業務内容", value: "自動車製造に関するマシンオペレーター" },
        law4: { title: "令第4条第1項", value: "該当しない" },
        noRestrictions: { title: "派遣可能期間の制限を受けない業務に関する事項", value: "該当しない" },
        dayLabor: { title: "日雇派遣例外要件確認書類", value: false },
        flextime: { title: "フレックスタイム制の有無", value: false },
        shiftWork: { title: "交替制の有無", value: true },
        convenience: {
            title: "便宜供与",
            value: [
                { id: 1, value: "診断所", checked: true },
                { id: 2, value: "保育所", checked: false },
                { id: 3, value: "制服貸与", checked: false },
            ],
        },
        limited: {
            title: "派遣労働者の限定",
            value: [
                { id: 1, value: "無期雇用労働者", checked: true },
                { id: 2, value: "60歳以上の者", checked: false },
                { id: 3, value: "協定対象労働者", checked: false },
            ],
        },
        closingDate: { title: "勤怠の締日", value: "末日" },
        dispatchFee: { title: "派遣料金", value: "1500000" },
        dispatchSalary: { title: "給与区分", value: "月給制" },
        baseSalary: { title: "基本給", value: "1000000" },
        jobSalary: { title: "変動基本給", value: "100000" },
        hourSalary: { title: "1時間あたり", value: "1500" },
        dailyCalculation: { title: "日々計算", decimal: "1", process: "切り上げ" },
        variousTotal: { title: "各合計", decimal: "0", process: "切り捨て" },
        total: { title: "合計", decimal: "0", process: "切り捨て" },
        division: { title: "管轄部署", value: "営業部" },
    });

    const [locationData, setLocationData] = useState([
        {
            locationName: "〇〇工場",
            locationAddress: {
                title: "住所",
                post: "1500000",
                prefecture: "東京都",
                city: "渋谷区",
                other: "代々木1-1-1",
            },
            location: {
                departmentName: "製造部 第1〇〇課",
                departmentPosition: "課長",
                departmentPhone: "03-1234-5678",
                departmentCommander: "製造部 第1〇〇課 〇〇工程",
                departmentCommanderPhone: "03-1234-5678",
                departmentCommanderPosition: [{ value: "課長" }],
                departmentCommanderName: [{ value: "〇〇太郎" }],
                departmentPerson: "〇〇一郎",
                departmentPersonPhone: "03-1234-5678",
                departmentPersonPosition: "係長",
                departmentPersonName: "〇〇二郎",
                departmentTrouble: "総務部 第1〇〇課",
                departmentTroublePhone: "03-1234-5678",
                departmentTroublePosition: "課長",
                departmentTroubleName: "〇〇三郎",
                departmentShift: [
                    {
                        name: "日勤",
                        startHour: "09",
                        startMinute: "00",
                        endHour: "18",
                        endMinute: "00",
                        breakTime: [
                            {
                                startHour: "12",
                                startMinute: "00",
                                endHour: "13",
                                endMinute: "00",
                            },
                        ],
                    },
                    {
                        name: "夜勤",
                        startHour: "18",
                        startMinute: "00",
                        endHour: "09",
                        endMinute: "00",
                        breakTime: [
                            {
                                startHour: "00",
                                startMinute: "00",
                                endHour: "01",
                                endMinute: "00",
                            },
                            {
                                startHour: "04",
                                startMinute: "00",
                                endHour: "05",
                                endMinute: "00",
                            },
                        ],
                    },
                ],
            },
        },
        {
            locationName: "△△工場",
            locationAddress: {
                title: "住所",
                post: "1600000",
                prefecture: "東京都",
                city: "新宿区",
                other: "新宿1-1-1",
            },
            location: {
                departmentName: "製造部 第1〇〇課",
                departmentPosition: "課長",
                departmentPhone: "03-1234-5678",
                departmentCommander: "製造部 第1〇〇課 〇〇工程",
                departmentCommanderPhone: "03-1234-5678",
                departmentCommanderPosition: [{ value: "課長" }],
                departmentCommanderName: [{ value: "〇〇太郎" }],
                departmentPerson: "〇〇一郎",
                departmentPersonPhone: "03-1234-5678",
                departmentPersonPosition: "係長",
                departmentPersonName: "〇〇二郎",
                departmentTrouble: "総務部 第1〇〇課",
                departmentTroublePhone: "03-1234-5678",
                departmentTroublePosition: "課長",
                departmentTroubleName: "〇〇三郎",
                departmentShift: [
                    {
                        name: "日勤",
                        startHour: "09",
                        startMinute: "00",
                        endHour: "18",
                        endMinute: "00",
                        breakTime: [
                            {
                                startHour: "12",
                                startMinute: "00",
                                endHour: "13",
                                endMinute: "00",
                            },
                        ],
                    },
                ],
            },
        },
    ]);

    const [staffData, setStaffData] = useState([
        {
            name: "立花 響",
            gender: "女性",
            age: "18",
            location: "〇〇工場",
            department: "製造部第1〇〇部〇〇課",
            shift: "1係",
        },
        {
            name: "風鳴 翼",
            gender: "女性",
            age: "20",
            location: "〇〇工場",
            department: "製造部第2〇〇部〇〇課",
            shift: "1係",
        },
        {
            name: "雪音 クリス",
            gender: "女性",
            age: "17",
            location: "〇〇工場",
            department: "製造部第3〇〇部〇〇課",
            shift: "2係",
        },
        {
            name: "月読 調",
            gender: "女性",
            age: "16",
            location: "〇〇工場",
            department: "製造部第4〇〇部〇〇課",
            shift: "2係",
        },
        {
            name: "天羽 奏",
            gender: "女性",
            age: "19",
            location: "〇〇工場",
            department: "製造部第1〇〇部〇〇課",
            shift: "1係",
        },
        {
            name: "小日向 未来",
            gender: "女性",
            age: "21",
            location: "〇〇工場",
            department: "製造部第8〇〇部〇〇課",
            shift: "1係",
        },
        {
            name: "マリア・カデンツァヴナ・イヴ",
            gender: "女性",
            age: "22",
            location: "〇〇工場",
            department: "製造部第9〇〇部〇〇課",
            shift: "1係",
        },
    ]);

    const [genderData] = useState(["男性", "女性"]);

    const columns = useMemo(
        () => [
            {
                header: "事業所 / 工場",
                accessorKey: "location",
            },
            {
                header: "部署",
                accessorKey: "department",
            },
            {
                header: "シフト",
                accessorKey: "shift",
            },
            {
                header: "氏名",
                accessorKey: "name",
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "性別",
                accessorKey: "gender",
                filterVariant: "select",
                filterSelectOptions: genderData,
            },
            {
                header: "年齢",
                accessorKey: "age",
                filterVariant: "range",
                filterFn: "betweenInclusive",
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: staffData,
        enableColumnDragging: false,
        groupedColumnMode: "remove",
        enableColumnActions: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        localization: MRT_Localization_JA,
        columnFilterDisplayMode: "popover",
        initialState: {
            pagination: { pageIndex: 0, pageSize: 10 },
            showGlobalFilter: true,
            sorting: [
                { id: "location", desc: false },
                { id: "department", desc: false },
                { id: "shift", desc: false },
                { id: "name", desc: false },
            ],
        },
        paginationDisplayMode: "pages",
        positionGlobalFilter: "left",
        muiTablePaperProps: { elevation: 0 },
        positionToolbarAlertBanner: "none",
        enablePagination: true,
        muiTableBodyRowProps: { hover: false },
        muiPaginationProps: {
            shape: "rounded",
            color: "primary",
        },
    });

    const [workTimeData, setWorkTimeData] = useState([
        {
            name: "日勤",
            startHour: "09",
            startMinute: "00",
            endHour: "18",
            endMinute: "00",
            breakTime: [
                {
                    startHour: "12",
                    startMinute: "00",
                    endHour: "13",
                    endMinute: "00",
                },
            ],
        },
        {
            name: "夜勤",
            startHour: "18",
            startMinute: "00",
            endHour: "09",
            endMinute: "00",
            breakTime: [
                {
                    startHour: "00",
                    startMinute: "00",
                    endHour: "01",
                    endMinute: "00",
                },
                {
                    startHour: "04",
                    startMinute: "00",
                    endHour: "05",
                    endMinute: "00",
                },
            ],
        },
    ]);

    const workTimeColumns = useMemo(
        () => [
            {
                header: "シフト名",
                accessorKey: "name",
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "操作",
                accessorKey: "actions",
                Cell: () => (
                    <Button size="small" color="primary" onClick={handleWorkTimeEdit}>
                        編集する
                    </Button>
                ),
            },
        ],
        []
    );

    const workTimeTable = useMaterialReactTable({
        columns: workTimeColumns,
        data: workTimeData,
        enableColumnActions: false,
        enablePagination: false,
        enableSorting: false,
        enableRowDetails: true,
        localization: MRT_Localization_JA,
        initialState: {
            density: "compact",
            expanded: false,
        },
        muiTablePaperProps: { elevation: 0 },
        enableTopToolbar: false,
        enableBottomToolbar: false,
        renderDetailPanel: ({ row }) => (
            <>
                <Grid2 container sx={{ mb: 1 }}>
                    <Grid2 size={{ xs: 2, md: 2 }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            勤務時間
                        </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 6, md: 6 }}>
                        <Typography variant="body2">
                            {row.original.startHour +
                                ":" +
                                row.original.startMinute +
                                " 〜 " +
                                row.original.endHour +
                                ":" +
                                row.original.endMinute}
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2 container>
                    <Grid2 size={{ xs: 2, md: 2 }} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            休憩時間
                        </Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 6, md: 6 }} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2">
                            {row.original.breakTime.map((breakTime, key) => (
                                <Box key={key}>
                                    {breakTime.startHour +
                                        ":" +
                                        breakTime.startMinute +
                                        " 〜 " +
                                        breakTime.endHour +
                                        ":" +
                                        breakTime.endMinute}
                                </Box>
                            ))}
                        </Typography>
                    </Grid2>
                </Grid2>
            </>
        ),
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                if (row.depth === 0) {
                    row.toggleExpanded();
                }
            },
            sx: {
                cursor: row.depth === 0 ? "pointer" : "default",
                "&:hover": {
                    backgroundColor: row.depth === 0 ? "#f5f5f5" : "inherit",
                },
            },
        }),
        muiDetailPanelProps: {
            onClick: (e) => {
                e.stopPropagation();
            },
            sx: {
                cursor: "default",
                backgroundColor: "#FFF",
            },
        },
    });

    const [workTimeAddData, setWorkTimeAddData] = useState({
        name: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
    });
    const [addBreakTimeData, setAddBreakTimeData] = useState([
        { startHour: "", startMinute: "", endHour: "", endMinute: "" },
    ]);

    const [workTimeAddError, setWorkTimeAddError] = useState({
        name: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
    });

    const [addBreakTimeError, setAddBreakTimeError] = useState([
        { startHour: "", startMinute: "", endHour: "", endMinute: "" },
    ]);

    const [workTimeEditData, setWorkTimeEditData] = useState({
        name: "日勤",
        startHour: "09",
        startMinute: "00",
        endHour: "18",
        endMinute: "00",
    });
    const [editBreakTimeData, setEditBreakTimeData] = useState([
        { startHour: "12", startMinute: "00", endHour: "13", endMinute: "00" },
    ]);

    const [workTimeEditError, setWorkTimeEditError] = useState({
        name: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
    });

    const [editBreakTimeError, setEditBreakTimeError] = useState([
        { startHour: "", startMinute: "", endHour: "", endMinute: "" },
    ]);

    const [validation, setValidation] = useState("");
    const [editValidation, setEditValidation] = useState("");

    const handleDispatchEdit = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch/detail/edit");
    };

    const handleLocationAdd = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch/detail/location/add");
    };

    const handleShiftList = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch/detail/location/shift");
    };

    const handleLocationEdit = (id) => {
        window.scrollTo(0, 0);
        // navigate(`/dispatch/detail/${id}`);
        navigate("/dispatch/detail/location/edit");
    };

    const [workTimeOpen, setWorkTimeOpen] = useState(false);
    const [workTimeAddOpen, setWorkTimeAddOpen] = useState(false);
    const [workTimeAddCompleteOpen, setWorkTimeAddCompleteOpen] = useState(false);
    const [workTimeEditOpen, setWorkTimeEditOpen] = useState(false);
    const [workTimeEditCompleteOpen, setWorkTimeEditCompleteOpen] = useState(false);
    const [workTimeDeleteOpen, setWorkTimeDeleteOpen] = useState(false);
    const [workTimeDeleteCompleteOpen, setWorkTimeDeleteCompleteOpen] = useState(false);

    const handleWorkTimeOpen = () => {
        setWorkTimeOpen(true);
    };
    const handleWorkTimeClose = () => {
        setWorkTimeOpen(false);
    };

    const handleWorkTimeAdd = () => {
        setWorkTimeOpen(false);
        setWorkTimeAddOpen(true);
    };
    const handleWorkTimeAddClose = () => {
        setWorkTimeAddOpen(false);
        setWorkTimeOpen(true);
    };

    const handleWorkTimeAddNameChange = (e) => {
        setWorkTimeAddData({
            ...workTimeAddData,
            name: e.target.value,
        });
    };
    const handleWorkTimeAddNameBlur = () => {
        setWorkTimeAddError({
            ...workTimeAddError,
            name: workTimeAddData.name.trim() === "" ? "シフト名を入力してください" : "",
        });
    };

    const handleWorkTimeAddStartHourChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeAddData({ ...workTimeAddData, startHour: value });
    };
    const handleWorkTimeAddStartHourBlur = () => {
        setWorkTimeAddError({
            ...workTimeAddError,
            startHour:
                workTimeAddData.startHour === "" || parseInt(workTimeAddData.startHour) >= 24
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeAddStartMinuteChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeAddData({ ...workTimeAddData, startMinute: value });
    };
    const handleWorkTimeAddStartMinuteBlur = () => {
        setWorkTimeAddError({
            ...workTimeAddError,
            startMinute:
                workTimeAddData.startMinute === "" || parseInt(workTimeAddData.startMinute) >= 60
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeAddEndHourChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeAddData({ ...workTimeAddData, endHour: value });
    };
    const handleWorkTimeAddEndHourBlur = () => {
        setWorkTimeAddError({
            ...workTimeAddError,
            endHour:
                workTimeAddData.endHour === "" || parseInt(workTimeAddData.endHour) >= 24
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeAddEndMinuteChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeAddData({ ...workTimeAddData, endMinute: value });
    };
    const handleWorkTimeAddEndMinuteBlur = () => {
        setWorkTimeAddError({
            ...workTimeAddError,
            endMinute:
                workTimeAddData.endMinute === "" || parseInt(workTimeAddData.endMinute) >= 60
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeAddBreakTimeStartHourChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setAddBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], startHour: value };
            return newData;
        });
    };
    const handleWorkTimeAddBreakTimeStartHourBlur = (i) => {
        setAddBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].startHour =
                addBreakTimeData[i].startHour === "" || parseInt(addBreakTimeData[i].startHour) >= 24
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeAddBreakTimeStartMinuteChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setAddBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], startMinute: value };
            return newData;
        });
    };
    const handleWorkTimeAddBreakTimeStartMinuteBlur = (i) => {
        setAddBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].startMinute =
                addBreakTimeData[i].startMinute === "" || parseInt(addBreakTimeData[i].startMinute) >= 60
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeAddBreakTimeEndHourChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setAddBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], endHour: value };
            return newData;
        });
    };
    const handleWorkTimeAddBreakTimeEndHourBlur = (i) => {
        setAddBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].endHour =
                addBreakTimeData[i].endHour === "" || parseInt(addBreakTimeData[i].endHour) >= 24
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeAddBreakTimeEndMinuteChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setAddBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], endMinute: value };
            return newData;
        });
    };
    const handleWorkTimeAddBreakTimeEndMinuteBlur = (i) => {
        setAddBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].endMinute =
                addBreakTimeData[i].endMinute === "" || parseInt(addBreakTimeData[i].endMinute) >= 60
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeAddBreakTimeAdd = () => {
        if (addBreakTimeData.length < 4) {
            setAddBreakTimeData([...addBreakTimeData, { startHour: "", startMinute: "", endHour: "", endMinute: "" }]);
            setAddBreakTimeError([
                ...addBreakTimeError,
                { startHour: "", startMinute: "", endHour: "", endMinute: "" },
            ]);
        }
    };

    const handleWorkTimeAddBreakTimeDelete = (i) => {
        setAddBreakTimeData(addBreakTimeData.filter((_, index) => index !== i));
        setAddBreakTimeError(addBreakTimeError.filter((_, index) => index !== i));
    };

    const handleWorkTimeAddComplete = () => {
        workTimeAddError.name = workTimeAddData.name.trim() === "" ? "シフト名を入力してください" : "";
        workTimeAddError.startHour =
            workTimeAddData.startHour === "" || parseInt(workTimeAddData.startHour) >= 24
                ? "勤務時間を入力してください"
                : "";
        workTimeAddError.startMinute =
            workTimeAddData.startMinute === "" || parseInt(workTimeAddData.startMinute) >= 60
                ? "勤務時間を入力してください"
                : "";
        workTimeAddError.endHour =
            workTimeAddData.endHour === "" || parseInt(workTimeAddData.endHour) >= 24
                ? "勤務時間を入力してください"
                : "";
        workTimeAddError.endMinute =
            workTimeAddData.endMinute === "" || parseInt(workTimeAddData.endMinute) >= 60
                ? "勤務時間を入力してください"
                : "";

        if (addBreakTimeData.length > 0) {
            addBreakTimeError.forEach((item, index) => {
                addBreakTimeError[index].startHour =
                    addBreakTimeData[index].startHour === "" || parseInt(addBreakTimeData[index].startHour) >= 24
                        ? "休憩時間を入力してください"
                        : "";
                addBreakTimeError[index].startMinute =
                    addBreakTimeData[index].startMinute === "" || parseInt(addBreakTimeData[index].startMinute) >= 60
                        ? "休憩時間を入力してください"
                        : "";
                addBreakTimeError[index].endHour =
                    addBreakTimeData[index].endHour === "" || parseInt(addBreakTimeData[index].endHour) >= 24
                        ? "休憩時間を入力してください"
                        : "";
                addBreakTimeError[index].endMinute =
                    addBreakTimeData[index].endMinute === "" || parseInt(addBreakTimeData[index].endMinute) >= 60
                        ? "休憩時間を入力してください"
                        : "";
            });
        }

        if (
            workTimeAddError.name === "" &&
            workTimeAddError.startHour === "" &&
            workTimeAddError.startMinute === "" &&
            workTimeAddError.endHour === "" &&
            workTimeAddError.endMinute === "" &&
            addBreakTimeError.every(
                (item) =>
                    item.startHour === "" && item.startMinute === "" && item.endHour === "" && item.endMinute === ""
            )
        ) {
            setValidation("");
            setWorkTimeAddOpen(false);
            setWorkTimeAddCompleteOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const handleWorkTimeAddCompleteClose = () => {
        setWorkTimeAddData({
            name: "",
            startHour: "",
            startMinute: "",
            endHour: "",
            endMinute: "",
        });
        setAddBreakTimeData([{ startHour: "", startMinute: "", endHour: "", endMinute: "" }]);
        setAddBreakTimeError([{ startHour: "", startMinute: "", endHour: "", endMinute: "" }]);
        setWorkTimeAddCompleteOpen(false);
        setWorkTimeOpen(true);
    };

    const handleWorkTimeEdit = (e) => {
        e.stopPropagation();
        setWorkTimeEditOpen(true);
        setWorkTimeOpen(false);
    };
    const handleWorkTimeEditClose = () => {
        setWorkTimeEditOpen(false);
    };

    const handleWorkTimeEditNameChange = (e) => {
        setWorkTimeEditData({
            ...workTimeEditData,
            name: e.target.value,
        });
    };
    const handleWorkTimeEditNameBlur = () => {
        setWorkTimeEditError({
            ...workTimeEditError,
            name: workTimeEditData.name.trim() === "" ? "シフト名を入力してください" : "",
        });
    };

    const handleWorkTimeEditStartHourChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeEditData({ ...workTimeEditData, startHour: value });
    };
    const handleWorkTimeEditStartHourBlur = () => {
        setWorkTimeEditError({
            ...workTimeEditError,
            startHour:
                workTimeEditData.startHour === "" || parseInt(workTimeEditData.startHour) >= 24
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeEditStartMinuteChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeEditData({ ...workTimeEditData, startMinute: value });
    };
    const handleWorkTimeEditStartMinuteBlur = () => {
        setWorkTimeEditError({
            ...workTimeEditError,
            startMinute:
                workTimeEditData.startMinute === "" || parseInt(workTimeEditData.startMinute) >= 60
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeEditEndHourChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeEditData({ ...workTimeEditData, endHour: value });
    };
    const handleWorkTimeEditEndHourBlur = () => {
        setWorkTimeEditError({
            ...workTimeEditError,
            endHour:
                workTimeEditData.endHour === "" || parseInt(workTimeEditData.endHour) >= 24
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeEditEndMinuteChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setWorkTimeEditData({ ...workTimeEditData, endMinute: value });
    };
    const handleWorkTimeEditEndMinuteBlur = () => {
        setWorkTimeEditError({
            ...workTimeEditError,
            endMinute:
                workTimeEditData.endMinute === "" || parseInt(workTimeEditData.endMinute) >= 60
                    ? "勤務時間を入力してください"
                    : "",
        });
    };

    const handleWorkTimeEditBreakTimeStartHourChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setEditBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], startHour: value };
            return newData;
        });
    };
    const handleWorkTimeEditBreakTimeStartHourBlur = (i) => {
        setEditBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].startHour =
                editBreakTimeData[i].startHour === "" || parseInt(editBreakTimeData[i].startHour) >= 24
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeEditBreakTimeStartMinuteChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setEditBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], startMinute: value };
            return newData;
        });
    };
    const handleWorkTimeEditBreakTimeStartMinuteBlur = (i) => {
        setEditBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].startMinute =
                editBreakTimeData[i].startMinute === "" || parseInt(editBreakTimeData[i].startMinute) >= 60
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeEditBreakTimeEndHourChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setEditBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], endHour: value };
            return newData;
        });
    };
    const handleWorkTimeEditBreakTimeEndHourBlur = (i) => {
        setEditBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].endHour =
                editBreakTimeData[i].endHour === "" || parseInt(editBreakTimeData[i].endHour) >= 24
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeEditBreakTimeEndMinuteChange = (e, i) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
        setEditBreakTimeData((prev) => {
            const newData = [...prev];
            newData[i] = { ...newData[i], endMinute: value };
            return newData;
        });
    };
    const handleWorkTimeEditBreakTimeEndMinuteBlur = (i) => {
        setEditBreakTimeError((prev) => {
            const newData = [...prev];
            newData[i].endMinute =
                editBreakTimeData[i].endMinute === "" || parseInt(editBreakTimeData[i].endMinute) >= 60
                    ? "休憩時間を入力してください"
                    : "";
            return newData;
        });
    };

    const handleWorkTimeEditBreakTimeAdd = () => {
        if (editBreakTimeData.length < 4) {
            setEditBreakTimeData([
                ...editBreakTimeData,
                { startHour: "", startMinute: "", endHour: "", endMinute: "" },
            ]);
            setEditBreakTimeError([
                ...editBreakTimeError,
                { startHour: "", startMinute: "", endHour: "", endMinute: "" },
            ]);
        }
    };

    const handleWorkTimeEditBreakTimeDelete = (i) => {
        setEditBreakTimeData(editBreakTimeData.filter((_, index) => index !== i));
        setEditBreakTimeError(editBreakTimeError.filter((_, index) => index !== i));
    };

    const handleWorkTimeEditComplete = () => {
        workTimeEditError.name = workTimeEditData.name.trim() === "" ? "シフト名を入力してください" : "";
        workTimeEditError.startHour =
            workTimeEditData.startHour === "" || parseInt(workTimeEditData.startHour) >= 24
                ? "勤務時間を入力してください"
                : "";
        workTimeEditError.startMinute =
            workTimeEditData.startMinute === "" || parseInt(workTimeEditData.startMinute) >= 60
                ? "勤務時間を入力してください"
                : "";
        workTimeEditError.endHour =
            workTimeEditData.endHour === "" || parseInt(workTimeEditData.endHour) >= 24
                ? "勤務時間を入力してください"
                : "";
        workTimeEditError.endMinute =
            workTimeEditData.endMinute === "" || parseInt(workTimeEditData.endMinute) >= 60
                ? "勤務時間を入力してください"
                : "";

        if (editBreakTimeData.length > 0) {
            editBreakTimeError.forEach((item, index) => {
                editBreakTimeError[index].startHour =
                    editBreakTimeData[index].startHour === "" || parseInt(editBreakTimeData[index].startHour) >= 24
                        ? "休憩時間を入力してください"
                        : "";
                editBreakTimeError[index].startMinute =
                    editBreakTimeData[index].startMinute === "" || parseInt(editBreakTimeData[index].startMinute) >= 60
                        ? "休憩時間を入力してください"
                        : "";
                editBreakTimeError[index].endHour =
                    editBreakTimeData[index].endHour === "" || parseInt(editBreakTimeData[index].endHour) >= 24
                        ? "休憩時間を入力してください"
                        : "";
                editBreakTimeError[index].endMinute =
                    editBreakTimeData[index].endMinute === "" || parseInt(editBreakTimeData[index].endMinute) >= 60
                        ? "休憩時間を入力してください"
                        : "";
            });
        }

        if (
            workTimeEditError.name === "" &&
            workTimeEditError.startHour === "" &&
            workTimeEditError.startMinute === "" &&
            workTimeEditError.endHour === "" &&
            workTimeEditError.endMinute === "" &&
            editBreakTimeError.every(
                (item) =>
                    item.startHour === "" && item.startMinute === "" && item.endHour === "" && item.endMinute === ""
            )
        ) {
            setEditValidation("");
            setWorkTimeEditOpen(false);
            setWorkTimeEditCompleteOpen(true);
        } else {
            setEditValidation("入力内容に不備があります");
        }
    };

    const handleWorkTimeEditCompleteClose = () => {
        setWorkTimeEditData({
            name: "日勤",
            startHour: "9",
            startMinute: "00",
            endHour: "18",
            endMinute: "00",
        });
        setEditBreakTimeData([{ startHour: "12", startMinute: "00", endHour: "13", endMinute: "00" }]);
        setEditBreakTimeError([{ startHour: "", startMinute: "", endHour: "", endMinute: "" }]);
        setWorkTimeEditCompleteOpen(false);
        setWorkTimeOpen(true);
    };

    const handleWorkTimeDelete = () => {
        setWorkTimeEditOpen(false);
        setWorkTimeDeleteOpen(true);
    };
    const handleWorkTimeDeleteClose = () => {
        setWorkTimeDeleteOpen(false);
        setWorkTimeEditOpen(true);
    };

    const handleWorkTimeDeleteComplete = () => {
        setWorkTimeDeleteOpen(false);
        setWorkTimeDeleteCompleteOpen(true);
    };
    const handleWorkTimeDeleteCompleteClose = () => {
        setWorkTimeDeleteCompleteOpen(false);
        setWorkTimeOpen(true);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/dispatch");
    };

    return (
        <>
            <Helmet>
                <title>派遣先企業情報 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                        <Typography variant="h6">派遣先企業情報</Typography>
                    </Box>
                    <Grid2 container sx={{ mb: 3 }}>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">基本情報</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleDispatchEdit}>
                                基本情報を編集する
                            </Button>
                            <IconButton sx={{ ml: 2 }} onClick={handleWorkTimeOpen}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    {Object.entries(dispatchData)
                        .slice(0, 15)
                        .map(([key, data]) => (
                            <Box key={key}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        {key === "noRestrictions" ? (
                                            <Typography textAlign="left" fontWeight="bold">
                                                派遣可能期間の制限を受けない
                                                <br />
                                                業務に関する事項
                                            </Typography>
                                        ) : (
                                            <Typography fontWeight="bold">{data.title}</Typography>
                                        )}
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        {key === "dispatchRepresentative" ? (
                                            <>
                                                <Typography>{data.value1}</Typography>
                                                <Typography sx={{ ml: 2 }}>{data.value2}</Typography>
                                            </>
                                        ) : key === "dispatchAddress" ? (
                                            <>
                                                <Typography sx={{ textAlign: "left" }}>
                                                    {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                    <br />
                                                    {data.prefecture}
                                                    {data.city}
                                                    {data.other} {data.building}
                                                </Typography>
                                            </>
                                        ) : key === "dailyCalculation" ? (
                                            <>
                                                <Typography>小数点第{data.decimal}位</Typography>
                                                <Typography sx={{ ml: 2 }}>{data.process}</Typography>
                                            </>
                                        ) : key === "variousTotal" ? (
                                            <>
                                                <Typography>小数点第{data.decimal}位</Typography>
                                                <Typography sx={{ ml: 2 }}>{data.process}</Typography>
                                            </>
                                        ) : key === "total" ? (
                                            <>
                                                <Typography>小数点第{data.decimal}位</Typography>
                                                <Typography sx={{ ml: 2 }}>{data.process}</Typography>
                                            </>
                                        ) : key === "acceptance" ? (
                                            <Typography>{new Date(data.value).toLocaleDateString("ja-JP")}</Typography>
                                        ) : key === "conflict" ? (
                                            <Typography>{new Date(data.value).toLocaleDateString("ja-JP")}</Typography>
                                        ) : key === "dayLabor" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "flextime" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "shiftWork" ? (
                                            <Typography>{data.value === false ? "無" : "有"}</Typography>
                                        ) : key === "convenience" ? (
                                            <>
                                                <Grid2 size={{ xs: 1, md: 1 }}>
                                                    <Typography textAlign="left">{data.value[0].value}</Typography>
                                                    <Typography textAlign="left">{data.value[1].value}</Typography>
                                                    <Typography textAlign="left">{data.value[2].value}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 1.2, md: 1.2 }}>
                                                    <Typography>
                                                        {data.value[0].checked === true ? "有" : "無"}
                                                    </Typography>
                                                    <Typography>
                                                        {data.value[1].checked === true ? "有" : "無"}
                                                    </Typography>
                                                    <Typography>
                                                        {data.value[2].checked === true ? "有" : "無"}
                                                    </Typography>
                                                </Grid2>
                                            </>
                                        ) : key === "limited" ? (
                                            <>
                                                <Grid2 size={{ xs: 1.7, md: 1.7 }}>
                                                    <Typography textAlign="left">{data.value[0].value}</Typography>
                                                    <Typography textAlign="left">{data.value[1].value}</Typography>
                                                    <Typography textAlign="left">{data.value[2].value}</Typography>
                                                </Grid2>
                                                <Grid2 size={{ xs: 1.7, md: 1.7 }}>
                                                    <Typography textAlign="left">
                                                        {data.value[0].checked === true ? "限定する" : "限定しない"}
                                                    </Typography>
                                                    <Typography textAlign="left">
                                                        {data.value[1].checked === true ? "限定する" : "限定しない"}
                                                    </Typography>
                                                    <Typography textAlign="left">
                                                        {data.value[2].checked === true ? "限定する" : "限定しない"}
                                                    </Typography>
                                                </Grid2>
                                            </>
                                        ) : key === "dispatchFee" ? (
                                            <Typography>
                                                {data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円 / 時間
                                            </Typography>
                                        ) : (
                                            <Typography>{data.value}</Typography>
                                        )}
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">社員支払条件</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Grid2 size={{ xs: 1.5, md: 1.5 }}>
                                <Typography textAlign="left">{dispatchData.dispatchSalary.title}</Typography>
                                <Typography textAlign="left" fontWeight="bold">
                                    内訳
                                </Typography>
                                {dispatchData.dispatchSalary.value === "月給制" ? (
                                    <>
                                        <Typography textAlign="left">{dispatchData.baseSalary.title}</Typography>
                                        <Typography textAlign="left">{dispatchData.jobSalary.title}</Typography>
                                        <Typography textAlign="left">{dispatchData.hourSalary.title}</Typography>
                                    </>
                                ) : dispatchData.dispatchSalary.value === "日給制" ? (
                                    <Typography textAlign="left">{dispatchData.baseSalary.title}</Typography>
                                ) : (
                                    <Typography textAlign="left">{dispatchData.hourSalary.title}</Typography>
                                )}
                            </Grid2>
                            <Grid2 size={{ xs: 1.2, md: 1.2 }}>
                                <Typography textAlign="left">{dispatchData.dispatchSalary.value}</Typography>
                                <Typography>
                                    <br />
                                </Typography>
                                {dispatchData.dispatchSalary.value === "月給制" ? (
                                    <>
                                        <Typography textAlign="right">
                                            {dispatchData.baseSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                        <Typography textAlign="right">
                                            {dispatchData.jobSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                        <Typography textAlign="right">
                                            {dispatchData.hourSalary.value
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            円
                                        </Typography>
                                    </>
                                ) : dispatchData.dispatchSalary.value === "日給制" ? (
                                    <Typography textAlign="right">
                                        {dispatchData.baseSalary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                ) : (
                                    <Typography textAlign="right">
                                        {dispatchData.hourSalary.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        円
                                    </Typography>
                                )}
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">請求処理</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Grid2 size={{ xs: 1.5, md: 1.5 }}>
                                <Typography textAlign="left">{dispatchData.dailyCalculation.title}</Typography>
                                <Typography textAlign="left">{dispatchData.variousTotal.title}</Typography>
                                <Typography textAlign="left">{dispatchData.total.title}</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 10.5, md: 10.5 }}>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.dailyCalculation.decimal}位
                                    {dispatchData.dailyCalculation.process}
                                </Typography>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.variousTotal.decimal}位{dispatchData.variousTotal.process}
                                </Typography>
                                <Typography textAlign="left">
                                    小数点第{dispatchData.total.decimal}位{dispatchData.total.process}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container spacing={2}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography fontWeight="bold">{dispatchData.division.title}</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography textAlign="left">{dispatchData.division.value}</Typography>
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 1 }} />

                    <Grid2 container sx={{ mt: 5 }}>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">事業所 / 工場情報</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleLocationAdd}>
                                事業所 / 工場を追加する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 3 }}>
                        {locationData.map((location, i) => (
                            <Accordion key={i}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{location.locationName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid2 container spacing={2}>
                                        <Grid2
                                            size={{ xs: 4, md: 4 }}
                                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                        >
                                            <Typography fontWeight="bold">{location.locationAddress.title}</Typography>
                                        </Grid2>
                                        <Grid2
                                            size={{ xs: 8, md: 8 }}
                                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                        >
                                            <Typography sx={{ textAlign: "left" }}>
                                                {"〒" + location.locationAddress.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                <br />
                                                {location.locationAddress.prefecture}
                                                {location.locationAddress.city}
                                                {location.locationAddress.other}
                                                {location.locationAddress.building}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Divider sx={{ my: 1 }} />

                                    <Box sx={{ mt: 3 }}>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            sx={{ width: "400px" }}
                                            onClick={handleShiftList}
                                        >
                                            シフトを確認する
                                        </Button>
                                    </Box>

                                    <Typography textAlign={"left"} sx={{ mt: 3, mb: 1 }}>
                                        部署
                                    </Typography>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{location.location.departmentName}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">部署長の役職</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentPosition}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">電話番号</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentPhone}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />

                                            <Typography textAlign="left" sx={{ mt: 3 }}>
                                                指揮命令者
                                            </Typography>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">部署名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentCommander}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">電話番号</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>
                                                        {location.location.departmentCommanderPhone}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">役職</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>
                                                        {location.location.departmentCommanderPosition
                                                            .map((position) => position.value)
                                                            .join(", ")}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">氏名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>
                                                        {location.location.departmentCommanderName
                                                            .map((name) => name.value)
                                                            .join(", ")}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />

                                            <Typography textAlign="left" sx={{ mt: 3 }}>
                                                派遣元責任者
                                            </Typography>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">部署名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentPerson}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">電話番号</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentPersonPhone}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">役職</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>
                                                        {location.location.departmentPersonPosition}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">氏名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentPersonName}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />

                                            <Typography textAlign="left" sx={{ mt: 3 }}>
                                                苦情申し出先
                                            </Typography>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">部署名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentTrouble}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">電話番号</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentTroublePhone}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">役職</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>
                                                        {location.location.departmentTroublePosition}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 4, md: 4 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">氏名</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 8, md: 8 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{location.location.departmentTroubleName}</Typography>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />

                                            <Typography textAlign="left" sx={{ mt: 3 }}>
                                                シフト
                                            </Typography>
                                            {location.location.departmentShift.map((shift, i) => (
                                                <React.Fragment key={i}>
                                                    <Typography textAlign="left" sx={{ mt: 3 }}>
                                                        {shift.name}
                                                    </Typography>
                                                    <Grid2 key={i} container spacing={2}>
                                                        <Grid2
                                                            size={{ xs: 4, md: 4 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold">勤務時間</Typography>
                                                        </Grid2>
                                                        <Grid2
                                                            size={{ xs: 8, md: 8 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography>
                                                                {shift.startHour}時{shift.startMinute}分 〜{" "}
                                                                {shift.endHour}時{shift.endMinute}分
                                                            </Typography>
                                                        </Grid2>
                                                    </Grid2>
                                                    <Divider sx={{ my: 1 }} />
                                                    <Grid2 container spacing={2}>
                                                        <Grid2
                                                            size={{ xs: 4, md: 4 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold">休憩時間</Typography>
                                                        </Grid2>
                                                        <Grid2
                                                            size={{ xs: 8, md: 8 }}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box>
                                                                {Array.isArray(shift?.breakTime)
                                                                    ? shift.breakTime.map((breakTime, index) => (
                                                                          <Typography key={index}>
                                                                              {breakTime.startHour +
                                                                                  "時" +
                                                                                  breakTime.startMinute +
                                                                                  "分 〜 " +
                                                                                  breakTime.endHour +
                                                                                  "時" +
                                                                                  breakTime.endMinute +
                                                                                  "分"}
                                                                          </Typography>
                                                                      ))
                                                                    : ""}
                                                            </Box>
                                                        </Grid2>
                                                    </Grid2>
                                                    <Divider sx={{ my: 1 }} />
                                                </React.Fragment>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>

                                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            color="primary"
                                            onClick={handleLocationEdit}
                                            sx={{ width: "400px" }}
                                        >
                                            事業所 / 工場を編集する
                                        </Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        <Grid2 container sx={{ mb: 3 }}>
                            <Grid2
                                size={{ xs: 6, md: 6 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography variant="h6">スタッフ情報</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 6, md: 6 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography textAlign="right" sx={{ mr: 2 }}>
                                    {staffData.length}人
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Box>

                    <Box sx={{ mb: 5 }}>
                        <MaterialReactTable table={table} />
                    </Box>

                    <Box sx={{ mb: 10 }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            派遣先企業一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={workTimeOpen} onClose={handleWorkTimeClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleWorkTimeClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6">シフト一覧</Typography>

                    <MaterialReactTable table={workTimeTable} />

                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleWorkTimeAdd}
                        sx={{ width: "400px", my: 5 }}
                    >
                        シフトを追加する
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={handleWorkTimeClose}
                        sx={{ width: "400px" }}
                    >
                        閉じる
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={workTimeAddOpen} onClose={handleWorkTimeAddClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleWorkTimeAddClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography textAlign="center" variant="h6">
                            シフトを追加する
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <TextField
                            label="シフト名"
                            variant="outlined"
                            sx={{ width: "400px" }}
                            value={workTimeAddData.name}
                            onChange={handleWorkTimeAddNameChange}
                            onBlur={handleWorkTimeAddNameBlur}
                            error={workTimeAddError.name !== ""}
                        />
                        {workTimeAddError.name !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                {workTimeAddError.name}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography textAlign="left" sx={{ width: "400px" }}>
                            勤務時間
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeAddData.startHour}
                            onChange={handleWorkTimeAddStartHourChange}
                            onBlur={handleWorkTimeAddStartHourBlur}
                            error={workTimeAddError.startHour !== ""}
                        />
                        <Typography> ： </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeAddData.startMinute}
                            onChange={handleWorkTimeAddStartMinuteChange}
                            onBlur={handleWorkTimeAddStartMinuteBlur}
                            error={workTimeAddError.startMinute !== ""}
                        />
                        <Typography sx={{ mx: 1 }}> 〜 </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeAddData.endHour}
                            onChange={handleWorkTimeAddEndHourChange}
                            onBlur={handleWorkTimeAddEndHourBlur}
                            error={workTimeAddError.endHour !== ""}
                        />
                        <Typography> ： </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeAddData.endMinute}
                            onChange={handleWorkTimeAddEndMinuteChange}
                            onBlur={handleWorkTimeAddEndMinuteBlur}
                            error={workTimeAddError.endMinute !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {workTimeAddError.startHour !== "" ||
                        workTimeAddError.startMinute !== "" ||
                        workTimeAddError.endHour !== "" ||
                        workTimeAddError.endMinute !== "" ? (
                            <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                勤務時間に不備があります
                            </Alert>
                        ) : null}
                    </Box>

                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography textAlign="left" sx={{ width: "400px" }}>
                            休憩時間
                        </Typography>
                    </Box>
                    {addBreakTimeData.map((breakTime, i) => (
                        <Box key={i}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid2
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "400px",
                                    }}
                                >
                                    <Grid2 size={{ xs: 6, md: 6 }}>
                                        <Typography textAlign="left">{i + 1}.</Typography>
                                    </Grid2>
                                    <Grid2 size={{ xs: 6, md: 6 }} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton onClick={() => handleWorkTimeAddBreakTimeDelete(i)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.startHour}
                                    onChange={(e) => handleWorkTimeAddBreakTimeStartHourChange(e, i)}
                                    onBlur={() => handleWorkTimeAddBreakTimeStartHourBlur(i)}
                                    error={addBreakTimeError[i]?.startHour !== ""}
                                />
                                <Typography> ： </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.startMinute}
                                    onChange={(e) => handleWorkTimeAddBreakTimeStartMinuteChange(e, i)}
                                    onBlur={() => handleWorkTimeAddBreakTimeStartMinuteBlur(i)}
                                    error={addBreakTimeError[i]?.startMinute !== ""}
                                />
                                <Typography sx={{ mx: 1 }}> 〜 </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.endHour}
                                    onChange={(e) => handleWorkTimeAddBreakTimeEndHourChange(e, i)}
                                    onBlur={() => handleWorkTimeAddBreakTimeEndHourBlur(i)}
                                    error={addBreakTimeError[i]?.endHour !== ""}
                                />
                                <Typography> ： </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.endMinute}
                                    onChange={(e) => handleWorkTimeAddBreakTimeEndMinuteChange(e, i)}
                                    onBlur={() => handleWorkTimeAddBreakTimeEndMinuteBlur(i)}
                                    error={addBreakTimeError[i]?.endMinute !== ""}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {addBreakTimeError[i]?.startHour !== "" ||
                                addBreakTimeError[i]?.startMinute !== "" ||
                                addBreakTimeError[i]?.endHour !== "" ||
                                addBreakTimeError[i]?.endMinute !== "" ? (
                                    <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                        休憩時間に不備があります
                                    </Alert>
                                ) : null}
                            </Box>
                        </Box>
                    ))}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeAddBreakTimeAdd}
                            sx={{ width: "200px", mt: 3 }}
                            disabled={addBreakTimeData.length >= 4}
                        >
                            休憩時間を追加する
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            mt: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mb: 1 }}>
                                {validation}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeAddComplete}
                            sx={{ width: "400px" }}
                        >
                            シフトを登録する
                        </Button>

                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeAddClose}
                            sx={{ width: "400px", mt: 5 }}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={workTimeAddCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        シフトの登録が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleWorkTimeAddCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={workTimeEditOpen} onClose={handleWorkTimeEditClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleWorkTimeEditClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography textAlign="center" variant="h6">
                            シフトを編集する
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <TextField
                            label="シフト名"
                            variant="outlined"
                            sx={{ width: "400px" }}
                            value={workTimeEditData.name}
                            onChange={handleWorkTimeEditNameChange}
                            onBlur={handleWorkTimeEditNameBlur}
                            error={workTimeEditError.name !== ""}
                        />
                        {workTimeEditError.name !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                {workTimeEditError.name}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography textAlign="left" sx={{ width: "400px" }}>
                            勤務時間
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeEditData.startHour}
                            onChange={handleWorkTimeEditStartHourChange}
                            onBlur={handleWorkTimeEditStartHourBlur}
                            error={workTimeEditError.startHour !== ""}
                        />
                        <Typography> ： </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeEditData.startMinute}
                            onChange={handleWorkTimeEditStartMinuteChange}
                            onBlur={handleWorkTimeEditStartMinuteBlur}
                            error={workTimeEditError.startMinute !== ""}
                        />
                        <Typography sx={{ mx: 1 }}> 〜 </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeEditData.endHour}
                            onChange={handleWorkTimeEditEndHourChange}
                            onBlur={handleWorkTimeEditEndHourBlur}
                            error={workTimeEditError.endHour !== ""}
                        />
                        <Typography> ： </Typography>
                        <TextField
                            variant="outlined"
                            sx={{ width: "85px" }}
                            value={workTimeEditData.endMinute}
                            onChange={handleWorkTimeEditEndMinuteChange}
                            onBlur={handleWorkTimeEditEndMinuteBlur}
                            error={workTimeEditError.endMinute !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {workTimeEditError.startHour !== "" ||
                        workTimeEditError.startMinute !== "" ||
                        workTimeEditError.endHour !== "" ||
                        workTimeEditError.endMinute !== "" ? (
                            <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                勤務時間に不備があります
                            </Alert>
                        ) : null}
                    </Box>

                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography textAlign="left" sx={{ width: "400px" }}>
                            休憩時間
                        </Typography>
                    </Box>
                    {editBreakTimeData.map((breakTime, i) => (
                        <Box key={i}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid2
                                    container
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "400px",
                                    }}
                                >
                                    <Grid2 size={{ xs: 6, md: 6 }}>
                                        <Typography textAlign="left">{i + 1}.</Typography>
                                    </Grid2>
                                    <Grid2 size={{ xs: 6, md: 6 }} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton onClick={() => handleWorkTimeEditBreakTimeDelete(i)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Grid2>
                                </Grid2>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.startHour}
                                    onChange={(e) => handleWorkTimeEditBreakTimeStartHourChange(e, i)}
                                    onBlur={() => handleWorkTimeEditBreakTimeStartHourBlur(i)}
                                    error={editBreakTimeError[i]?.startHour !== ""}
                                />
                                <Typography> ： </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.startMinute}
                                    onChange={(e) => handleWorkTimeEditBreakTimeStartMinuteChange(e, i)}
                                    onBlur={() => handleWorkTimeEditBreakTimeStartMinuteBlur(i)}
                                    error={editBreakTimeError[i]?.startMinute !== ""}
                                />
                                <Typography sx={{ mx: 1 }}> 〜 </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.endHour}
                                    onChange={(e) => handleWorkTimeEditBreakTimeEndHourChange(e, i)}
                                    onBlur={() => handleWorkTimeEditBreakTimeEndHourBlur(i)}
                                    error={editBreakTimeError[i]?.endHour !== ""}
                                />
                                <Typography> ： </Typography>
                                <TextField
                                    variant="outlined"
                                    sx={{ width: "85px" }}
                                    value={breakTime.endMinute}
                                    onChange={(e) => handleWorkTimeEditBreakTimeEndMinuteChange(e, i)}
                                    onBlur={() => handleWorkTimeEditBreakTimeEndMinuteBlur(i)}
                                    error={editBreakTimeError[i]?.endMinute !== ""}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {editBreakTimeError[i]?.startHour !== "" ||
                                editBreakTimeError[i]?.startMinute !== "" ||
                                editBreakTimeError[i]?.endHour !== "" ||
                                editBreakTimeError[i]?.endMinute !== "" ? (
                                    <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                                        休憩時間に不備があります
                                    </Alert>
                                ) : null}
                            </Box>
                        </Box>
                    ))}

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeEditBreakTimeAdd}
                            sx={{ width: "200px", mt: 3 }}
                            disabled={editBreakTimeData.length >= 4}
                        >
                            休憩時間を追加する
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            mt: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {editValidation !== "" && (
                            <Alert severity="error" sx={{ width: "400px", mb: 1 }}>
                                {editValidation}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeEditComplete}
                            sx={{ width: "400px" }}
                        >
                            シフトを更新する
                        </Button>

                        <Button size="large" color="error" onClick={handleWorkTimeDelete} sx={{ mt: 5 }}>
                            シフトを削除する
                        </Button>

                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={handleWorkTimeEditClose}
                            sx={{ width: "400px", mt: 5 }}
                        >
                            閉じる
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={workTimeEditCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        シフトの編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleWorkTimeEditCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={workTimeDeleteOpen} onClose={handleWorkTimeDeleteClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleWorkTimeDeleteClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        {workTimeEditData.name}を削除します。よろしいですか？
                    </DialogContentText>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "140px" }}
                            onClick={handleWorkTimeDeleteComplete}
                        >
                            削除する
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "140px", ml: 5 }}
                            onClick={handleWorkTimeDeleteClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={workTimeDeleteCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        シフトの削除が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ width: 400, mt: 5 }}
                        onClick={handleWorkTimeDeleteCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DispatchDetail;
