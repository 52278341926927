import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Divider,
    TextField,
    Alert,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogContentText,
    Grid2,
    InputLabel,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const UserAdd = ({ val }) => {
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        userName: { title: "ユーザー名", value: "" },
        userPhone: { title: "電話番号", value: "" },
        userEmail: { title: "メールアドレス", value: "" },
        userDivision: { title: "部署", value: "" },
        userPosition: { title: "役職", value: "" },
        userCharge: { title: "担当業務", value: [] },
        userAuthority: { title: "権限", value: "管理者" },
        userBetween: { title: "グループ会社間移動", value: false },
    });

    const [userDataError, setUserDataError] = useState({
        userName: "",
        userPhone: "",
        userEmail: "",
        userDivision: "",
        userPosition: "",
        userAuthority: "",
    });

    const [validation, setValidation] = useState("");

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const [userPositionData, setUserPositionData] = useState(["一般", "係長", "課長", "部長"]);

    const [chargeData, setChargeData] = useState([{ id: 1, label: "派遣元責任者" }]);

    const handleUserNameChange = (e) => {
        setUserData({ ...userData, userName: { ...userData.userName, value: e.target.value } });
    };
    const handleUserNameBlur = () => {
        const value = userData.userName.value.replace(/　/g, " ").trim();
        setUserData({ ...userData, userName: { ...userData.userName, value: value } });
        setUserDataError({
            ...userDataError,
            userName: userData.userName.value === "" ? "ユーザー名を入力してください" : "",
        });
    };

    const handleUserPhoneChange = (e) => {
        const value = e.target.value.replace(/[^0-9-]/g, "");
        setUserData({ ...userData, userPhone: { ...userData.userPhone, value: value } });
    };
    const handleUserPhoneBlur = () => {
        setUserDataError({
            ...userDataError,
            userPhone: userData.userPhone.value === "" ? "電話番号を入力してください" : "",
        });
    };

    const handleUserEmailChange = (e) => {
        setUserData({ ...userData, userEmail: { ...userData.userEmail, value: e.target.value } });
    };
    const handleUserEmailBlur = () => {
        const emailRegex =
            /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (userData.userEmail.value === "") {
            setUserDataError({
                ...userDataError,
                userEmail: "メールアドレスを入力してください",
            });
        } else if (!emailRegex.test(userData.userEmail.value)) {
            setUserDataError({
                ...userDataError,
                userEmail: "メールアドレスの形式が正しくありません",
            });
        } else {
            setUserDataError({
                ...userDataError,
                userEmail: "",
            });
        }
    };

    const handleUserDivisionChange = (e) => {
        setUserData({ ...userData, userDivision: { ...userData.userDivision, value: e.target.value } });
        setUserDataError({
            ...userDataError,
            userDivision: e.target.value === "" ? "部署を選択してください" : "",
        });
    };

    const handleUserPositionChange = (e) => {
        setUserData({ ...userData, userPosition: { ...userData.userPosition, value: e.target.value } });
        setUserDataError({
            ...userDataError,
            userPosition: e.target.value === "" ? "役職を選択してください" : "",
        });
    };

    const handleUserChargeChange = (e) => {
        const { value } = e.target;
        setUserData((prevState) => {
            const isChargePresent = prevState.userCharge.value.includes(value);
            const newChargeValue =
                value === "派遣元責任者"
                    ? isChargePresent
                        ? prevState.userCharge.value.filter((charge) => charge !== value)
                        : [value, ...prevState.userCharge.value]
                    : isChargePresent
                    ? prevState.userCharge.value.filter((charge) => charge !== value)
                    : [...prevState.userCharge.value, value];

            return {
                ...prevState,
                userCharge: {
                    ...prevState.userCharge,
                    value: newChargeValue,
                },
            };
        });
    };

    const handleUserAuthorityChange = (e) => {
        setUserData({ ...userData, userAuthority: { ...userData.userAuthority, value: e.target.value } });
    };

    const handleUserBetweenChange = (e) => {
        setUserData({ ...userData, userBetween: { ...userData.userBetween, value: e.target.checked } });
    };

    const handleAddConfirm = () => {
        userDataError.userName = userData.userName.value === "" ? "ユーザー名を入力してください" : "";
        userDataError.userPhone = userData.userPhone.value === "" ? "電話番号を入力してください" : "";
        userDataError.userEmail = userData.userEmail.value === "" ? "メールアドレスを入力してください" : "";
        userDataError.userDivision = userData.userDivision.value === "" ? "部署を選択してください" : "";
        userDataError.userPosition = userData.userPosition.value === "" ? "役職を入力してください" : "";

        if (
            userDataError.userName === "" &&
            userDataError.userPhone === "" &&
            userDataError.userEmail === "" &&
            userDataError.userDivision === "" &&
            userDataError.userPosition === ""
        ) {
            setValidation("");
            setOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/company");
    };

    const handleAddRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleEditComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/company");
    };

    return (
        <>
            <Helmet>
                <title>ユーザーを追加 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">ユーザーを追加</Typography>
                    </Box>

                    <Box>
                        <TextField
                            variant="outlined"
                            label={userData.userName.title}
                            value={userData.userName.value}
                            sx={{ width: "600px" }}
                            onChange={handleUserNameChange}
                            onBlur={handleUserNameBlur}
                            error={userDataError.userName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {userDataError.userName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {userDataError.userName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label={userData.userPhone.title}
                            value={userData.userPhone.value}
                            sx={{ width: "600px" }}
                            onChange={handleUserPhoneChange}
                            onBlur={handleUserPhoneBlur}
                            helperText="数字とハイフンのみ入力してください"
                            error={userDataError.userPhone !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {userDataError.userPhone !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {userDataError.userPhone}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label={userData.userEmail.title}
                            value={userData.userEmail.value}
                            sx={{ width: "600px" }}
                            onChange={handleUserEmailChange}
                            onBlur={handleUserEmailBlur}
                            error={userDataError.userEmail !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {userDataError.userEmail !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {userDataError.userEmail}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <FormControl sx={{ width: "600px" }} error={userDataError.userDivision !== ""}>
                            <InputLabel>{userData.userDivision.title}</InputLabel>
                            <Select
                                label={userData.userDivision.title}
                                value={userData.userDivision.value}
                                onChange={handleUserDivisionChange}
                                sx={{ textAlign: "left" }}
                            >
                                {divisionData.map((division, index) => (
                                    <MenuItem key={index} value={division} sx={{ textAlign: "left" }}>
                                        {division}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {userDataError.userDivision !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {userDataError.userDivision}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <FormControl sx={{ width: "600px" }} error={userDataError.userPosition !== ""}>
                            <InputLabel>{userData.userPosition.title}</InputLabel>
                            <Select
                                label={userData.userPosition.title}
                                value={userData.userPosition.value}
                                onChange={handleUserPositionChange}
                                sx={{ textAlign: "left" }}
                            >
                                {userPositionData.map((position, index) => (
                                    <MenuItem key={index} value={position} sx={{ textAlign: "left" }}>
                                        {position}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {userDataError.userPosition !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {userDataError.userPosition}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>{userData.userCharge.title}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            {chargeData.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            checked={userData.userCharge.value.includes(item.label)}
                                            onChange={handleUserChargeChange}
                                            value={item.label}
                                        />
                                    }
                                    label={item.label}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        height: "56px",
                                        mr: 5,
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>
                            {userData.userAuthority.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControl>
                                <RadioGroup row defaultValue="管理者">
                                    <FormControlLabel
                                        value="管理者"
                                        control={<Radio />}
                                        label="管理者"
                                        onChange={handleUserAuthorityChange}
                                    />
                                    <FormControlLabel
                                        value="担当者"
                                        control={<Radio />}
                                        label="担当者"
                                        onChange={handleUserAuthorityChange}
                                        sx={{ ml: 7.8 }}
                                    />
                                    <FormControlLabel
                                        value="閲覧のみ"
                                        control={<Radio />}
                                        label="閲覧のみ"
                                        onChange={handleUserAuthorityChange}
                                        sx={{ ml: 7.8 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>{userData.userBetween.title}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={userData.userBetween.value} onChange={handleUserBetweenChange} />
                                }
                                label="有"
                                sx={{ display: "flex", justifyContent: "left", alignItems: "center", height: "56px" }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleAddConfirm}
                        >
                            入力内容を確認する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            会社情報へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        ユーザー情報の入力内容を確認
                    </Typography>

                    {Object.entries(userData).map(([key, data]) => (
                        <Box key={key}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 4, md: 4 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 8, md: 8 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <Typography>
                                        {data.title === "担当業務"
                                            ? data.value.length === 0
                                                ? "なし"
                                                : data.value.join(", ")
                                            : data.title === "グループ会社間移動"
                                            ? data.value
                                                ? "あり"
                                                : "なし"
                                            : data.value}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleAddRegister}
                            sx={{ width: "400px" }}
                        >
                            入力内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            入力画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        ユーザー情報の登録が完了しました。
                    </DialogContentText>
                    <Typography sx={{ textAlign: "left", mt: 3 }}>
                        入力したメールアドレスへ初期設定用のメールが届きますので、
                        <br />
                        初期設定を行ってください。
                    </Typography>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleEditComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UserAdd;
