import React, { useState } from "react";
import { Container, Typography, Box, Button, Grid2, Divider, Popper, Alert } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const ApplyMediaDetail = ({ val }) => {
    const navigate = useNavigate();

    const [applyMediaData, setApplyMediaData] = useState({
        mediaName: { title: "媒体名", type: "テキストフィールド (単行)", value: "YouTube" },
        mediaApplyName: { title: "氏名", type: "テキストフィールド (単行)", value: "" },
        mediaApplyFuri: { title: "フリガナ", type: "テキストフィールド (単行)", value: "" },
        mediaApplyCompany: {
            title: "住所",
            type: "テキストフィールド (単行)",
            post: "",
            prefecture: "",
            city: "",
            other: "",
            building: "",
        },
        mediaApplyBirthday: { title: "生年月日", type: "テキストフィールド (単行)", value: "" },
        mediaApplyGender: { title: "性別", type: "ラジオボタン", value: "" },
        mediaApplyPhone: { title: "電話番号", type: "テキストフィールド (単行)", value: "" },
        mediaApplyEmail: { title: "メールアドレス", type: "テキストフィールド (単行)", value: "" },
        mediaEmploymentNumber: { title: "就業状況", type: "ラジオボタン", value: "" },
        mediaAddition: { title: "追加入力項目", value: [] },
        mediaStatus: { title: "受付状況", type: "ラジオボタン", value: "受付中" },
        mediaUrl: { title: "応募フォームURL", value: "https://stg-hr-system.hr-ms.jp/apply/fqn83wvh55it" },
    });

    const [mediaAdditionData, setMediaAdditionData] = useState([
        { title: "本人確認書類", type: "ファイルアップロード", value: "" },
        { title: "履歴書", type: "ファイルアップロード", value: "" },
    ]);

    const handleEditMedia = () => {
        window.scrollTo(0, 0);
        navigate("/apply/media/detail/edit");
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleCopyUrl = (e, url) => {
        e.stopPropagation();
        navigator.clipboard.writeText(url);

        setAnchorEl(e.target);

        setTimeout(() => {
            setAnchorEl(null);
        }, 2000);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/apply/media");
    };

    return (
        <>
            <Helmet>
                <title>応募媒体詳細 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">応募媒体詳細</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleEditMedia}>
                                応募媒体を編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaName.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaName.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    <Grid2 container spacing={2} sx={{ mb: 1, mt: 3 }}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography>必須項目</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        ></Grid2>
                    </Grid2>

                    {Object.entries(applyMediaData)
                        .slice(1, -3)
                        .map(([key, data]) => (
                            <Box key={key} sx={{}}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">{data.title}</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        <Typography>{data.type}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))}

                    <Grid2 container spacing={2} sx={{ mb: 1, mt: 3 }}>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Typography>追加入力項目</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        ></Grid2>
                    </Grid2>

                    {mediaAdditionData.length === 0 ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography sx={{ width: "430px", textAlign: "left", fontWeight: "bold" }}>なし</Typography>
                        </Box>
                    ) : (
                        mediaAdditionData.map((data, index) => (
                            <Box key={index} sx={{}}>
                                <Grid2 container spacing={2}>
                                    <Grid2
                                        size={{ xs: 4, md: 4 }}
                                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography fontWeight="bold">{data.title}</Typography>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 8, md: 8 }}
                                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                    >
                                        <Typography>{data.type}</Typography>
                                    </Grid2>
                                </Grid2>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        ))
                    )}

                    <Box sx={{ mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaStatus.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaStatus.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Grid2 container spacing={2}>
                            <Grid2
                                size={{ xs: 4, md: 4 }}
                                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                            >
                                <Typography fontWeight="bold">{applyMediaData.mediaUrl.title}</Typography>
                            </Grid2>
                            <Grid2
                                size={{ xs: 8, md: 8 }}
                                sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <Typography>{applyMediaData.mediaUrl.value}</Typography>
                            </Grid2>
                        </Grid2>
                        <Divider sx={{ my: 1 }} />
                    </Box>

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleCopyUrl}
                            sx={{ width: "600px" }}
                        >
                            URLをコピーする
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "600px" }}
                        >
                            応募媒体一覧へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Popper open={open} anchorEl={anchorEl} placement="top">
                <Alert sx={{ mb: 2 }} severity="success">
                    URLをコピーしました
                </Alert>
            </Popper>
        </>
    );
};

export default ApplyMediaDetail;
